import i18n from 'i18next';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import { HOME } from 'constants/routings';
import { PATHNAME_REG_EXP } from 'utils/regexp';

export const localeCurrencies = { RU: 'RUB', BY: 'BYN', EN: 'USD' };
export const localeTags = { Russia: 'RU', Belarus: 'BY', Other: 'EN' };
export const languages = ['ru', 'en'];
export const ruLangCountries = [
  'Belarus',
  'Russia',
  'Ukraine',
  'Azerbaijan',
  'Armenia',
  'Kazakhstan',
  'Kyrgyzstan',
  'Moldova',
  'Tajikistan',
  'Uzbekistan',
  'Georgia',
  'Turkmenistan',
];

export const locales = ['ru-BY', 'ru-RU', 'ru-EN', 'en-BY', 'en-RU', 'en-EN'];

export const setSumsubLang = country =>
  localStorage.setItem(
    'sumsub_lang',
    ruLangCountries.includes(country) ? 'ru' : 'en'
  );

export const getPathRoute = path => {
  return `/:lang(${locales.join('|')})${path}`;
};

export const getPartner = location => {
  const query = parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return query.partner;
};

export const getPathname = location => {
  if (location.pathname) {
    return location.pathname !== HOME
      ? `${location.pathname.replace(PATHNAME_REG_EXP, '')}${location.search}${
          location.hash
        }`
      : `${HOME}${location.search}${location.hash}`;
  }
  return '';
};

export const getPathnameWithoutRouteProps = location => {
  if (location.pathname) {
    return location.pathname !== HOME
      ? `${location.pathname.replace(PATHNAME_REG_EXP, '')}`
      : HOME;
  }
  return '';
};

export const getClearPathname = location => {
  if (location.pathname) {
    return location.pathname !== HOME
      ? `${location.pathname.replace(PATHNAME_REG_EXP, '').replace(/\/+$/, '')}`
      : `${HOME}${location.search}${location.hash}`;
  }
  return '';
};

export const getLocaleFromPath = value => {
  return value ? value.substring(1, 6) : '';
};

export const getLanguage = value => {
  return value ? value.substring(0, 2) : '';
};

export const getLocale = value => {
  return value ? getLocaleFromPath(value).substring(3) : '';
};

export const getLocaleCurrency = country => {
  const locale =
    Object.keys(localeTags).find(value => country === value) || 'Other';
  return localeCurrencies[localeTags[locale]];
};

export const isAvailableLocale = value => {
  return value && locales.includes(getLocaleFromPath(value));
};

export const getLocaleTag = value => {
  const locale = Object.keys(localeTags).find(country => country === value);
  return locale ? localeTags[value] : 'EN';
};

export const getUserLanguage = () => {
  return localStorage.getItem('language') || i18n.language;
};

export const setUserLanguage = language => {
  const existedLanguage = languages.includes(language) ? language : 'ru';
  return localStorage.setItem('language', existedLanguage);
};

export const setUserLocale = locale => {
  const existedLocale = getLocaleTag(locale);
  return localStorage.setItem('locale', existedLocale);
};

export const getUserLocale = () => {
  return localStorage.getItem('locale') || 'EN';
};

export const getCurrentLocale = () => {
  return `${getUserLanguage()}-${getUserLocale()}`;
};

export const replaceLocalePath = () => {
  window.history.replaceState(
    null,
    document.title,
    `/${getCurrentLocale()}${getPathname(window.location)}`
  );
};

export const removeUrlParams = () =>
  window.history.replaceState(null, null, window.location.href?.split('?')[0]);

export const useQuery = () => new URLSearchParams(useLocation().search);
