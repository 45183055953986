import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FormGroup } from 'components/Verification/styled-ui';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import Error from 'ui-kit/Error';
import {
  Input,
  ButtonWrapper,
  Header,
  FormWrapper,
  LogoWrapper,
  InputWrapper,
} from './styled-ui';

const AttachCardNumberModal = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  isSubmitting,
  t,
  handleCloseAttachCardNumberModal,
  resetForm,
  onClickLogo,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <>
      <LogoWrapper onClick={onClickLogo}>
        <img src={themeContext.footerLogo} alt="Logo" />
      </LogoWrapper>
      <Header>{t('profile.cardActions.attachCardModalTitle')}</Header>
      <FormWrapper>
        <FormGroup>
          <InputWrapper>
            <Input
              type="text"
              id="cardNumber"
              placeholder={t('profile.cardActions.attachCardTitlePlaceholder')}
              maxLength="19"
              minLength="19"
              value={values.cardNumber
                .replace(/[^0-9]/g, '')
                .replace(/\s/g, '')
                .replace(/([0-9]{4})/g, '$1 ')
                .trim()}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={errors.cardNumber && touched.cardNumber}
            />
          </InputWrapper>

          {errors.cardNumber && touched.cardNumber && (
            <Error>{errors.cardNumber}</Error>
          )}
        </FormGroup>
        <ButtonWrapper>
          <Button type="submit" disabled={isSubmitting}>
            {t('profile.cardActions.attachCard')}
          </Button>
          <CancelChangesButton
            type="button"
            onClick={() => handleCloseAttachCardNumberModal(resetForm)}
          >
            {t('profile.cardActions.attachCardCancel')}
          </CancelChangesButton>
        </ButtonWrapper>
      </FormWrapper>
    </>
  );
};
export default AttachCardNumberModal;
