import React, { useContext } from 'react';
import Select from 'react-select';
import { getIn } from 'formik';
import { ThemeContext } from 'styled-components';
import { Label } from 'ui-kit/DropdownInput/styled-ui';
import customStyle from './select-style';
import { WrapSelect } from './styled-ui';

const SelectText = ({
  field,
  form,
  label,
  setText,
  setFieldTouched,
  ...props
}) => {
  const { id, options } = props;

  const { name, value, ...fieldProps } = field;
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = !!error && isTouched;
  const themeContext = useContext(ThemeContext);

  const onChange = selectedOptions => {
    form.setFieldValue(field.name, selectedOptions.value);
    setText(selectedOptions.value);
  };

  return (
    <>
      <WrapSelect>
        <Label htmlFor={id}>{label}</Label>
        <Select
          {...props}
          {...fieldProps}
          value={options.find(it => it.value === value)}
          onChange={onChange}
          options={options}
          styles={customStyle(isError)}
          error={isError}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              borderColor: '1px solid red',
              primary: themeContext.linkColor,
              primary25: themeContext.selectHoverColor,
            },
          })}
        />
      </WrapSelect>
    </>
  );
};

export default SelectText;
