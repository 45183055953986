import styled from 'styled-components';
import device from 'device';

export const FaceLivenessWrapper = styled.div`
  height: 100%;
  face-liveness {
    backdrop-filter: blur(10px);
    background: none;
    display: flex;
    align-items: center;
  }
`;

export const LivenessStartScreen = styled.div`
  flex-basis: 360px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

export const LivenessTitle = styled.div`
  margin-top: 14px;
  margin-bottom: 34px;
  font-size: 25px;
  font-weight: 600;
  color: ${({ theme }) => theme.fullBlackText};
  line-height: 1;
  text-align: left;
`;

export const LivenessSubTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.blackText};
  line-height: 1.1;
  text-align: left;
`;

export const LivenessConditions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
  ul {
    margin: 20px 0px 20px;
    list-style-type: none;
    li {
      padding: 5px 0px;
    }
  }
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const Video = styled('video')`
  height: 220px;
`;
