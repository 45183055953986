import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Pause } from 'assets/images/icons/pause.svg';
import { ReactComponent as Play } from 'assets/images/icons/check-blue-small.svg';
import { CustomTr } from './styled-ui';

export default function CurrencyPairsTableRow({ buyPair, sellPair }) {
  return (
    <CustomTr>
      <td>{buyPair.label}</td>
      <td>{buyPair.enabled ? <Play /> : <Pause />}</td>
      <td>{sellPair.enabled ? <Play /> : <Pause />}</td>
    </CustomTr>
  );
}

CurrencyPairsTableRow.propTypes = {
  buyPair: PropTypes.shape({}).isRequired,
  sellPair: PropTypes.shape({}).isRequired,
};
