import React from 'react';
import { useTranslation } from 'react-i18next';
import { Description, PresentationGrid, Title } from './styled-ui';

const Presentation = () => {
  const { t } = useTranslation();

  return (
    <PresentationGrid>
      <Title>{t('calculationView.mainTitle')}</Title>
      <Description>{t('calculationView.mainTitleDesc')}</Description>
    </PresentationGrid>
  );
};

export default Presentation;
