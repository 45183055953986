import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 321px;
`;

export const ModalHeading = styled.div`
  margin-bottom: 21px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 500;
    font-size: 21px;
  }

  button {
    color: ${({ theme }) => theme.linkColor};
    margin: 0 !important;
    padding: 0;
    cursor: pointer;
  }
`;

export const CategoryLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const DatesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 14px 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 0;
`;

export const Input = styled.input`
  height: 38px;
  border: 1px solid ${({ error }) => (error ? '#f00' : '#dedede')};
  border-radius: 8px;
  margin: 10px 0;
  padding: 5px 10px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #f00;
  margin: 0 auto 15px 3px;
  display: inline-flex;
`;

export const Splitter = styled.span`
  margin: 10px 5px auto 5px;
`;
