import React, { useState, useEffect } from 'react';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getUserPaymentOptionById } from 'utils/services/request/card';
import { ReactComponent as SettlementSvg } from 'assets/images/payments/settlement.svg';
import { BankAccountNumber, Title, Wrapper } from './styled-ui';

const BankAccountView = ({ paymentTokenId, provider, label }) => {
  const [bankAccount, setBankAccount] = useState(null);
  const bankAccountsInformation = useAxiosStateWithRefetch(
    getUserPaymentOptionById({ id: paymentTokenId, provider })
  );

  useEffect(() => {
    if (!bankAccountsInformation.fetching && bankAccountsInformation.loaded) {
      setBankAccount(bankAccountsInformation.data);
    }
  }, [bankAccountsInformation]);

  if (!bankAccount) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <Title>{label}</Title>
        <BankAccountNumber>
          <div>
            <SettlementSvg />
          </div>
          <span>{`${bankAccount.number}, ${bankAccount.brand}`}</span>
        </BankAccountNumber>
      </Wrapper>
    </>
  );
};

export default BankAccountView;
