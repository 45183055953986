import React from 'react';
import { useTranslation } from 'react-i18next';
import Cards from './cards';
import { CardFeaturesWrapper, HeaderText, GlobalWrapper } from './styled-ui';

const CardFeatures = () => {
  const { t } = useTranslation();
  return (
    <GlobalWrapper>
      <CardFeaturesWrapper>
        <HeaderText>{t('partners.headerFirstLine')}</HeaderText>
        <HeaderText additional>{t('partners.headerSecondLine')}</HeaderText>
      </CardFeaturesWrapper>
      <Cards />
    </GlobalWrapper>
  );
};

export default CardFeatures;
