import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { pushAnalytics } from './analytics';

function scrollToTop() {
  window.scrollTo(0, 0);
}

function HistoryListener({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      scrollToTop();
      pushAnalytics(action, location);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <>{children}</>;
}

export default withRouter(HistoryListener);
