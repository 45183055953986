import styled from 'styled-components';
import device from 'device';

export const ModalBodyWrapper = styled.div`
  max-width: 557px;
  margin: 0 auto;
`;

export const ModalLabel = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 125%;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.blackText};
  @media ${device.mobile920} {
    margin-top: 60px;
  }
`;

export const ModalDesc = styled.div`
  font-size: 0.9rem;
  line-height: 140%;
  margin-bottom: ${({ highlighted, lastChild }) =>
    highlighted || lastChild ? '35px' : '20px'};
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.blackText : theme.grayText};
  font-weight: ${({ highlighted }) => highlighted && '500'};
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  height: 58px;
  width: 100%;
  @media ${device.mobile920} {
    margin-top: 25px;
  }
`;

export const EmailValue = styled.span`
  color: ${({ theme }) => theme.blackText};
`;

export const PhoneCodeWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;
