import { useRef, useState, useEffect } from 'react';

const EVERY_SECOND = 1000;
const useTimer = settings => {
  const { expiryTimestamp, onExpire } = settings || {};

  const intervalRef = useRef();

  function isValidExpiryTimestamp(timestamp) {
    return new Date(timestamp).getTime() > 0;
  }

  function isValidOnExpire(onExpireFunction) {
    return onExpireFunction && typeof onExpireFunction === 'function';
  }

  let secondsS = 0;
  let minutesS = 0;
  let hoursS = 0;
  if (isValidExpiryTimestamp(expiryTimestamp) && !intervalRef.current) {
    const now = new Date().getTime();
    const distance = expiryTimestamp - now;
    hoursS = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutesS = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    secondsS = Math.floor((distance % (1000 * 60)) / 1000);
    if (!secondsS) {
      secondsS = 0;
    }
  }

  const [seconds, setSeconds] = useState(secondsS);
  const [minutes, setMinutes] = useState(minutesS);
  const [hours, setHours] = useState(hoursS);

  function reset() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  }

  function calculateExpiryDate() {
    const now = new Date().getTime();
    const distance = expiryTimestamp - now;
    const hoursE = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesE = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsE = Math.floor((distance % (1000 * 60)) / 1000);
    if (secondsE < 0) {
      reset();
      if (isValidOnExpire(onExpire)) {
        onExpire();
      }
    } else {
      setSeconds(secondsE);
      setMinutes(minutesE);
      setHours(hoursE);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function start() {
    if (isValidExpiryTimestamp(expiryTimestamp) && !intervalRef.current) {
      calculateExpiryDate();
      intervalRef.current = setInterval(
        () => calculateExpiryDate(),
        EVERY_SECOND
      );
    }
  }

  useEffect(() => {
    start();
    return reset;
  }, []);

  return { seconds, minutes, hours };
};

export default useTimer;
