import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { FAQ, SUPPORT, MEDIA } from 'constants/routings';
import { getPathname } from 'utils/location';
import {
  MainBlock,
  ButtonMailUs,
  ButtonMailUsText,
  ButtonsWrapper,
  LinksWrapper,
  LinkItem,
  IconWrapper,
  WriteUsIcon,
  SupportWrapper,
} from './styled-ui';

const links = [
  {
    id: 'faq',
    link: FAQ,
  },
  {
    id: 'media',
    link: MEDIA,
  },
  {
    id: 'support',
    link: SUPPORT,
  },
];

const Support = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <SupportWrapper>
      {getPathname(location).includes('support') ? null : (
        <div>
          <MainBlock>{t(`footerPreview.title`)}</MainBlock>
          <ButtonsWrapper>
            <ButtonMailUs onClick={() => history.push(SUPPORT)}>
              <IconWrapper>
                <WriteUsIcon />
              </IconWrapper>
              <ButtonMailUsText>{t(`footerPreview.mailUs`)}</ButtonMailUsText>
            </ButtonMailUs>
          </ButtonsWrapper>
        </div>
      )}
      <LinksWrapper>
        {links.map((it, index) => (
          <LinkItem key={it.id}>
            <Link to={it.link}>{t(`footerPreview.links.${index}.label`)}</Link>
          </LinkItem>
        ))}
      </LinksWrapper>
    </SupportWrapper>
  );
};

export default Support;
