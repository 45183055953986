import React, { useContext } from 'react';
import UserContext from 'utils/contexts/User';
import USER_ROLES from 'utils/constants/userRoles';
import { getUserRole } from 'utils/services/selectors/user';
import Merchant from 'components/Merchant';

const PersonalAccount = () => {
  const user = useContext(UserContext);
  const isMerchant = getUserRole(user, USER_ROLES.MERCHANT);

  return <>{isMerchant && <Merchant />}</>;
};

export default PersonalAccount;
