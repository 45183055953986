import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Button } from 'ui-kit/Button';
import CustomDateInput from 'ui-kit/CustomField/DateInput';
import {
  ModalBodyWrapper,
  ModalLabel,
  ButtonWrapper,
  DatesWrapper,
  Splitter,
} from './styled-ui';

const OperationReportModal = ({ onSubmitHandler }) => {
  const { t } = useTranslation();

  return (
    <ModalBodyWrapper>
      <ModalLabel>{t('profile.operationsTable.report')}</ModalLabel>
      <Formik
        initialValues={{
          start: new Date().toISOString(),
          end: new Date().toISOString(),
        }}
        onSubmit={onSubmitHandler}
      >
        {form => {
          const { values } = form;
          return (
            <>
              <Form>
                <DatesWrapper>
                  <CustomDateInput
                    field={{
                      name: 'start',
                      value: values.start,
                    }}
                    form={form}
                    type="date"
                    id="startDate"
                    maxWidth="200px"
                    pickerLeft="-19px"
                    maxDate={new Date()}
                  />
                  <Splitter>-</Splitter>
                  <CustomDateInput
                    field={{
                      name: 'end',
                      value: values.end,
                    }}
                    form={form}
                    type="date"
                    id="endDate"
                    maxWidth="200px"
                    pickerLeft="-19px"
                    maxDate={new Date()}
                  />
                </DatesWrapper>
                <ButtonWrapper>
                  <Button type="submit">
                    {t('profile.modalLabels.approveButton')}
                  </Button>
                </ButtonWrapper>
              </Form>
            </>
          );
        }}
      </Formik>
    </ModalBodyWrapper>
  );
};

export default OperationReportModal;
