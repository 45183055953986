import { pipe, pathOr, multiply } from 'ramda';

const getBlockchainFeeByValues = (plainRatio, cryptoFee) =>
  pipe(multiply(cryptoFee), n => Math.floor(n * 100) / 100)(plainRatio);

export const getBlockchainFeeValueCalculation = calculationRes => {
  const plainRatio = pathOr(null, ['rate', 'plainRatio'], calculationRes);
  const cryptoFee = pathOr(null, ['rate', 'cryptoFee'], calculationRes);

  return getBlockchainFeeByValues(plainRatio, cryptoFee);
};

export const getServiceFeeValueCalculation = calculationRes => {
  const serviceFee = pathOr(
    null,
    ['calculation', 'exchangeFeeAssetInFiat'],
    calculationRes
  );

  if (!serviceFee || serviceFee <= 0) {
    return null;
  }

  return serviceFee;
};

export const getBlockchainFeeValueOrder = order => {
  const plainRatio = pathOr(null, ['exchangeOperation', 'plainRatio'], order);
  const cryptoFee = pathOr(null, ['cryptoTransaction', 'fee'], order);
  return getBlockchainFeeByValues(plainRatio, cryptoFee);
};

export const getServiceFeeValueOrder = (order, currency) => {
  const serviceFee = pathOr(
    null,
    ['exchangeOperation', 'exchangeFeeAssetInFiat'],
    order
  );

  if (!serviceFee || serviceFee <= 0) {
    return null;
  }

  return `${serviceFee} ${currency}`;
};
