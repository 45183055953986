import styled from 'styled-components';
import device from 'device';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  min-height: 22px;
  margin-bottom: 3px;
  @media ${device.tablet} {
    align-items: unset;
    flex-direction: column;
    gap: 2px;
  }
`;

export const InputField = styled.div`
  padding: 4px 10px;
  color: ${({ theme }) => theme.dimgray};
  flex: 1;
  min-height: inherit;
  background-color: ${({ theme }) => theme.grayBg};
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
