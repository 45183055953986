import React, { useState, useEffect } from 'react';
import Portal from 'ui-kit/Modal';
import { ModalWrapper, ModalCard, Close } from './styled-ui';

const RenderComponent = ({ Component, ...props }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  if (!mounted) {
    return <div />;
  }
  return <Component {...props} />;
};

const ApproveModal = ({ Component, onClose, ...props }) => {
  useEffect(() => {
    const close = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);
  return (
    <Portal>
      <ModalWrapper noRoute>
        <ModalCard>
          {!props.initialValues?.email?.length > 0 && (
            <Close onClick={onClose} alt="close" />
          )}
          <RenderComponent
            Component={Component}
            hideModal={onClose}
            {...props}
          />
        </ModalCard>
      </ModalWrapper>
    </Portal>
  );
};

export default ApproveModal;
