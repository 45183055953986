import { ReactComponent as Delete } from 'assets/images/cards/delete.svg';
import { ReactComponent as Drop } from 'assets/images/icons/drop.svg';
import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Label = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 34px 1fr 26px;
  grid-column-gap: 10px;
  align-items: center;
`;

export const CardInfoBlock = styled(CardInfo)`
  padding: 21px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  span {
    color: ${props => props.theme.linkColor};
    font-size: 14px;
  }

  background: ${props => (props.active ? '#f1f3f5' : 'none')};
`;

export const CardErrorBlock = styled(CardInfo)`
  padding: 21px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  span {
    color: red;
    font-size: 14px;
  }

  background: ${props => (props.active ? '#f1f3f5' : 'none')};
`;

export const ImageType = styled.div`
  display: flex;
`;

export const CardNumber = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.001em;
  align-items: center;
`;

export const CardNumberItem = styled(CardNumber)`
  font-size: 16px;
  line-height: 19px;
`;

export const LoadingPlaceholder = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  height: ${props => props.options.height};
  width: ${props => props.options.width};
  /* position: relative; */
  border-radius: 5px;
  position: ${props => props.options.position || 'absolute'};

  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const LoadingPlaceholderQr = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  height: ${props => props.options.height};
  width: ${props => props.options.width};
  /* position: relative; */
  border-radius: 5px;
  margin: 25px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const AddButton = styled.button`
  color: ${props => props.theme.linkColor};
  font-size: 14px;
  padding-left: 5px;
  font-weight: 300;
  cursor: pointer;
`;

export const CardInformationOverlay = styled.div`
  position: absolute;
  /* right: 0;
  top: 40px; */
  z-index: 1;
`;

export const CardInformationDetails = styled.div`
  height: auto;
  width: 340px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
  margin-top: 20px;
  box-sizing: border-box;
  display: grid;
  /* grid-gap: 10px; */
  grid-template-rows: 55px repeat(auto-fill, 55px);
`;

export const DeleteIcon = styled(Delete)`
  display: none;
`;

export const CardField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  justify-items: center;

  &:hover {
    ${DeleteIcon} {
      display: block;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    background: #f1f3f5;
  }
`;

export const DropIcon = styled(Drop)`
  transform: ${props => (props.open ? 'rotate(180deg)' : 'none')};
  opacity: 0.7;
`;
