import React, { useState } from 'react';
import axios from 'axios';
import getLabelByCurrency from 'utils/form/getLabelByCurrency';
import { getTransaction } from 'utils/services/request/transactions';
import {
  Title,
  Content,
  ButtonWrapper,
  Row,
  Value,
  Container,
  Text,
  UserName,
  ErrorInfo,
  SubmitButton,
  CancelButton,
} from './styled-ui';

const TransferConfirmModal = ({ queryParams, toHomePage, t, transaction }) => {
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const confirmTransaction = async submit => {
    try {
      setIsError(false);
      setIsFetching(true);
      await axios.post(getTransaction, {
        requestId: queryParams?.request_id,
        submit,
      });
      await setIsFetching(false);
      await toHomePage();
    } catch {
      setIsFetching(false);
      setIsError(true);
    }
  };

  return (
    <Container>
      <Title>{t('transfer.title')}</Title>
      <Row>
        <UserName>
          {t('transfer.userName', { name: transaction?.userName })}
        </UserName>
      </Row>
      <Content>
        <Row>
          <Text>{t('transfer.currency')}</Text>
          <Value>{getLabelByCurrency(transaction?.currency)}</Value>
        </Row>
        <Row>
          <Text>{t('transfer.inputAddress')}</Text>
          <Value>{transaction?.inputAddress}</Value>
        </Row>
        <Row>
          <Text>{t('transfer.outputAddress')}</Text>
          <Value>{transaction?.outputAddress}</Value>
        </Row>
        <Row>
          <Text>{t('transfer.total')}</Text>
          <Value>{transaction?.asset}</Value>
        </Row>
      </Content>
      {isError && <ErrorInfo>{t('transfer.error.failed')}</ErrorInfo>}
      <ButtonWrapper isError={isError}>
        <CancelButton
          secondary
          disabled={isFetching}
          type="button"
          onClick={() => confirmTransaction(false)}
        >
          {t('transfer.cancel')}
        </CancelButton>
        <SubmitButton
          disabled={isFetching}
          type="button"
          onClick={() => confirmTransaction(true)}
        >
          {t('transfer.confirm')}
        </SubmitButton>
      </ButtonWrapper>
    </Container>
  );
};

export default TransferConfirmModal;
