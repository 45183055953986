import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

const validationPhoneScheme = t =>
  Yup.object().shape({
    phone: Yup.string()
      .required(t('profile.requiredField'))
      .test('phone-validation', t('profile.phoneValidation'), function(value) {
        try {
          const countryCode = this.resolve(Yup.ref('countryCode'));
          const phoneUtil = PhoneNumberUtil.getInstance();
          const isValid = phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(value, countryCode),
            countryCode
          );
          return isValid;
        } catch (e) {
          return false;
        }
      }),
  });

export default validationPhoneScheme;
