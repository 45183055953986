import React from 'react';
import moment from 'moment';
import Pagination from 'components/Auth/UserProfile/Pagination';
import { Loader } from 'components/Auth/UserProfile/styled-ui';
import { NotFound, StyledTable, TBody, TD, TH, THead } from './styled.ui';

const Table = ({
  t,
  tableData,
  totalPages,
  totalRecords,
  onPageChange,
  isLoading,
  isWithdrawal,
}) =>
  isLoading ? (
    <Loader />
  ) : (
    <>
      {tableData?.length > 0 ? (
        <StyledTable>
          <THead>
            <tr>
              {!isWithdrawal && (
                <>
                  <TH>{t('profile.referralProgram.table.email')}</TH>
                  <TH>{t('profile.referralProgram.table.userId')}</TH>
                </>
              )}
              <TH>{t('profile.referralProgram.table.orderNumber')}</TH>
              <TH>{t('profile.referralProgram.table.transactionDate')}</TH>
              <TH>{t('profile.referralProgram.table.amount')}</TH>
            </tr>
          </THead>
          <TBody>
            {tableData.map(
              ({
                details,
                userId,
                externalTxId,
                createdTimestamp,
                amount,
                currency,
              }) => (
                <tr key={userId}>
                  {!isWithdrawal && (
                    <>
                      <TD>{details?.refereeEmail}</TD>
                      <TD>{userId}</TD>
                    </>
                  )}
                  <TD>{externalTxId}</TD>
                  <TD>
                    {moment.utc(createdTimestamp).format('DD.MM.YYYY HH:mm:ss')}
                  </TD>
                  <TD>{`${amount} ${currency}`}</TD>
                </tr>
              )
            )}
          </TBody>
          <Pagination
            totalPages={totalPages}
            totalRecords={totalRecords}
            onPageChanged={onPageChange}
          />
        </StyledTable>
      ) : (
        <NotFound>{t('profile.referralProgram.table.notFound')}</NotFound>
      )}
    </>
  );

export default Table;
