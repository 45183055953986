import styled from 'styled-components';
import device from '../../../device';

export const Wrapper = styled.div`
  padding: 20px 0 30px 45px;
  position: relative;
  @media ${device.tablet} {
    box-sizing: border-box;
  }
`;

export const SelectOption = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 17px;
  }
  display: flex;
  align-items: center;
`;

export const SelectOptionText = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 19px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 17px;
  }
`;
