import styled from 'styled-components';
import device from 'device';

export const WrapperDropdownInput = styled.div`
  padding: 10px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LabelDropdownInput = styled.label`
  color: ${({ labelInside, theme }) =>
    labelInside ? theme.blueBg : theme.blackText};
  font-family: 'Inter';
  font-size: 14px;
  font-weight: ${({ labelInside }) => (labelInside ? 'normal' : 'bold')};
  padding: ${({ labelInside }) => (labelInside ? '0 14px 0 7px' : 0)};
  background-color: #fff;

  position: ${({ labelInside }) => (labelInside ? 'absolute' : 'inherit')};
  margin-left: ${({ labelInside }) => (labelInside ? '13px' : 'inherit')};
`;

export const Label = styled.label`
  color: ${props => props.theme.linkColor};
  font-size: 0.7rem;
  line-height: 19px;
  @media ${device.mobile920} {
    font-size: 12px;
    line-height: 15px;
    top: -9px;
    left: 9px;
  }
  position: absolute;
  top: -11px;
  left: 11px;
  background-color: #fff;
  padding: 0 5px;
  z-index: 1;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin: 10px 0;
`;

export const SelectWrapper = styled.div`
  display: block;
  width: 100%;
  padding-left: ${({ labelInside }) => (labelInside ? '10px' : 'inherit')};
`;
