export const userStatuses = {
  PENDING: 'PENDING',
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
  FROZEN: 'FROZEN',
};

const statuses = {
  NOT_VERIFIED: 'Не верифицирован',
  PENDING: 'Верифицируется',
  VERIFIED: 'Верифицирован',
  FROZEN: 'Заморожен',
};

export default statuses;
