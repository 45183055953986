import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import Spinner from 'ui-kit/Spinner';
import DocumentCard from 'components/DocumentCard';
import MediaTypeSwitcher from 'components/Blog/MediaTypeSwitcher';
import { MediaLoader } from 'components/Blog/styled-ui';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getCategoriesDocuments } from 'utils/services/request/documents';
import { DocumentsWrapper, PageWrapper } from './styled-ui';

const DocumentsPage = () => {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState(null);
  const queryAllPosts = qs.stringify(
    {
      locale: ['ru'],
      sort: ['Order:asc'],
      populate: {
        documents: {
          sort: ['Published:desc'],
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const documentCategories = useAxiosState(
    getCategoriesDocuments(queryAllPosts)
  );

  const selectedCategory = documentCategories.data?.data.find(
    category => category.id === categoryId
  );

  useEffect(() => {
    if (!selectedCategory) {
      setCategoryId(documentCategories.data?.data[0].id);
    }
  }, [documentCategories, selectedCategory]);

  const handleSelectCategory = id => {
    setCategoryId(id);
  };

  return (
    <PageWrapper>
      {!documentCategories.data && !documentCategories.error ? (
        <MediaLoader>
          <Spinner color="#FFFFFF" />
        </MediaLoader>
      ) : (
        <>
          {categoryId && (
            <MediaTypeSwitcher
              value={categoryId}
              categories={documentCategories}
              handleSelectCategory={handleSelectCategory}
            />
          )}
        </>
      )}

      <DocumentsWrapper>
        {selectedCategory?.attributes?.documents?.data?.map(item => (
          <DocumentCard t={t} data={item} key={item.attributes.Caption} />
        ))}
      </DocumentsWrapper>
    </PageWrapper>
  );
};

export default DocumentsPage;
