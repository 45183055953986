import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { parse } from 'query-string';
import ModalContext from 'utils/contexts/Modal';
import ClientContext from 'utils/contexts/Client';
import { useAxiosStateWithRefetch, useAxiosState } from 'utils/hooks/axiosHook';
import { DOCUMENTS } from 'utils/constants/supportMessageTopics';
import usePersistedState from 'utils/hooks/usePersistedState';
import useMobileSize from 'utils/hooks/useMobileSize';
import { SUPPORT } from 'constants/routings';
import {
  getUserAllPaymentOptions,
  getAllPaymentsComission,
} from 'utils/services/request/card';

import {
  PAYMENT_METHODS,
  CARD_PAYMENT_SERVICE,
  SETTLEMENT,
  CRYPTO_CARD,
  ERIP,
} from 'utils/constants/paymentMethods';
import { CARD_ATTACH_STATUS } from 'utils/constants/alfa';
import { getCardsCommissionByProvider } from 'utils/number';
import { DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import { pushAnalyticsEvent } from 'components/App/analytics';
import { Loader } from 'components/Auth/UserProfile/styled-ui';
import CardManagement from './CardManagement';
import SettlementManagement from './SettlementManagement';
import CryptoCardManagement from './CryptoCardManagement';
import EripManagement from './EripManagement';
import {
  PaymentListWrapper,
  PaymentWrapper,
  CardLogo,
  SettlementLogo,
  MainPaymentInfo,
  RecommendLabel,
  LinkText,
  CardLoadError,
  CryptoCardLogo,
  EripLogo,
  Title,
  Description,
  ReloadButton,
  TitleWrapper,
} from './styled-ui';

const renderLogo = type => {
  switch (type) {
    case CARD_PAYMENT_SERVICE:
      return <CardLogo />;
    case SETTLEMENT:
      return <SettlementLogo />;
    case CRYPTO_CARD:
      return <CryptoCardLogo />;
    case ERIP:
      return <EripLogo />;
    default:
      return <div />;
  }
};

const filterCardsByProvider = (cards, provider) => ({
  ...cards,
  data: cards?.data?.length
    ? cards.data.filter(({ providerType }) => providerType === provider)
    : [],
});

const filterCardsBySettlement = cards => ({
  ...cards,
  data: cards?.data?.length
    ? cards.data.filter(
        ({ providerType, crypto }) => providerType !== SETTLEMENT && !crypto
      )
    : [],
});

const filterCardsByCryptoCard = cards => ({
  ...cards,
  data: cards?.data?.length ? cards.data.filter(({ crypto }) => crypto) : [],
});

const renderTypeData = (
  t,
  method,
  allCardsInformation,
  getAllCardsCommission
) => {
  switch (method.type) {
    case CRYPTO_CARD:
      return (
        <CryptoCardManagement
          t={t}
          cardsInformation={filterCardsByCryptoCard(allCardsInformation)}
          attachCardRequest={method.attachCardRequest}
          getRemoveCardUrl={method.getRemoveCardUrl}
          getCardDetailsRequest={method.getCardDetailsRequest}
          commissionInformation={getCardsCommissionByProvider(
            getAllCardsCommission,
            method.type
          )}
        />
      );
    case CARD_PAYMENT_SERVICE:
      return (
        <CardManagement
          t={t}
          cardsInformation={filterCardsBySettlement(allCardsInformation)}
          cards={method.paymentServices}
          commissionInformation={getAllCardsCommission}
        />
      );
    case ERIP:
      return (
        <EripManagement
          t={t}
          commissionInformation={getCardsCommissionByProvider(
            getAllCardsCommission,
            method.type
          )}
        />
      );
    case SETTLEMENT:
      return (
        <SettlementManagement
          t={t}
          cardsInformation={filterCardsByProvider(
            allCardsInformation,
            method.id
          )}
          getRemoveCardUrl={method.getRemoveCardUrl}
          commissionInformation={getCardsCommissionByProvider(
            getAllCardsCommission,
            method.type
          )}
        />
      );
    default:
      return null;
  }
};

const PaymentMethodList = ({ t }) => {
  const { openModal } = useContext(ModalContext);
  const client = useContext(ClientContext);
  const isMobile = useMobileSize();

  const [filteredPayMethods, setFilteredPayMethods] = useState([]);
  const [allCardsInformation, setAllCardsInformation] = usePersistedState(
    'payments',
    []
  );
  const getAllCardsInformation = useAxiosStateWithRefetch(
    getUserAllPaymentOptions()
  );
  const getAllCardsCommission = useAxiosState(getAllPaymentsComission);
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const removeQueryString = () =>
    window.history.replaceState(
      null,
      null,
      window.location.href?.split('&')[0]
    );

  useEffect(() => {
    if (getAllCardsInformation.data) {
      setAllCardsInformation(getAllCardsInformation.data);
    }
  }, [getAllCardsInformation.data]);

  useEffect(() => {
    const filterPayMethods =
      client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY
        ? PAYMENT_METHODS
        : PAYMENT_METHODS.filter(method => method.type !== ERIP);
    setFilteredPayMethods(filterPayMethods);
  }, [allCardsInformation]);

  useEffect(() => {
    if (queryParams?.card_bind?.toUpperCase() === CARD_ATTACH_STATUS.FAILED) {
      openModal({
        isError: true,
        message: t('profile.cardActions.attachFailed'),
      });
    } else if (
      queryParams?.card_bind?.toUpperCase() === CARD_ATTACH_STATUS.SUCCESS
    ) {
      pushAnalyticsEvent('card_binding_success');
    }
    removeQueryString();
  }, [queryParams?.card_bind]);

  if (getAllCardsInformation.error) {
    return (
      <CardLoadError>
        {t(`calculationView.cardInput.errorCardResponse`)}
      </CardLoadError>
    );
  }

  if (!filteredPayMethods?.length) return <Loader />;

  return (
    <PaymentListWrapper>
      {filteredPayMethods.map(method => (
        <PaymentWrapper
          key={method.type}
          isRecommended={method.type === CRYPTO_CARD}
        >
          {!isMobile && renderLogo(method.type)}
          <MainPaymentInfo>
            <TitleWrapper>
              {isMobile && renderLogo(method.type)}
              <Title>
                <h4>{t(`profile.paymentMethods.${method.type}.title`)}</h4>
                <RecommendLabel isRecommended={method.type === CRYPTO_CARD}>
                  {t('profile.recommendText')}
                </RecommendLabel>
              </Title>
            </TitleWrapper>
            <Description>
              <Trans
                i18nKey={`profile.paymentMethods.${method.type}.description`}
                t={t}
                components={{
                  linkTag: (
                    <LinkText
                      to={{
                        pathname: SUPPORT,
                        search: `?topic=${DOCUMENTS}`,
                      }}
                    />
                  ),
                  button: (
                    <ReloadButton onClick={() => window.location.reload()} />
                  ),
                }}
              />
            </Description>
            {renderTypeData(
              t,
              method,
              getAllCardsInformation,
              getAllCardsCommission
            )}
          </MainPaymentInfo>
        </PaymentWrapper>
      ))}
    </PaymentListWrapper>
  );
};

export default PaymentMethodList;
