const customStyle = isError => {
  return {
    control: (base, state) => ({
      ...base,
      borderRadius: '5px',
      border: 'none',
      borderBottomRightRadius: state.menuIsOpen ? 0 : '5px',
      borderBottomLeftRadius: state.menuIsOpen ? 0 : '5px',
      cursor: 'pointer',
      boxShadow: 'none',
      minHeight: '42px',
      ':hover': {
        border: 0,
      },
    }),
    container: base => ({
      ...base,
      display: 'inline-block',
      width: '100%',
      border: isError ? '1px solid red' : 'none',
      ':focus': {
        outline: 0,
      },
    }),
    valueContainer: base => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: '14px',
      padding: 0,
      position: 'initial',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: '#A2B4CB',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
    }),
    menu: base => ({
      ...base,
      zIndex: 5,
      margin: 0,
      borderRadius: '8px',
      boxShadow: '0px 10px 30px rgba(16, 58, 121, 0.15)',
      boxSizing: 'unset',
      left: '-10px',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      borderRadius: '8px',
    }),
    indicatorsContainer: base => ({
      ...base,
      padding: 0,
      '> div': {
        padding: 0,
      },
    }),
    input: base => ({
      ...base,
      color: 'transparent',
      textShadow: '0 0 0 black',
    }),
    option: base => ({
      ...base,
      backgroundColor: '#fff',
      color: '#000',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        color: '#000',
      },
      ':hover': {
        backgroundColor: '#F6F6F6',
      },
      '> div > span': {
        color: '#000',
      },
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    singleValue: base => ({
      ...base,
      marginLeft: 0,
      marginRight: 0,
      transform: 'translateY(0)',
      position: 'initial',
      maxWidth: `calc(100% - 6px)`,
    }),
  };
};

export default customStyle;
