import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, HiddenInput, LabelCheckbox, LabelText } from './styled-ui';

const CustomCheckbox = ({
  field,
  form,
  label,
  alignItems,
  isMain,
  ...props
}) => {
  const { id } = props;
  const { name } = field;
  const error = getIn(form.errors, name);

  const isTouched = getIn(form.touched, name);
  const isChecked = getIn(form.values, name);

  const isError = isTouched && !!error;
  const onChange = event => {
    form.setFieldValue(field.name, isChecked);
    field.onChange(event);
  };

  return (
    <>
      <LabelCheckbox
        error={isError}
        htmlFor={id}
        className="label-check option"
        alignItems={alignItems}
      >
        <HiddenInput
          type="checkbox"
          className="label-check__input"
          {...field}
          {...props}
          checked={isChecked}
          onChange={onChange}
          isMain={isMain}
        />
        <Checkbox isMain={isMain} className="label-check__new-input" />
        <LabelText isMain={isMain}>{label}</LabelText>
      </LabelCheckbox>
    </>
  );
};

CustomCheckbox.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

CustomCheckbox.defaultProps = {
  disabled: false,
};

export default CustomCheckbox;
