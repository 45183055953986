import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, Field, getIn } from 'formik';
import { useAxiosState } from 'utils/hooks/axiosHook';
import CustomRadio from 'ui-kit/CustomField/Radio';
import Loader from 'components/Loader';
import { FullRow, Question, QuizTitle } from './styled-ui';

const QuizTitleMessage = ({ name, title, formik }) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  return (
    <QuizTitle error={error && (touch || touch === undefined)}>
      {title}
    </QuizTitle>
  );
};

QuizTitleMessage.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape({}).isRequired,
};

const QuizTitleComponent = connect(QuizTitleMessage);

const Quiz = ({ setQuestionsLoaded, t }) => {
  const questions = useAxiosState({
    method: 'get',
    url: `${process.env.REACT_APP_KYC_URL}/verification-test`,
  });

  useEffect(() => {
    if (questions.loaded && !questions.error) {
      if (setQuestionsLoaded) setQuestionsLoaded(true);
      const quizAnswers = questions.data.questions.map(question => {
        let answer;
        question.answers.forEach((item, index) => {
          if (item.correct) {
            answer = `answer${index}`;
          }
        });
        return answer;
      });
      localStorage.setItem('quiz', JSON.stringify(quizAnswers));
    }
  }, [questions, setQuestionsLoaded]);

  if (!questions.loaded || questions.fetching) {
    return (
      <FullRow>
        <Loader />
      </FullRow>
    );
  }

  if (questions.error) {
    return <FullRow>{t('verification.quizLoadError')}</FullRow>;
  }

  return (
    <>
      <FullRow>
        {questions.data.questions.map((item, index) => {
          return (
            <Question key={item.id}>
              <QuizTitleComponent
                name={`questions.question${index}`}
                title={item.title}
              />
              {item.answers.map((answer, key) => {
                return (
                  <Field
                    key={key}
                    id={`opt${key}${item.id}`}
                    name={`questions.question${index}`}
                    component={CustomRadio}
                    value={`answer${key}`}
                    type="radio"
                    label={answer.title}
                  />
                );
              })}
            </Question>
          );
        })}
      </FullRow>
    </>
  );
};

export default Quiz;
