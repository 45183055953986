import styled from 'styled-components';
import device from 'device';
import { ReactComponent as Instagram } from 'assets/images/icons/instagram.svg';
import { ReactComponent as Tiktok } from 'assets/images/icons/tiktok.svg';
import { ReactComponent as Facebook } from 'assets/images/icons/facebook.svg';
import { ReactComponent as Youtube } from 'assets/images/icons/youtube.svg';
import { ReactComponent as Viber } from 'assets/images/icons/viber.svg';
import { ReactComponent as XCom } from 'assets/images/icons/xcom.svg';
import { ReactComponent as Telegram } from 'assets/images/icons/telegram.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: unset;
  @media ${device.mobileL} {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const Link = styled.a`
  margin-right: 34px;
  transition: all 0.3s;
  path:first-child {
    transition: all 0.3s;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    transform: scale(1.7);
    path:first-child {
      fill-opacity: 0.7;
    }
  }
  @media ${device.tablet} {
    margin-right: 24px;
  }
  @media ${device.mobileL} {
    margin: 0px;
  }
`;

export const InstagramIcon = styled(Instagram)``;
export const TiktokIcon = styled(Tiktok)``;
export const FacebookIcon = styled(Facebook)``;
export const YoutubeIcon = styled(Youtube)``;
export const ViberIcon = styled(Viber)``;
export const XComIcon = styled(XCom)``;
export const TelegramIcon = styled(Telegram)``;
