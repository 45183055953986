import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import { Button } from 'ui-kit/Button';
import { VERIFICATIONS } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import { isUserConfirmedEmail } from 'utils/services/selectors/user';
import { changePhoneNotifications } from 'utils/services/request/auth';
import { DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import { userStatuses } from 'utils/constants/statutes';
import { REGULA_MODES } from 'utils/constants/regula';
import Activate2FA from 'components/Auth/2FA';
import Decline2FA from 'components/Auth/UserProfile/ApproveModal/Decline2Fa';
import SettingsSwitcher from 'components/Auth/UserProfile/SettingsSwitcher';
import ErrorModal from 'components/ErrorModal';
import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import ChangePhone from './ChangePhone';
import { SettingsWrapper, Block, SettingsHeader } from './styled-ui';

const Settings = ({ t }) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const isConfirmedEmail = isUserConfirmedEmail(user);
  const clientStatus = client?.data?.status;
  const isClientDataError =
    !client?.data?.countryCode && clientStatus === 'VERIFIED';
  const isBalanceEnabled = client?.data?.internalBalanceEnabled;
  const isUserNotHavePhone =
    !user?.data?.phone &&
    client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY &&
    clientStatus === 'VERIFIED';

  const changeDocsHandler = () =>
    history.push(
      clientStatus === userStatuses.NOT_VERIFIED
        ? VERIFICATIONS
        : `${VERIFICATIONS}?mode=${REGULA_MODES.changeDocuments}`
    );

  const changeRegHandler = () =>
    history.push(`${VERIFICATIONS}?mode=${REGULA_MODES.changeRegistration}`);

  return (
    <SettingsWrapper isError={isClientDataError || !isNil(client?.error)}>
      {!isClientDataError && isNil(client?.error) ? (
        <>
          <Block isInputBlock>
            <SettingsHeader>{t('profile.settings.privateData')}</SettingsHeader>
            <ChangeEmail
              t={t}
              initialMail={user?.data?.email}
              isConfirmedEmail={isConfirmedEmail}
            />
            <ChangePhone
              t={t}
              initialPhone={user?.data?.phone}
              user={user}
              isUserNotHavePhone={isUserNotHavePhone}
              isConfirmedEmail={isConfirmedEmail}
            />
          </Block>
          {isConfirmedEmail && (
            <Block>
              <SettingsHeader>
                {t('profile.settings.accountLogin')}
              </SettingsHeader>
              <SettingsSwitcher
                label={t('profile.inputLabels.twoFa')}
                checkedValue={user?.data?.mfaEnabled}
                OnActiveModal={props => (
                  <Activate2FA t={t} userUpdate={user.refetch} {...props} />
                )}
                OnDisabledModal={props => (
                  <Decline2FA t={t} userUpdate={user.refetch} {...props} />
                )}
              />
            </Block>
          )}
          <Block>
            <SettingsHeader>
              {t('profile.settings.notifications')}
            </SettingsHeader>
            <SettingsSwitcher
              checkedValue
              label={t('profile.inputLabels.emailNotification')}
              disabled
            />
            {client?.data?.countryCode === DIGITAL_COUNTRY_CODES.BY &&
              clientStatus === 'VERIFIED' && (
                <SettingsSwitcher
                  checkedValue={user?.data?.phoneNotificationsEnabled}
                  label={t('profile.inputLabels.phoneNotification')}
                  disabled={!user?.data?.phone}
                  onChangeRequest={changePhoneNotifications}
                  isPhone
                  user={user}
                />
              )}
          </Block>
          <Block>
            <SettingsHeader>
              {t('profile.settings.changePassword')}
            </SettingsHeader>
            <ChangePassword t={t} />
          </Block>
          {isConfirmedEmail && isBalanceEnabled && (
            <>
              <Block>
                <Button padding="10px" width="100%" onClick={changeDocsHandler}>
                  {clientStatus === userStatuses.NOT_VERIFIED
                    ? t('profile.buttons.goVerify')
                    : t('profile.buttons.goChangeDocs')}
                </Button>
              </Block>
              {clientStatus !== userStatuses.NOT_VERIFIED && (
                <Block>
                  <Button
                    padding="10px"
                    width="100%"
                    onClick={changeRegHandler}
                  >
                    {t('profile.buttons.goChangeReg')}
                  </Button>
                </Block>
              )}
            </>
          )}
        </>
      ) : (
        <ErrorModal
          title={
            isClientDataError
              ? t('errorModal.titleClientError')
              : t('errorModal.title')
          }
          isReloadButton={!isNil(client?.error)}
        />
      )}
    </SettingsWrapper>
  );
};

export default Settings;
