import device from 'device';

const customStyle = isError => {
  return {
    control: base => ({
      ...base,
      borderRadius: '8px',
      cursor: 'pointer',
      height: '46px',
      border: '1px solid #f6f6f6 !important',
      boxShadow: '0 0 0 0',
      padding: '0 10px',
      backgroundColor: '#f6f6f6', // get from theme (blueTheme)
    }),
    container: base => ({
      ...base,
      display: 'inline-block',
      width: '100%',
      border: isError ? '1px solid red' : 'none',
      ':focus': {
        outline: 0,
      },
    }),
    valueContainer: base => ({
      ...base,
      fontSize: '14px',
      height: '38px',
      padding: 0,
      '> div > div': {
        padding: 0,
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: '#A2B4CB',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
    }),
    singleValue: provided => {
      return {
        ...provided,
        color: 'black',
        '> div > svg:first-of-type': { width: 'auto', minWidth: 'fit-content' },
      };
    },
    menu: base => ({
      ...base,
      zIndex: 2,
      margin: 0,
      border: 0,
      top: 0,
      boxShadow: '0px 10px 30px rgba(16, 58, 121, 0.15)',
      borderRadius: '8px',
      overflow: 'hidden',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      border: 0,
      color: '#0F2F56',
      fontSize: '14px',
      borderRadius: '8px',
      width: '100%',
      paddingRight: '50px',
      boxSizing: 'content-box',
      overflowX: 'hidden',
    }),
    indicatorsContainer: base => ({
      ...base,
      padding: 0,
      '> div': {
        padding: 0,
      },
    }),
    option: (base, state) => ({
      ...base,
      lineHeight: '30px',
      fontSize: '14px',
      width: 'auto',
      backgroundColor: '#fff',
      margin: '0 30px',
      padding: 0,
      color: '#000',
      cursor: 'pointer',
      borderBottom: '1px solid #EBEBEB',
      ':active': {
        ...base[':active'],
      },
      ':last-of-type': {
        backgroundColor: '#fff',
        borderBottom: 'none',
        height: '46px',
        display: 'flex',
        alignItems: 'center',
      },
      '> div > svg:last-child': {
        zoom: 1.25,
        display: !state.isSelected && 'none',
      },
      '@media (max-width: 1150px)': {
        margin: '0 20px',
      },
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    group: base => ({
      ...base,
      padding: 0,
      width: '500px',
      '@media (max-width: 1150px)': {
        width: 'auto',
      },
      '> div:first-of-type': {
        margin: '0 30px',
        padding: '5px 20px',
        color: '#929ba8',
        borderBottom: '1px solid #EBEBEB',
        fontSize: '0.94rem',
        lineHeight: '35px',
        fontWeight: 400,
        textTransform: 'initial',
        '@media (max-width: 1150px)': {
          padding: '5px 10px',
          margin: '0 20px',
        },
        [`@media ${device.mobile920}`]: {
          padding: '5px 15px',
        },
      },
    }),
  };
};

export default customStyle;
