import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { isNil } from 'ramda';
import { parse } from 'query-string';
import { HOME, SIGN_IN } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import { getOAuth } from 'utils/services/request/auth';
import ErrorModal from 'components/ErrorModal';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OAuth = ({ history }) => {
  const user = useContext(UserContext);
  const [query, setQuery] = useState(null);
  const [isError, setIsError] = useState(false);
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (
      queryParams?.grant_type ||
      queryParams?.response_type ||
      queryParams?.client_id
    ) {
      setQuery(queryParams);
    } else {
      history.push(HOME);
    }
  }, [
    queryParams?.grant_type,
    queryParams?.response_type,
    queryParams?.client_id,
  ]);

  const getUrlOAuth = async () => {
    try {
      if (!isNil(query)) {
        let params = '';
        Object.keys(query).forEach(key => {
          if (query[key]) {
            params += `${key}=${query[key]}&`;
          }
          return params;
        });
        const response = await axios.get(getOAuth(params.slice(0, -1)));
        await window.location.replace(response.data);
      }
    } catch {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (user.loaded && !isNil(query)) {
      if (user.data) {
        getUrlOAuth();
      } else {
        history.push({
          pathname: SIGN_IN,
          search: window.location.search,
        });
      }
    }
  }, [query, user.data, user.loaded]);

  return <Wrapper>{isError && <ErrorModal />}</Wrapper>;
};

export default OAuth;
