import React, { useState } from 'react';
import MerchantOperationTableRow from 'components/MerchantOperationTableRow';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import {
  getOrdersHistory,
  exportReport,
} from 'utils/services/request/merchant';
import axios from 'axios';
import { reportDownloadANSI } from 'utils/fileDownload';
import { ReactComponent as IconReport } from 'assets/images/icons/Report.svg';
import { ReactComponent as IconFilter } from 'assets/images/icons/Filter.svg';
import { ReactComponent as IconSearch } from 'assets/images/icons/Search.svg';
import { DateTime } from 'luxon';
import { FilterButton } from 'components/ButtonPanel/styled-ui';
import { ReactComponent as Close } from 'assets/images/icons/close.svg';
import FiltersModal from './FiltersModal';
import {
  StyledTable,
  TableBody,
  PageButton,
  TableHead,
  TransactionStatusHead,
  TableRow,
  LoadingTd,
  FilterItem,
  SearchInput,
  SearchInputWrapper,
} from '../MerchantBalancesTable/styled-ui';
import {
  ClientIdHeading,
  CreationDateHeading,
  DirectionHeading,
  FirstLevelTr,
  InputHeading,
  PairHeading,
  SecondLevelTh,
  PaginationContainer,
  ControlWrapper,
  FiltersContainer,
} from './styled-ui';
import ButtonPanel from '../ButtonPanel';

const MerchantOperationTable = ({ t, merchantData }) => {
  const initialFilters = {
    creationDateFrom: null,
    creationDateTo: null,
    completionDateFrom: null,
    completionDateTo: null,
    types: [],
    providerTypes: [],
    statuses: [],
    fiatStatuses: [],
    cryptoStatuses: [],
    fiatCurrencies: [],
    cryptoCurrencies: [],
    clientId: null,
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);
  const [stateClientId, setStateClientId] = useState('');
  const [filters, setFilter] = useState(initialFilters);
  const formData = {
    creationDateFrame: {
      start: filters.creationDateFrom,
      end: filters.creationDateTo,
    },
    completionDateFrame: {
      start: filters.completionDateFrom,
      end: filters.completionDateTo,
    },
    merchantId: merchantData.id,
    clientId: filters.clientId,
    types: filters.types,
    providerTypes: filters.providerTypes,
    statuses: filters.statuses,
    fiatStatuses: filters.fiatStatuses,
    cryptoStatuses: filters.cryptoStatuses,
    fiatCurrencies: filters.fiatCurrencies,
    cryptoCurrencies: filters.cryptoCurrencies,
  };
  const { data: ordersData, refetch, fetching } = useAxiosStateWithRefetch(
    getOrdersHistory({ page: currentPage, filter: formData })
  );
  const totalPagesArr = [...Array(ordersData?.totalPages).keys()];

  const handleSetFilter = props => {
    setCurrentPage(0);
    setFilter(props);
  };
  const handleResetFilters = () => {
    handleSetFilter(initialFilters);
    refetch();
  };
  const handleRemoveFilter = filterName => {
    handleSetFilter({ ...filters, [filterName]: initialFilters[filterName] });
    refetch();
  };
  const handleExportReport = () => {
    setReportLoading(true);
    axios(exportReport(formData)).then(response => {
      reportDownloadANSI(response, 'text/csv;charset=windows-1251');
      setReportLoading(false);
    });
  };
  const isoStringToHumanDate = isoString =>
    DateTime.fromISO(isoString, { zone: 'utc' }).toFormat('dd.MM.yyyy');
  const getFilterString = (value, filter) =>
    (filter === 'clientId' && value) ||
    (filter === 'creationDateFrom' && isoStringToHumanDate(value)) ||
    (filter === 'creationDateTo' && isoStringToHumanDate(value)) ||
    (filter === 'completionDateFrom' && isoStringToHumanDate(value)) ||
    (filter === 'completionDateTo' && isoStringToHumanDate(value)) ||
    JSON.stringify(
      value?.map(item => t(`merchantPage.filterItems.${filter}.${item}`))
    )?.replace(/["\][]/g, '');

  return (
    <>
      <ControlWrapper>
        <ButtonPanel
          label={t('merchantPage.addFilter')}
          extraNumber={0}
          Component={props => (
            <FiltersModal
              filters={filters}
              setFilter={handleSetFilter}
              resetFilters={handleResetFilters}
              refetch={refetch}
              t={t}
              {...props}
            />
          )}
          icon={<IconFilter />}
          overflow
        />
        <FilterButton
          type="button"
          onClick={handleExportReport}
          disabled={reportLoading}
        >
          <IconReport />
          <div>{t('merchantPage.report')}</div>
        </FilterButton>
        <SearchInputWrapper>
          <SearchInput
            type="text"
            name="clientId"
            placeholder={t('merchantPage.search')}
            onChange={({ target }) => {
              setStateClientId(target.value);
            }}
            value={stateClientId}
          />
          <button
            type="button"
            onClick={() => {
              handleSetFilter({ ...filters, clientId: stateClientId || null });
              refetch();
            }}
          >
            <IconSearch />
          </button>
        </SearchInputWrapper>
      </ControlWrapper>
      <FiltersContainer disabled={fetching}>
        {Object.keys(filters).map(currentFilter => {
          const currentValue = filters[currentFilter];
          return currentValue?.length ? (
            <FilterItem
              key={`filterBadge-${currentFilter}`}
              disabled={fetching}
            >
              <span title={getFilterString(currentValue, currentFilter)}>
                {t(`filters.${currentFilter}`)}:{' '}
                {getFilterString(currentValue, currentFilter)}
              </span>
              <Close onClick={() => handleRemoveFilter(currentFilter)} />
            </FilterItem>
          ) : null;
        })}
      </FiltersContainer>
      <StyledTable>
        <thead>
          <FirstLevelTr>
            <TableHead>{t('merchantPage.number')}</TableHead>
            <CreationDateHeading>
              {t('merchantPage.creationDate')}
            </CreationDateHeading>
            <ClientIdHeading>{t('merchantPage.clientId')}</ClientIdHeading>
            <PairHeading>{t('merchantPage.pair')}</PairHeading>
            <InputHeading>{t('merchantPage.inputAsset')}</InputHeading>
            <InputHeading>{t('merchantPage.outputAsset')}</InputHeading>
            <DirectionHeading>{t('merchantPage.direction')}</DirectionHeading>
            <TransactionStatusHead colSpan="2">
              <span>{t('merchantPage.transactionStatus')}</span>
            </TransactionStatusHead>
            <TableHead>{t('merchantPage.operationStatus')}</TableHead>
          </FirstLevelTr>
          <tr>
            {[...Array(7).keys()].map(item => (
              <th key={`th-${item}`}>&nbsp;</th>
            ))}
            <SecondLevelTh>
              <span>{t('merchantPage.fiatTransactionStatus')}</span>
            </SecondLevelTh>
            <SecondLevelTh>
              <span>{t('merchantPage.cryptoTransactionStatus')}</span>
            </SecondLevelTh>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <TableBody>
          {!fetching
            ? ordersData?.content.map(({ order }) => (
                <MerchantOperationTableRow key={order.id} order={order} t={t} />
              )) || null
            : [...Array(9).keys()].map(recordNumber => (
                <TableRow key={`operationsTr-${recordNumber}`}>
                  {[...Array(10).keys()].map(dataNumber => (
                    <LoadingTd key={`operationsTd-${dataNumber}`} />
                  ))}
                </TableRow>
              ))}
        </TableBody>
        <tfoot>
          <tr>
            <td colSpan={10}>
              <PaginationContainer>
                <div>
                  {ordersData
                    ? totalPagesArr.map(item => (
                        <PageButton
                          key={`page-${item}`}
                          onClick={() => {
                            setCurrentPage(item);
                            refetch();
                          }}
                          type="button"
                          isCurrent={item === currentPage}
                        >
                          {item + 1}
                        </PageButton>
                      ))
                    : null}
                </div>
              </PaginationContainer>
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
};

export default MerchantOperationTable;
