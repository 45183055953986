export const DEPOSIT = 'DEPOSIT';
export const WITHDRAWAL = 'WITHDRAWAL';
export const DEFAULT = 'DEFAULT';
export const WT = 'WT';

export const orderTypes = {
  DEPOSIT,
  WITHDRAWAL,
  DEFAULT,
  WT,
};
