/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { isEmpty } from 'ramda';
import {
  updateAxiosDefault,
  axiosResponseInterceptorError,
} from '../axiosUtil';

axios.interceptors.response.use(
  response => response,
  axiosResponseInterceptorError(axios)
);

export const axiosEffect = (options, dispatch) => () => {
  const source = axios.CancelToken.source();
  if (
    options.method === 'get' ||
    ((options.method === 'post' || options.method === 'put') &&
      (options.data || options.data === undefined))
  ) {
    dispatch({
      loaded: false,
      fetching: true,
      data: null,
      error: null,
    });
    axios({
      ...options,
      cancelToken: source.token,
    })
      .then(response => {
        const { data } = response;
        if (data) {
          dispatch({
            loaded: true,
            fetching: false,
            data,
            error: null,
          });
        } else {
          dispatch({
            loaded: true,
            fetching: false,
            data: null,
            error: 'Not found',
          });
        }
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          dispatch({
            loaded: true,
            fetching: false,
            data: null,
            error: e.response?.data || 'Error',
          });
        }
      });
  }

  return () => {
    source.cancel('Operation canceled by the user.');
  };
};
const initialState = {
  loaded: false,
  fetching: false,
  data: null,
  error: null,
};

export const useAxiosState = (axiosProps, depenences = []) => {
  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );
  useEffect(axiosEffect(axiosProps, dispatch), depenences);
  return state;
};

export const useAxiosStateWithRefetch = axiosProps => {
  const [innerTrigger, setInnerTrigger] = useState(0);

  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );

  useEffect(axiosEffect(axiosProps, dispatch), [innerTrigger]);
  const refetch = useCallback(() => setInnerTrigger(Date.now()), []);
  return {
    ...state,
    refetch,
  };
};

export const useAxiosStateWithRefetchWithData = axiosProps => {
  const [axiosPropsState, setAxiosPropsState] = useState(axiosProps);
  const [innerTrigger, setInnerTrigger] = useState(0);

  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );

  useEffect(axiosEffect(axiosPropsState, dispatch), [innerTrigger]);
  const refetch = useCallback(updData => {
    setAxiosPropsState({
      ...axiosPropsState,
      data: { ...axiosPropsState.data, ...updData },
    });
    setInnerTrigger(Date.now());
  }, []);
  return {
    ...state,
    refetch,
  };
};

export const useAxiosStateWithRefetchNoFirstCall = axiosProps => {
  const [innerTrigger, setInnerTrigger] = useState(0);

  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    initialState
  );

  useEffect(() => {
    if (innerTrigger !== 0) {
      axiosEffect(axiosProps, dispatch)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTrigger]);

  const refetch = useCallback(() => setInnerTrigger(Date.now()), []);
  return {
    ...state,
    refetch,
    fetch: refetch,
  };
};

export const useConditionAxiosStateWithRefetch = axiosProps => {
  const [innerTrigger, setInnerTrigger] = useState(0);
  const [state, dispatch] = useReducer(
    (oldState, newState) => {
      if (oldState.loaded && newState.fetching) {
        return {
          ...newState,
          fetching: false,
          refetching: true,
        };
      }
      return { ...oldState, ...newState, refetch: false };
    },
    {
      ...initialState,
      refetching: false,
    }
  );

  const condition = updateAxiosDefault();

  const axEff = useCallback(axiosEffect(axiosProps, dispatch), [axiosProps]);

  useEffect(() => {
    if (!isEmpty(axiosProps)) {
      if (condition) {
        axEff();
      } else {
        dispatch({
          loaded: true,
          fetching: false,
          data: null,
          error: 'Not found',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTrigger, condition, axiosProps]);

  const refetch = useCallback(() => setInnerTrigger(Date.now()), []);

  return useMemo(
    () => ({
      ...state,
      refetch,
    }),
    [state, refetch]
  );
};

export const useAxiosSubmitingEffect = axiosProps => {
  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    {
      loaded: false,
      fetching: false,
      data: null,
      error: null,
    }
  );

  const [form, setFormAndSubmit] = useState(null);
  const options = {
    ...axiosProps,
    data: form?.values ? form.values : null,
  };

  useEffect(axiosEffect(options, dispatch), [form]);

  const resetError = () => {
    dispatch({ error: null });
  };

  return useMemo(
    () => ({
      ...state,
      form,
      setFormAndSubmit,
      resetError,
    }),
    [state, form, setFormAndSubmit]
  );
};

export const useAxiosSubmitingEffectWithHeaders = axiosProps => {
  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    {
      loaded: false,
      fetching: false,
      data: null,
      error: null,
    }
  );

  const [form, setFormAndSubmit] = useState(null);

  const options = {
    ...axiosProps,
    data: form?.values || null,
    ...(form?.headers && { headers: form.headers }),
  };

  useEffect(axiosEffect(options, dispatch), [form]);
  return useMemo(
    () => ({
      ...state,
      form,
      setFormAndSubmit,
    }),
    [state, form, setFormAndSubmit]
  );
};

export const useAxiosGetParamsSubmitingEffect = axiosProps => {
  const [state, dispatch] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    {
      loaded: false,
      fetching: false,
      data: null,
      error: null,
    }
  );
  const [form, setFormAndSubmit] = useState(null);
  const options = {
    ...axiosProps,
    params: form?.params || null,
  };

  useEffect(() => {
    if (form?.params) {
      axiosEffect(options, dispatch)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return useMemo(
    () => ({
      ...state,
      form,
      setFormAndSubmit,
    }),
    [state, form, setFormAndSubmit]
  );
};

export const useNotifications = () => {
  const initialSt = {
    isLoading: false,
    notifications: [],
    page: null,
    error: null,
    totalPages: null,
  };

  const searchReducer = (currentState, action) => {
    if (action.type === 'FETCH_NOTIFICATIONS') {
      return {
        ...currentState,
        isLoading: true,
        page: action.page,
      };
    }
    if (action.type === 'FETCH_NOTIFICATIONS_SUCCESS') {
      return {
        ...currentState,
        isLoading: false,
        notifications: [
          ...currentState.notifications,
          ...action.data.notifications,
        ],
        totalPages: action.data.totalPages,
        error: null,
      };
    }
    if (action.type === 'FETCH_NOTIFICATIONS_ERROR') {
      return {
        ...currentState,
        isLoading: false,
        error: 'Что-то пошло не так',
      };
    }
    if (action.type === 'REMOVE_NOTIFICATION_BY_ID') {
      const newNotifications = currentState.notifications.filter(
        o => o.id !== action.idToRemove
      );
      return {
        ...currentState,
        notifications: newNotifications,
      };
    }
    if (action.type === 'REMOVE_ALL_NOTIFICATIONS') {
      return {
        ...currentState,
        notifications: [],
      };
    }
    if (action.type === 'ADD_NOTIFICATION_BY_SOCKET') {
      return {
        ...currentState,
        notifications: [action.data, ...currentState.notifications],
      };
    }
    return currentState;
  };

  const [state, dispatch] = useReducer(searchReducer, initialSt);

  return {
    notificationState: state,
    dispatchNotification: dispatch,
  };
};
