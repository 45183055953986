import PropTypes from 'prop-types';
import React, { useState } from 'react';
import axios from 'axios';
import Switch from 'react-switch';
import ApproveModal from 'components/Auth/UserProfile/ApproveModal';

import { SwitchWrapper, SwitchLabel } from './styled-ui';

const SettingsSwitcher = ({
  label,
  checkedValue,
  OnActiveModal,
  OnDisabledModal,
  disabled,
  onChangeRequest,
  isPhone,
  user,
}) => {
  const [activePositiveModal, setActivePositiveModal] = useState(false);
  const [activeNegativeModal, setActiveNegativeModal] = useState(false);
  const [localSwitcherValue, setLocalSwitcherValue] = useState(checkedValue);

  const onChange = () => {
    if (OnActiveModal || OnDisabledModal) {
      if (checkedValue) {
        setActiveNegativeModal(true);
      } else {
        setActivePositiveModal(true);
      }
    } else if (onChangeRequest) {
      const tempValue = localSwitcherValue;
      setLocalSwitcherValue(!tempValue);
      axios(onChangeRequest({ enabled: !tempValue }))
        .then(() => {
          if (isPhone && user) {
            // eslint-disable-next-line no-param-reassign
            user.data.phoneNotificationsEnabled = !tempValue;
          }
        })
        .catch(() => {
          setLocalSwitcherValue(tempValue);
        });
    }
  };

  const onClosePositiveModal = () => setActivePositiveModal(false);
  const onCloseNegativeModal = () => setActiveNegativeModal(false);

  return (
    <SwitchWrapper>
      <SwitchLabel>{label}</SwitchLabel>
      <Switch
        checked={localSwitcherValue}
        onChange={onChange}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#0069ff"
        offColor="#c2c7cc"
        height={23}
        width={45}
        handleDiameter={17}
        disabled={disabled}
      />
      {activePositiveModal && (
        <ApproveModal
          Component={OnActiveModal}
          onClose={onClosePositiveModal}
        />
      )}
      {activeNegativeModal && (
        <ApproveModal
          Component={OnDisabledModal}
          onClose={onCloseNegativeModal}
        />
      )}
    </SwitchWrapper>
  );
};

SettingsSwitcher.defaultProps = {
  OnDisabledModal: null,
  OnActiveModal: null,
  disabled: false,
};

SettingsSwitcher.propTypes = {
  label: PropTypes.string.isRequired,
  checkedValue: PropTypes.bool.isRequired,
  OnActiveModal: PropTypes.func,
  OnDisabledModal: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SettingsSwitcher;
