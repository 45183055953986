import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { restorePasswordActivated } from 'utils/services/request/auth';
import { Button } from 'ui-kit/Button';
import { SIGN_IN } from 'constants/routings';
import RestorePasswordActivatedForm from 'components/Auth/RestorePassword/RestorePasswordActivated/form';
import { ButtonWrapper, Header, RestorePasswordLabel } from '../../styled-ui';

const RestorePassword = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { token } = parse(location.search);
  const [stepUpdatePassword, setStepUpdatePassword] = useState(1);
  const [isTwoFactorForm, setIsTwoFactorForm] = useState(false);

  const initData = {
    newPassword: '',
    repeatedPassword: '',
    token,
    mfaSecret: '',
  };

  const stateSubmitingLoginForm = useAxiosSubmitingEffect(
    restorePasswordActivated
  );

  const onSubmitHandler = useCallback(
    (values, actions) =>
      stateSubmitingLoginForm.setFormAndSubmit({
        values,
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      if (stateSubmitingLoginForm.error) {
        const { actions } = stateSubmitingLoginForm.form;
        if (stateSubmitingLoginForm.error.status === 'INVALID_CODE') {
          if (
            stateSubmitingLoginForm.error.message === 'Secret must not be empty'
          ) {
            if (isTwoFactorForm) {
              actions.setErrors({
                mfaSecret: t('passwordForms.errors.requiredField'),
              });
            } else {
              setIsTwoFactorForm(true);
            }
          }
          if (stateSubmitingLoginForm.error.message === 'Invalid code') {
            actions.setErrors({
              mfaSecret: t('passwordForms.errors.invalidCode'),
            });
          }
          if (stateSubmitingLoginForm.error.message === "Code doesn't exist") {
            actions.setErrors({
              custom: t('passwordForms.errors.failedLink'),
            });
          }
        }
        if (stateSubmitingLoginForm.error.status === 'EXPIRED_CODE') {
          actions.setErrors({
            custom: t('passwordForms.errors.codeExpire'),
          });
        }

        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmitingLoginForm.data.status === 'OK') {
        setStepUpdatePassword(2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm.loaded]);

  const getUpdatePassComponent = step => {
    switch (step) {
      case 1:
        return (
          <RestorePasswordActivatedForm
            initialValues={initData}
            onSubmitHandler={onSubmitHandler}
            isTwoFactorForm={isTwoFactorForm}
            t={t}
          />
        );
      case 2:
        return (
          <>
            <Header>{t('passwordForms.titleSuccesfulChange')}</Header>
            <RestorePasswordLabel>
              {t('passwordForms.restorePasswordLabelText')}
            </RestorePasswordLabel>
            <ButtonWrapper>
              <Button
                type="button"
                disabled={false}
                onClick={() => history.push(SIGN_IN)}
              >
                {t('passwordForms.accountLogin')}
              </Button>
            </ButtonWrapper>
          </>
        );
      default:
        return null;
    }
  };

  return <>{getUpdatePassComponent(stepUpdatePassword)}</>;
};

export default RestorePassword;
