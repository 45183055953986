import styled from 'styled-components';
import device from 'device';

export const FormGrid = styled.form`
  display: grid;
  grid-row-gap: 35px;
  grid-column: 1/-1;
  margin-top: 15px;
  @media ${device.laptop} {
    grid-template-columns: none;
  }
  @media ${device.mobile920} {
    margin-top: 25px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const WrapperInput = styled.div`
  margin-top: 20px;
`;

export const Header = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  button {
    &:first-child {
      margin-right: 25px;
    }
  }
`;
