export const getMerchantDetails = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current`,
};

export const getBalancesHistory = ({ page, filters }) => ({
  method: 'post',
  url: `${
    process.env.REACT_APP_EXCHANGE_URL
  }/merchant/current/balances/history/paged?page=${page ||
    0}&size=10&sort=creationDate,desc`,
  data: filters || {},
});

export const getOrdersHistory = ({ page, filter }) => ({
  method: 'post',
  url: `${
    process.env.REACT_APP_EXCHANGE_URL
  }/merchant/current/orders/paged?page=${page ||
    0}&size=10&sort=order.creationDate,desc`,
  data: filter || {},
});

export const getBlockerCurrency = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/blocker/currency`,
};

export const updateMerchantName = {
  method: 'put',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/showName`,
};

export const updateMerchantWebhookUrl = {
  method: 'put',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/webhookURL`,
};

export const updateMerchantUploadUrl = {
  method: 'put',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/sumsub/photos/external/uploadURL`,
};

export const updateMerchantValidationUrl = {
  method: 'put',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/validation`,
};

export const getMerchantWebhooks = page => ({
  method: 'post',
  url: `${
    process.env.REACT_APP_EXCHANGE_URL
  }/merchant/current/webhook/server/paged?page=${page ||
    0}&size=10&sort=creationDate,desc`,
  data: {},
});

export const resendMerchantWebhook = webhookId => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/webhook/server/${webhookId}/resend `,
  data: {},
});

export const regenerateMerchantApiKey = {
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/generate`,
  data: {},
};

export const exportReport = data => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/reports/merchant`,
  data,
});

export const getMerchantDebt = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/balances/dept`,
};

export const getMerchantPayments = page => ({
  method: 'post',
  url: `${
    process.env.REACT_APP_EXCHANGE_URL
  }/merchant/current/payment/paged?page=${page ||
    0}&size=10&sort=creationDate,desc`,
  data: {},
});

export const createMerchantPayment = ({ type, currency }) => ({
  method: 'post',
  url: `${
    process.env.REACT_APP_EXCHANGE_URL
  }/merchant/current/payment/${type?.toLowerCase()}?currency=${currency}`,
  data: {},
});

export const rejectMerchantPayment = {
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/payment/reject`,
  data: {},
};

export const getMerchantPaymentQr = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/merchant/current/payment/active/qr/address`,
};
