import styled from 'styled-components';
import device from 'device';
import { ReactComponent as QuestionDesc } from 'assets/images/icons/question-desc.svg';

export const DescriptionBlock = styled.div`
  display: none;
  z-index: 10;
  position: absolute;
  bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  font-size: 0.8rem;
  width: 180px;
  background-color: ${props => props.theme.descriptionBgColor};
  color: ${props => props.theme.fullWhite};
  @media (max-width: 1080px) {
    right: 0;
  }
  @media (max-width: 520px) {
    right: 0;
  }
`;

export const DescriptionInfoWrapper = styled.div`
  position: relative;
  display: inline-flex;
  &:hover ${DescriptionBlock} {
    display: block;
  }
`;

export const QuestionIcon = styled(QuestionDesc)`
  position: relative;
  align-self: center;
  font-size: 0.8rem;
  cursor: pointer;
  width: 19px;
  height: 19px;
  circle {
    fill: ${({ $iserror }) => ($iserror ? '#ffdedc' : '#ececec')};
  }
  path {
    fill: ${({ $iserror }) => ($iserror ? '#ef4a40' : '#555960')};
  }
  @media ${device.laptopXL} {
    width: 15px;
    height: 15px;
  }
`;
