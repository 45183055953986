import React from 'react';
import CardsCommissionInfo from '../CardsCommissionInfo';

const EripManagement = ({ t, commissionInformation }) => {
  return (
    <CardsCommissionInfo
      t={t}
      commissionInformation={commissionInformation}
      isErip
    />
  );
};

export default EripManagement;
