import styled from 'styled-components';
import device from 'device';

import { ReactComponent as CloseImg } from 'assets/images/icons/close-modal.svg';
import { ReactComponent as CheckEmailImg } from 'assets/images/icons/check-email.svg';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99999;
  position: fixed;
  background: rgba(0, 0, 0, 0.15);
  height: 100%;
  overflow-y: auto;
  @media ${device.mobile920} {
    background: #fff;
    padding: 0;
  }
`;

export const ModalCard = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  border-radius: 10px;
  width: 557px;
  padding: 50px;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  box-sizing: border-box;
  margin: auto;
  @media ${device.mobile920} {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    padding: 20px;
    margin: initial;
  }
`;

export const Close = styled(CloseImg)`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 10px;
  background: transparent;
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media ${device.laptop} {
    top: ${({ top }) => (top ? `${top}px` : '15px')};
    right: ${({ right }) => (right ? `${right}px` : '15px')};
  }

  @media ${device.mobileL} {
    top: ${({ top }) => (top ? `${top}px` : '10px')};
    right: ${({ right }) => (right ? `${right}px` : '10px')};
  }
`;

export const CheckEmailIcon = styled(CheckEmailImg)`
  margin-right: 10px;
  width: 19px;
  height: 17px;
`;

export const ModalBodyWrapper = styled.div`
  max-width: 557px;
  margin: 0 auto;
`;

export const CancelWrapper = styled.div`
  max-width: 557px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 125%;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.blackText};
  @media ${device.mobile920} {
    margin-top: 60px;
  }
`;

export const ModalLabel = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 125%;
  margin-bottom: 35px;
  color: ${({ theme }) => theme.blackText};
  @media ${device.mobile920} {
    margin-top: 60px;
  }
`;

export const ModalDesc = styled.div`
  font-size: 0.9rem;
  line-height: 140%;
  margin-bottom: ${({ highlighted, lastChild }) =>
    highlighted || lastChild ? '35px' : '20px'};
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.blackText : theme.grayText};
  font-weight: ${({ highlighted }) => highlighted && '500'};
`;

export const ButtonWrapper = styled.div`
  margin-top: 35px;
  height: 58px;
  width: 100%;
  @media ${device.mobile920} {
    margin-top: 25px;
  }
`;

export const EmailValue = styled.span`
  color: ${({ theme }) => theme.blackText};
`;

export const WrapperTimer = styled.div`
  margin-top: 25px;
  font-size: 0.9rem;
  text-align: center;
`;

export const ResendTimer = styled.div`
  color: ${({ theme }) => theme.grayText};
`;

export const ResendEmail = styled.button`
  color: ${props => props.theme.linkColor};
  font-family: 'Inter';
  cursor: pointer;
`;

export const PhoneCodeWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const TimerWrapper = styled.div`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 17px;
  }
  letter-spacing: 0;
`;
