import { ReactComponent as ErrorImage } from 'assets/images/icons/error.svg';
import styled from 'styled-components';
import device from 'device';

export const ErrorFormText = styled.div`
  font-size: 12px;
  color: #ff0200;
  box-sizing: border-box;
  @media ${device.tablet} {
    font-size: 10px;
  }
`;

export const ErrorIcon = styled(ErrorImage)`
  padding-right: 10px;
`;

export const WrapError = styled.div`
  padding: ${props => (props.isSupportPage ? '3px 0 8px 0' : '10px 0')};
  padding-bottom: ${props => props.password && '0'};
  display: flex;
  position: ${props => (props.isSupportPage ? 'absolute' : 'initial')};
  align-items: flex-end;
  top: ${props => props.top && `${props.top}px`};
  max-width: ${props => props.maxwidth || 'initial'};
  bottom: ${({ bottom }) => bottom && `${bottom}px`};
  @media ${device.laptop} {
    max-width: initial;
  }
`;
