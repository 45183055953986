import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterButton, FiltersPanel, FiltersWrapper } from './styled-ui';

const RenderComponent = ({ Component, ...props }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  if (!mounted) {
    return <div />;
  }
  return <Component {...props} />;
};

RenderComponent.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  hideModal: PropTypes.func.isRequired,
};

const ButtonPanel = ({ label, extraNumber, Component, icon, overflow }) => {
  const [showPanel, setShowPanel] = useState(false);
  const myRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setShowPanel(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <FiltersWrapper ref={myRef} onClick={() => setShowPanel(true)}>
      <FilterButton>
        {icon}
        <div>{label}</div>
        {extraNumber && extraNumber > 0 ? <span>{extraNumber}</span> : null}
      </FilterButton>
      <FiltersPanel show={showPanel} overFlow={overflow}>
        {Component && (
          <RenderComponent
            Component={Component}
            hideModal={() => setShowPanel(false)}
          />
        )}
      </FiltersPanel>
    </FiltersWrapper>
  );
};

export default ButtonPanel;
