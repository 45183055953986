import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import {
  Label as LabelRadio,
  RadioInput,
  RadioSpan,
} from 'ui-kit/Checkbox/styled-ui';

const CustomRadio = ({ field, form, label, blackText, ...props }) => {
  const { id, cb } = props;
  const { name } = field;

  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const valueSelected = getIn(form.values, name);
  const isError = isTouched && !!error && field.value === valueSelected;

  const handleChange = useCallback(
    event => {
      if (isTouched) {
        form.setFieldTouched(field.name, false);
      }
      if (cb) {
        cb(form);
      }
      form.setFieldValue(field.name, valueSelected);
      field.onChange(event);
    },
    [cb, field, form, isTouched, valueSelected]
  );

  return (
    <LabelRadio error={isError} blackText={blackText} forHtml={id}>
      <RadioInput
        {...field}
        {...props}
        onChange={handleChange}
        error={isError}
      />
      <RadioSpan error={isError} />
      {label}
    </LabelRadio>
  );
};

CustomRadio.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

CustomRadio.defaultProps = {
  disabled: false,
};

export default CustomRadio;
