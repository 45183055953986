import moment from 'moment';

const observerWC = new MutationObserver(mutationsList => {
  // eslint-disable-next-line no-restricted-syntax
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of mutation.addedNodes) {
        if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'DIV') {
          const input = node.querySelector('input');
          const addFileEvent = e => {
            const file = e.target.files[0];
            const requiredDate = moment().add(-3, 'months');
            const dateImage = moment(file.lastModified);
            const isExpired = dateImage < requiredDate;
            localStorage.setItem('imageExpired', JSON.stringify(isExpired));
          };

          const addStylesEvent = () => {
            const isMobile = window.innerWidth < 640;
            node.setAttribute(
              'style',
              `box-shadow: 0px 0px 26px 8px rgba(0, 0, 0, .2); border-radius: ${
                isMobile ? '0px' : '10px'
              }`
            );
          };
          addStylesEvent();
          window.addEventListener('resize', addStylesEvent);
          input?.addEventListener('change', addFileEvent);
          observerWC.disconnect();
        }
      }
    }
  }
});
export default observerWC;
