import styled from 'styled-components';

const statusColors = {
  NEW: '#D0FFC4',
  REQUEST: '#D0FFC4',
  NOT_FOUND: '#EFEFEF',
  REJECTED: '#FFD7DE',
  TIMEOUT: '#FFD7DE',
  INVALID_AMOUNT: '#FFD7DE',
  ERROR: '#FFD7DE',
  AML_ERROR: '#FFD7DE',
  ARREST: '#FFD7DE',
  PENDING: '#FFE6A7',
  SELECTED: '#FFE6A7',
  CONFIRMED: '#EFEFEF',
};

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: -10px auto 94px;
`;

export const TableBody = styled.tbody`
  border-top: 1px solid ${props => props.theme.grayBorder};
  transform: translateY(8px);
`;

export const CancelButton = styled.label`
  position: absolute;
  margin-left: 150px;
  margin-top: -28px;
  cursor: pointer;
  display: none;
  align-items: center;
  color: #fff;
  background-color: #0068fb;
  border-radius: 8px;
  height: 38px;
  padding: 0 15px;

  svg {
    width: 12px;
    margin-left: 5px;
  }

  :hover {
    display: flex;
  }

  :focus-within {
    display: none;
  }
`;

export const TableRow = styled.tr`
  height: 45px;

  td {
    padding-left: 15px;
  }

  :hover {
    background-color: ${props => props.theme.grayBg};

    label {
      display: flex;
    }
  }
`;

export const LoadingTd = styled.td`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #f1f1f1 33%);
  background-size: 1200px 104px;
  width: 150px;
  height: 35px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
  opacity: 0.5;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const TableHead = styled.th`
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const PageButton = styled.button`
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.fullBlackText : theme.grayText};
  margin: 0 10px;
`;

export const FirstLevelTr = styled.tr`
  transform: translate(0, -8px);
`;

export const DateHeading = styled(TableHead)`
  width: 135px;
`;

export const TotalBalanceTd = styled.td`
  text-align: right;
  padding-right: 15px;
`;

export const StatusSpan = styled.span`
  background: ${({ status }) => statusColors[status] || statusColors.NOT_FOUND};
  border-radius: 22px;
  height: 27px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 13px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;
