import React from 'react';
import qs from 'qs';
import ErrorModal from 'components/ErrorModal';
import Spinner from 'ui-kit/Spinner';
import { useAxiosState } from 'utils/hooks/axiosHook';
import getInformations from 'utils/services/request/information';
import { Info, InformationWrapper, MediaLoader } from './styled-ui';

const query = qs.stringify(
  {
    locale: ['ru'],
    sort: ['Order:asc'],
    populate: {
      informations: {
        sort: ['Order:asc'],
      },
    },
  },
  {
    encodeValuesOnly: true,
  }
);

const MemberMeetingInformation = () => {
  const informations = useAxiosState(getInformations(query));

  return (
    <InformationWrapper>
      {!informations.data && !informations.error ? (
        <MediaLoader>
          <Spinner color="#FFFFFF" />
        </MediaLoader>
      ) : (
        <>
          {informations.data.data.map(information => (
            <Info key={information.id}>
              {information.attributes.Description}
            </Info>
          ))}
        </>
      )}
      {informations.error && <ErrorModal isReloadButton />}
    </InformationWrapper>
  );
};

export default MemberMeetingInformation;
