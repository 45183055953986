export const changeTypes = [
  {
    label: 'EXCHANGE_OPERATION',
    value: 'EXCHANGE_OPERATION',
  },
  {
    label: 'MANUAL',
    value: 'MANUAL',
  },
  {
    label: 'EXCHANGE_REWARD',
    value: 'EXCHANGE_REWARD',
  },
  {
    label: 'DEPOSIT',
    value: 'DEPOSIT',
  },
];

export const orderTypes = [
  {
    label: 'DEPOSIT',
    value: 'DEPOSIT',
  },
  {
    label: 'WITHDRAWAL',
    value: 'WITHDRAWAL',
  },
  {
    label: 'DEFAULT',
    value: 'DEFAULT',
  },
];

export const providerTypes = [
  {
    label: 'ASSIST',
    value: 'ASSIST',
  },
  {
    label: 'BGP',
    value: 'BGP',
  },
  {
    label: 'CA',
    value: 'CA',
  },
];

export const orderStatuses = [
  {
    label: 'REQUEST',
    value: 'REQUEST',
  },
  {
    label: 'PROCESSING',
    value: 'PROCESSING',
  },
  {
    label: 'CHANGED',
    value: 'CHANGED',
  },
  {
    label: 'CONFIRMED',
    value: 'CONFIRMED',
  },
  {
    label: 'DECLINED',
    value: 'DECLINED',
  },
  {
    label: 'EXPIRED',
    value: 'EXPIRED',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
  {
    label: 'ARREST',
    value: 'ARREST',
  },
  {
    label: 'PAUSED',
    value: 'PAUSED',
  },
];

export const fiatStatuses = [
  {
    label: 'NEW',
    value: 'NEW',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
  {
    label: 'TIMEOUT',
    value: 'TIMEOUT',
  },
  {
    label: 'DECLINED',
    value: 'DECLINED',
  },
  {
    label: 'ERROR',
    value: 'ERROR',
  },
  {
    label: 'PENDING',
    value: 'PENDING',
  },
  {
    label: 'PROCESSING',
    value: 'PROCESSING',
  },
  {
    label: 'APPROVED',
    value: 'APPROVED',
  },
];

export const cryptoStatuses = [
  {
    label: 'NEW',
    value: 'NEW',
  },
  {
    label: 'NOT_FOUND',
    value: 'NOT_FOUND',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
  {
    label: 'TIMEOUT',
    value: 'TIMEOUT',
  },
  {
    label: 'INVALID_AMOUNT',
    value: 'INVALID_AMOUNT',
  },
  {
    label: 'ERROR',
    value: 'ERROR',
  },
  {
    label: 'AML_ERROR',
    value: 'AML_ERROR',
  },
  {
    label: 'ARREST',
    value: 'ARREST',
  },
  {
    label: 'SELECTED',
    value: 'SELECTED',
  },
  {
    label: 'CONFIRMED',
    value: 'CONFIRMED',
  },
  {
    label: 'PENDING',
    value: 'PENDING',
  },
];

export const fiatCurrencies = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'BYN',
    value: 'BYN',
  },
  {
    label: 'RUB',
    value: 'RUB',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
];

export const cryptoCurrencies = [
  {
    label: 'BTC',
    value: 'BTC',
  },
  {
    label: 'ETH',
    value: 'ETH',
  },
  {
    label: 'USDT',
    value: 'USDT',
  },
  {
    label: 'TRX',
    value: 'TRX',
  },
  {
    label: 'USDT',
    value: 'USDT_TRC',
  },
  {
    label: 'TON',
    value: 'TON',
  },
  {
    label: 'USDT',
    value: 'USDT_TON',
  },
  {
    label: 'WBP',
    value: 'WBP',
  },
];
