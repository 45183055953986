import { Link } from 'react-router-dom';
import styled from 'styled-components';
import device from '../../device';

export const ButtonLink = styled(Link)`
  width: 100%;
  max-width: ${({ maxwidth }) => maxwidth || 'unset'};
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 1rem;
  background-color: ${props => props.theme.btnColor};
  border-radius: ${({ borderradius }) => borderradius || '5px'};
  color: #ffffff;
  line-height: 31px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  margin-top: ${({ margintop }) => margintop || 0};
  &:hover {
    background-color: ${props => props.theme.btnHoverColor};
  }

  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }
  @media ${device.mobile920} {
    max-width: unset;
  }
`;

export const ButtonLinkTransparentOnHeader = styled(Link)`
  border-radius: 8px;
  color: ${props => props.theme.blackText};
  text-align: center;
  font-size: 1rem;
  line-height: 24px;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid ${props => props.theme.grayBg};
  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }
`;

export const ButtonLinkTransparentSmall = styled(Link)`
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  padding: 15px;
  font-size: 16px;
  line-height: 19px;
  background-color: ${props => props.theme.btnColor};
  display: block;
  margin: 0 40px;
  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }
`;

export const Button = styled.button`
  font-size: 1rem;
  line-height: 22px;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxwidth }) => maxwidth || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-family: 'Inter';
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.grayBg : theme.btnColor};
  border-radius: ${({ borderradius }) => borderradius || '5px'};

  color: ${({ secondary }) => (secondary ? '#000' : '#fff')};
  text-align: center;
  cursor: pointer;
  margin-top: ${({ margintop }) => margintop || 0};
  &:hover {
    background-color: ${({ theme, secondary }) =>
      secondary ? theme.emptySelectHoverBackground : theme.btnHoverColor};
    color: ${({ theme, secondary }) => (secondary ? theme.btnColor : '#fff')};
  }

  &:disabled {
    background-color: ${props => props.theme.btnDisabledColor};
    pointer-events: none;
  }
  @media ${device.mobile920} {
    max-width: unset;
  }
`;

export const CancelChangesButton = styled(Button)`
  background-color: ${props => props.theme.fullWhite};
  color: ${props => props.theme.fullBlackText};
  border: 1px solid ${props => props.theme.grayBorder};
  &:hover {
    background-color: ${props => props.theme.fullWhite};
    color: ${props => props.theme.fullBlackText};
  }
`;

export const SmallButton = styled(Button)`
  width: 140px;
  height: 40px;
  flex: none;
`;

export const ExtraSmallButton = styled(Button)`
  white-space: nowrap;
  background-color: ${props =>
    props.active ? props.theme.fullWhite : props.theme.lightBlue};
  width: -webkit-fill-available;
  display: inline-block;
  font-weight: 600;
  padding: ${({ isMedia }) => (isMedia ? '5px 25px' : '5px 15px')};
  letter-spacing: -0.01em;
  color: ${props =>
    props.active ? props.theme.btnColor : props.theme.fullWhite};
  &:hover {
    background-color: ${props =>
      props.active ? props.theme.fullWhite : props.theme.lightBlue};
    color: ${props =>
      props.active ? props.theme.btnColor : props.theme.fullWhite};
  }
`;

export const LargeButtonLink = styled(ButtonLink)`
  height: 50px;
  font-size: 1rem;
  max-width: 272px;
  margin: 0;
  background-color: ${props => props.theme.fullWhite};
  color: ${props => props.theme.blueBg};
  &:hover {
    background-color: ${props => props.theme.fullWhite};
    color: ${props => props.theme.blueBg};
  }
`;

export const LargeButton = styled(Button)`
  /* width: 398px; */
  width: 100%;
  height: 80px;
  font-size: 20px;
`;

export const SupportSubmitButton = styled(Button)`
  width: 292px;
  height: 56px;
  font-size: 16px;
  margin-bottom: 72px;
  background-color: #0069ff;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100%;
  }
  :hover {
    background-color: #0069ff;
  }
`;
export const Agreement = styled.div`
  font-size: 13px;
  margin-bottom: 72px;
  margin: 0 auto;
  @media ${device.laptop} {
    font-size: 10px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ButtonText = styled.span`
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const EndOrderButton = styled(Button)`
  background: ${props => props.theme.btnColor};
  font-family: 'Inter';
  font-size: 18px;
  line-height: 22px;
  display: inline-flex;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  margin: 0;
  height: 54px;
  max-width: 411px;
  @media (max-width: 480px) {
    width: 100%;
  }
  &:hover {
    background-color: ${props => props.theme.btnHoverColor};
  }
`;

export const CancelOrderButton = styled(Button)`
  background: #fff;
  color: #000000;
  font-family: 'Inter';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  max-width: 411px;
  height: 54px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 8px;
  @media ${device.laptop} {
    min-height: 50px;
  }
  @media ${device.tablet} {
    height: 50px;
    order: ${props => props.orderInRow && 1};
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }
  :hover {
    background-color: #fff;
    color: #000000;
  }
  :disabled {
    color: #a6a9c2;
    background-color: #fff;
  }
`;

export const ContinueOrderButton = styled(Button)`
  background: ${props => props.theme.btnColor};
  font-family: 'Inter';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  height: 54px;
  border-radius: 8px;
  max-width: 411px;
  @media ${device.laptop} {
    min-height: 50px;
  }
  @media ${device.tablet} {
    height: 50px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }
  &:hover {
    background-color: ${props => props.theme.btnHoverColor};
  }
`;

export const ExtraLargeButton = styled(LargeButton)`
  width: 836px;
  height: 70px;
`;

export const DownloadButton = styled(Button)`
  background: ${props => props.theme.btnColor};
  font-family: 'Inter';
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  margin: 0;
  height: 54px;
  max-width: 411px;
  @media (max-width: 480px) {
    width: 100%;
  }
  &:hover {
    background-color: ${props => props.theme.btnHoverColor};
  }
`;
