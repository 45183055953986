export const CAPSLOCK_ERROR = 'Caps Lock включен';

export const ERROR_STATUSES = {
  INVALID_CODE: 'INVALID_CODE',
  USER_EXISTS: 'USER_EXISTS',
  BAD_REQUEST: 'BAD_REQUEST',
  NOT_FOUND: 'NOT_FOUND',
  INVALID_CORPORATE_CARD: 'INVALID_CORPORATE_CARD',
  INVALID_CLIENT_CARD: 'INVALID_CLIENT_CARD',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_PROVIDER_TYPE: 'INVALID_PROVIDER_TYPE',
  CARD_ALREADY_EXISTS: 'CARD_ALREADY_EXISTS',
  CARD_BINDING_ERROR: 'CARD_BINDING_ERROR',
  EMPTY_PHONE_NUMBER: 'EMPTY_PHONE_NUMBER',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_RECAPTCHA: 'INVALID_RECAPTCHA',
  INVALID_AGREEMENT: 'INVALID_AGREEMENT',
};

export const ERROR_MESSAGES = {
  INVALID_CODE: 'Invalid code',
  CODE_HAS_BEEN_SENT: 'Code has been sent. Try again later',
  SECRET_MUST_NOT_BE_EMPTY: 'Secret must not be empty',
  ORDER_FAILED: 'Order MTS validation failed',
  USER_EXIST: 'User already exists',
  NO_PAYMENT_TOKEN: 'No payment token present',
  INVALID_IBAN_NUMBER: 'Wrong IBAN number',
};
