import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { parse } from 'query-string';
import { ThemeContext } from 'styled-components';
import { SmallModal } from 'ui-kit/Modal/Modal';
import Spinner from 'ui-kit/Spinner';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getTransactionData } from 'utils/services/request/transactions';
import { HOME } from 'constants/routings';
import ErrorModal from 'components/ErrorModal';
import { pushAnalyticsEvent } from 'components/App/analytics';
import TransferConfirmModal from './TransferConfirmModal';
import { LogoWrapper, SpinnerWrapper, Wrapper } from './styled-ui';

const NOT_FOUND = 400;

const TransferConfirm = () => {
  const [transaction, setTransaction] = useState(null);
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const { t } = useTranslation();
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const getTransaction = useAxiosState(
    queryParams?.request_id ? getTransactionData(queryParams?.request_id) : {},
    [queryParams?.request_id]
  );

  const toHomePage = () => {
    history.push(HOME);
    pushAnalyticsEvent('logo_button_click');
  };

  useEffect(() => {
    if (!queryParams?.request_id) {
      toHomePage();
    }
  }, []);

  useEffect(() => {
    if (getTransaction.data) {
      setTransaction(getTransaction.data);
    }
  }, [getTransaction.data]);

  return (
    <Wrapper>
      <LogoWrapper onClick={toHomePage}>
        <img src={themeContext.mainLogo} alt="Logo" />
      </LogoWrapper>
      {!getTransaction.loaded && getTransaction.fetching && (
        <SpinnerWrapper>
          <Spinner color="#FFFFFF" />
        </SpinnerWrapper>
      )}
      {getTransaction.error && (
        <ErrorModal
          title={
            getTransaction.error.code === NOT_FOUND
              ? t('transfer.error.notFound')
              : null
          }
          isReloadButton
        />
      )}
      {!isNil(transaction) && (
        <SmallModal
          noHeader
          width="auto"
          Component={TransferConfirmModal}
          t={t}
          toHomePage={toHomePage}
          queryParams={queryParams}
          transaction={transaction}
        />
      )}
    </Wrapper>
  );
};

export default TransferConfirm;
