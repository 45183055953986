import * as Yup from 'yup';

const WithdrawalDataFormSchema = t =>
  Yup.object().shape({
    account: Yup.string()
      .required(
        t('profile.referralProgram.withdrawalDataForm.errors.requiredField')
      )
      .min(
        15,
        t('profile.referralProgram.withdrawalDataForm.errors.accountMin', {
          count: 15,
        })
      ),
    bankAccountName: Yup.string()
      .transform(value => value.trim())
      .required(
        t('profile.referralProgram.withdrawalDataForm.errors.requiredField')
      ),
    bankAccountCode: Yup.string()
      .transform(value => value.trim())
      .required(
        t('profile.referralProgram.withdrawalDataForm.errors.requiredField')
      ),
  });

export default WithdrawalDataFormSchema;
