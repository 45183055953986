import React from 'react';
import Select from 'react-select';
import countryCodes from 'utils/constants/countryCodes';
import customStyle from './select-style';
import {
  LabelWrapper,
  LabelText,
  FlagBY,
  FlagRU,
  FlagAM,
  FlagAZ,
  FlagGE,
  FlagKG,
  FlagKZ,
  FlagTJ,
  FlagMD,
  FlagTM,
  FlagUA,
  FlagUZ,
  ChoosenMark,
} from './styled-ui';

const getCountryCodeLabel = countryCode => {
  switch (countryCode) {
    case 'BY':
      return <FlagBY />;
    case 'RU':
      return <FlagRU />;
    case 'AM':
      return <FlagAM />;
    case 'AZ':
      return <FlagAZ />;
    case 'GE':
      return <FlagGE />;
    case 'KG':
      return <FlagKG />;
    case 'KZ':
      return <FlagKZ />;
    case 'TJ':
      return <FlagTJ />;
    case 'MD':
      return <FlagMD />;
    case 'TM':
      return <FlagTM />;
    case 'UA':
      return <FlagUA />;
    case 'UZ':
      return <FlagUZ />;
    default:
      return <span>{countryCode}</span>;
  }
};

const CountryCodeSelect = ({
  field: { name, value, ...fieldProps },
  form,
  label,
  t,
  isRegistration,
  ...props
}) => {
  const onChange = selectedOptions => {
    form.setFieldValue('countryCode', selectedOptions.value);
    const codeItem = countryCodes.find(it => selectedOptions.value === it.code);
    form.setFieldValue('phone', codeItem?.dial_code || '');
    form.setErrors('phone', '');
  };

  const countryCodesFormatted = countryCodes.map(ccItem => ({
    value: ccItem.code,
    label: (
      <LabelWrapper>
        {getCountryCodeLabel(ccItem.code)}{' '}
        <LabelText>
          {' '}
          {`${t(`profile.countries.${ccItem.code}`)} (${ccItem.dial_code})`}
        </LabelText>
        <ChoosenMark />
      </LabelWrapper>
    ),
  }));

  const getSelectValue = selectValue => ({
    value: selectValue,
    label: getCountryCodeLabel(value),
  });

  return (
    <Select
      {...props}
      {...fieldProps}
      value={getSelectValue(value)}
      options={countryCodesFormatted}
      onChange={onChange}
      styles={customStyle(false)}
      isSearchable={false}
      isRegistration={isRegistration}
    />
  );
};

export default CountryCodeSelect;
