import React, { useState } from 'react';
import MerchantWebhookTableRow from './TableRow';
import { useAxiosStateWithRefetch } from '../../utils/hooks/axiosHook';
import { getMerchantWebhooks } from '../../utils/services/request/merchant';
import {
  StyledTable,
  TableBody,
  PageButton,
  TableHead,
  TableRow,
  LoadingTd,
} from '../MerchantBalancesTable/styled-ui';
import { PaginationContainer } from './styled-ui';

const MerchantWebhooksTable = ({ t }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { data: webhooksData, refetch } = useAxiosStateWithRefetch(
    getMerchantWebhooks(currentPage)
  );
  const totalPagesArr = [...Array(webhooksData?.totalPages).keys()];

  return (
    <StyledTable>
      <thead>
        <tr>
          <TableHead>{t('merchantPage.webhookStatus')}</TableHead>
          <TableHead>{t('merchantPage.webhookType')}</TableHead>
          <TableHead>{t('merchantPage.webhookMessage')}</TableHead>
          <TableHead>{t('merchantPage.webhookCreationDate')}</TableHead>
          <TableHead>{t('merchantPage.webhookLastSentDate')}</TableHead>
          <TableHead>{t('merchantPage.webhookAction')}</TableHead>
        </tr>
      </thead>
      <TableBody>
        {webhooksData
          ? webhooksData?.content.map(webhook => (
              <MerchantWebhookTableRow
                t={t}
                key={webhook.id}
                webhook={webhook}
              />
            ))
          : [...Array(10).keys()].map(recordNumber => (
              <TableRow key={`tr-${recordNumber}`}>
                {[...Array(6).keys()].map(dataNumber => (
                  <LoadingTd key={`td-${dataNumber}`} />
                ))}
              </TableRow>
            ))}
      </TableBody>
      <tfoot>
        <tr>
          <td colSpan={6}>
            <PaginationContainer>
              {webhooksData
                ? totalPagesArr.map(item => (
                    <PageButton
                      key={`page-${item}`}
                      onClick={() => {
                        setCurrentPage(item);
                        refetch();
                      }}
                      type="button"
                      isCurrent={item === currentPage}
                    >
                      {item + 1}
                    </PageButton>
                  ))
                : null}
            </PaginationContainer>
          </td>
        </tr>
      </tfoot>
    </StyledTable>
  );
};

export default MerchantWebhooksTable;
