import { ReactComponent as LogoComponent } from 'assets/images/main-logo.svg';
import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
`;

export const HeaderGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  padding-bottom: 20px;

  @media (max-width: 509px) {
    grid-template-rows: inherit;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 600;
  font-size: 56px;
  line-height: 67px;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: pre-line;
  @media ${device.laptop} {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    white-space: normal;
  }
  @media ${device.mobileL} {
    white-space: pre-line;
  }
`;

export const HeaderDescription = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Line = styled.div`
  margin-top: 30px;
  border: 1px solid #e7e7e7;
`;

export const CheckboxDescription = styled.div`
  padding-left: 35px;
  color: ${props => props.theme.grayText};
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ImagePeople = styled.div`
  background: #000000;
  background-size: contain;
  width: 95%;
  height: 95%;
  position: absolute;
  bottom: 0;
  left: 320px;
  right: 0;
`;

export const Logo = styled(LogoComponent)`
  height: 30px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 0;
`;

export const ErrorMessageText = styled.div`
  position: absolute;
  bottom: -25px;
  color: #f92828;
  font-size: 14px;
  line-height: 13px;
`;

export const FormGrid = styled.form`
  display: grid;
  grid-template-columns: ${props => (props.isLastPage ? '1fr' : '2fr 1fr 1fr')};
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  grid-column: 1/-1;
  @media ${device.laptop} {
    grid-template-columns: none;
    grid-row-gap: 20px;
  }
`;

export const WrapperButton = styled(FormGrid)`
  padding-top: 20px;
`;

export const ContentTitle = styled.div`
  color: #323233;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
`;

export const FullRow = styled.div`
  grid-column: 1/-1;
  min-height: 30px;
`;

export const Row = styled.div`
  margin-bottom: 40px;
`;

export const FormContentText = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: #323233;
  padding-bottom: 20px;
`;

export const FormContentSection = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: #323233;
  padding-bottom: 20px;
`;

export const FormContentSectionText = styled.div`
  font-size: 14px;
  line-height: 23px;
  color: #323233;
`;

export const DocumentContentSectionText = styled(FormContentSectionText)`
  color: #ffffff;
  padding-bottom: 20px;
`;

export const InformationText = styled.div`
  width: 245px;
  padding: 30px;
`;

export const PhotoDocument = styled.div`
  height: 364px;
  width: 836px;
  border-radius: 10px;
  background: linear-gradient(224.76deg, #3a6be9 0%, #1b39ce 100%);
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);
  position: relative;
`;

export const FormUploadDocumentText = styled.div`
  color: #858585;
  font-size: 12px;
  padding-top: 5px;
  line-height: 20px;
  text-align: center;
`;

export const FormUploadDocument = styled.div`
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  background-color: #ffffff;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    border-color: #858585;
  }
  &:hover ${FormUploadDocumentText} {
    color: #323233;
  }
`;

export const FormUploadDocumentHelpText = styled.div`
  font-size: 13px;
  color: ${props => props.theme.grayText};
  @media ${device.laptop} {
    font-size: 10px;
  }
`;

export const FormGroup = styled.div`
  position: relative;
  grid-column: ${props => (props.half ? '2/-1' : undefined)};
`;

export const AgreementGrid = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-gap: 20px;
  padding-bottom: 40px;

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
  }
`;

export const LaterButton = styled.button`
  width: 100%;
  height: 80px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  background-color: #ffffff;

  color: #858585;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
`;

export const NextButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background: ${props => props.theme.btnColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'Inter';
  font-size: 1rem;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const Question = styled.div``;

export const QuizTitle = styled.div`
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
  padding-bottom: 20px;
  color: ${props => (props.error ? '#F92828' : props.theme.blackText)};
`;

export const WrapInfo = styled.div`
  padding: ${props => (props.noHorizontalPadding ? 0 : '10')}px;
  font-size: 16px;
  color: ${props => props.theme.grayText};
  text-align: ${props => (props.notCentered ? 'initial' : 'center')};
  @media ${device.laptop} {
    font-size: 12px;
  }
`;

export const FormInputDescription = styled.div`
  color: #323233;
  font-size: 14px;
  line-height: 23px;
  padding-top: 20px;
`;

export const WrapSelect = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: 16px;
  color: ${({ theme }) => theme.errorColor};
`;

export const LivenessTitle = styled(ContentTitle)`
  margin-bottom: 20px;
  text-align: center;
  color: #2a348f;
`;

export const FormPhone = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

export const ConfirmButton = styled(NextButton)`
  height: 50px;
  max-width: 400px;
`;
