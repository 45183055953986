import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh-white.svg';
import { Button } from '../../../../ui-kit/Button';
import { regenerateMerchantApiKey } from '../../../../utils/services/request/merchant';
import {
  ApiKeyLabel,
  ApiKeyValue,
  ApiKeyWrapper,
  ButtonWrapper,
} from './styled-ui';

export default function ApiKey({ merchantRefetch, apiKey, label }) {
  const [isLoading, setIsLoading] = useState(false);
  const resendWebhook = () => {
    setIsLoading(true);
    axios(regenerateMerchantApiKey).then(() => merchantRefetch());
  };

  return (
    <ApiKeyWrapper>
      <ApiKeyLabel>{label}</ApiKeyLabel>
      <ApiKeyValue defaultValue={apiKey} />
      <ButtonWrapper>
        <Button onClick={resendWebhook} disabled={isLoading} secondary>
          <Refresh />
        </Button>
      </ButtonWrapper>
    </ApiKeyWrapper>
  );
}

ApiKey.propTypes = {
  apiKey: PropTypes.string,
  label: PropTypes.string,
};

ApiKey.defaultProps = {
  apiKey: '',
  label: '',
};
