import styled from 'styled-components';
import device from 'device';

export const LimitInfo = styled.div`
  font-size: 0.8rem;
  padding-left: 10px;

  @media ${device.mobile920} {
    margin-bottom: 10px;
  }
`;

export const PromoErrorWrapper = styled.div`
  display: flex;
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 0.8rem;
  white-space: nowrap;
  margin: 0 10px;

  @media (max-width: 1150px) {
    font-size: 13px;
    margin: 0 5px;
  }

  @media ${device.tablet} {
    white-space: pre-line;
    margin: 0 10px;
  }
`;
