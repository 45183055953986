import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CheckboxGroup from 'components/CheckboxGroup';
import {
  changeTypes,
  cryptoCurrencies,
  fiatCurrencies,
} from 'constants/filters';
import validationSchema from './validation';
import { ModalHeading, ModalWrapper } from './styled-ui';

const FiltersModal = ({ t, setFilter, resetFilters, refetch, filters }) => (
  <ModalWrapper>
    <ModalHeading>
      <span>{t('merchantPage.addFilter')}</span>
      <button type="button" onClick={resetFilters}>
        {t('merchantPage.resetFilter')}
      </button>
    </ModalHeading>
    <Formik
      initialValues={filters}
      validationSchema={validationSchema(t)}
      validateOnChange
    >
      {form => {
        const { values } = form;
        const customSetFieldValue = (name, value) => {
          setFilter({ ...values, [name]: value });
          refetch();
        };
        const customForm = { ...form, setFieldValue: customSetFieldValue };
        return (
          <>
            <CheckboxGroup
              items={changeTypes}
              form={customForm}
              groupLabel={t('filters.changeTypes')}
              groupName="changeTypes"
            />
            <CheckboxGroup
              items={fiatCurrencies}
              form={customForm}
              groupLabel={t('merchantPage.fiatCurrenciesLabel')}
              groupName="fiatCurrencies"
            />
            <CheckboxGroup
              items={cryptoCurrencies}
              form={customForm}
              groupLabel={t('merchantPage.cryptoCurrenciesLabel')}
              groupName="cryptoCurrencies"
            />
          </>
        );
      }}
    </Formik>
  </ModalWrapper>
);

FiltersModal.propTypes = {
  setFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};

export default FiltersModal;
