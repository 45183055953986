import styled from 'styled-components';

export const GroupStep = styled.div`
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 14px;
    left: 0;
    right: 0;
    background: #c5c5c5;
  }

  &::after {
    background: ${props => props.theme.linkColor};
    transition: right 0.5s;
  }

  &[data-step='0']::after {
    right: 83%;
  }

  &[data-step='1']::after {
    right: 50%;
  }

  &[data-step='2']::after {
    right: 17%;
  }

  &[data-step='0'] p:nth-child(1),
  &[data-step='1'] p:nth-child(2),
  &[data-step='2'] p:nth-child(3),
  &[data-step='3'] p:nth-child(4) {
    color: black;
  }

  &[data-step='1'] p:nth-child(1)::before,
  &[data-step='2'] p:nth-child(1)::before,
  &[data-step='2'] p:nth-child(2)::before,
  &[data-step='3'] p:nth-child(1)::before,
  &[data-step='3'] p:nth-child(2)::before,
  &[data-step='3'] p:nth-child(3)::before {
    content: '✔︎';
    font-size: 8px;
  }

  &[data-step='0'] p:nth-child(1)::before,
  &[data-step='1'] p:nth-child(2)::before,
  &[data-step='2'] p:nth-child(3)::before,
  &[data-step='1'] p:nth-child(1)::before,
  &[data-step='2'] p:nth-child(1)::before,
  &[data-step='2'] p:nth-child(2)::before,
  &[data-step='3'] p:nth-child(1)::before,
  &[data-step='3'] p:nth-child(2)::before,
  &[data-step='3'] p:nth-child(3)::before {
    background: ${props => props.theme.linkColor};
    color: white;
    border: 2px solid ${props => props.theme.linkColor};
  }
`;

export const Step = styled.p`
  counter-increment: step;
  height: 24px;
  position: relative;
  z-index: 2;
  /* cursor: pointer; */
  margin: 0 auto;

  color: #858585;
  font-size: 12px;
  line-height: 23px;

  &::before {
    content: '';
    display: flex;
    margin: 0 auto 0.5em;
    height: 16px;
    width: 16px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px solid #c5c5c5;
    font-size: 12px;
  }
`;
