import countryCodes from 'utils/constants/countryCodes';

export const getInputMask = countryCode => {
  const codeItem = countryCodes.find(it => countryCode === it.code);
  return codeItem?.inputMask;
};

export const getInitialCountryCode = (phone = '') => {
  const foundCodeItem = countryCodes.find(codeItem => {
    const regexp = new RegExp(`^\\${codeItem.dial_code}\\s`, 'g');
    return phone.match(regexp);
  });
  return foundCodeItem?.code || 'BY';
};

export const getCodeByCountry = country => {
  const foundCountry = countryCodes.find(value => value.name === country);

  return foundCountry?.code || 'BY';
};
