import moment from 'moment/moment';
import { isEmpty } from 'ramda';
import { cryptoCurrencies, fiatCurrencies } from 'utils/constant';
import {
  CURRENCY_KEY_TYPES,
  UTM_LABEL_TYPES,
} from 'utils/constants/utmLabelTypes';
import compareArrayWithObjectKeys from 'utils/compareArrayWithObjectKeys';

const encodedData = data => btoa(data);
const decodedData = data => atob(data);

const getUtmLabel = label => {
  if (localStorage.getItem(label)) {
    return JSON.parse(decodedData(localStorage.getItem(label)));
  }

  return null;
};

export const setUtmLabels = params => {
  const utmLabels = compareArrayWithObjectKeys(UTM_LABEL_TYPES, params);
  utmLabels.entry_point = window.location.href;
  utmLabels.browser_referrer = document.referrer;
  const expirationTime = moment()
    .add(1, 'year')
    .format();
  const currentTime = moment().format();

  UTM_LABEL_TYPES.forEach(label => {
    if (
      (utmLabels[label] && !localStorage.getItem(label)) ||
      currentTime > getUtmLabel(label)?.expirationTime
    ) {
      const object = {
        value: utmLabels[label],
        expirationTime,
        creationTime: currentTime,
      };
      localStorage.setItem(label, encodedData(JSON.stringify(object)));
    }
  });
};

export const auditRequestData = () => {
  const requestData = {
    entryPoint: '',
    browserReferrer: '',
    refId: '',
    utmCampaign: '',
    utmContent: '',
    utmTerm: '',
    utmMedium: '',
    utmSource: '',
  };

  UTM_LABEL_TYPES.forEach(label => {
    Object.keys(requestData).forEach(value => {
      if (localStorage.getItem(label)) {
        if (label.replace(/_/g, '').toUpperCase() === value.toUpperCase()) {
          requestData[value] =
            value === 'refId'
              ? localStorage.getItem(label)
              : getUtmLabel(label)?.value;
        }
      }
    });
  });

  return requestData;
};

export const removeUtmLabels = () => {
  UTM_LABEL_TYPES.forEach(value => {
    if (localStorage.getItem(value)) {
      localStorage.removeItem(value);
    }
  });
};

export const getCurrencyByParam = (label, key) => {
  const getCryptoCurrency = cryptoCurrencies.filter(crypto =>
    label?.includes(crypto)
  );

  if (isEmpty(getCryptoCurrency)) {
    fiatCurrencies.forEach(fiat => {
      if (label?.includes(fiat)) {
        localStorage.setItem(key, fiat);
      }
    });
  } else {
    cryptoCurrencies.forEach(crypto => {
      if (label?.includes(crypto)) {
        localStorage.setItem(key, crypto);
      }
    });
  }
};

export const setCurrenciesByParams = params => {
  const currencies = compareArrayWithObjectKeys(CURRENCY_KEY_TYPES, params);

  getCurrencyByParam(currencies?.cur_from, 'from_currency');
  getCurrencyByParam(currencies?.cur_to, 'to_currency');
};
