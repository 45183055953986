import axios from 'axios';

export const cardAddUrlOptions = returnUrl => ({
  method: 'get',
  url: `${process.env.REACT_APP_ASSIST_URL}/card/bind/url?type=CLIENT&returnUrl=${returnUrl}`,
});

export const getRemoveUserAssistCardUrl = deleteCardId =>
  `${process.env.REACT_APP_ASSIST_URL}/card/unbind/${deleteCardId}`;

export const attachGazpromCard = {
  method: 'get',
  url: `${process.env.REACT_APP_GAZPROM_URL}/card/bind/url`,
};

export const getRemoveUserGazpromCardUrl = deleteCardId =>
  `${process.env.REACT_APP_GAZPROM_URL}/card/unbind/${deleteCardId}`;

export const attachBepaidCard = returnUrl => ({
  method: 'get',
  url: `${process.env.REACT_APP_BEPAID_URL}/card/bind/url?returnUrl=${returnUrl}`,
});

export const getRemoveUserBepaidCardUrl = deleteCardId =>
  `${process.env.REACT_APP_BEPAID_URL}/card/unbind/${deleteCardId}`;

export const attachMtsCard = () => `${process.env.REACT_APP_MTS_URL}/card/bind`;

export const getRemoveUserMtsCardUrl = deleteCardId =>
  `${process.env.REACT_APP_MTS_URL}/card/unbind/${deleteCardId}`;

export const getSystemBankAccountsOptions = currency => ({
  method: 'get',
  url: `${process.env.REACT_APP_CA_URL}/currentAccount/system/general`,
  params: {
    currency,
  },
});

export const getUserAllPaymentOptions = (
  fromCurrency,
  toCurrency,
  currency
) => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/payment`,
  data: {
    fromCurrency,
    toCurrency,
    ...(currency && { currencies: [currency] }),
  },
});

export const getUserPaymentOptionById = ({ id, provider }) => ({
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/payment/${id}/provider/${provider}`,
});

export const getRemoveUserSettlementUrl = deleteCardId =>
  `${process.env.REACT_APP_CA_URL}/currentAccount/${deleteCardId}`;

export const getSystemRequisitesForClient = () =>
  axios.post(
    `${process.env.REACT_APP_CA_URL}/currentAccount/order/current/invoice`,
    null,
    { responseType: 'blob' }
  );

export const getClientOperations = ({ start, end }) =>
  axios.post(
    `${process.env.REACT_APP_EXCHANGE_URL}/client/report`,
    { start, end },
    { responseType: 'blob' }
  );

export const getUserPaymentRecommended = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/payment/provider`,
};

export const changeCardName = (id, providerType) =>
  `${process.env.REACT_APP_EXCHANGE_URL}/client/payment/${id}/provider/${providerType}`;

export const getAllPaymentsComission = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/payment/commissions`,
};

export default cardAddUrlOptions;
