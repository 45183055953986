const customStyle = isError => {
  return {
    placeholder: base => {
      return {
        ...base,
        fontFamily: 'Inter',
        fontSize: '14px',
        color: '#8f9db1',
      };
    },
    control: base => ({
      ...base,
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '10px',
      border: '1px solid #e5ebf1 !important',
      boxShadow: '0 0 0 0',
    }),
    container: base => ({
      ...base,
      display: 'inline-block',
      width: '100%',
      border: isError ? '1px solid red' : 'none',
      ':focus': {
        outline: 0,
      },
    }),
    valueContainer: base => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: '14px',
      height: '38px',
    }),
    menu: base => ({
      ...base,
      zIndex: 2,
      margin: '5px 0 0 0',
      borderRadius: '3px',
      border: 0,
      boxShadow: '0 4px 16px 0 rgba(3,3,69,0.16)',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      border: 0,
      color: '#0F2F56',
      fontSize: '14px',
      borderRadius: '3px',
    }),
    indicatorsContainer: base => ({
      ...base,
      padding: 0,
      '> div': {
        padding: 0,
      },
    }),
    option: (base, state) => ({
      ...base,
      lineHeight: '48px',
      fontSize: '14px',
      backgroundColor: state.isSelected ? '#F4F8FB' : '#fff',
      padding: '8px 18px',
      color: '#0F2F56',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
      },
      ':hover': {
        backgroundColor: '#f1f3f5',
      },
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
  };
};

export default customStyle;
