import styled, { keyframes } from 'styled-components';
import device from 'device';
import {
  AccordionItem,
  AccordionItemHeading as AccordionItemHead,
  AccordionItemPanel as AccordionItemPan,
} from 'react-accessible-accordion';
import { ReactComponent as Down } from 'assets/images/icons/down.svg';
import { ReactComponent as Up } from 'assets/images/icons/up.svg';

export const AccordionItems = styled(AccordionItem)`
  font-size: 18px;
  background: #ffffff;
  border-radius: 12px;
  border-bottom: ${({ expanded }) => (expanded ? 'none' : '1px solid #edf2f7')};
  margin-bottom: 5px;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const AccordionItemHeading = styled(AccordionItemHead)`
  font-size: 18px;
  background: #ffffff;
  border-radius: 12px;
  border-bottom: ${({ $expanded }) =>
    $expanded ? 'none' : '1px solid #edf2f7'};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  > div {
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:focus {
      outline: 0;
    }
    @media ${device.laptop} {
      font-size: 14px;
    }
    @media ${device.mobileL} {
      padding: 26px 15px;
    }
  }
`;

export const HeadBlock = styled.h4`
  font-weight: 500;
  margin: 0;
  flex: 0.95;
  color: ${props => props.theme.blackText};
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const DownIcon = styled(Up)``;

export const UpIcon = styled(Down)``;

const FadeIn = keyframes`
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
`;

export const AccordionItemPanel = styled(AccordionItemPan)`
  font-size: 18px;
  background: #ffffff;
  border-radius: 12px;
  padding: 0 25px;
  animation: ${FadeIn} 0.35s ease;
  @media ${device.laptop} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    padding: 0 15px;
  }
`;

export const TextBlock = styled.div`
  padding-bottom: 25px;
  color: ${props => props.theme.grayText};
  font-size: 18px;
  @media ${device.laptop} {
    font-size: 14px;
  }

  p {
    margin: 0;
  }
  ul {
    margin-left: 2em;
    @media ${device.mobileL} {
      margin-left: 1em;
    }
  }
  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
  }
  figure {
    width: auto !important;
    margin: 0;
  }
  table {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid;
    border-collapse: collapse;
  }
  table td {
    padding: 10px;
  }
  img {
    max-width: 800px;
  }
  @media (max-width: 540px) {
    figure {
      overflow-x: auto;
    }
    img {
      max-width: 300px;
    }
  }
`;
