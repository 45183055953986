import styled from 'styled-components';
import device from 'device';
import calendar from 'assets/images/icons/Calendar.svg';

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #f00;
  margin: 5px auto 15px 3px;
  display: inline-flex;
`;

export const DatepickerWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ error }) => (error ? '#f00' : '#dedede')};
  border-radius: 8px;
  height: 38px;
  padding-left: 18px;
  background-image: url(${calendar});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};

  input {
    font-size: 14px;
  }

  .react-datepicker-wrapper input {
    width: ${({ maxWidth }) => maxWidth || 'auto'};
  }

  .react-datepicker-popper {
    left: ${({ pickerLeft }) => `${pickerLeft} !important` || '0'};
  }
  @media ${device.mobile920} {
    max-width: none;

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker-wrapper input {
      width: 100%;
    }
  }
`;
