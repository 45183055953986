import styled from 'styled-components';
import device from 'device';

export const BalancesList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  flex-basis: 125px;
  height: 113px;
  border: 1px solid ${props => props.theme.grayBorder};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const LoadingBalance = styled.div`
  width: 295px;
  height: 113px;
  border: 1px solid ${props => props.theme.grayBorder};
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 8px;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #f1f1f1 33%);
  background-size: 1200px 104px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
  opacity: 0.5;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
  @media ${device.mobile920} {
    width: 135px;
  }
`;

export const BalanceLabel = styled.div`
  display: flex;
  font-size: 20px;
  height: 40px;

  svg {
    margin-right: 8px;
  }
`;

export const BalancesHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  margin: 28px 0 40px;
`;

export const CryptoNetwork = styled.div`
  color: ${props => props.theme.linkColor};
  line-height: 1;
  font-style: italic;
  font-weight: 500;
  font-size: 0.6rem;
`;

export const CreditContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 34px;

  div {
    display: flex;
    align-items: center;
    height: 35px;
    border-radius: 4px;
    color: ${({ theme }) => theme.grayText};
    background-color: ${({ theme }) => theme.grayBg};
    padding: 0 18px;
  }

  span:first-child {
    margin-right: 10px;
  }
`;
