import React from 'react';
import { Trans } from 'react-i18next';
import {
  CardsInfoList,
  CardsInfoItem,
  CardsInfoBlock,
  WrapperHover,
  InfoIcon,
  InnerHover,
} from './styled-ui';

const CardsCommissionInfo = ({ t, commissionInformation, isErip, text }) => {
  const isSellCommission =
    commissionInformation?.sellCommission &&
    commissionInformation?.sellCommission !== '0';

  return (
    <CardsInfoBlock>
      <CardsInfoList>
        {!isErip && (
          <CardsInfoItem>
            {t('profile.cardActions.сomissionInformationBuy', {
              value: commissionInformation?.buyCommission,
            })}
          </CardsInfoItem>
        )}
        {isSellCommission && (
          <CardsInfoItem>
            {t('profile.cardActions.сomissionInformationSell', {
              value: commissionInformation?.sellCommission,
            })}
          </CardsInfoItem>
        )}
      </CardsInfoList>
      {text && (
        <WrapperHover>
          <InfoIcon />
          <InnerHover>
            <Trans i18nKey={text} t={t} />
          </InnerHover>
        </WrapperHover>
      )}
    </CardsInfoBlock>
  );
};

export default CardsCommissionInfo;
