import React from 'react';
import qs from 'qs';
import {
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getCommonFaqs } from 'utils/services/request/faq';
import SanitizeHtml from 'ui-kit/SanitizeHtml/SanitizeHtml';
import {
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItems,
  AccordionWrapper,
  HeadBlock,
  TextBlock,
  FaqWrapper,
  QuestionWrapper,
  IconWrapper,
  UpIcon,
  DownIcon,
  MainBlock,
} from './styled-ui';

const query = qs.stringify(
  {
    locale: ['ru'],
    sort: ['Order:asc'],
    populate: {
      questions: {
        sort: ['Order:asc'],
      },
    },
  },
  {
    encodeValuesOnly: true,
  }
);

const Faq = () => {
  const faqsCategories = useAxiosState(getCommonFaqs(query));

  return (
    <FaqWrapper>
      {faqsCategories?.data?.data.map(category => (
        <div key={category.id}>
          <MainBlock>{category.attributes.Caption}</MainBlock>
          <QuestionWrapper>
            <AccordionWrapper allowMultipleExpanded allowZeroExpanded>
              {category.attributes.questions.data.map(question => (
                <AccordionItems key={question.id}>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <>
                        <AccordionItemHeading $expanded={expanded}>
                          <AccordionItemButton>
                            <HeadBlock>{question.attributes.Caption}</HeadBlock>
                            <IconWrapper>
                              {expanded ? <UpIcon /> : <DownIcon />}
                            </IconWrapper>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel $expanded={expanded}>
                          <TextBlock>
                            <SanitizeHtml html={question.attributes.Text} />
                          </TextBlock>
                        </AccordionItemPanel>
                      </>
                    )}
                  </AccordionItemState>
                </AccordionItems>
              ))}
            </AccordionWrapper>
          </QuestionWrapper>
        </div>
      ))}
    </FaqWrapper>
  );
};

export default Faq;
