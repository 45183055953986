import React, { useState } from 'react';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getBalancesHistory } from 'utils/services/request/merchant';
import {
  LoadingTd,
  ClientIdHeading,
  DateHeading,
  PageButton,
  PaginationContainer,
  StyledTable,
  FirstLevelTr,
  TableBody,
  TableHead,
  TableRow,
  TotalBalanceTh,
  FiltersContainer,
  FilterItem,
} from './styled-ui';
import MerchantBalancesTableRow from './TableRow';
import { ControlWrapper } from '../MerchantOperationTable/styled-ui';
import ButtonPanel from '../ButtonPanel';
import FiltersModal from './FiltersModal';
import { ReactComponent as IconFilter } from '../../assets/images/icons/Filter.svg';
import { ReactComponent as Close } from '../../assets/images/icons/close.svg';

const MerchantBalancesTable = ({ t }) => {
  const initialFilters = {
    changeTypes: [],
    fiatCurrencies: [],
    cryptoCurrencies: [],
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilter] = useState(initialFilters);
  const { data: balancesData, refetch, fetching } = useAxiosStateWithRefetch(
    getBalancesHistory({
      page: currentPage,
      filters: {
        currencies: [...filters.fiatCurrencies, ...filters.cryptoCurrencies],
        changeTypes: filters.changeTypes,
      },
    })
  );

  const handleSetFilter = props => {
    setCurrentPage(0);
    setFilter(props);
  };
  const handleResetFilters = () => {
    handleSetFilter(initialFilters);
    refetch();
  };
  const handleRemoveFilter = filterName => {
    handleSetFilter({ ...filters, [filterName]: [] });
    refetch();
  };
  const totalPagesArr = [...Array(balancesData?.totalPages).keys()];

  return (
    <>
      <ControlWrapper>
        <ButtonPanel
          label={t('merchantPage.addFilter')}
          extraNumber={0}
          Component={props => (
            <FiltersModal
              filters={filters}
              setFilter={handleSetFilter}
              resetFilters={handleResetFilters}
              refetch={refetch}
              t={t}
              {...props}
            />
          )}
          icon={<IconFilter />}
          overflow
        />
      </ControlWrapper>
      <FiltersContainer disabled={fetching}>
        {Object.keys(filters).map(currentFilter => {
          const currentValue = filters[currentFilter];
          const filterString = JSON.stringify(
            currentValue?.map(item =>
              t(`merchantPage.filterItems.${currentFilter}.${item}`)
            )
          )?.replace(/["\][]/g, '');
          return currentValue?.length ? (
            <FilterItem
              key={`filterBadge-${currentFilter}`}
              disabled={fetching}
            >
              <span title={filterString}>
                {t(`filters.${currentFilter}`)}: {filterString}
              </span>
              <Close onClick={() => handleRemoveFilter(currentFilter)} />
            </FilterItem>
          ) : null;
        })}
      </FiltersContainer>
      <StyledTable>
        <thead>
          <FirstLevelTr>
            <DateHeading>{t('merchantPage.creationDate')}</DateHeading>
            <ClientIdHeading>{t('merchantPage.id')}</ClientIdHeading>
            <TableHead>{t('merchantPage.asset')}</TableHead>
            <TableHead>{t('merchantPage.currency')}</TableHead>
            <TableHead>{t('merchantPage.changeType')}</TableHead>
            <TotalBalanceTh>{t('merchantPage.total')}</TotalBalanceTh>
          </FirstLevelTr>
          <tr>
            {[...Array(5).keys()].map(item => (
              <th key={`th-${item}`}>&nbsp;</th>
            ))}
            <TotalBalanceTh>{t('merchantPage.balance')}</TotalBalanceTh>
          </tr>
        </thead>
        <TableBody>
          {balancesData
            ? balancesData.content.map(order => (
                <MerchantBalancesTableRow key={order.id} order={order} t={t} />
              ))
            : [...Array(9).keys()].map(recordNumber => (
                <TableRow key={`balancesTr-${recordNumber}`}>
                  {[...Array(6).keys()].map(dataNumber => (
                    <LoadingTd key={`balancesTd-${dataNumber}`} />
                  ))}
                </TableRow>
              ))}
        </TableBody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <PaginationContainer>
                {balancesData
                  ? totalPagesArr.map(item => (
                      <PageButton
                        key={`page-${item}`}
                        onClick={() => {
                          setCurrentPage(item);
                          refetch();
                        }}
                        type="button"
                        isCurrent={item === currentPage}
                      >
                        {item + 1}
                      </PageButton>
                    ))
                  : null}
              </PaginationContainer>
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
};

export default MerchantBalancesTable;
