import {
  cardAddUrlOptions as attachAssistCard,
  attachGazpromCard,
  getRemoveUserAssistCardUrl,
  getRemoveUserGazpromCardUrl,
  getRemoveUserSettlementUrl,
  attachBepaidCard,
  getRemoveUserBepaidCardUrl,
  attachMtsCard,
  getRemoveUserMtsCardUrl,
} from 'utils/services/request/card';
import {
  getCryptoCardDetails,
  attachAlfaCard,
  removeAlfaCard,
} from 'utils/services/request/alfa';

// providers
export const GAZPROM = 'BGP';
export const ASSIST = 'ASSIST';
export const ASSIST_BELARUSBANK = 'ASSIST_BELARUSBANK';
export const SETTLEMENT_ACCOUNT = 'CA';
export const ERIP = 'ERIP';
export const BEPAID = 'BEPAID';
export const ALFA = 'ALFA';
export const MTS = 'MTS';

// types
export const CARD_PAYMENT_SERVICE = 'CARD_PAYMENT_SERVICE';
export const SETTLEMENT = 'CA';
export const CRYPTO_CARD = 'CRYPTO_CARD';

// banks
export const BELARUSBANK = 'BELARUSBANK';

export const PROVIDER_TYPES = {
  ASSIST,
  GAZPROM,
  SETTLEMENT,
  ERIP,
  BEPAID,
  ALFA,
};

export const ORDER_TYPES = {
  CARD: 'add_card',
  SETTLEMENT: 'settlement_order',
  ERIP: 'erip_order',
};

export const CARD_TYPES = {
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  ERIP: 'ERIP',
  MIR: 'MIR',
};

export const PAYMENT_STATUS = {
  ENABLED: 'ENABLED',
  DIRECTION_DISABLED: 'DIRECTION_DISABLED',
  CURRENCY_DISABLED: 'CURRENCY_DISABLED',
};

const returnUrl = `${
  window.location.origin
}/profile?tab=payments${encodeURIComponent('&card_bind=success')}`;

const attachAlfaCardRequest = attachAlfaCard({
  failUrl: `${window.location.origin}/profile?tab=payments&card_bind=failed`,
  returnUrl: `${window.location.origin}/profile?tab=payments&card_bind=success`,
});

export const PAYMENT_METHODS = [
  {
    id: ALFA,
    type: CRYPTO_CARD,
    attachCardRequest: attachAlfaCardRequest,
    getRemoveCardUrl: removeAlfaCard,
    getCardDetailsRequest: getCryptoCardDetails,
  },
  {
    type: CARD_PAYMENT_SERVICE,
    paymentServices: [
      {
        id: ALFA,
        attachCardRequest: attachAlfaCardRequest,
        getRemoveCardUrl: removeAlfaCard,
      },
      {
        id: ASSIST_BELARUSBANK,
        bank: BELARUSBANK,
        attachCardRequest: attachAssistCard(returnUrl),
        getRemoveCardUrl: getRemoveUserAssistCardUrl,
      },
      {
        id: ASSIST,
        attachCardRequest: attachAssistCard(returnUrl),
        getRemoveCardUrl: getRemoveUserAssistCardUrl,
      },
      // {
      //   id: BEPAID,
      //   attachCardRequest: attachBepaidCard(returnUrl),
      //   getRemoveCardUrl: getRemoveUserBepaidCardUrl,
      // },
      {
        id: MTS,
        attachCardRequest: attachMtsCard,
        getRemoveCardUrl: getRemoveUserMtsCardUrl,
      },
    ],
  },
  // {
  //   id: GAZPROM,
  //   type: CARD_PAYMENT_SERVICE,
  //   attachCardRequest: attachGazpromCard,
  //   getRemoveCardUrl: getRemoveUserGazpromCardUrl,
  // },
  // {
  //   id: ERIP,
  //   type: ERIP,
  // },
  {
    id: SETTLEMENT_ACCOUNT,
    type: SETTLEMENT,
    getRemoveCardUrl: getRemoveUserSettlementUrl,
  },
];
