import React, { useEffect } from 'react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from 'ui-kit/Button';
import CodeInput from 'ui-kit/CodeInput';
import Error from 'ui-kit/Error';
import {
  ModalBodyWrapper,
  ModalLabel,
  ModalDesc,
  ButtonWrapper,
  EmailValue,
  PhoneCodeWrapper,
} from './styled-ui';

const phoneCodeIds = [...Array(6).keys()].map((_, n) => `otc-${n + 1}`);

const validationPhoneScheme = t =>
  Yup.object().shape({
    ...phoneCodeIds.reduce(
      (res, id) => ({
        ...res,
        [id]: Yup.number().required(t('profile.requiredField')),
      }),
      {}
    ),
  });

const ValueChangeListener = () => {
  const { submitForm, values } = useFormikContext();

  useEffect(() => {
    if (Object.values(values).join('').length === 6) {
      submitForm();
    }
  }, [submitForm, values]);

  return null;
};

const ConfirmationPhone = ({ t, initialValues, handleSubmit }) => {
  return (
    <ModalBodyWrapper>
      <ModalLabel>{t('profile.modalLabels.confirmChangePhone')}</ModalLabel>
      <ModalDesc>
        <Trans t={t} i18nKey="profile.modalLabels.confirmChangePhoneDescSecond">
          {/* eslint-disable no-irregular-whitespace */}
          На телефон <EmailValue>
            {{ phone: initialValues.phone }}
          </EmailValue>{' '}
          отправлено сообщение с проверочным кодом. Введите код из SMS для
          подтверждения телефона.
        </Trans>
      </ModalDesc>
      <ModalDesc highlighted>
        {t('profile.modalLabels.confirmChangePhoneWarning')}
      </ModalDesc>
      <Formik
        initialValues={{
          ...phoneCodeIds.reduce(
            (res, id) => ({
              ...res,
              [id]: '',
            }),
            {}
          ),
        }}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={validationPhoneScheme(t)}
      >
        {({ errors, setFieldValue, isSubmitting }) => (
          <Form>
            <PhoneCodeWrapper>
              {phoneCodeIds.map((id, n) => (
                <Field
                  key={id}
                  name={id}
                  autoFocus={n === 0}
                  component={CodeInput}
                  id={id}
                  setFieldValue={setFieldValue}
                />
              ))}
              {errors.code && <Error isSupportPage>{errors.code}</Error>}
            </PhoneCodeWrapper>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('profile.modalLabels.approveButton')}
              </Button>
            </ButtonWrapper>
            <ValueChangeListener />
          </Form>
        )}
      </Formik>
    </ModalBodyWrapper>
  );
};

export default ConfirmationPhone;
