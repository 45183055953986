import { useState, useEffect } from 'react';

const useMobileSize = () => {
  const [isMobile, setIsMobile] = useState(window.screen.width <= 768);

  useEffect(() => {
    const resizeListener = () => {
      if (window.screen.width <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return isMobile;
};

export default useMobileSize;
