import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Field, Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useAxiosState, useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { isUserMFAEnabled } from 'utils/services/selectors/user';
import {
  mfaQrOptions,
  twoFactorFromOptions,
} from 'utils/services/request/auth';
import LoadingPlaceholder from 'ui-kit/LoadingPlaceholder';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import CopyTooltip from 'ui-kit/Tooltip/CopyTooltip';
import UserContext from 'utils/contexts/User';
import {
  ButtonWrapper,
  ModalLabel,
} from 'components/Auth/UserProfile/ApproveModal/styled-ui';
import { FormGroup } from 'components/Verification/styled-ui';
import {
  Adding,
  AppInfo,
  ButtonGrid,
  FormGrid,
  QRCode,
  Secret,
  DescriptionLink,
  Wrapped,
  SecretWrapper,
} from '../styled-ui';

const TwoFactorAuth = ({ setCompleteRegistration, hideModal, laterButton }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [mfaQrImage, setQr] = useState(null);
  const [secret, setSecret] = useState('');
  const mfaQr = useAxiosState(mfaQrOptions, []);

  const request = () => {
    return twoFactorFromOptions;
  };

  const stateSubmitingForm = useAxiosSubmitingEffect(request());

  useEffect(() => {
    if (!mfaQr.fetching && mfaQr.loaded) {
      setQr(mfaQr.data.qrCode);
      setSecret(mfaQr.data.secret);
    }
  }, [mfaQr]);

  const onSubmitHandler = useCallback((values, actions) => {
    const { ...data } = values;

    const mfaData = {
      ...data,
      state: !isUserMFAEnabled(user),
    };

    stateSubmitingForm.setFormAndSubmit({ values: mfaData, actions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateSubmitingForm.loaded) {
      if (stateSubmitingForm.error) {
        const { actions } = stateSubmitingForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        actions.setFieldError('code', t('signUpForm.errors.invalidCode'));
      } else {
        user.refetch();
        if (setCompleteRegistration) {
          setCompleteRegistration(true);
        } else {
          hideModal();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingForm, stateSubmitingForm.loaded]);

  return (
    <>
      <ModalLabel>{t('profile.modalLabels.twoFa')}</ModalLabel>
      <QRCode>
        {!mfaQrImage ? (
          <LoadingPlaceholder options={{}} />
        ) : (
          <img src={mfaQrImage} alt="" />
        )}
      </QRCode>
      <Wrapped>
        {secret && (
          <SecretWrapper>
            <Secret value={secret} readOnly />
            <CopyTooltip t={t} copyText={secret} />
          </SecretWrapper>
        )}
      </Wrapped>
      <AppInfo>
        <Trans i18nKey="signUpForm.twoFaRule1" t={t}>
          1. Скачайте приложение Google Authenticator в
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
          >
            Google Play
          </a>
          (Android) или
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          >
            <Adding>App Store</Adding>
          </a>
          (iPhone).
        </Trans>
      </AppInfo>
      <AppInfo>{t('signUpForm.twoFaRule2')}</AppInfo>
      <AppInfo>{t('signUpForm.twoFaRule3')}</AppInfo>
      <AppInfo>{t('signUpForm.twoFaCodeInfo')}</AppInfo>
      <Formik
        initialValues={{ code: '' }}
        validate={values => {
          const errors = {};
          if (!values.code) {
            errors.code = t('signUpForm.errors.requiredField');
          }
          return errors;
        }}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status,
        }) => (
          <FormGrid onSubmit={handleSubmit} twoFaFlag>
            <FormGroup>
              <Field
                id="code"
                type="text"
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                component={Input}
                placeholder="******"
                label={t('signUpForm.codeLabel')}
                autoComplete="off"
                autoFocus
              />
              {status && status.code && !errors.code ? (
                <Error isSupportPage>{status.code}</Error>
              ) : (
                errors.code &&
                touched.code && <Error isSupportPage>{errors.code}</Error>
              )}
            </FormGroup>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('signUpForm.finish')}
              </Button>
            </ButtonWrapper>
          </FormGrid>
        )}
      </Formik>
      <ButtonGrid>
        {laterButton && (
          <DescriptionLink to="/" textalign="center">
            {t('signUpForm.later')}
          </DescriptionLink>
        )}
      </ButtonGrid>
    </>
  );
};

export default TwoFactorAuth;
