import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18n from 'i18n';
import Spinner from 'ui-kit/Spinner';
import { pushAnalyticsEvent } from 'components/App/analytics';
import validationFieldsValue from './validation';

import {
  Wrapper,
  Input,
  ApplyPromoButton,
  OpenPromoButton,
  AppliedPromo,
  WForm,
  CancelIcon,
  EMessage,
  EText,
} from './styled-ui';

const PromocodeSchema = t =>
  Yup.object().shape({
    promo: Yup.string()
      .min(3, t('promocode.errors.promoMin', { count: 3 }))
      .max(20, t('promocode.errors.promoMax', { count: 20 }))
      .test(
        'isCorrectSymbol',
        i18n.t('promocode.errors.promoFormat'),
        val => !/[^a-z0-9-_]/.test(val || '')
      ),
  });

const FormComponent = ({
  t,
  promo,
  handleChange,
  isSubmitting,
  setSubmitting,
  getInnerButtonComponent,
  isPromoRequestFinished,
  promoError,
  isPromoApplied,
  handleCancel,
}) => {
  useEffect(() => {
    setSubmitting(false);
  }, [isPromoRequestFinished, setSubmitting]);

  return (
    <WForm>
      {isPromoRequestFinished && isPromoApplied && promo ? (
        <AppliedPromo>
          {t('promocode.appliedPromo', { code: promo })}
        </AppliedPromo>
      ) : (
        <>
          <Input
            autoFocus
            id="promo"
            name="promo"
            value={promo}
            onChange={handleChange}
          />
          {!isSubmitting && (
            <ApplyPromoButton type="button" onClick={handleCancel}>
              <CancelIcon />
            </ApplyPromoButton>
          )}
        </>
      )}
      <ApplyPromoButton type="submit" disabled={isSubmitting}>
        {getInnerButtonComponent(promo, isSubmitting, promoError)}
      </ApplyPromoButton>
    </WForm>
  );
};

const ErrorMessage = ({ handleCancel, errors }) => {
  useEffect(() => {
    if (errors.promo) {
      pushAnalyticsEvent('promocode_reject');
    }
  }, [errors.promo]);

  return (
    <EMessage>
      <EText>{errors.promo}</EText>
      <ApplyPromoButton type="submit" onClick={handleCancel}>
        <CancelIcon />
      </ApplyPromoButton>
    </EMessage>
  );
};

const PromocodeRow = ({
  t,
  setFieldValue,
  promoInfo,
  isPromoRequestFinished,
  promoError,
}) => {
  const [isPromoActive, setIsPromoActive] = useState(false);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const promoCode = JSON.parse(localStorage.getItem('promocode'));

  const onSubmitHandler = (values, { resetForm }) => {
    if (promoInfo) {
      setIsPromoApplied(false);
      resetForm({ promo: '' });
      setFieldValue('promoCode', '');
      setIsPromoActive(false);
      localStorage.removeItem('promocode');
    } else {
      setFieldValue('promoCode', values.promo);
      setIsPromoApplied(true);
    }
  };

  const getInnerButtonComponent = (promo, isSubmitting) => {
    if (isSubmitting) return <Spinner color="#fff" />;
    return isPromoApplied && promoInfo && promo ? (
      <CancelIcon />
    ) : (
      t('promocode.applyButtonText')
    );
  };

  useEffect(() => {
    if (promoCode?.value) {
      setIsPromoApplied(true);
      setIsPromoActive(true);
    }
  }, [promoCode?.value]);

  return (
    <Wrapper
      isPromoActive={isPromoActive}
      isPromoApplied={isPromoRequestFinished && isPromoApplied}
    >
      {isPromoActive ? (
        <Formik
          initialValues={{ promo: promoCode?.value || '' }}
          validate={validationFieldsValue(t)}
          onSubmit={onSubmitHandler}
          validationSchema={PromocodeSchema(t)}
        >
          {({
            handleChange,
            values,
            isSubmitting,
            setSubmitting,
            errors,
            touched,
          }) =>
            errors.promo && touched.promo ? (
              <ErrorMessage
                errors={errors}
                handleCancel={() => setIsPromoActive(false)}
              />
            ) : (
              <FormComponent
                t={t}
                promo={values.promo}
                promoError={promoError}
                handleCancel={() => setIsPromoActive(false)}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                setSubmitting={setSubmitting}
                isPromoRequestFinished={isPromoRequestFinished}
                getInnerButtonComponent={getInnerButtonComponent}
                isPromoApplied={isPromoApplied}
              />
            )
          }
        </Formik>
      ) : (
        <OpenPromoButton onClick={setIsPromoActive}>
          {t('promocode.inputPlaceholder')}
        </OpenPromoButton>
      )}
    </Wrapper>
  );
};

export default PromocodeRow;
