import styled from 'styled-components';
import device from 'device';

export const FormGrid = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  grid-column: 1/-1;
  @media ${device.laptop} {
    grid-template-columns: none;
    grid-row-gap: 20px;
  }
`;

export const FullRow = styled.div`
  grid-column: 1/-1;
  min-height: 30px;
`;

export const Title = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 600;
  font-size: 40px;
  line-height: 67px;
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: pre-line;
  @media ${device.laptop} {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    white-space: normal;
  }
  @media ${device.mobileL} {
    white-space: pre-line;
  }
`;

export const QuizTitle = styled.div`
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
  padding-bottom: 20px;
  color: ${props =>
    props.error ? props.theme.errorColor : props.theme.blackText};
`;

export const HeaderGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  padding-bottom: 20px;

  @media (max-width: 509px) {
    grid-template-rows: inherit;
  }
`;

export const HeaderDescription = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const NoticeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const NoticeTitle = styled.div`
  text-align: center;
`;

export const NoticeSubTitle = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
