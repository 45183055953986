import React from 'react';
import { SpinnerGroup, Spinner1, Spinner2 } from './styled-ui';

const Spinner = ({ color }) => (
  <SpinnerGroup color={color}>
    <Spinner1 />
    <Spinner2 />
    <div />
  </SpinnerGroup>
);

export default Spinner;
