import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: -10px auto 94px;
`;

export const TableBody = styled.tbody`
  border-top: 1px solid ${props => props.theme.grayBorder};
`;

export const TableRow = styled.tr`
  height: 35px;

  td {
    padding-left: 15px;
  }

  :hover {
    background-color: ${props => props.theme.grayBg};
  }
`;

export const LoadingTd = styled.td`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #f1f1f1 33%);
  background-size: 1200px 104px;
  width: 150px;
  height: 35px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
  opacity: 0.5;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const TableHead = styled.th`
  padding: 0 15px 20px 15px;
  font-size: 14px;
  font-weight: bold;
`;

export const TransactionStatusHead = styled(TableHead)`
  vertical-align: top;

  span {
    border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
    padding-bottom: 3px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const PageButton = styled.button`
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.fullBlackText : theme.grayText};
  margin: 0 10px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
  margin-bottom: 24px;
`;

export const FiltersButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;

export const FilterItem = styled.div`
  background-color: ${({ theme }) => theme.emptySelectHoverBackground};
  border-radius: 4px;
  padding: 5px 7px 5px 10px;
  margin: 10px 8px 0 0;
  display: flex;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  height: 24px;
  font-size: 14px;
  max-width: 210px;

  span {
    overflow: hidden;
    max-width: 192px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    width: 12px;
    margin: auto 3px;
    cursor: pointer;

    &:hover {
      filter: invert(29%) sepia(79%) saturate(5586%) hue-rotate(211deg)
        brightness(105%) contrast(103%);
    }
  }
`;

export const FilterItemsContainer = styled.div`
  position: absolute;
  height: auto;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.grayBorder};
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  background: ${({ theme }) => theme.fullWhite};

  span:first-child {
    display: block;
    margin-bottom: 5px;
  }
`;

export const FirstLevelTr = styled.tr`
  transform: translate(0, 16px);
`;

export const ClientIdHeading = styled(TableHead)`
  width: 308px;
`;

export const DateHeading = styled(TableHead)`
  width: 135px;
`;

export const TotalBalanceTh = styled(TableHead)`
  text-align: right !important;
`;

export const TotalBalanceTd = styled.td`
  text-align: right;
  padding-right: 15px;
`;

export const SearchInputWrapper = styled.div`
  display: flex;

  button {
    margin-left: -35px !important;
  }
`;

export const SearchInput = styled.input`
  border: 1px solid ${({ theme }) => theme.grayBorder};
  width: 314px;
  border-radius: 6px;
  padding-left: 12px;

  ::placeholder {
    color: ${({ theme }) => theme.grayCharge};
  }
`;
