import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormGroup } from 'components/Verification/styled-ui';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import {
  FormGrid,
  Header,
  RestorePasswordLabel,
  WrapEye,
  OpenEyeIcon,
  CloseEyeIcon,
  ButtonWrapper,
} from '../../styled-ui';

const restorePasswordSchema = t =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required(t('passwordForms.errors.requiredField'))
      .min(12, t('passwordForms.errors.passMin', { count: 12 }))
      .max(128, t('passwordForms.errors.passMax', { count: 128 })),
    repeatedPassword: Yup.string()
      .required(t('passwordForms.errors.requiredField'))
      .min(12, t('passwordForms.errors.passMin', { count: 12 }))
      .max(128, t('passwordForms.errors.passMax', { count: 128 }))
      .oneOf([Yup.ref('newPassword')], t('passwordForms.errors.wrongPassword')),
  });

const RestorePasswordForm = ({
  initialValues,
  onSubmitHandler,
  isTwoFactorForm,
  t,
}) => {
  const [isShowNewPassword, setShowNewPassword] = useState(false);
  const [isShowRepeatedPassword, setShowRepeatedPassword] = useState(false);

  return (
    <>
      <Header>
        {isTwoFactorForm
          ? t('profile.modalLabels.twoFa')
          : t('passwordForms.titleRestore')}
      </Header>
      <RestorePasswordLabel>
        {isTwoFactorForm
          ? t('profile.modalLabels.twoFaCodeInfo')
          : t('passwordForms.newPasswordLabelText')}
      </RestorePasswordLabel>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validateOnChange={false}
        validationSchema={restorePasswordSchema(t)}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormGrid onSubmit={handleSubmit}>
            {isTwoFactorForm ? (
              <FormGroup>
                <Field
                  id="mfaSecret"
                  type="text"
                  name="mfaSecret"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  component={Input}
                  placeholder="******"
                  label={t('passwordForms.codeLabel')}
                  autoComplete="off"
                />
                {errors.mfaSecret && touched.mfaSecret && (
                  <Error>{errors.mfaSecret}</Error>
                )}
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <WrapEye>
                    {isShowNewPassword ? (
                      <OpenEyeIcon onClick={() => setShowNewPassword(false)} />
                    ) : (
                      <CloseEyeIcon onClick={() => setShowNewPassword(true)} />
                    )}
                  </WrapEye>
                  <Field
                    id="newPassword"
                    type={isShowNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={Input}
                    placeholder={t('passwordForms.newPassLabel')}
                    label={t('passwordForms.newPassLabel')}
                    autoComplete="off"
                    inputHiddenText
                  />

                  {errors.newPassword && touched.newPassword && (
                    <Error>{errors.newPassword}</Error>
                  )}
                </FormGroup>
                <FormGroup>
                  <WrapEye>
                    {isShowRepeatedPassword ? (
                      <OpenEyeIcon
                        onClick={() => setShowRepeatedPassword(false)}
                      />
                    ) : (
                      <CloseEyeIcon
                        onClick={() => setShowRepeatedPassword(true)}
                      />
                    )}
                  </WrapEye>
                  <Field
                    id="repeatedPassword"
                    type={isShowRepeatedPassword ? 'text' : 'password'}
                    name="repeatedPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    component={Input}
                    placeholder={t('passwordForms.newPassLabel')}
                    label={t('passwordForms.repeatedPassLabel')}
                    autoComplete="off"
                    inputHiddenText
                  />

                  {errors.repeatedPassword && touched.repeatedPassword && (
                    <Error>{errors.repeatedPassword}</Error>
                  )}
                </FormGroup>
              </>
            )}
            {errors.custom && <Error bottom={-5}>{errors.custom}</Error>}
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {isTwoFactorForm
                  ? t('passwordForms.submitCode')
                  : t('passwordForms.buttonRestoreLabel')}
              </Button>
            </ButtonWrapper>
          </FormGrid>
        )}
      </Formik>
    </>
  );
};

RestorePasswordForm.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    newPassword: PropTypes.string.isRequired,
    repeatedPassword: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    mfaSecret: PropTypes.string.isRequired,
  }).isRequired,
};

export default RestorePasswordForm;
