import React, { useCallback, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { twoFactorFromOptions } from 'utils/services/request/auth';
import {
  ModalBodyWrapper,
  ModalLabel,
  ModalDesc,
  ButtonWrapper,
} from './styled-ui';

const validationScheme = t => values => {
  const errors = {};
  if (!values.code) {
    errors.code = t('signUpForm.errors.requiredField');
  }
  return errors;
};

const Decline2FA = ({ t, hideModal, userUpdate }) => {
  const stateSubmitingLoginForm = useAxiosSubmitingEffect(twoFactorFromOptions);
  const onSubmitHandler = useCallback(
    (values, actions) =>
      stateSubmitingLoginForm.setFormAndSubmit({
        values,
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      const { actions } = stateSubmitingLoginForm.form;

      if (stateSubmitingLoginForm.error) {
        if (stateSubmitingLoginForm.error.status === 'INVALID_CODE') {
          if (
            stateSubmitingLoginForm.error.message === 'Secret must not be empty'
          ) {
            actions.setErrors({
              code: t('passwordForms.errors.requiredField'),
            });
          }
        }
        if (stateSubmitingLoginForm.error.message === 'Invalid code') {
          actions.setErrors({
            code: t('passwordForms.errors.invalidCode'),
          });
        }
      }
      if (stateSubmitingLoginForm.data) {
        userUpdate();
        hideModal();
      }
      actions.setSubmitting(false);
      actions.setStatus(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm.loaded]);

  return (
    <ModalBodyWrapper>
      <ModalLabel>{t('profile.modalLabels.decline2Fa')}</ModalLabel>
      <ModalDesc>{t('profile.modalLabels.decline2FaDesc')}</ModalDesc>
      <Formik
        initialValues={{ code: '', state: false }}
        validate={validationScheme(t)}
        onSubmit={onSubmitHandler}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Field
              type="text"
              name="code"
              component={Input}
              placeholder="******"
              label={t('profile.inputLabels.code')}
            />
            {errors.code && <Error isSupportPage>{errors.code}</Error>}
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('profile.modalLabels.approveButton')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalBodyWrapper>
  );
};

export default Decline2FA;
