import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CardReceiveIcon } from 'assets/images/cards/card-receive.svg';
import cryptoCardImage from 'assets/images/cards/cryptoCard.svg';
import cryptoVibeImage from 'assets/images/cryptoVibe.png';

import {
  Button,
  Checkbox,
  Wrapper,
  Top,
  Title,
  TopLeft,
  CardReceiveContainer,
  BottomTitle,
  CryptoCardImage,
  CryptoVibeImage,
  SmallPurpleText,
  BigPurpleText,
  BigPurplePercent,
  SmallGreyText,
  SmallGreyPercent,
} from './styled-ui';

const CryptoVibeModal = () => {
  const { t } = useTranslation();
  const [isModalDisabled, setIsModalDisabled] = useState(
    JSON.parse(localStorage.getItem('isCryptoVibeModalDisabled'))
  );

  const toggleModalHandler = () => {
    setIsModalDisabled(!isModalDisabled);
    localStorage.setItem('isCryptoVibeModalDisabled', !isModalDisabled);
  };

  return (
    <Wrapper>
      <Top>
        <TopLeft>
          <div>
            <CryptoCardImage src={cryptoCardImage} alt="Crypto card image" />
            <Title>{t('cryptoVibeModal.title')}</Title>
          </div>
          <div>
            <div>
              <BigPurpleText>1.5</BigPurpleText>
              <BigPurplePercent>%&nbsp;</BigPurplePercent>

              <SmallGreyText>2.5</SmallGreyText>
              <SmallGreyPercent>%</SmallGreyPercent>
              <CardReceiveContainer>
                <CardReceiveIcon /> {t('cryptoVibeModal.sell')}
              </CardReceiveContainer>
            </div>
            <div>
              <SmallPurpleText>от</SmallPurpleText>
              <BigPurpleText>2</BigPurpleText>
              <BigPurplePercent>%&nbsp;</BigPurplePercent>

              <SmallGreyText>3.0</SmallGreyText>
              <SmallGreyPercent>%</SmallGreyPercent>
              <CardReceiveContainer>
                <CardReceiveIcon /> {t('cryptoVibeModal.buy')}
              </CardReceiveContainer>
            </div>
          </div>
        </TopLeft>
        <CryptoVibeImage src={cryptoVibeImage} alt="Crypto vibe image" />
      </Top>
      <Button href="https://info.whitebird.io/crypto-vibe/">
        {t('cryptoVibeModal.button')}
      </Button>
      <BottomTitle>
        <Checkbox
          type="checkbox"
          onChange={toggleModalHandler}
          checked={isModalDisabled}
        />
        {t('cryptoVibeModal.checkboxTitle')}
      </BottomTitle>
    </Wrapper>
  );
};

export default CryptoVibeModal;
