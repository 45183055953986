import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 8px 8px 24px;

  @media ${device.mobileL} {
    padding: 0px 0px 16px;
  }
`;

export const Top = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  border-radius: 4px;
  padding: 32px;
  background: ${props => props.theme.grayBorder};
  width: 100%;
  transition: padding 0.3s;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: center;
    padding: 32px 16px;
  }

  @media ${device.mobileL} {
    padding: 32px 8px;
  }
`;

export const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    transition: gap 0.3s;
  }

  @media ${device.laptop} {
    & > div {
      gap: 15px;
    }
  }

  @media ${device.mobileL} {
    & > div:last-child {
      gap: 10px;
    }
  }
`;

export const CryptoVibeImage = styled.img``;

export const CryptoCardImage = styled.img`
  width: 175px;
  border-radius: 8px;
  transition: width 0.3s;

  @media ${device.mobileL} {
    width: 150px;
  }
`;

export const Title = styled.span`
  display: block;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: ${props => props.theme.fullBlackText};
  max-width: 200px;
  transition: font-size 0.3s;

  @media ${device.mobileL} {
    font-size: 22px;
  }
`;

export const Checkbox = styled.input`
  border: 1px solid ${props => props.theme.blueBg};
  border-radius: 4px;
  width: 24px;
  height: 24px;
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: ${props => props.theme.fullWhite};
  background-color: ${props => props.theme.blueBg};
  height: 58px;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.theme.fullWhite};
    color: ${props => props.theme.blueBg};
    border: 1px solid ${props => props.theme.blueBg};
  }
`;

export const BigPurpleText = styled.span`
  font-weight: 700;
  font-size: 64px;
  color: ${props => props.theme.purple};
  transition: font-size 0.3s;

  .percent {
    font-size: 34px;
  }

  .text {
    font-weight: 400;
    font-size: 22px;
  }

  @media ${device.laptop} {
    font-size: 56px;

    .percent {
      font-size: 30px;
    }

    .text {
      font-size: 18px;
    }
  }

  @media ${device.tablet} {
    font-size: 44px;

    .percent {
      font-size: 26px;
    }

    .text {
      font-size: 16px;
    }
  }
`;

export const BigPurplePercent = styled.span`
  font-weight: 700;
  font-size: 34px;
  color: ${props => props.theme.purple};
  transition: font-size 0.3s;

  @media ${device.laptop} {
    font-size: 30px;
  }

  @media ${device.tablet} {
    font-size: 26px;
  }
`;

export const SmallPurpleText = styled.span`
  font-weight: 400;
  font-size: 22px;
  color: ${props => props.theme.purple};
  transition: font-size 0.3s;

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const SmallGreyText = styled.span`
  font-weight: 500;
  font-size: 26px;
  color: ${props => props.theme.blackText};
  opacity: 0.6;
  text-decoration: line-through;
  transition: font-size 0.3s;

  @media ${device.laptop} {
    font-size: 24px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const SmallGreyPercent = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.blackText};
  text-decoration: unset;
  transition: font-size 0.3s;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const CardReceiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const BottomTitle = styled.div`
  padding: 0px 15px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${props => props.theme.blackText};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
