const blueTheme = {
  btnColor: '#0069ff',
  btnLightColor: '#0290ea',
  errorBtnColor: '#1f40cb',
  btnHoverColor: '#0069ff',
  btnDisabledColor: '#D3E4fd',
  blackText: '#1e1e28',
  inputErrorColor: '#c6384c',
  borderErrorColor: '#f3dcdc',
  grayText: '#8f9db1',
  grayTextLight: '#b7bac6',
  fullBlackText: '#000',
  grayBg: '#f6f6f6',
  dimgray: '#a9a9a9',
  darkgray: '#696969',
  blueBg: '#0069ff',
  blue: '#1677ff',
  borderAlertSuccess: '#fff2f0',
  borderAlertError: '#f6ffed',
  borderCard: '#aebac64d',
  blueType2: '#1890ff',
  fullWhite: '#fff',
  lightBlue: '#2b82ff',
  lightBlueWhite: 'rgba(255,255,255, 0.6)',
  grayCharge: '#929ba8',
  errorColor: '#ef4a40',
  descriptionBgColor: '#010101',
  green: '#3fc57f',
  orange: '#f2921b',
  linkColor: '#0069ff',
  selectHoverColor: '#b2d4ff',
  emptySelectHoverBackground: '#eff3f7',
  bgFooterColor: '#1b39ce',
  bgModalColor: 'rgba(21, 50, 189, 0.9)',
  grayBorder: '#e2e2e2',
  purple: '#cc01ff',
  favicon: '/assets/images/favicon.png',
  mainLogo: '/assets/images/light-logo.svg',
  footerLogo: '/assets/images/main-logo.svg',
  uploadImage: '/assets/images/upload.svg',
  checkboxImage: '/assets/images/icons/checkboxV2.svg',
  shortLogo: '/assets/images/main-logo.svg',
  emailImage: '/assets/images/mail.svg',
  mfaImage: '/assets/images/mfa.svg',
  cancelImage: '/assets/images/cancel.svg',

  notificatonErrorColor: '#ff2a1d',
};

export default blueTheme;
