import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const modalRoot = document.getElementById('modal');
  const el = document.createElement('div');
  const [scrollY, setScrollY] = useState(0);

  function preventDefault(e) {
    e.preventDefault();
  }

  useEffect(() => {
    setScrollY(window.scrollY);
  }, []);

  useEffect(() => {
    modalRoot.appendChild(el);
    document.documentElement.classList.add('is-locked');
    modalRoot.addEventListener('touchmove', preventDefault);

    return () => {
      document.documentElement.classList.remove('is-locked');
      modalRoot.removeEventListener('touchmove', preventDefault);
      if (scrollY && window.innerWidth <= 920) window.scrollTo(0, scrollY);
      if (modalRoot.contains(el)) {
        modalRoot.removeChild(el);
      }
    };
  }, [el, modalRoot, scrollY]);

  return createPortal(children, el);
};

export default Portal;
