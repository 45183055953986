import styled, { keyframes } from 'styled-components';
import device from 'device';

import { ReactComponent as CloseEyeImage } from 'assets/images/icons/close-eye.svg';
import { ReactComponent as OpenEyeImage } from 'assets/images/icons/open-eye.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';

export const SettingsWrapper = styled.div`
  max-width: ${({ isError }) => (isError ? '100%' : 'max-content')};
  min-width: 400px;
  margin: 0 auto;
  padding: 35px 15px;

  @media ${device.mobile920} {
    min-width: 320px;
  }
  @media ${device.mobileL} {
    min-width: 300px;
    padding: 35px 0;
  }
  @media ${device.mobileM} {
    min-width: 280px;
    margin-left: 30px;
  }
`;

export const Block = styled.div`
  > * {
    margin-bottom: ${({ isInputBlock }) => (isInputBlock ? '35px' : 0)};
  }
  margin-bottom: 35px;
`;

export const SettingsHeader = styled.h4`
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0 0 35px 0;
  color: ${({ theme }) => theme.blackText};
`;

export const Error = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0;
  color: ${({ theme }) => theme.blackText};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const FormGroup = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  position: relative;
  :input {
    max-height: 20px;
  }
  @media ${device.mobile920} {
    margin-bottom: ${({ isInputActivated }) =>
      isInputActivated ? '95px' : '35px'};
  }
`;

export const WrapEye = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 1;
`;

export const OpenEyeIcon = styled(OpenEyeImage)`
  cursor: pointer;
`;

export const CloseEyeIcon = styled(CloseEyeImage)`
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  margin-left: 15px;
  position: absolute;
  height: 48px;
  left: 100%;
  display: flex;
  > * {
    margin-right: 5px;
    padding: 0px 10px;
  }
  @media ${device.mobile920} {
    margin-top: 35px;
    position: initial;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    > * {
      margin-right: 0;
      margin-bottom: 15px;
      min-height: 48px;
    }
  }
`;

const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const OnSuccessText = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.btnColor};
  text-align: center;
  opacity: 0;
  animation: ${FadeOut} 3s linear;
`;

export const EditIconButton = styled(EditIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.grayText};
`;

export const EditButton = styled.button`
  position: absolute;
  top: 12px;
  right: -45px;
  padding: 0;
  margin-left: 15px;
  cursor: pointer;
  @media ${device.mobile920} {
    right: -35px;
  }
`;
