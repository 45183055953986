import React from 'react';
import Switch from 'react-switch';
import { SwitchLabel, SwitchWrapper } from './styled-ui';

const Switcher = ({ checked, onChange, disabled, padding, label }) => (
  <SwitchWrapper disabled={disabled} padding={padding}>
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor="#0069ff"
      offColor="#c2c7cc"
      height={23}
      width={45}
      handleDiameter={17}
    />
    {label && <SwitchLabel>{label}</SwitchLabel>}
  </SwitchWrapper>
);

export default Switcher;
