import React from 'react';
import { Tooltip, TooltipText } from '../styled-ui';

const DefaultTooltip = ({ children, tooltipText, onMouseLeave, margin }) => {
  return (
    <Tooltip margin={margin} onMouseLeave={onMouseLeave}>
      {children}
      <TooltipText>{tooltipText}</TooltipText>
    </Tooltip>
  );
};

export default DefaultTooltip;
