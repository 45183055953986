import styled from 'styled-components';
import device from 'device';
import { Link } from 'react-router-dom';
import { ReactComponent as notFoundImage } from 'assets/images/noFoundImage.svg';

export const ErrorWrapper = styled.div`
  position: ${({ isVerification }) =>
    isVerification ? 'absolute' : 'inherit'};
  top: ${({ isVerification }) => isVerification && '0'};
  left: ${({ isVerification }) => isVerification && '0'};
  width: 100%;
  max-width: ${({ isVerification }) => (isVerification ? 'inherit' : '1132px')};
  z-index: 999999;
  height: 323px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 0;
  margin: 0 auto;
  background-color: #c8deff;
  border-radius: 12px;
  @media ${device.laptopL} {
    max-width: ${({ isVerification }) => (isVerification ? 'inherit' : 'auto')};
  }
  @media ${device.laptop} {
    width: ${({ isVerification }) => (isVerification ? 'inherit' : '900px')};
  }
  @media ${device.mobile920} {
    width: ${({ isVerification }) => (isVerification ? 'inherit' : '500px')};
  }
  @media ${device.tablet} {
    width: ${({ isVerification }) => (isVerification ? 'inherit' : '350px')};
  }
  @media ${device.mobileM} {
    width: 100%;
  }
`;

export const ErrorTitle = styled.span`
  font-family: Inter;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #020202;
  @media ${device.mobileM} {
    font-size: 20px;
  }
`;

export const ErrorButton = styled.button`
  width: 500px;
  height: 60px;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.01em;
  background: ${props => props.theme.btnColor};
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  @media ${device.laptop} {
    width: 200px;
  }
`;

export const ErrorImage = styled(notFoundImage)`
  width: 165px;
  height: 115px;
}
`;

export const ErrorLink = styled(Link)`
  color: ${props => props.theme.btnColor};
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-align: center;
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 16px;
  }
`;
