import device from 'device';
import styled from 'styled-components';
import { ButtonLinkTransparentOnHeader } from 'ui-kit/Button';

export const GlobalWrapper = styled.div`
  overflow: hidden;
`;

export const CardFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  margin-bottom: 54px;
  @media ${device.laptopL} {
    margin-top: 54px;
    margin-bottom: 49px;
  }
  @media ${device.laptop} {
    align-items: initial;
    margin-top: 36px;
    margin-bottom: 32px;
  }
`;

export const HeaderText = styled.h3`
  font-weight: 500;
  color: ${({ theme, additional }) =>
    additional ? theme.grayText : theme.blackText};
  font-size: 2rem;
  text-align: center;
  margin: 0 23px;
  max-width: 966px;
  @media ${device.desktop1280} {
    font-size: 1.7rem;
  }
  @media ${device.mobile920} {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: initial;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 35px;
  margin: 0 auto 150px;
  padding: 0 50px;
  max-width: 3860px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1669px) {
    column-gap: 25px;
    padding: 0 25px;
  }
  @media ${device.laptop} {
    padding: 0 20px;
    margin: 0 auto 78px;
    overflow: scroll;
  }
`;

export const Card = styled.div`
  position: relative;
`;

export const CardImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  @media ${device.laptop} {
    width: 25.5vw;
    min-width: 270px;
  }
`;

export const CardButton = styled(ButtonLinkTransparentOnHeader)`
  position: absolute;
  top: 75%;
  left: 8%;
  align-self: center;
  padding: 6px 15px;
  @media ${device.laptop} {
    padding: 3px 10px;
  }
`;

export const CardLink = styled.a`
  position: absolute;
  top: 75%;
  left: 8%;
  width: fit-content;
  display: flex;
  align-items: center;
  align-self: center;
  padding: 6px 15px;
  font-size: 1rem;
  line-height: 24px;
  color: #1e1e28;
  background-color: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  @media ${device.laptop} {
    padding: 3px 10px;
  }
`;
