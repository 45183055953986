import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { resendMerchantWebhook } from 'utils/services/request/merchant';
import { StatusWrapper, ResendButton } from './styled-ui';
import { TableRow } from '../MerchantBalancesTable/styled-ui';

const MerchantWebhookTableRow = ({ t, webhook }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resendWebhook = () => {
    setIsLoading(true);
    axios(resendMerchantWebhook(webhook.id)).then(() => setIsLoading(false));
  };

  return (
    <TableRow key={webhook.id}>
      <td>
        <StatusWrapper>{webhook.responseCode}</StatusWrapper>
      </td>
      <td>{webhook.type}</td>
      <td>{webhook.responseMessage}</td>
      <td>
        {DateTime.fromISO(webhook.createdAt).toFormat('dd.MM.yyyy HH:mm:ss')}
      </td>
      <td>
        {DateTime.fromISO(webhook.lastSentAt).toFormat('dd.MM.yyyy HH:mm:ss')}
      </td>
      <td>
        <ResendButton onClick={resendWebhook} disabled={isLoading}>
          {t('merchantPage.resend')}
        </ResendButton>
      </td>
      <td />
    </TableRow>
  );
};

export default MerchantWebhookTableRow;
