import React from 'react';
import qs from 'qs';
import { useAxiosState } from 'utils/hooks/axiosHook';
import getBanners from 'utils/services/request/banners';
import { CardsWrapper, Card, CardImg, CardButton, CardLink } from './styled-ui';

const query = qs.stringify(
  {
    locale: ['ru'],
    sort: ['Order:asc'],
    populate: '*',
  },
  {
    encodeValuesOnly: true,
  }
);

const Cards = () => {
  const banners = useAxiosState(getBanners(query));

  const renderCardInfo = banner => {
    return (
      <Card key={banner.id}>
        {banner.attributes.Image.data && (
          <CardImg
            alt={banner.attributes.Image.data.attributes.alternativeText}
            src={`${process.env.REACT_APP_STRAPI_URL}${banner.attributes.Image.data.attributes.url}`}
          />
        )}
        {banner.attributes.newsLink && (
          <CardButton to={banner.attributes.newsLink}>
            {banner.attributes.buttonText}
          </CardButton>
        )}
        {banner.attributes.siteLink && (
          <CardLink
            target="_blank"
            href={banner.attributes.siteLink}
            rel="nofollow"
          >
            {banner.attributes.buttonText}
          </CardLink>
        )}
      </Card>
    );
  };

  return (
    <CardsWrapper>
      {banners?.data?.data?.map(banner => renderCardInfo(banner))}
    </CardsWrapper>
  );
};

export default Cards;
