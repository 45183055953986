const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  laptopXL: '1920px',
  desktop: '2560px',

  desktop1280: '1280px',
  mobile920: '920px',
  mobileHeader: '970px',
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopXL: `(max-width: ${size.laptopXL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  desktop1280: `(max-width: ${size.desktop1280})`,
  mobile920: `(max-width: ${size.mobile920})`,
  mobileHeader: `(max-width: ${size.mobileHeader})`,
};

export default device;
