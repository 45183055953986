import iconv from 'iconv-lite';

export const fileDownload = (fileName, byte) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(byte);
  link.download = fileName;
  link.click();
};

export const reportDownloadANSI = (response, type) => {
  const header = response.headers['content-disposition'];
  const filename = header.substring(header.indexOf('=') + 1, header.length);
  const blob = new Blob([iconv.encode(response.data, 'win1251')], { type });
  fileDownload(filename, blob);
};
