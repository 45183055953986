import styled from 'styled-components';
import { ModalCard } from 'ui-kit/Modal/styled-ui';
import device from 'device';
import { ReactComponent as RectangleQr } from 'assets/images/RectangleQr.svg';

export const CustomCard = styled(ModalCard)`
  width: 933px;
  height: 457px;
  padding: 100px 49px 38px;
`;

export const ModalHeading = styled.div`
  margin-bottom: 21px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 500;
    font-size: 21px;
  }
  button {
    color: ${({ theme }) => theme.linkColor};
    margin: 0 !important;
    padding: 0;
    cursor: pointer;
  }
`;

export const ModalLabel = styled.span`
  font-weight: 500;
  font-size: 28px;
  line-height: 125%;
  position: absolute;
  width: 275px;
  height: 35px;
  left: 49px;
  top: 44px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 0;
`;

export const SubmitWrapper = styled.div`
  height: 48px;
  margin-top: 38px;

  button {
    width: 301px;
  }
`;

export const Input = styled.input`
  height: 38px;
  border: 1px solid ${({ error }) => (error ? '#f00' : '#dedede')};
  border-radius: 8px;
  margin: 10px 0;
  padding: 5px 10px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #f00;
  margin: 0 auto 15px 3px;
  display: inline-flex;
`;

export const ModalContent = styled.div`
  display: flex;
`;

export const PaymentRequisites = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 23px;
  }
`;

export const QRCode = styled.div`
  position: absolute;
  height: 200px;
  top: 3px;
  left: 3px;
  z-index: -1;
  display: flex;
  justify-content: center;
`;

export const QrRectangle = styled(RectangleQr)`
  height: 204px;
  width: 204px;
`;

export const WrapperQr = styled.div`
  position: relative;
  @media ${device.tablet} {
    display: none;
  }
`;

export const WarningMessage = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 13px;
  color: ${({ theme }) => theme.inputErrorColor};
`;

export const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 23px;

  svg {
    margin-left: 18px;
  }

  span {
    margin: 0;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 577px;
  height: 54px;
  left: 49px;
  top: 201px;
  background: ${({ theme }) => theme.grayBg};
  border-radius: 8px;

  span {
    margin: auto auto auto 16px;
  }

  button {
    margin: auto 10px auto auto;
    cursor: pointer;
  }
`;
