import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-kit/Button';
import Portal from 'ui-kit/Modal';
import { Window, ModalWrapper, Close } from 'ui-kit/Modal/styled-ui';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getMerchantPaymentQr } from 'utils/services/request/merchant';
import LoadingPlaceholderQr from 'ui-kit/LoadingPlaceholder/qr';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copyV2.svg';
import {
  CustomCard,
  ModalContent,
  ModalLabel,
  PaymentRequisites,
  SubmitWrapper,
  QrRectangle,
  WrapperQr,
  QRCode,
  WarningMessage,
  CurrencyWrapper,
  AddressWrapper,
} from './styled-ui';

const FiltersModal = ({ t, modalData, setModalData, getCurrencyLabel }) => {
  const { data: addressQrData } = useAxiosState(getMerchantPaymentQr, []);
  return (
    <Portal>
      <Window>
        <ModalWrapper noRoute>
          <CustomCard>
            <Close alt="close" onClick={() => setModalData(null)} />
            <ModalContent>
              <PaymentRequisites>
                <ModalLabel>{t('merchantPage.paymentRequisites')}</ModalLabel>
                <CurrencyWrapper>
                  {t('merchantPage.currency')}
                  <>{getCurrencyLabel(modalData.currency)}</>
                </CurrencyWrapper>
                <span>{t('merchantPage.paymentAddress')}</span>
                <AddressWrapper>
                  <span>{modalData.depositCryptoAddress}</span>
                  <button
                    type="button"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        modalData.depositCryptoAddress
                      )
                    }
                  >
                    <CopyIcon />
                  </button>
                </AddressWrapper>
                <WarningMessage>
                  {t('merchantPage.paymentWarning')}
                </WarningMessage>
              </PaymentRequisites>
              <WrapperQr>
                <QrRectangle />
                <QRCode>
                  {!addressQrData ? (
                    <LoadingPlaceholderQr
                      options={{ width: '150px', height: '150px' }}
                    />
                  ) : (
                    <img src={addressQrData} alt="" />
                  )}
                </QRCode>
              </WrapperQr>
            </ModalContent>
            <SubmitWrapper>
              <Button type="button" onClick={() => setModalData(null)}>
                {t('merchantPage.iSent')}
              </Button>
            </SubmitWrapper>
          </CustomCard>
        </ModalWrapper>
      </Window>
    </Portal>
  );
};

FiltersModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default FiltersModal;
