import React from 'react';

const Iframe = ({ iframesrc, width, height, title }) => (
  <iframe
    src={iframesrc}
    width={width}
    height={height}
    title={title}
    frameBorder="0"
  />
);

export default Iframe;
