import styled from 'styled-components';
import device from 'device';

export const ProfileWrapper = styled.div`
  position: relative;
`;

export const ProfileView = styled.div`
  box-shadow: 1px 1px 1px 0 #fff;
  background-color: ${props => props.theme.fullWhite};
`;

export const OperationWrapper = styled.div`
  padding: 35px 15px;

  @media ${device.mobileL} {
    padding: 30px 10px;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;

  @media ${device.laptop} {
    top: 5px;
  }
`;

export const Loader = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid #0069ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 0 auto;
  display: block;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
