import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { updateAxiosDefault } from 'utils/axiosUtil';
import UserContext from 'utils/contexts/User';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { setToken } from 'utils/services/request/localStorage';
import { loginFormOptions } from 'utils/services/request/auth';
import SignInForm from 'components/Auth/SignIn/form';
import TwoFactorCode from 'components/Auth/SignIn/twoFactor';
import { pushAnalyticsEvent } from 'components/App/analytics';

const SignIn = ({ hideModal, handleGoToOAuth }) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [enabled, setEnable] = useState(false);
  const initData = {
    email: '',
    password: '',
  };
  const [initialValues, setData] = useState(initData);
  const stateSubmitingLoginForm = useAxiosSubmitingEffect(loginFormOptions);

  const onSubmitHandler = useCallback((values, actions) => {
    const loginData = new FormData();
    loginData.append('grant_type', 'password');
    loginData.append(
      'username',
      values.email ? values.email.toLowerCase() : ``
    );
    loginData.append('password', values.password);

    stateSubmitingLoginForm.setFormAndSubmit({
      values: loginData,
      actions,
    });

    setData(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      if (stateSubmitingLoginForm.error) {
        const { actions } = stateSubmitingLoginForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        if (
          stateSubmitingLoginForm.error.error_description ===
          'Username is blocked'
        ) {
          actions.setErrors({
            email: '.',
            password: t('signInForm.errors.accountSigninLimited'),
          });
        } else {
          actions.setErrors({
            email: '.',
            password: t('signInForm.errors.incorrectCreds'),
          });
        }
      } else {
        if (stateSubmitingLoginForm.data.access_token) {
          setToken(stateSubmitingLoginForm.data);
          user.refetch();
          handleGoToOAuth();
          hideModal();
          pushAnalyticsEvent('login_success');
        }
        setEnable(stateSubmitingLoginForm.data.mfaEnabled);
        updateAxiosDefault();
      }
    } else if (stateSubmitingLoginForm.fetching) {
      const { actions } = stateSubmitingLoginForm.form;
      actions.setSubmitting(true);
      actions.setErrors({
        email: '',
        password: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm, stateSubmitingLoginForm.loaded]);

  if (enabled) {
    return <TwoFactorCode dataFrom={initialValues} t={t} laterButton />;
  }

  return (
    <SignInForm
      initialValues={initialValues}
      setEnable={setEnable}
      onSubmitHandler={onSubmitHandler}
      t={t}
    />
  );
};

export default SignIn;
