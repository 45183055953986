import React, { useState } from 'react';
import Switcher from 'ui-kit/Switcher';
import Spinner from 'ui-kit/Spinner';
import { Button } from 'ui-kit/Button';
import {
  Description,
  ButtonWrapper,
  ConsentFormTitle,
  CancelButton,
} from './styled-ui';

const ConsentForm = ({ hideModal, handleOnSubmit, isLoading, t }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <ConsentFormTitle>
        {t('profile.cryptoCardActions.consentForm.title')}
      </ConsentFormTitle>
      <Description>
        {t('profile.cryptoCardActions.consentForm.description')}
      </Description>
      <Switcher
        checked={isChecked}
        disabled={isLoading}
        onChange={() => setIsChecked(prev => !prev)}
        label={t('profile.cryptoCardActions.consentForm.toggleText')}
        padding="0 0 10px 0"
      />
      <ButtonWrapper marginTop="20px">
        <CancelButton type="button" onClick={hideModal} disabled={isLoading}>
          {t('profile.cryptoCardActions.consentForm.cancel')}
        </CancelButton>
        <Button type="button" onClick={handleOnSubmit} disabled={!isChecked}>
          {isLoading ? (
            <Spinner />
          ) : (
            t('profile.cryptoCardActions.consentForm.confirm')
          )}
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ConsentForm;
