import React from 'react';
import { LoaderWrapper, Loader } from './styled-ui';

const AnimatedLoader = () => {
  return (
    <LoaderWrapper>
      <Loader>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Loader>
    </LoaderWrapper>
  );
};

export default AnimatedLoader;
