import styled from 'styled-components';
import device from 'device';

export const LoadingPlaceholderImg = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  border-radius: 12px;
  width: 100%;
  height: ${({ mediaView }) => (mediaView ? '50vh' : '250px')};
  margin-bottom: 30px;
  @media ${device.laptopXL} {
    height: ${({ mediaView }) => (mediaView ? '50vh' : '200px')};
  }
  @media ${device.laptop} {
    height: ${({ mediaView }) => (mediaView ? '25vh' : '200px')};
  }
  @media ${device.mobile920} {
    height: ${({ mediaView }) => (mediaView ? '25vh' : '190px')};
    max-width: inherit;
  }
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const LoadingPlaceholderText = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  width: 100%;
  height: ${({ mediaView }) => (mediaView ? '50px' : '30px')};
  border-radius: 12px;
  margin-bottom: 30px;
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: ${({ isMainPage }) => !isMainPage && '100vh'};
  background-color: ${({ theme, isMainPage }) => !isMainPage && theme.blueBg};
  width: ${({ mediaView }) => (mediaView ? '100%' : '65%')};
  margin: 0 auto;
  @media ${device.laptopL} {
    width: ${({ mediaView }) => (mediaView ? '100%' : '80%')};
  }
  @media ${device.laptop} {
    width: 100%;
  }
`;

export const WrapperMedia = styled.div`
  flex: 1 0 40%;
  margin: ${({ mediaView }) => (mediaView ? '100px 0 0 0' : '40px')};
  :nth-child(n + 2) {
    display: ${({ mediaView }) => mediaView && 'none'};
  }
  @media ${device.laptop} {
    margin: 0;
    padding: ${({ isMainPage, mediaView }) =>
      !isMainPage && !mediaView && '25px 25px 50px'};
    box-sizing: border-box;
  }
  @media ${device.mobile920} {
    padding: ${({ isMainPage }) => isMainPage && '20px'};
  }
  @media (max-width: 580px) {
    flex: 1 0 100%;
    :nth-child(n + 2) {
      display: none;
    }
  }
`;
