import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  max-width: 736px;
  margin: 0 auto;
  width: 100%;
  @media ${device.laptop} {
    padding: 0 25px;
    box-sizing: border-box;
  }
`;

export const Info = styled.div`
  line-height: 150%;
  padding-bottom: 10px;
  white-space: pre-line;
  color: ${props => props.theme.blackText};
  font-size: 18px;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const InfoGray = styled.div`
  line-height: 150%;
  padding-top: 30px;
  padding-bottom: 30px;
  white-space: pre-line;
  color: ${props => props.theme.grayText};
  font-size: 18px;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const CardsWrapper = styled.div`
  margin: 0 auto;
`;

export const CardImg = styled.img`
  height: 100%;
  width: 100%;
  @media ${device.laptop} {
    margin-right: 20px;
  }
`;
