import { useState } from 'react';

const initialState = {
  open: false,
  message: '',
  isError: false,
};

const UseModal = () => {
  const [modal, setModal] = useState(initialState);

  const openModal = props => {
    setModal({
      ...props,
      open: !modal.open,
    });
  };

  const closeModal = () => setModal({ ...modal, open: !modal.open });

  return { modal, openModal, closeModal };
};

export default UseModal;
