import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORT } from 'constants/routings';
import {
  ErrorWrapper,
  ErrorTitle,
  ErrorButton,
  ErrorImage,
  ErrorLink,
} from './styled-ui';

const ErrorModal = ({ isVerification, title, isReloadButton }) => {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <ErrorWrapper isVerification={isVerification}>
      <ErrorTitle>{title || t('errorModal.title')}</ErrorTitle>
      <ErrorImage />
      {isReloadButton && (
        <ErrorButton type="button" onClick={handleReload}>
          {t('errorModal.button')}
        </ErrorButton>
      )}
      <ErrorLink to={SUPPORT}>{t('errorModal.link')}</ErrorLink>
    </ErrorWrapper>
  );
};

ErrorModal.propTypes = {
  isVerification: PropTypes.bool,
  isReloadButton: PropTypes.bool,
};

ErrorModal.defaultProps = {
  isVerification: false,
  isReloadButton: false,
};

export default ErrorModal;
