import styled from 'styled-components';
import device from 'device';

export const PresentationGrid = styled.div`
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 3.55rem;
  text-align: center;
  letter-spacing: 0.7px;
  margin-top: 0;
  color: ${props => props.theme.fullWhite};
  @media ${device.desktop1280} {
    margin-bottom: 16px;
    font-size: 2.9rem;
    line-height: 120%;
    letter-spacing: 0;
  }
  @media ${device.mobile920} {
    margin-bottom: 16px;
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 120%;
  }
`;

export const Description = styled.h1`
  color: ${props => props.theme.lightBlueWhite};
  font-size: 1.45rem;
  margin: 0;
  font-weight: 400;
  padding-bottom: 40px;
  text-align: center;
  letter-spacing: 0.5px;
  max-width: 966px;
  margin: 0 auto;
  @media ${device.desktop1280} {
    font-size: 1.4rem;
    line-height: 120%;
    letter-spacing: 0;
  }
  @media ${device.mobile920} {
    font-size: 1rem;
    padding-bottom: 16px;
  }
`;

export const IconWrapper = styled.div`
  display: ${props => (props.isSmallView ? 'none' : 'flex')};
  > * {
    margin-right: 56px;
  }
  > *:last-child {
    margin-right: 0;
  }
  @media (max-width: 1024px) {
    display: ${props => (props.isSmallView ? 'flex' : 'none')};
    margin-top: 25px;
    row-gap: 10px;
    justify-content: center;
  }
  @media (max-width: 480px) {
    > * {
      margin-right: 0;
    }
    justify-content: space-around;
    flex-wrap: wrap;
  }
  @media (max-width: 400px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const BoldText = styled.div`
  font-weight: 500;
  font-size: 48px;
  @media (max-width: 480px) {
    font-size: 32px;
  }
`;
