import styled from 'styled-components';

export const SessionTimer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 219px;
  width: 310px;
  background-color: #f1a034;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 999999;
  margin: 20px;
`;

export const Countdown = styled.div`
  color: #323233;
  font-size: 14px;
  font-weight: bold;
  padding-top: 30px;
`;

export const Title = styled.div`
  color: #323233;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 23px;
`;

export const Description = styled.div`
  padding-top: 14px;
  padding: 14px 20px 0 20px;
`;
export const Text = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
  color: #323233;
  font-size: 12px;
  line-height: 16px;
`;
