export const clientInit = `${process.env.REACT_APP_ALFA_URL}/release/partner/init`;

export const clientValidate = {
  method: 'post',
  url: `${process.env.REACT_APP_ALFA_URL}/release/partner/validate`,
};

export const clientInfo = `${process.env.REACT_APP_ALFA_URL}/release/partner/client-info `;

export const getCryptoCardStatus = {
  method: 'get',
  url: `${process.env.REACT_APP_ALFA_URL}/release/crypto-card-release-details`,
};

export const getCryptoCardDetails = {
  method: 'get',
  url: `${process.env.REACT_APP_ALFA_URL}/customer/card/details`,
};

export const attachAlfaCard = ({ failUrl, returnUrl }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ALFA_URL}/customer/card/bind/url`,
  data: {
    failUrl,
    returnUrl,
  },
});

export const removeAlfaCard = id =>
  `${process.env.REACT_APP_ALFA_URL}/customer/card/unbind/${id}`;

export const cryptoCardCheckLimit = `${process.env.REACT_APP_ALFA_URL}/limit/check`;

export const getCryptoCardContentPage = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/crypto-card-contents/?${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});
