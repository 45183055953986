import styled from 'styled-components';
import device from 'device';

export const CopyButton = styled.button`
  width: 50px;
  background-color: ${props => props.theme.grayBg};
  color: white;
  border: 0;
  -webkit-appearance: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  height: 28px;
  @media ${device.mobileL} {
    width: 30px;
    height: 24px;
  }
  @media (max-width: 280px) {
    width: 30px;
    height: 24px;
  }
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: max-content;
  background-color: ${props => props.theme.fullBlackText};
  color: #fff;
  font-size: 15px;
  text-align: center;
  white-space: pre-line;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -50px;

  &.arrow:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
`;

export const Tooltip = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  position: relative;
  display: inline-flex;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;
