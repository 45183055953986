import { PropTypes } from 'prop-types';
import { Trans } from 'react-i18next';
import React from 'react';
import LoadingPlaceholderQr from 'ui-kit/LoadingPlaceholder/qr';
import Checkbox from 'ui-kit/Checkbox';
import Wallet from './address';
import {
  Hint,
  Instruction,
  StyledPendingInstruction,
  Title,
  QRCode,
  AddressInfo,
  AddressTextInfo,
  IconFirst,
  IconSecond,
  QrRectangle,
  WrapperQr,
  LabelText,
  InnerHover,
  WrapperHover,
  InfoIcon,
  CheckboxWrapper,
} from './styled-ui';

const PendingInstruction = ({
  additionalSubmitTimeout,
  setAdditionalSubmitTimeout,
  address,
  walletQrData,
  t,
  title,
  instruction,
}) => (
  <StyledPendingInstruction>
    {title && <Title>{title}</Title>}
    <Instruction>
      <IconFirst />
      {instruction}
    </Instruction>
    <AddressInfo>
      <AddressTextInfo>
        <Wallet address={address} t={t} />
        <Hint>{t('pending.errorLabel')}</Hint>
      </AddressTextInfo>
      <WrapperQr>
        <QrRectangle />
        <QRCode>
          {!walletQrData ? (
            <LoadingPlaceholderQr
              options={{ width: '150px', height: '150px' }}
            />
          ) : (
            <img src={walletQrData} alt="" />
          )}
        </QRCode>
      </WrapperQr>
    </AddressInfo>
    <Instruction>
      <IconSecond />
      {t('pending.instruction2')}
    </Instruction>
    <Instruction>
      <CheckboxWrapper>
        <Checkbox
          checked={additionalSubmitTimeout}
          onChange={() => setAdditionalSubmitTimeout(!additionalSubmitTimeout)}
        >
          <LabelText>{t('pending.checkboxText')}</LabelText>
          <WrapperHover>
            <InfoIcon />
            <InnerHover>
              <Trans i18nKey="pending.checkboxHover" t={t} />
            </InnerHover>
          </WrapperHover>
        </Checkbox>
      </CheckboxWrapper>
    </Instruction>
  </StyledPendingInstruction>
);

PendingInstruction.propTypes = {
  address: PropTypes.string.isRequired,
};

export default PendingInstruction;
