import styled from 'styled-components';
import device from 'device';

export const Switcher = styled.div`
  background-color: ${props => props.theme.lightBlue};
  margin: 0 auto 30px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  @media ${device.mobile920} {
    width: auto;
    margin: 25px;
  }
  > button {
    font-size: 0.96rem;
    @media (max-width: 520px) {
      flex-basis: 50%;
    }
    @media ${device.mobileM} {
      flex-basis: 100%;
    }
  }
`;

export const GridWrapper = styled.div`
  display: grid;
`;
