import styled from 'styled-components';
import device from 'device';
import { Form } from 'formik';
import { ReactComponent as Cancel } from 'assets/images/icons/notification-close.svg';

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: -30px;
  bottom: initial;
  @media ${device.mobile920} {
    top: initial;
    left: 0;
    bottom: -70px;
    display: flex;
    justify-content: center;
    border-radius: 5px;

    background-color: ${({ isPromoApplied, isPromoActive, theme }) =>
      isPromoActive && !isPromoApplied && theme.lightBlue};
    margin: 0 auto;
    height: 40px;
    box-sizing: border-box;
    border: ${({ isPromoApplied, theme }) =>
      isPromoApplied && `1px solid ${theme.lightBlueWhite}`};
  }
`;

export const Input = styled.input.attrs(props => ({
  type: props.type || 'text',
  placeholder: props.placeholder || undefined,
}))`
  border: none;
  font-size: 0.9rem;
  font-family: 'Inter';
  color: ${props => props.theme.fullWhite};
  background-color: ${props => props.theme.lightBlue};
  height: 20px;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  width: 12ch;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${props => props.theme.lightBlue} inset !important;
    caret-color: ${props => props.theme.fullWhite};
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: ${props => props.theme.fullWhite} !important;
  }
  @media ${device.mobile920} {
    width: inherit;
  }
`;

export const ApplyPromoButton = styled.button`
  cursor: pointer;
  font-size: 0.9rem;
  font-family: 'Inter';
  border: 0;
  color: ${props => props.theme.lightBlueWhite};
  @media ${device.mobile920} {
    padding: 0;
  }
`;

export const PromoInfoWrapper = styled.div`
  color: ${props => props.theme.grayText};
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  @media ${device.laptop} {
    top: -9px;
    left: 9px;
  }
  @media ${device.mobile920} {
    font-size: 0.9rem;
  }
  padding: 0 2px;
  z-index: 1;
`;

export const OpenPromoButton = styled.button`
  cursor: pointer;
  font-family: 'Inter';
  font-size: 0.9rem;
  border-radius: 6px;
  height: 30px;
  color: ${({ theme }) => theme.lightBlueWhite};
  @media ${device.mobile920} {
    border: 1px solid ${props => props.theme.lightBlueWhite};
    max-width: 320px;
    width: 320px;
    margin: 0 auto;
    height: 40px;
    padding: 10px 0;
  }
`;

export const AppliedPromo = styled.span`
  color: ${({ theme }) => theme.fullWhite};
`;

export const WForm = styled(Form)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  @media ${device.mobile920} {
    height: initial;
    width: calc(100% - 20px);
  }
`;

export const CancelIcon = styled(Cancel)`
  display: block;
  circle {
    fill: #2a82ff;
  }
  path {
    stroke: #fff;
  }
`;

export const EMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EText = styled.span`
  color: ${({ theme }) => theme.errorColor};
`;
