import styled from 'styled-components';
import device from 'device';

const TextareaWrapper = styled.div`
  .textareaAutoResize {
    font-family: 'Inter';
    color: ${props => props.theme.blackText};
    font-size: 18px;
    width: -webkit-fill-available;
    resize: none;
    padding: 10px 12px;
    border: 1px solid
      ${props => (props.error ? '#FF0200' : props.theme.grayBorder)};
    border-radius: 4px;
    position: relative;
    line-height: 38px;
    @media ${device.laptop} {
      font-size: 14px;
    }
    ::placeholder {
      font-family: 'Inter';
      font-size: 18px;
      color: ${props => props.theme.grayText};
      @media ${device.laptop} {
        font-size: 14px;
      }
    }
  }
  textarea[error|='true'] {
    border: 1px solid red;
  }
`;

export default TextareaWrapper;
