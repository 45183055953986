import styled from 'styled-components';
import device from 'device';
import { ReactComponent as notFoundImage } from 'assets/images/noFoundImage.svg';

export const ErrorWrapper = styled.div``;

export const ErrorGrid = styled.div`
  margin-top: 50px;
  & ::selection {
    color: ${props => props.theme.blueBg};
    background-color: ${props => props.theme.fullWhite};
  }
  > * {
    z-index: 1;
  }
  @media (max-width: 845px) {
    margin: 0;
  }
`;

export const ErrorCode = styled.div`
  font-size: calc(10px + 9vmin);
  font-weight: 600;
  color: ${props => props.theme.fullWhite};
`;

export const ErrorMessage = styled.div`
  font-size: calc(1rem + 3vmin);
  font-weight: 600;
  color: ${props => props.theme.fullWhite};
`;

export const ErrorInfo = styled.div`
  max-width: 640px;
  font-size: 1.1rem;
  line-height: 140%;
  margin: 25px 0;
  color: ${props => props.theme.fullWhite};
  a {
    color: ${props => props.theme.btnColor};
    padding-left: 5px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
  }
`;

export const Relative = styled.div`
  display: flex;
  margin-top: 100px;
  > * {
    display: flex;
    justify-content: center;
    flex: 1;
    z-index: 1;
  }
  padding: 0 5%;
  @media ${device.laptopL} {
    padding: 0 25px;
  }
  @media (max-width: 845px) {
    margin-top: 25px;
  }
}
`;

export const MainImageNotFound = styled(notFoundImage)`
  z-index: 0;
  @media (max-width: 845px) {
    position: absolute;
    bottom: 0;
    width: 90vw;
    right: 0;
    height: auto;
  }
}
`;
