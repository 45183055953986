import React from 'react';
import { useTranslation } from 'react-i18next';
import { FAQ, SUPPORT } from 'constants/routings';
import Faq from './faq';
import {
  ButtonFaq,
  ButtonWriteUsText,
  WriteUsIcon,
  ButtonsWrapper,
  RestrictWidth,
} from './styled-ui';

const FaqFeatures = () => {
  const { t } = useTranslation();
  return (
    <RestrictWidth>
      <Faq />
      <ButtonsWrapper>
        <ButtonFaq to={FAQ}>{t(`faqPreview.buttonFaqText`)}</ButtonFaq>
        <ButtonWriteUsText to={SUPPORT}>
          {t(`faqPreview.buttonWriteUsText`)}
          <WriteUsIcon />
        </ButtonWriteUsText>
      </ButtonsWrapper>
    </RestrictWidth>
  );
};

export default FaqFeatures;
