const tickerDescriptions = {
  BTC: 'Bitcoin',
  USDT: 'Tether ERC-20',
  USDT_TRC: 'Tether TRC-20',
  USDT_TON: 'Tether TON',
  ETH: 'Ethereum ERC-20',
  WBP: 'Tether TRC-20',
  TRX: 'Tron',
  TON: 'Ton',
};

export default tickerDescriptions;
