import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { changePassword } from 'utils/services/request/auth';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import {
  ModalBodyWrapper,
  ModalLabel,
  ModalDesc,
  ButtonWrapper,
} from './styled-ui';

const changePasswordSchema = t =>
  Yup.object().shape({
    mfaSecret: Yup.string().required(t('signUpForm.errors.requiredField')),
  });

const ChangePasswordForm = ({
  t,
  initialValues,
  hideModal,
  handleReset,
  showSuccessLabel,
}) => {
  const stateSubmitingLoginForm = useAxiosSubmitingEffect(changePassword);

  const onSubmitHandler = (values, actions) => {
    stateSubmitingLoginForm.setFormAndSubmit({ values, actions });
  };

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      const { actions } = stateSubmitingLoginForm.form;
      if (stateSubmitingLoginForm.error) {
        if (stateSubmitingLoginForm.error.status === 'BAD_CREDENTIALS') {
          actions.setErrors({
            mfaSecret: t('passwordForms.errors.failedOldPassword'),
          });
        }
        if (stateSubmitingLoginForm.error.status === 'INVALID_CODE') {
          if (stateSubmitingLoginForm.error.message === 'Invalid code') {
            actions.setErrors({
              mfaSecret: t('passwordForms.errors.invalidCode'),
            });
          }
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmitingLoginForm.data.status === 'OK') {
        hideModal();
        showSuccessLabel();
        handleReset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm.loaded]);

  return (
    <ModalBodyWrapper>
      <ModalLabel>{t('profile.modalLabels.confirmChangePassword')}</ModalLabel>
      <ModalDesc>{t('profile.modalLabels.twoFaCodeInfo')}</ModalDesc>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={changePasswordSchema(t)}
      >
        {({ errors }) => (
          <Form>
            <Field
              type="text"
              name="mfaSecret"
              component={Input}
              placeholder="******"
              label={t('profile.inputLabels.code')}
            />
            {errors.mfaSecret && (
              <Error isSupportPage>{errors.mfaSecret}</Error>
            )}
            <ButtonWrapper>
              <Button type="submit">
                {t('profile.modalLabels.approveButton')}
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </ModalBodyWrapper>
  );
};

export default ChangePasswordForm;
