import PropTypes from 'prop-types';
import React from 'react';
import { ExtraSmallButton } from 'ui-kit/Button/index';
import { Switcher } from './styled-ui';

const MediaTypeSwitcher = ({ categories, value, handleSelectCategory }) => (
  <Switcher>
    {categories?.data.data.map(category => (
      <ExtraSmallButton
        isMedia
        key={category.id}
        name="mediaType"
        onClick={() => handleSelectCategory(category.id)}
        type="button"
        active={value === category.id}
      >
        {category.attributes.Caption}
      </ExtraSmallButton>
    ))}
  </Switcher>
);

MediaTypeSwitcher.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  categories: PropTypes.object.isRequired,
};

export default MediaTypeSwitcher;
