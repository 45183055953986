import styled from 'styled-components';
import device from 'device';
import { ReactComponent as WriteUsBird } from 'assets/images/write-us-bird.svg';

export const Footer = styled.div`
  margin: 0 50px;
  border-top: 1px solid #dfdfdf;
  display: flex;
  @media ${device.laptopL} {
    margin: 0 50px;
  }
  @media ${device.laptop} {
    margin: 0 25px;
  }
`;

export const ContainerGrid = styled.div`
  display: flex;
  padding: 30px 0;
  column-gap: 30px;
  @media ${device.laptop} {
    flex-direction: column;
    row-gap: 15px;
  }
`;

export const ContactsWrapper = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    order: 1;
    font-size: 12px;
    flex: 1;
    box-sizing: border-box;
  }
`;

export const ContactInfo = styled.div`
  font-size: 0.9rem;
  line-height: 24px;
  color: ${props => props.theme.grayText};
  letter-spacing: 0;
  white-space: pre-wrap;
  margin: ${({ margin }) => margin};
  a {
    color: ${props => props.theme.grayText};
    text-decoration: none;
  }
`;

export const InfoWrapper = styled.div`
  flex: 1;
`;

export const Info = styled.div`
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: ${props => props.theme.grayText};
  &:last-child {
    margin-bottom: 15px;
  }
`;

export const InfoLinks = styled.div``;

export const InfoLinkItem = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.grayText};
  flex: 1 0 21%;
  margin-bottom: 15px;
  a {
    color: ${props => props.theme.grayText};
    text-decoration: none;
    transition: color 0.2s;
    &:hover {
      color: ${props => props.theme.btnHoverColor};
    }
  }
`;

export const MainBlock = styled.h2`
  margin: 0 0 40px 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 2.7rem;
  @media ${device.desktop1280} {
    font-size: 2.1rem;
  }
  @media ${device.mobile920} {
    font-size: 2.2rem;
  }
  color: ${props => props.theme.blackText};
  @media ${device.laptopL} {
    text-align: center;
    margin: 0 0 67px 0;
  }
  @media ${device.laptop} {
    margin: 0 0 50px 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: block;
  width: 50%;
  margin: ${({ isMedia }) => (isMedia ? '70px 0 0 0' : '0 auto')};
  margin-bottom: 130px;
  @media ${device.laptopL} {
    width: 75%;
  }
  @media ${device.laptop} {
    width: 100%;
    padding: ${({ isMedia }) => (isMedia ? 0 : '0 25px')};
    box-sizing: border-box;
    margin-bottom: 52px;
  }
`;

export const ButtonMailUs = styled.button`
  position: relative;
  display: block;
  width: 100%;
  max-width: 330px;
  margin: ${({ isMedia }) => !isMedia && '0 auto'};
  height: 54px;
  cursor: pointer;
  text-align: ${({ isMedia }) => !isMedia && 'left'};
  background: ${props => props.theme.btnColor};
  border-radius: 8px;
  font-family: 'Inter';
  padding-left: ${({ isMedia }) => !isMedia && '22px'};
  @media ${device.laptopL} {
    height: 44px;
  }
  @media ${device.laptop} {
    height: 50px;
  }
`;

export const ButtonMailUsText = styled.span`
  width: 192px;
  height: 25px;
  font-size: 0.8rem;
  cursor: pointer;
  color: #ffffff;
  @media ${device.desktop} {
    font-size: 1.1rem;
    width: 145px;
    height: 20px;
  }
  @media ${device.laptopL} {
    width: 145px;
    height: 20px;
  }
  @media ${device.laptop} {
    width: 249px;
    height: 20px;
    font-size: 1rem;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${device.mobile920} {
    width: auto;
    padding: 0 25px;
  }
  @media ${device.mobileL} {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;
    margin-left: 0;
    box-sizing: border-box;
    margin-bottom: 26px;
  }
`;

export const LinkItem = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.blackText};
  margin-bottom: 15px;
  a {
    color: ${props => props.theme.blackText};
    text-decoration: none;
    transition: color 0.2s;
    &:hover {
      color: ${props => props.theme.btnHoverColor};
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: -2px;
  top: 1px;
  @media ${device.laptopL} {
    top: 2px;
  }
  @media ${device.laptop} {
    top: 1px;
  }
`;

export const WriteUsIcon = styled(WriteUsBird)`
  fill: #0069ff;
  width: 52px;
  height: 52px;
  @media ${device.laptopL} {
    width: 41px;
    height: 41px;
  }
  @media ${device.laptop} {
    width: 49px;
    height: 48px;
  }
`;

export const SupportWrapper = styled.div`
  margin-top: 123px;
  @media ${device.laptop} {
    margin-top: 78px;
  }
  @media ${device.laptop} {
    margin-top: 43px;
  }
`;

export const Wrapper = styled.div``;
