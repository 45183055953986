import React, { useContext, useRef, useEffect } from 'react';
import moment from 'moment';
import { getUserLanguage } from 'utils/location';
import NotificationContext from 'utils/contexts/Notification';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import { USER_PROFILE } from 'constants/routings';
import { NOTIFICATIONS, SETTINGS } from 'utils/constants/profileTabs';
import {
  getNotifications,
  readNotification,
  removeAllNotifications,
} from 'utils/services/request/notifications';

import {
  NotificationWrapper,
  Notification,
  InfoIcon,
  CloseIcon,
  TextWrapper,
  Label,
  Text,
  Time,
  CommonWrapper,
  ClearAllButton,
  NotificationInner,
  NoNewNotifications,
  InfoGeneralIcon,
  ActionsWrapper,
  LinkNotifications,
  NotificationLink,
  NotificationInnerLink,
} from './styled-ui';

const NotificationPopup = ({
  t,
  setNotificationOverlay,
  isUserNotHavePhone,
}) => {
  const wrapperRef = useRef();
  const observer = useRef();

  const {
    notificationState: state,
    dispatchNotification: dispatch,
  } = useContext(NotificationContext);

  const lastEventElementRef = node => {
    const { isLoading, page, totalPages } = state;
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        getNotifications(page + 1, dispatch);
      }
    });
    if (node) observer.current.observe(node);
  };

  const handleClick = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setNotificationOverlay(false);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const onCloseNotificaton = id => e => {
    e.preventDefault();
    e.stopPropagation();
    readNotification(id, dispatch);
  };

  const onRemoveAllNotificatons = e => {
    e.preventDefault();
    e.stopPropagation();
    removeAllNotifications(dispatch);
    setNotificationOverlay(false);
  };

  const { notifications } = state;

  return (
    <CommonWrapper ref={wrapperRef}>
      <NotificationWrapper>
        {!notifications.length && !isUserNotHavePhone ? (
          <NoNewNotifications>
            <p>{t('notifications.noNewNotifications')}</p>
          </NoNewNotifications>
        ) : (
          <>
            {isUserNotHavePhone && (
              <NotificationLink
                to={{
                  pathname: USER_PROFILE,
                  search: `?tab=${SETTINGS}`,
                }}
              >
                <NotificationInnerLink>
                  <InfoIcon />
                  <TextWrapper>
                    <Text isInfo={isUserNotHavePhone}>
                      {t('profile.settings.addPersonalData')}
                    </Text>
                  </TextWrapper>
                </NotificationInnerLink>
              </NotificationLink>
            )}
            {notifications.map(
              ({ id, title, body, creationDate, level }, index) => (
                <Notification
                  key={id}
                  ref={
                    notifications.length === index + 1
                      ? lastEventElementRef
                      : null
                  }
                >
                  <NotificationInner>
                    {level === 'GENERAL' ? (
                      <InfoGeneralIcon />
                    ) : (
                      <InfoIcon level={level} />
                    )}
                    {level === 'GENERAL' ? (
                      <TextWrapper>
                        <Label isGeneral level={level}>
                          {body}
                        </Label>
                      </TextWrapper>
                    ) : (
                      <TextWrapper>
                        <Label level={level}>{title}</Label>
                        <Text>{body}</Text>
                      </TextWrapper>
                    )}

                    <CloseIcon onClick={onCloseNotificaton(id)} />
                  </NotificationInner>
                  <Time>
                    {moment(creationDate)
                      .locale(getUserLanguage())
                      .format('DD MMMM, HH:mm')}
                  </Time>
                </Notification>
              )
            )}
          </>
        )}
      </NotificationWrapper>
      <ActionsWrapper>
        <ClearAllButton
          isHidden={!notifications.length}
          onClick={onRemoveAllNotificatons}
        >
          {t('notifications.clearAll')}
        </ClearAllButton>

        <LinkNotifications
          to={{
            pathname: USER_PROFILE,
            search: `?tab=${NOTIFICATIONS}`,
          }}
        >
          {t('notifications.allNotificationLink')}
        </LinkNotifications>
      </ActionsWrapper>
    </CommonWrapper>
  );
};

export default NotificationPopup;
