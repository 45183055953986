import React, { useContext, useCallback, useEffect } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import UserContext from 'utils/contexts/User';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { setToken } from 'utils/services/request/localStorage';
import { loginFormOptions } from 'utils/services/request/auth';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import { FormGroup } from 'components/Verification/styled-ui';
import { ButtonWrapper, Description, FormGrid, Header } from '../styled-ui';

const TwoFactorCode = ({ dataFrom, t }) => {
  const stateSubmitingForm = useAxiosSubmitingEffect(loginFormOptions);
  const user = useContext(UserContext);

  const onSubmitHandler = useCallback((values, actions) => {
    const loginData = new FormData();
    loginData.append('grant_type', 'password');
    loginData.append(
      'username',
      dataFrom.email ? dataFrom.email.toLowerCase() : ``
    );
    loginData.append('password', dataFrom.password);
    loginData.append('code', values.code);

    stateSubmitingForm.setFormAndSubmit({ values: loginData, actions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateSubmitingForm.loaded) {
      if (stateSubmitingForm.error) {
        const { actions } = stateSubmitingForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        actions.setFieldError('code', t('signUpForm.errors.invalidCode'));
      } else {
        setToken(stateSubmitingForm.data);
        user.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingForm, stateSubmitingForm.loaded]);

  return (
    <>
      <Header>{t('profile.modalLabels.twoFa')}</Header>
      <Description>{t('profile.modalLabels.twoFaCodeInfo')}</Description>
      <Formik
        initialValues={{ code: '' }}
        validate={values => {
          const errors = {};
          if (!values.code) {
            errors.code = t('signUpForm.errors.requiredField');
          }
          return errors;
        }}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status,
        }) => (
          <FormGrid onSubmit={handleSubmit}>
            <FormGroup>
              <Field
                id="code"
                type="text"
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                component={Input}
                placeholder="******"
                label={t('signUpForm.codeLabel')}
                autoComplete="off"
                autoFocus
              />
              {status && status.code && !errors.code ? (
                <Error> {status.code}</Error>
              ) : (
                errors.code && touched.code && <Error>{errors.code}</Error>
              )}
            </FormGroup>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('passwordForms.submitCode')}
              </Button>
            </ButtonWrapper>
          </FormGrid>
        )}
      </Formik>
    </>
  );
};

TwoFactorCode.propTypes = {
  dataFrom: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

export default TwoFactorCode;
