import styled from 'styled-components';

export const CurrencyPairsLabel = styled.div`
  font-size: 28px;
  margin-left: 14px;
  margin-bottom: 24px;
`;

export const CurrencyPairsTableContainer = styled.div`
  margin: 45px 0;
`;

export const CustomTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: -4px;
  width: 100%;
  text-align: center;

  thead {
    td {
      height: 41px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      color: ${props => props.theme.grayText};
    }
  }

  td {
    font-size: 16px;
  }

  td:first-child {
    text-align: left;
    padding-left: 14px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  td:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

export const CustomTr = styled.tr`
  background-color: ${props => props.theme.grayBg};
  border-radius: 6px;
  height: 41px;
`;
