import React, { useState, useEffect, useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { HOME } from 'constants/routings';
import { logout, loginFormOptions } from 'utils/services/request/auth';
import {
  getAccessToken,
  getRefreshToken,
  setToken,
} from 'utils/services/request/localStorage';
import { Countdown, Description, SessionTimer, Text, Title } from './styted-ui';

const sessionTime = 15;

const getTimeLiveToken = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    window.location.replace(HOME);
  }
  const decoded = jwtDecode(accessToken);
  const dateDiff = decoded.exp * 1000 - Date.now();

  return dateDiff;
};

const IdleTimerApp = () => {
  const [state] = useState({
    timeout: 60 * sessionTime * 1000,
    remaining: null,
  });
  const [showTimer, setShowTimer] = useState(false);
  const [idleTimer, setidleTimer] = useState(null);
  const [countdown, setCountdown] = useState(60 * 1 * 1000);

  // eslint-disable-next-line consistent-return
  const startTimer = () => {
    const handle = setInterval(() => {
      const remainingTime = idleTimer.getRemainingTime();
      const diffTime = getTimeLiveToken();

      if (remainingTime && remainingTime === 0) {
        setShowTimer(false);
        clearInterval(handle);
        logout();
      }

      if (remainingTime > 0 && remainingTime < 60000) {
        setShowTimer(true);
        setCountdown(Math.round(remainingTime / 1000));
      }

      if (diffTime > 0 && diffTime < 60000) {
        const refreshToken = getRefreshToken();
        clearInterval(handle);
        const loginData = new FormData();
        loginData.append('grant_type', 'refresh_token');
        loginData.append('refresh_token', refreshToken);
        axios
          .post(
            `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`,
            loginData,
            loginFormOptions
          )
          .then(res => {
            if (res.status === 200) {
              setToken(res.data);
              localStorage.removeItem('timerId');
              startTimer();
            }
          });
      }
    }, 10000);
  };

  useEffect(() => {
    if (idleTimer) {
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleTimer]);

  const onIdle = () => {
    logout();
  };

  const onAction = () => {
    setShowTimer(false);
  };

  const setTimerRef = useCallback(ref => {
    setidleTimer(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <IdleTimer
        ref={ref => {
          setTimerRef(ref);
        }}
        onIdle={onIdle}
        onAction={onAction}
        timeout={state.timeout}
        debounce={250}
      />

      {showTimer && (
        <SessionTimer>
          <Description>
            <Title>Время бездействия ограничено</Title>
            <Text>
              В случае дальнейшего бездействия в системе ваш сеанс будет
              автоматически прекращен.
            </Text>
            <Text>
              Чтобы отменить автоматический выход продолжите работу в системе.
            </Text>
            <Countdown>До окончания сеанса {countdown} </Countdown>
          </Description>
        </SessionTimer>
      )}
    </div>
  );
};

export default IdleTimerApp;
