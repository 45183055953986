export const REGISTER = 'register';
export const DOCUMENTS = 'documents';
export const OPERATIONS = 'operations';
export const CUSTOM_CHANGE = 'customChange';
export const SERVICE_ERROR = 'serviceError';
export const ENTITY = 'entity';
export const OTHER = 'other';

export const keyValuesMapper = {
  [REGISTER]: 'Регистрация',
  [DOCUMENTS]: 'Документы',
  [OPERATIONS]: 'Операции',
  [CUSTOM_CHANGE]: 'Индивидуальные условия обмена',
  [SERVICE_ERROR]: 'Ошибка работы сервиса',
  [ENTITY]: 'Юридическое лицо',
  [OTHER]: 'Другое',
};
