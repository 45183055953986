import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import HeaderPage from 'components/Header';
import ModalContext from 'utils/contexts/Modal';
import {
  Close,
  DialogAcceptButton,
  DialogCancelButton,
  Header,
  Info,
  ModalCard,
  ModalWrapper,
  SmallModalCard,
  WrapperButton,
  Window,
  CrossErrorIcon,
  CompletedIcon,
} from './styled-ui';
import Portal from './index';

const RenderComponent = ({ Component, ...props }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  if (!mounted) {
    return <div />;
  }
  return <Component {...props} />;
};

RenderComponent.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
};

const Modal = ({
  Component,
  onClose,
  NestedComponent,
  refetch,
  noRoute,
  padding,
  maxWidth,
  ...props
}) => {
  return (
    <Window noRoute={noRoute}>
      {!noRoute && <HeaderPage />}
      <ModalWrapper noRoute={noRoute}>
        <ModalCard noRoute={noRoute} padding={padding} maxWidth={maxWidth}>
          <Close onClick={onClose} alt="close" />

          <RenderComponent
            Component={Component}
            hideModal={onClose}
            refetch={refetch}
            NestedComponent={NestedComponent}
            {...props}
          />
        </ModalCard>
      </ModalWrapper>
    </Window>
  );
};

export const SmallModal = ({ Component, noHeader, onClose, ...props }) => {
  return (
    <Window>
      {!noHeader && <HeaderPage />}
      <ModalWrapper>
        <SmallModalCard {...props}>
          <RenderComponent
            Component={Component}
            hideModal={onClose}
            {...props}
          />
        </SmallModalCard>
      </ModalWrapper>
    </Window>
  );
};

export const SimpleModal = ({
  Component,
  centered,
  backgroundColorWrapper,
  onClose,
  ...props
}) => (
  <ModalWrapper noRoute centered={centered} background={backgroundColorWrapper}>
    <SmallModalCard {...props}>
      <Close onClick={onClose} alt="close" />
      <RenderComponent Component={Component} hideModal={onClose} {...props} />
    </SmallModalCard>
  </ModalWrapper>
);

export const Dialog = ({
  title,
  message,
  acceptFunc,
  acceptButtonName,
  cancelButtonName,
  hideModal,
  notification,
  children,
  mainBtnColor,
  disabled,
  t,
}) => {
  return (
    <>
      <Header className="header" textalign="center">
        {title}
      </Header>
      {message && <Info textalign="center">{message}</Info>}
      {children}
      <WrapperButton>
        <DialogCancelButton
          type="button"
          onClick={hideModal}
          notification={!!notification}
        >
          {cancelButtonName || t('modals.cancel')}
        </DialogCancelButton>

        {!notification && (
          <DialogAcceptButton
            type="button"
            onClick={acceptFunc}
            mainBtnColor={mainBtnColor}
            disabled={disabled}
          >
            {acceptButtonName || t('modals.yes')}
          </DialogAcceptButton>
        )}
      </WrapperButton>
    </>
  );
};

export const ModalContent = ({ onClose, hasCloseIcon, ...props }) => (
  <ModalWrapper noRoute>
    <SmallModalCard {...props}>
      {hasCloseIcon && <Close onClick={onClose} alt="close" />}
      <Dialog hideModal={onClose} {...props}>
        {props.children}
      </Dialog>
    </SmallModalCard>
  </ModalWrapper>
);

export const DialogModal = ({ Component, onClose, centered, ...props }) => {
  return (
    <Portal>
      <ModalWrapper noRoute centered={centered}>
        <SmallModalCard>
          <RenderComponent
            Component={Component}
            hideModal={onClose}
            {...props}
          />
        </SmallModalCard>
      </ModalWrapper>
    </Portal>
  );
};

export const GlobalModal = () => {
  const { modal, closeModal } = useContext(ModalContext);

  return (
    modal.open && (
      <Portal>
        <ModalWrapper noRoute>
          <SmallModalCard width="450px" margin="auto">
            <Close onClick={closeModal} alt="close" />
            {modal.isError ? <CrossErrorIcon /> : <CompletedIcon />}
            <Info textalign="center">{modal.message}</Info>
          </SmallModalCard>
        </ModalWrapper>
      </Portal>
    )
  );
};

Modal.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  onClose: () => {},
};

SmallModal.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func,
  ]).isRequired,
  onClose: PropTypes.func,
};

SmallModal.defaultProps = {
  onClose: () => {},
};

export default Modal;
