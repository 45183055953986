import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Input, Label } from 'ui-kit/DropdownInput/styled-ui';
import InputWrapper from './styled-ui';

const CustomInput = ({
  field,
  form,
  label,
  onBlur,
  trimInput,
  isReducedPadding,
  isFieldError,
  ...props
}) => {
  const handleBlur = event => {
    const {
      target: { value },
    } = event;

    if (trimInput) {
      props.setFieldValue(field.name, value.trim());
    }
    if (value !== '' && !!onBlur) {
      onBlur(event);
    }
  };

  const { id } = props;
  const { name, value, ...fieldProps } = field;
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = (!!error && isTouched) || isFieldError;

  return (
    <InputWrapper isReducedPadding={isReducedPadding} isError={isError}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        name={name}
        value={value === null ? '' : value}
        {...fieldProps}
        {...props}
        error={isError}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
};

CustomInput.propTypes = {
  readOnly: PropTypes.bool,
  capsLock: PropTypes.bool,
  autoFocus: PropTypes.bool,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
};

CustomInput.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default CustomInput;
