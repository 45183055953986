import React from 'react';
import {
  DescriptionInfoWrapper,
  QuestionIcon,
  DescriptionBlock,
} from './styled-ui';

const DescriptionInfo = ({ isError, description }) => (
  <DescriptionInfoWrapper>
    <QuestionIcon $iserror={isError} />
    <DescriptionBlock>{description}</DescriptionBlock>
  </DescriptionInfoWrapper>
);

export default DescriptionInfo;
