import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  SIGN_IN,
  LOGOUT,
  FAQ,
  SUPPORT,
  MEDIA,
  SIGN_UP,
  DOCUMENTS,
} from 'constants/routings';
import { Item, ItemList } from './styled-ui';

const menuItems = [
  {
    id: 'faq',
    link: FAQ,
  },
  {
    id: 'media',
    link: MEDIA,
  },
  {
    id: 'documents',
    link: DOCUMENTS,
  },
  {
    id: 'support',
    link: SUPPORT,
  },
];

const Menu = ({ toggleNav, isSmallScreen, isAnonymous }) => {
  const { t } = useTranslation();

  return (
    <ItemList>
      {menuItems.map(({ id, link }, index) => (
        <Item key={id}>
          <Link to={link} onClick={toggleNav}>
            {t(`header.menuItems.${index}.text`)}
          </Link>
        </Item>
      ))}
      {isSmallScreen &&
        (isAnonymous ? (
          <>
            <Item additional>
              <Link to={SIGN_UP} onClick={toggleNav}>
                {t(`header.registration`)}
              </Link>
            </Item>
            <Item additional>
              <Link to={SIGN_IN} onClick={toggleNav}>
                {t(`header.login`)}
              </Link>
            </Item>
          </>
        ) : (
          <Item additional>
            <Link to={LOGOUT} onClick={toggleNav}>
              {t(`header.logout`)}
            </Link>
          </Item>
        ))}
    </ItemList>
  );
};

export default Menu;
