import PropTypes from 'prop-types';
import React from 'react';
import { ErrorFormText, ErrorIcon, WrapError } from './styled-ui';

const Error = ({
  children,
  icon,
  bottom,
  maxwidth,
  isSupportPage,
  password,
  top,
}) => {
  return (
    <WrapError
      bottom={bottom}
      top={top}
      maxwidth={maxwidth}
      isSupportPage={isSupportPage}
      password={password}
    >
      {icon && <ErrorIcon />}
      <ErrorFormText>{children}</ErrorFormText>
    </WrapError>
  );
};

export default Error;

Error.propTypes = {
  children: PropTypes.string.isRequired,
};
