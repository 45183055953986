import styled from 'styled-components';
import device from 'device';

export const WrapperDropdownInput = styled.div`
  padding: 13px 0;
  min-width: 560px;
  @media ${device.desktop} {
    min-width: 520px;
  }
  @media ${device.laptopXL} {
    min-width: 500px;
  }
  @media (max-width: 1150px) {
    min-width: 380px;
  }
  @media ${device.mobile920} {
    padding: 0;
    border: none;
    border-radius: 0;
  }
  @media (max-width: 520px) { {
    min-width: auto;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: row;
`;

export const LabelDropdownInput = styled.label`
  color: ${props => props.theme.blackText};
  font-family: 'Inter';
  font-size: 0.8rem;
  padding-left: 10px;
  z-index: 1;
  @media ${device.laptop} {
    top: -9px;
    left: 9px;
  }
  @media ${device.mobile920} {
    font-size: 0.9rem;
    padding-left: 0;
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.linkColor};
  font-size: 0.7rem;
  line-height: 19px;
  @media ${device.mobile920} {
    font-size: 12px;
    line-height: 15px;
    top: -9px;
    left: 9px;
  }
  position: absolute;
  top: -11px;
  left: 11px;
  background-color: #fff;
  padding: 0 5px;
  z-index: 1;
`;

export const Input = styled.input.attrs(props => ({
  type: props.type || 'text',
  placeholder: props.placeholder || undefined,
  size: 1,
}))`
  font-size: ${({ isCalcInput }) => (isCalcInput ? '1.3rem' : '1.1rem')};
  word-break: break-word;
  padding: 0;
  font-family: 'Inter';
  overflow: hidden;
  -webkit-appearance: none;
  @media ${device.laptop} {
    line-height: 17px;
  }
  border: ${({ borderAuto }) => (borderAuto ? '1px solid white' : 'none')};
  width: ${({ inputHiddenText }) =>
    inputHiddenText ? 'calc(100% - 48px)' : '100%'};
  min-width: 45%;
  font-weight: ${({ isCalcInput }) => isCalcInput && '700'};
  box-sizing: ${({ isCalcInput }) => (isCalcInput ? 'border-box' : 'unset')};
  height: ${({ isCalcInput }) => (isCalcInput ? 'unset' : '38px')};
  :disabled {
    -webkit-text-fill-color: ${({ theme, disabledSameColor }) =>
      disabledSameColor ? theme.blackText : theme.grayText};
  }
  @media ${device.mobile920} {
    font-size: ${({ isCalcInput }) => (isCalcInput ? '1.2rem' : '1.1rem')};
    height: ${({ emailConfirm }) => emailConfirm && '25px'};
  }
  @media (max-width: 340px) {
    font-size: ${({ isCalcInput }) => (isCalcInput ? '1.2rem' : '1rem')};
  }
  ::placeholder {
    color: ${props => props.theme.grayText};
    font-size: 1.1rem;
    line-height: ${({ isCalcInput }) => (isCalcInput ? '22px' : '38px')};
    left: 11px;
    @media ${device.laptop} {
      left: 9px;
    }
    @media ${device.tablet} {
      line-height: ${({ emailConfirm }) => emailConfirm && '25px'};
    }
    @media (max-width: 340px) {
      font-size: ${({ isCalcInput }) => (isCalcInput ? '1.1rem' : '1rem')};
    }
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    ::first-line {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      font-size: 1.1rem;
      font-family: 'Inter';
      @media ${device.tablet} {
        font-size: ${({ isCalcInput }) => (isCalcInput ? '1.2rem' : '1rem')};
      }
      @media (max-width: 340px) {
        font-size: ${({ isCalcInput }) => (isCalcInput ? '1.1rem' : '1rem')};
      }
    }
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin: 10px 0;
`;
