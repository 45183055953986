export const getCategoriesDocuments = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/razdely-documents/?${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});

export const getDocuments = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/documents/?${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});

export const getDocument = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/documents/${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});
