import { getIn } from 'formik';
import { useMask } from 'react-mask-field';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Input, Label } from 'ui-kit/DropdownInput/styled-ui';
import InputWrapper from './styled-ui';

const PhoneInput = ({
  field,
  form,
  label,
  onBlur,
  placeholder,
  resetInput,
  isUserNotHavePhone,
  isRegistration,
  ...props
}) => {
  const [inputMask, setInputMask] = useState(placeholder);

  const modify = ({ mask }) => {
    if (inputMask !== mask) {
      setInputMask(mask);
      return { unmaskedValue: '' };
    }
    return {};
  };

  const ref = useMask({
    showMask: true,
    mask: placeholder,
    replacement: { _: /\d/ },
    modify,
  });

  useEffect(() => {
    ref.current.blur();
    resetInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlur = event => {
    const {
      target: { value },
    } = event;

    if (value !== '' && !!onBlur) {
      onBlur(event);
    }
  };

  const { id } = props;
  const { name, value, ...fieldProps } = field;
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = (!!error && isTouched) || isUserNotHavePhone;

  return (
    <InputWrapper isError={isError} isRegistration={isRegistration}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        ref={ref}
        name={name}
        value={value === null ? '' : value}
        placeholder={placeholder}
        {...fieldProps}
        {...props}
        error={isError}
        onBlur={handleBlur}
        type="tel"
      />
    </InputWrapper>
  );
};

PhoneInput.propTypes = {
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
};

PhoneInput.defaultProps = {};

export default PhoneInput;
