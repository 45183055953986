import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const GoogleReCaptchaWrapper = ({ children }) => {
  const reCaptchaKey = process.env?.REACT_APP_RECAPTCHA_KEY;

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={reCaptchaKey ?? 'NOT DEFINED"'}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default GoogleReCaptchaWrapper;
