const customStyle = () => {
  return {
    control: (base, { selectProps: { isRegistration } }) => ({
      ...base,
      cursor: 'pointer',
      left: '8px',
      top: isRegistration ? '11px' : '7px',
      borderRadius: 0,
      border: 'none',
      borderRight: '1px solid #E2E2E2',
      boxShadow: '0 0 0 0',
      zIndex: 9,
      gridTemplateColumns: '0px max-content',
      position: 'absolute',
      backgroundColor: '#fff',
      ':hover': {
        borderColor: '#E2E2E2',
      },
    }),
    valueContainer: base => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: '18px',
      padding: '0 2px',
      color: '#1e1e28',
    }),
    menu: base => ({
      ...base,
      zIndex: 2,
      margin: '15px 0 0 0',
      borderRadius: '12px',
      border: 0,
      top: '35px',
      boxShadow: '0px 10px 30px rgba(16, 58, 121, 0.15)',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      border: 0,
      color: '#0F2F56',
      fontSize: '14px',
      borderRadius: '3px',
      maxHeight: '220px',
    }),
    indicatorsContainer: base => ({
      ...base,
      padding: 0,
      '> div': {
        padding: 0,
        margin: '0 10px 0 8px',
      },
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      fontSize: '18px',
      backgroundColor: '#fff',
      padding: '8px 13px',
      color: '#1e1e28',
      cursor: 'pointer',
      ':active': {
        ...base[':active'],
        color: '#1e1e28',
      },
      ':hover': {
        backgroundColor: '#f6f6f6',
      },
      '> div > svg': {
        zoom: 1.5,
      },
      '> div > svg:last-child': {
        zoom: 1.25,
        position: 'absolute',
        right: 0,
        display: !state.isSelected && 'none',
      },
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    singleValue: base => ({
      ...base,
      display: 'flex',
      zoom: 1.5,
    }),
    input: base => ({
      ...base,
      color: 'transparent',
      textShadow: '0 0 0 black',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: '#A2B4CB',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
    }),
  };
};

export default customStyle;
