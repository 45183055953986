import device from 'device';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  width: 65%;
  margin: 0 auto 195px;
  row-gap: 100px;
  max-width: 3860px;
  > *:nth-child(2n + 1) > img {
    margin-left: 100px;
    @media ${device.tablet} {
      margin-left: 0;
      margin-top: 50px;
    }
  }
  > *:nth-child(2n) > img {
    margin-right: 100px;
    @media ${device.tablet} {
      margin-right: 0;
      margin-top: 50px;
    }
  }

  @media ${device.laptopL} {
    width: 70%;
  }
  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
    row-gap: 50px;
    margin: 0 auto 70px;
  }
`;

export const Feature = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const Img = styled.img`
  object-fit: cover;
  height: 100%;
  width: 25vw;
  flex: 1;
  order: ${props => (props.orderFlex ? '1' : 'initial')};
  @media ${device.tablet} {
    align-self: center;
    width: 75%;
    order: 2;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  align-self: center;
  @media ${device.tablet} {
    order: 1;
  }
`;

export const Text = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  color: ${props =>
    props.mainText ? props.theme.blackText : props.theme.grayText};
  @media ${device.desktop1280} {
    font-size: 1.7rem;
  }
  @media ${device.mobile920} {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: initial;
  }
`;
