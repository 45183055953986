import styled from 'styled-components';
import checkbox from '../../assets/images/icons/checkboxV2.svg';

export const Label = styled.label`
  margin-right: 8px;
  color: #858585;
  font-size: 14px;
  line-height: 13px;
  z-index: 1;
`;

export const Input = styled.input`
  height: 60px;
  width: calc(100% - 40px);
  padding: 20px 20px 0;
  border-radius: 5px;
  background-color: #f1f3f5;
  color: #323233;
  font-size: 20px;
  line-height: 20px;
  padding-top: 20px;
  border: 1px solid ${props => (props.error ? '#F92828' : 'transporent')};

  &::-webkit-input-placeholder {
    color: #dcdcdc;
    transition: opacity 250ms ease-in-out;
  }

  /* &:focus {
    /* background: (props.active ? '' : url(${checkbox}) no-repeat center)}; ; */
    /* outline: ${props => (props.active ? '' : 'solid 10px red')}; */
    /* background: none;
      background-size: 60px 29px;
      background-position: right 20px center;
      background-color: #f1f3f5;
  }  */
`;
