import React from 'react';
import {
  ButtonDocument,
  ButtonsGroup,
  DocumentWrapper,
  DocumentCardTitle,
  PDFIcon,
} from './styled-ui';

const DocumentCard = ({ t, data }) => (
  <DocumentWrapper>
    <DocumentCardTitle>
      <PDFIcon />
      <div>{data.attributes.Caption}</div>
    </DocumentCardTitle>
    <ButtonsGroup>
      <ButtonDocument>
        <a
          href={`${process.env.REACT_APP_STRAPI_URL}${data.attributes.File?.data?.attributes?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('documents.buttons.read')}
        </a>
      </ButtonDocument>
      <ButtonDocument blue>
        <a
          href={`${process.env.REACT_APP_STRAPI_URL}${data.attributes.File?.data?.attributes?.url}`}
          download
        >
          {t('documents.buttons.download')}
        </a>
      </ButtonDocument>
    </ButtonsGroup>
  </DocumentWrapper>
);

export default DocumentCard;
