import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionInfo from 'ui-kit/DescriptionInfo';
import { isCrypto } from 'utils/crypto';
import { LimitInfo, PromoErrorWrapper, ErrorText } from './styled-ui';

const LimitRateError = ({
  information,
  errorLimit,
  promoError,
  isBlockchainFeeError,
  isLimitExceededError,
  isCryptoCardLimit,
  isShowLimitRate,
  isAuthorized,
}) => {
  const { t } = useTranslation();

  // Check for correct rendering of display of min and max amounts depending on the calculator
  const isShowErrorLimit =
    information?.currencyPair?.fromCurrency || isCrypto(information?.currency);

  if (promoError && isAuthorized) {
    return (
      <PromoErrorWrapper>
        <ErrorText>{t(`promocode.errors.${promoError}.title`)}</ErrorText>
        <DescriptionInfo
          isError
          description={t(`promocode.errors.${promoError}.description`)}
        />
      </PromoErrorWrapper>
    );
  }

  if (!information?.limit && isShowLimitRate && isAuthorized) {
    return (
      <div>
        <LimitInfo>{t('calculationView.errors.noCurrencyLimits')}</LimitInfo>
      </div>
    );
  }

  if (isBlockchainFeeError && isAuthorized) {
    return (
      <div>
        <LimitInfo>
          {t('calculationView.errors.validationValueLessThanCommission', {
            value: information.calculation.inputAsset,
            currency: information.currencyPair.fromCurrency,
          })}
        </LimitInfo>
      </div>
    );
  }

  if (isCryptoCardLimit && isAuthorized) {
    return (
      <div>
        <ErrorText>
          {t('calculationView.errors.validationErrorMonthlyLimitCryptoCard')}
        </ErrorText>
      </div>
    );
  }

  if (isShowErrorLimit && errorLimit?.inputAsset && !isLimitExceededError) {
    return (
      <div>
        <ErrorText>{errorLimit.inputAsset}</ErrorText>
      </div>
    );
  }

  return null;
};

export default LimitRateError;
