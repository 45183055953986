import styled from 'styled-components';
import { Link } from 'react-router-dom';
import device from 'device';

export const LimitInfo = styled.div`
  color: ${props => props.theme.lightBlueWhite};
  font-size: 0.8rem;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  height: 12px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${device.mobile920} {
    bottom: -100px;
    flex-direction: column;
    font-size: 0.75rem;
  }
`;

export const LimitInfoLink = styled(Link)`
  font-size: 0.8rem;
  color: ${props => props.theme.lightBlueWhite};
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: ${props => props.theme.fullWhite};
  }
  @media ${device.mobile920} {
    font-size: 0.75rem;
  }
`;

export const LimitText = styled.div`
  margin-right: 10px;
  @media ${device.mobile920} {
    margin: 10px 0;
  }
`;
