import styled from 'styled-components';
import device from 'device';

const LoadingPlaceholder = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  height: ${props => props.options.height || '100%'};
  width: ${props => props.options.width || '100%'};
  flex: ${props => props.options.flex};
  max-width: ${props => props.options.maxwidth || 'unset'};
  margin: ${props => (props.options.isCentered ? '0 auto' : 0)};
  margin-top: ${props => props.options.margintop || 0};
  border-radius: 5px;
  @media ${device.mobile920} {
    max-width: ${props => !props.options.notChangeMaxWidth && 'inherit'};
  }
  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export default LoadingPlaceholder;
