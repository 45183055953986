import React from 'react';
import { Trans } from 'react-i18next';
import { pipe, filter, isEmpty } from 'ramda';
import DescriptionInfo from 'ui-kit/DescriptionInfo';
import discountTypes from 'utils/constants/promocode';
import { getCurrencyValue } from 'utils/crypto';
import { Wrapper, Charge } from './styled-ui';

const AdditionalChargeRow = ({ t, chargeData }) => {
  const { serviceFee, blockchainFee, bonus } = chargeData;
  const isDataEmpty = pipe(
    filter(n => !!n),
    isEmpty
  )(chargeData);

  const serviceFeeValue = `${serviceFee?.value} ${serviceFee?.currency}`;
  const blockchainFeeValue = `${blockchainFee?.value} ${blockchainFee?.currency}`;
  const bonusValue = `+${bonus?.discount} ${
    bonus?.type === discountTypes.AMOUNT
      ? `${getCurrencyValue(bonus?.currency)}`
      : '%'
  }`;

  return isDataEmpty ? (
    <Wrapper isEmpty />
  ) : (
    <Wrapper>
      <Charge>{t('chargeRow.included')}</Charge>
      {serviceFee && (
        <Charge>
          {t('chargeRow.serviceFee', { value: serviceFeeValue })}
          &nbsp;
          <DescriptionInfo
            description={<Trans i18nKey="chargeRow.serviceFeeDescription" />}
          />
        </Charge>
      )}
      {blockchainFee && (
        <Charge>
          {t('chargeRow.blockchainFee', { value: blockchainFeeValue })}
        </Charge>
      )}
      {bonus && (
        <>
          {bonus?.type === discountTypes.COMMISSION_PERCENT ? (
            <Charge isBonus>
              {t('chargeRow.serviceCommissionBonus', { value: bonusValue })}
            </Charge>
          ) : (
            <Charge isBonus>
              {t('chargeRow.bonus', { value: bonusValue })}
            </Charge>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default AdditionalChargeRow;
