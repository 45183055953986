import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { connect, Field, Formik, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomRadio from 'ui-kit/CustomField/Radio';
import { Button } from 'ui-kit/Button';
import PropTypes from 'prop-types';
import { checkStep3 } from 'components/Verification/verification';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import ClientContext from 'utils/contexts/Client';
import { getClientTestingNeeded } from 'utils/user';
import UserContext from 'utils/contexts/User';
import {
  confirmFormRequestUrl,
  getVerificationTest,
  testComplete,
} from 'utils/services/request/verifications';
import { HOME } from 'constants/routings';
import {
  FormGrid,
  FullRow,
  HeaderDescription,
  HeaderGrid,
  NoticeSubTitle,
  NoticeTitle,
  NoticeWrapper,
  QuizTitle,
  Title,
} from './styled-ui';

const QuizTitleMessage = ({ name, title, formik }) => {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  return (
    <QuizTitle error={error && (touch || touch === undefined)}>
      {title}
    </QuizTitle>
  );
};

QuizTitleMessage.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.shape({}).isRequired,
};

const QuizTitleComponent = connect(QuizTitleMessage);

const TestForm = ({ callbackProps }) => {
  const client = useContext(ClientContext);
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const [questions, setQuestions] = useState(null);
  const questionsQuery = useAxiosSubmitingEffect(getVerificationTest);
  const testCompleteQuery = useAxiosSubmitingEffect(testComplete);
  const isClientTestingRequired = getClientTestingNeeded(client);
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);

  useEffect(() => {
    if (questionsQuery.data?.questions) {
      setQuestions(questionsQuery.data?.questions);
      const values = questionsQuery.data?.questions?.map(items => {
        const indexAnswer = items.answers?.findIndex(answer => answer.correct);
        return `answer${indexAnswer}`;
      });
      localStorage.setItem('quiz', JSON.stringify(values));
    }
  }, [questionsQuery.data]);

  useEffect(() => {
    if (testCompleteQuery.loaded && !testCompleteQuery.fetching) {
      if (callbackProps.setIsLoader) {
        callbackProps.setIsLoader(false);
        setIsVerificationComplete(true);
      }
    }
  }, [testCompleteQuery.loaded]);

  const getAnswerText = (index, values) => {
    const value = values.questions[`question${index}`];
    const answerIndex = value[value.length - 1];
    return questions[index].answers[answerIndex].title;
  };

  const submitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (callbackProps.setIsLoader) {
      callbackProps.setIsLoader(true);
    }
    const data = {
      questionOne: getAnswerText(0, values),
      questionTwo: getAnswerText(1, values),
      questionThree: getAnswerText(2, values),
      questionFour: getAnswerText(3, values),
      questionFive: getAnswerText(4, values),
    };
    axios
      .post(confirmFormRequestUrl, data)
      .then(() => {
        testCompleteQuery.setFormAndSubmit({ values: {} });
      })
      .catch(e => {
        if (e.response && e.response.error) {
          // eslint-disable-next-line no-console
          console.log(e.response.error);
        }
      });
  };

  const redirectHandler = () => {
    user.refetch();
    window.location.href = HOME;
  };

  return (
    <>
      {isClientTestingRequired && !isVerificationComplete && (
        <Formik
          initialValues={{}}
          onSubmit={submitHandler}
          isInitialValid={false}
          validate={checkStep3}
          validateOnChange={false}
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <Title>{t('verification.testTitle')}</Title>
              <HeaderGrid>
                <HeaderDescription>
                  {t('verification.testDescription')}
                </HeaderDescription>
              </HeaderGrid>
              <FormGrid onSubmit={handleSubmit}>
                <FullRow>
                  {questions?.map((item, index) => (
                    <div key={item.id}>
                      <QuizTitleComponent
                        name={`questions.question${index}`}
                        title={item.title}
                      />
                      {item.answers?.map((answer, key) => (
                        <Field
                          key={key}
                          id={`opt${key}${item.id}`}
                          name={`questions.question${index}`}
                          component={CustomRadio}
                          value={`answer${key}`}
                          type="radio"
                          label={answer.title}
                        />
                      ))}
                    </div>
                  ))}
                </FullRow>
                <Button
                  padding="10px"
                  width="200px"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t('verification.sumSubWizardNext')}
                </Button>
              </FormGrid>
            </>
          )}
        </Formik>
      )}
      {!isClientTestingRequired && !isVerificationComplete && (
        <NoticeWrapper>
          <NoticeTitle>
            {t('verification.noticeTextAfterRegula.title')}
          </NoticeTitle>
          <NoticeSubTitle>
            {t('verification.noticeTextAfterRegula.subTitle')}
          </NoticeSubTitle>
          <Button
            padding="10px"
            width="200px"
            type="button"
            onClick={redirectHandler}
          >
            {t('verification.noticeTextAfterRegula.button')}
          </Button>
        </NoticeWrapper>
      )}
      {isVerificationComplete && (
        <NoticeWrapper>
          <NoticeTitle>{t('verification.testFormSuccess')}</NoticeTitle>
          <Button
            padding="10px"
            width="200px"
            type="button"
            onClick={redirectHandler}
          >
            {t('verification.noticeTextAfterRegula.button')}
          </Button>
        </NoticeWrapper>
      )}
    </>
  );
};
export default TestForm;
