import Stomp from 'stompjs';
import SockJS from 'sockjs-client';

class WsConnection {
  wsConnection: any;

  constructor() {
    this.wsConnection = null;
  }

  connect = callbackApproveConnection => {
    const token = localStorage.getItem('access_token');

    if (!token) return;
    const sockJsInstance = new SockJS(
      `${process.env.REACT_APP_NOTIFICATION_URL}/wb-ws?bearerToken=${token}`
    );
    const stompClient = Stomp.over(sockJsInstance);
    stompClient.connect({}, () => {
      callbackApproveConnection();
    });

    stompClient.debug = null;
    this.wsConnection = stompClient;
  };

  disconnect = () => {
    if (this.wsConnection) {
      this.wsConnection.disconnect();
    }
  };
}

const wsConnectInstance = new WsConnection();
export default wsConnectInstance;
