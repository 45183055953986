import React, { useState } from 'react';
import MerchantPaymentsTable from 'components/MerchantPaymentsTable';
import Dropdown from 'ui-kit/Dropdown';
import {
  CryptoNetwork,
  LabelText,
  LabelWrapper,
} from 'components/Exchanger/styled-ui';
import {
  cryptoCurrencies,
  currenciesLogo,
  NETWORK_TYPES,
} from 'utils/constant';
import { Button } from 'ui-kit/Button';
import axios from 'axios';
import {
  createMerchantPayment,
  getMerchantPayments,
} from 'utils/services/request/merchant';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getCurrencyValue } from 'utils/crypto';
import PaymentModal from '../BalancesComponent/PaymentModal';
import {
  ButtonContainer,
  ControlsWrapper,
  DropdownContainer,
  ErrorMessage,
  NewPaymentContainer,
  NewPaymentLabel,
  PaymentsHeader,
} from './styled-ui';

export const getCurrencyLabel = currency => (
  <LabelWrapper>
    {currenciesLogo[currency]}
    <LabelText>
      {getCurrencyValue(currency)}
      {NETWORK_TYPES[currency] && (
        <CryptoNetwork>{NETWORK_TYPES[currency]}</CryptoNetwork>
      )}
    </LabelText>
  </LabelWrapper>
);

export default function PaymentsComponent({ t }) {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [currency, setCurrency] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [creationError, setCreationError] = useState(null);

  const { data: balancesData, refetch } = useAxiosStateWithRefetch(
    getMerchantPayments(currentPage)
  );

  const handleCreatePayment = () => {
    setIsLoading(true);
    setCreationError(null);
    axios(createMerchantPayment({ type, currency }))
      .then(({ data }) => {
        setModalData(data);
        setIsLoading(false);
        refetch();
      })
      .catch(({ response }) => {
        setIsLoading(false);
        setCreationError(response?.data.message);
      });
  };

  const selectOptions = cryptoCurrencies.map(it => ({
    value: it,
    label: getCurrencyLabel(it),
  }));

  return (
    <>
      <NewPaymentContainer>
        <NewPaymentLabel>{t('merchantPage.newPaymentHeader')}</NewPaymentLabel>
        <ControlsWrapper>
          <DropdownContainer>
            <Dropdown
              label={t('merchantPage.currency')}
              name="cryptoCurrency"
              value={type}
              setFieldValue={(name, value) => setCurrency(value)}
              selectOptions={selectOptions}
              labelInside
            />
          </DropdownContainer>
          <DropdownContainer>
            <Dropdown
              label={t('merchantPage.paymentType')}
              name="paymentType"
              value={currency}
              setFieldValue={(name, value) => setType(value)}
              selectOptions={[
                {
                  label: 'DEPOSIT',
                  value: 'DEPOSIT',
                },
              ]}
              labelInside
            />
          </DropdownContainer>
          <ButtonContainer>
            <Button
              type="button"
              onClick={handleCreatePayment}
              disabled={isLoading || !type || !currency}
            >
              {t('merchantPage.generatePayment')}
            </Button>
            {creationError && (
              <ErrorMessage>
                {t('merchantPage.createPaymentError')}
              </ErrorMessage>
            )}
          </ButtonContainer>
        </ControlsWrapper>
      </NewPaymentContainer>
      <PaymentsHeader>{t('merchantPage.paymentsHeader')}</PaymentsHeader>
      <MerchantPaymentsTable
        balancesData={balancesData}
        refetch={refetch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        t={t}
      />
      {modalData && (
        <PaymentModal
          modalData={modalData}
          setModalData={setModalData}
          getCurrencyLabel={getCurrencyLabel}
          t={t}
        />
      )}
    </>
  );
}
