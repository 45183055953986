import styled from 'styled-components';
import device from 'device';

export const SwitchWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  width: max-content;
  padding: ${props => props.padding};

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const SwitchLabel = styled.span`
  color: ${props => props.theme.blackText};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.2px;
  margin-left: 10px;

  @media ${device.mobile920} {
    font-size: 18px;
  }
`;
