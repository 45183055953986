import styled from 'styled-components';

export const AddressFullView = styled.div`
  position: absolute;
  bottom: 55px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111) 0px 7px 29px 0px;
  padding: 3px 10px;
  left: 0;
  border-radius: 8px;
  word-break: break-word;
  font-size: 0.94rem;
  z-index: 1;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  &::after {
    content: '';
    position: absolute;
    height: 20px;
    width: 100%;
    left: 0;
    bottom: -15px;
  }
`;

export const ThreeDotInputValue = styled.span`
  position: absolute;
  font-size: 0.94rem;
  transform: scale(0);
`;
