import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 20px;
  @media ${device.mobileL} {
    padding: 0px;
  }
`;

export const Title = styled.span`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 21px;
  line-height: 35px;
`;
