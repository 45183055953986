import PropTypes from 'prop-types';
import React from 'react';
import {
  Checkbox,
  HiddenInput,
  LabelCheckbox,
  LabelText,
} from 'ui-kit/CustomField/Checkbox/styled-ui';

const CustomCheckbox = ({ label, onChange, isChecked, ...props }) => (
  <>
    <LabelCheckbox className="label-check option">
      <HiddenInput
        type="checkbox"
        className="label-check__input"
        {...props}
        checked={isChecked}
        onChange={onChange}
      />
      <Checkbox className="label-check__new-input" />
      <LabelText>{label}</LabelText>
    </LabelCheckbox>
  </>
);

CustomCheckbox.propTypes = {
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

CustomCheckbox.defaultProps = {
  disabled: false,
  isChecked: false,
};

export default CustomCheckbox;
