import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { pipe, equals, pathOr } from 'ramda';
import { HOME } from 'constants/routings';
import { useAxiosState } from 'utils/hooks/axiosHook';
import UserContext from 'utils/contexts/User';
import { loadWebSdk, launchWebSdk } from './launchWebSdk';
import { NextButton } from '../styled-ui';

const SumSubFrame = ({ client, t }) => {
  const [loadedWebSdk, setLoadedWebSdk] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [docsSubmitted, setDocsSubmitted] = useState(false);
  const [sumsubResponseData, setSumsubResponseData] = useState({
    token: '',
  });

  useEffect(() => {
    loadWebSdk(() => {
      setLoadedWebSdk(true);
    });
  });

  const user = useContext(UserContext);
  const sumsubTokenData = useAxiosState(
    {
      method: 'get',
      url: `${process.env.REACT_APP_KYC_URL}/kyc/sumsub/sdk/token?levelType=MAIN`,
    },
    []
  );

  useEffect(() => {
    if (!sumsubTokenData.fetching && sumsubTokenData.loaded) {
      if (sumsubResponseData.token !== sumsubTokenData.data.token) {
        setSumsubResponseData({
          token: sumsubTokenData.data.token,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumsubTokenData]);

  useEffect(() => {
    const { token } = sumsubResponseData;
    if (token && client && loadedWebSdk) {
      launchWebSdk(
        token,
        user && user.data && user.data.email,
        null,
        setDocsSubmitted,
        client.refetch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sumsubResponseData, loadedWebSdk]);

  const isStatusPending = pipe(
    pathOr('', ['data', 'status']),
    equals('PENDING')
  )(client);

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      <div id="cyberity-websdk-container" />
      {(docsSubmitted || isStatusPending) && (
        <NextButton
          unsetGrid
          type="button"
          onClick={() => {
            setRedirect(true);
          }}
        >
          {t('verification.doneButton')}
        </NextButton>
      )}
    </>
  );
};

export default SumSubFrame;
