import React, { useContext } from 'react';
import * as RoutePaths from 'constants/routings';
import { pathOr, propOr } from 'ramda';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import { CUSTOM_CHANGE } from 'utils/constants/supportMessageTopics';
import { Button, ButtonLink } from 'ui-kit/Button';
import LoadingPlaceholder from 'ui-kit/LoadingPlaceholder';
import Spinner from 'ui-kit/Spinner';
import { pushAnalyticsEvent } from 'components/App/analytics';
import { ButtonWrapper } from '../styled-ui';

const SubmitButton = ({
  isSubmitting,
  calcError,
  isTestingRequired,
  buttonName,
  information,
  isPaymentNotAvailable,
  commission,
  isLimitExceededError,
  isCryptoCardLimit,
}) => {
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const { error } = information;
  const errorStatus = propOr('', 'status', error);

  if (!information.data && !errorStatus) {
    return (
      <ButtonWrapper>
        <LoadingPlaceholder
          options={{
            width: '100%',
            height: '49px',
            maxwidth: '357px',
            borderradius: '8px',
            margintop: '20px',
            isCentered: true,
          }}
        />
      </ButtonWrapper>
    );
  }

  const isOperationEnabled = pathOr(
    false,
    ['data', 'operationStatus', 'enabled'],
    information
  );

  const isDisabledButton =
    isSubmitting ||
    !isOperationEnabled ||
    (calcError?.inputAsset && !isLimitExceededError) ||
    (isLimitExceededError && !isOperationEnabled) ||
    isCryptoCardLimit ||
    calcError?.externalCryptoAddress ||
    calcError?.orderCreation ||
    !information?.data?.calculation?.inputAsset ||
    information?.data?.calculation?.inputAsset <= commission ||
    client?.data?.status !== 'VERIFIED' ||
    isPaymentNotAvailable ||
    isTestingRequired;

  const linkPath = isLimitExceededError
    ? {
        pathname: RoutePaths.SUPPORT,
        search: `?topic=${CUSTOM_CHANGE}&message=increaseLimit&val=${information?.data?.calculation?.inputAsset}&cur=${information?.data?.currencyPair?.fromCurrency}`,
      }
    : RoutePaths.SIGN_IN;

  const onClickSubmit = () => {
    pushAnalyticsEvent('exchange_button_click');
  };

  return (
    <ButtonWrapper>
      {(!isLimitExceededError ||
        (isLimitExceededError && !isOperationEnabled)) &&
      user.data &&
      (information.data || errorStatus) ? (
        <Button
          type="submit"
          maxwidth="357px"
          borderradius="8px"
          margintop="20px"
          disabled={isDisabledButton}
          onClick={onClickSubmit}
        >
          {isSubmitting ? <Spinner /> : buttonName}
        </Button>
      ) : (
        <ButtonLink
          margintop="20px"
          maxwidth="357px"
          borderradius="8px"
          to={linkPath}
        >
          {buttonName}
        </ButtonLink>
      )}
    </ButtonWrapper>
  );
};

export default SubmitButton;
