import styled from 'styled-components';

export const ApiKeyWrapper = styled.div`
  background: ${props => props.theme.grayBg};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  padding: 10px;
  position: relative;
  margin-top: 28px;
`;

export const ApiKeyLabel = styled.label`
  color: ${props => props.theme.grayText};
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  top: 10px;
  left: 11px;
  padding: 0 5px;
  z-index: 1;
`;

export const ApiKeyValue = styled.input`
  display: block;
  width: calc(100% - 48px);
  font-size: 14px;
  overflow: hidden;
  margin: 30px 0 8px 5px;
`;

export const ButtonWrapper = styled.div`
  width: 130px;
  display: flex;
  align-items: center;

  svg {
    filter: invert(29%) sepia(79%) saturate(5586%) hue-rotate(211deg)
      brightness(105%) contrast(103%);
  }
`;
