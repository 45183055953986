import styled from 'styled-components';
import device from 'device';

export const ConsentFormTitle = styled.div`
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  white-space: pre-line;
  text-align: left;
  padding-bottom: 20px;

  @media ${device.laptop} {
    font-size: 24px;
    line-height: 30px;
  }

  @media ${device.mobile920} {
    white-space: normal;
  }
`;

export const Description = styled.div`
  width: 550px;
  color: ${props => props.theme.blackText};
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
  padding: 20px;
  border-radius: 12px;
  background: ${props => props.theme.grayBg};
  margin-bottom: 30px;

  @media ${device.mobile920} {
    width: auto;
    font-size: 16px;
  }
`;

export const UserDetailsTitle = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: ${props => props.theme.blackText};

  @media ${device.mobile920} {
    font-size: 24px;
  }
`;

export const UserDetailsSubTitle = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  white-space: pre-line;

  @media ${device.mobile920} {
    font-size: 16px;
    white-space: normal;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PhoneContainer = styled.div`
  margin-top: 35px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const ButtonWrapper = styled.div`
  display: ${({ display }) => display || 'grid'};
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  height: 58px;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
`;

export const CancelButton = styled.button`
  height: 56px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  background-color: #ffffff;
  color: #0f2f56;
  line-height: 19px;
  text-align: center;
  cursor: pointer;

  &:disabled {
    background-color: #e2e2e2;
    opacity: 0.5;
    pointer-events: none;
  }
`;
