import React from 'react';
import { CheckMark, InputCheckbox, LabelCheckbox } from './styled-ui';

const Checkbox = ({ checked, onChange, disabled, children }) => (
  <LabelCheckbox disabled={disabled}>
    <InputCheckbox type="checkbox" checked={checked} onChange={onChange} />
    <CheckMark />
    {children}
  </LabelCheckbox>
);

export default Checkbox;
