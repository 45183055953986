import React from 'react';
import { DateTime } from 'luxon';
import { TableRow, TotalBalanceTd } from './styled-ui';

const MerchantBalancesTableRow = ({ order, t }) => {
  return order ? (
    <TableRow key={order.id}>
      <td>
        {DateTime.fromISO(order.creationDate).toFormat('dd.MM.yyyy HH:mm:ss')}
      </td>
      <td>{order.id}</td>
      <td>{order.asset}</td>
      <td>{order.currency}</td>
      <td>{t(`merchantPage.filterItems.changeTypes.${order.changeType}`)}</td>
      <TotalBalanceTd>{order.totalBalance}</TotalBalanceTd>
    </TableRow>
  ) : null;
};

export default MerchantBalancesTableRow;
