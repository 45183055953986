import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Button } from 'ui-kit/Button';
import {
  Wrapper,
  ButtonWrapper,
  EmailSentWrapper,
  EmailConfirmedWrapper,
  Header,
  AppInfo,
  InnerLink,
  WrapperLink,
  InfoIcon,
  SupportLinkInline,
} from '../styled-ui';

const ConfirmedEmail = ({ setEmailConfirmation, t }) => {
  useContext(ThemeContext);
  return (
    <>
      <Wrapper>
        <EmailSentWrapper>
          <EmailConfirmedWrapper />
        </EmailSentWrapper>
        <Header>{t('signUpForm.emailConfirmed')}</Header>
        <AppInfo padding="0 0 30px" textalign="center">
          {t('signUpForm.twoFaDescription')}
          <WrapperLink>
            <SupportLinkInline>
              {t('signUpForm.twoFaDescription2')}
            </SupportLinkInline>
            <InfoIcon />
            <InnerLink>{t('signUpForm.twoFaDescription3')}</InnerLink>
          </WrapperLink>
        </AppInfo>
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              setEmailConfirmation(true);
            }}
          >
            {t('signUpForm.finish')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

ConfirmedEmail.propTypes = {
  setEmailConfirmation: PropTypes.func.isRequired,
};

export default ConfirmedEmail;
