import { DANGLING_PREPOSITIONS_REG_EXP } from 'utils/regexp';

export const hasWhiteSpace = string => {
  return /\s/g.test(string);
};

// Polyfill replaceAll for older/legacy browsers
const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const replaceAll = (string, find, replace) => {
  return string.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const hyphenationOfPrepositions = string => {
  return replaceAll(
    string.replace(DANGLING_PREPOSITIONS_REG_EXP, '$&\u00A0'),
    '\u00A0 ',
    '\u00A0'
  );
};
