import styled from 'styled-components';
import { Button } from 'ui-kit/Button';

export const FilterButton = styled(Button)`
  max-width: 124px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.btnColor};
  font-family: 'Inter';

  span {
    background: ${({ theme }) => theme.btnColor};
    height: 20px;
    width: 20px;
    border-radius: 20px;
    font-size: 9px;
    color: #fff;
    margin: auto -11px auto 8px;
  }

  svg {
    height: 17px;
    margin: auto 8px auto -8px;
  }

  &:hover {
    background-color: inherit;
    color: ${({ theme }) => theme.btnColor};
  }
`;

export const FiltersPanel = styled.div`
  background-color: ${({ theme }) => theme.fullWhite};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  padding: ${({ overFlow }) =>
    overFlow ? '25px 22px 25px 35px' : '25px 35px'};
  box-shadow: 0 10px 30px rgb(16 58 121 / 15%);
  border-radius: 12px;
  margin-top: 7px;
  position: absolute;
  width: auto;
  max-height: 760px;
  overflow-y: ${({ overFlow }) => (overFlow ? 'auto' : 'visible')};
  overflow-x: hidden;
  z-index: 1;

  &::-webkit-scrollbar {
    opacity: 0;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 4px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
      transform: translateX(-50px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.btnColor};
      border-radius: 4px;
    }
  }
`;

export const FiltersWrapper = styled.div`
  width: 124px;
  margin-right: 10px;
`;
