import React, { useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { DateTime } from 'luxon';
import moment from 'moment';
import ru from 'date-fns/locale/ru';
import { Label } from '../styled-ui';
import { DatepickerWrapper, ErrorMessage } from './styled-ui';

registerLocale('ru', ru);

const CustomDateInput = ({
  field,
  form,
  label,
  maxWidth,
  id,
  placeholder,
  pickerLeft,
  maxDate,
}) => {
  const { name } = field;
  const userValue = getIn(form.values, name);
  const [startDate, setStartDate] = useState(
    userValue && DateTime.fromISO(userValue, { zone: 'utc' }).toJSDate()
  );
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = isTouched && !!error;

  const handleChange = date => {
    const dataString = DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');
    if (DateTime.fromFormat(dataString, 'dd.MM.yyyy').isValid) {
      form.setFieldValue(
        field.name,
        moment(date).format('YYYY-MM-DDTHH:mm:ssZZ')
      );
      setStartDate(date);
    } else {
      setStartDate('');
      form.setFieldValue(field.name, '');
    }
  };

  return (
    <div className="date-picker">
      <DatepickerWrapper
        error={error}
        maxWidth={maxWidth}
        pickerLeft={pickerLeft}
      >
        {label && <Label>{label}</Label>}
        <ReactDatePicker
          id={id}
          name={name}
          value={startDate}
          showPopperArrow={false}
          selected={startDate}
          className={isError ? 'error' : ''}
          onChange={date => handleChange(date)}
          dateFormat="dd.MM.yyyy"
          dropdownMode="select"
          yearDropdownItemNumber={15}
          placeholderText={placeholder}
          maxDate={maxDate}
          locale="ru"
          autoComplete="off"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
        />
      </DatepickerWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

CustomDateInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({ values: PropTypes.shape({}) }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

CustomDateInput.defaultProps = {
  label: '',
};

export default CustomDateInput;
