import React from 'react';
import { ReactComponent as Check } from 'assets/images/icons/check-blue-small.svg';
import {
  StepsWrapper,
  StepsIcon,
  StepsItem,
  StepsItemContainer,
  StepsItemContent,
  StepsItemDescription,
  StepsItemIcon,
  StepsItemTail,
  StepsItemTitle,
} from './styled-ui';

const Steps = ({ items, currentPage }) => {
  const getType = index => {
    if (currentPage && currentPage === index + 1) {
      return 'process';
    }
    if (currentPage && currentPage > index + 1) {
      return 'finish';
    }
    return '';
  };

  const printIcon = index => {
    if (currentPage && currentPage > index + 1) {
      return <Check />;
    }
    return index + 1;
  };

  const printItems = () => {
    return items.map((item, index) => (
      <StepsItem key={`step_${index}`}>
        <StepsItemContainer>
          <StepsItemTail type={getType(index)} />
          <StepsItemIcon type={getType(index)}>
            <StepsIcon type={getType(index)}>{printIcon(index)}</StepsIcon>
          </StepsItemIcon>
          {item.title && (
            <StepsItemContent>
              <StepsItemTitle>{item.title && item.title}</StepsItemTitle>
              <StepsItemDescription type={getType(index)}>
                {item.description && item.description}
              </StepsItemDescription>
            </StepsItemContent>
          )}
        </StepsItemContainer>
      </StepsItem>
    ));
  };

  return <StepsWrapper>{items && printItems(items)}</StepsWrapper>;
};

export default Steps;
