import React from 'react';
import { Container, LogoIcon, LogosWrapper } from './styled-ui';

const logos = [
  {
    id: 'alfa-bank',
    url: '/assets/images/logos/alfa-bank.png',
  },
  {
    id: 'visa',
    url: '/assets/images/logos/visa.png',
  },
  {
    id: 'visa-secure',
    url: '/assets/images/logos/visa-secure.png',
  },
  {
    id: 'mastercard-id-check',
    url: '/assets/images/logos/mastercard-id-check.png',
  },
  {
    id: 'mastercard',
    url: '/assets/images/logos/mastercard.png',
  },
  {
    id: 'belcard-internet-password',
    url: '/assets/images/logos/belcard-internet-password.png',
  },
  {
    id: 'belcard',
    url: '/assets/images/logos/belcard.png',
  },
];

const Logos = () => (
  <Container>
    <LogosWrapper>
      {logos.map(logo => (
        <div key={logo.id}>
          <LogoIcon src={logo.url} alt={logo.id} />
        </div>
      ))}
    </LogosWrapper>
  </Container>
);

export default Logos;
