import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { HOME } from 'constants/routings';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getClientTestingNeeded } from 'utils/user';
import ClientContext from 'utils/contexts/Client';
import Loader from 'components/Loader';
import ErrorModal from 'components/ErrorModal';
import { SUMSUB } from 'utils/constant';
import { getVerificationStatus } from 'utils/services/request/verifications';
import SumSubVerification from './SumSub';

const Verification = () => {
  const client = useContext(ClientContext);
  const [initialValues, setInitialValues] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const isClientTestingRequired = getClientTestingNeeded(client);

  const verificationStatus = useAxiosState(getVerificationStatus, []);

  useEffect(() => {
    const { loaded, error, fetching, data } = verificationStatus;
    if (!fetching && loaded) {
      if (initialValues !== data) {
        setInitialValues(data);
      }
    }
    if (
      loaded &&
      error?.status === 'INVALID_CLIENT_STATUS' &&
      !isClientTestingRequired
    ) {
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationStatus]);
  const renderVerification =
    initialValues?.provider === SUMSUB || isClientTestingRequired;

  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      {!renderVerification && verificationStatus.fetching && <Loader />}
      {!renderVerification && verificationStatus.error && (
        <ErrorModal isReloadButton isVerification />
      )}
      {renderVerification && verificationStatus.loaded && (
        <SumSubVerification verificationStatus={verificationStatus} />
      )}
    </>
  );
};

export default Verification;
