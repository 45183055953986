import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxiosStateWithRefetchNoFirstCall } from 'utils/hooks/axiosHook';
import { HOME } from 'constants/routings';
import {
  ResendEmail,
  WrapperResend,
  ResendTimer,
  WrapperTimer,
  ButtonWrapper,
  Header,
  AppInfo,
  AddingBlack,
  ConfirmButton,
} from '../styled-ui';

const checkRepeatSendIsAvailable = () => {
  const restrictStorageDate = localStorage.getItem('restrictEmailDate');
  if (!restrictStorageDate) {
    return true;
  }
  const dateNow = moment();
  return dateNow.isAfter(restrictStorageDate);
};

const calculateTimeLeft = () => {
  const restrictStorageDate = localStorage.getItem('restrictEmailDate');
  if (!restrictStorageDate) {
    return 0;
  }
  const dateNow = moment();
  const dateRestrict = moment(restrictStorageDate);
  if (dateNow.isAfter(dateRestrict)) {
    return 0;
  }
  return dateRestrict.diff(dateNow, 'seconds');
};

const ConfirmationEmail = ({ user, t }) => {
  const [redirect, setRedirect] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const history = useHistory();
  const request = useAxiosStateWithRefetchNoFirstCall(
    {
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_URL}/user/code/send`,
    },
    []
  );

  useEffect(() => {
    if (checkRepeatSendIsAvailable()) {
      request.fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    if (redirect) user.refetch();
  }, [redirect, user]);

  const onFinish = () => {
    history.push(HOME, { isShowSocialModal: true });
  };

  useEffect(() => {
    if (request.data) {
      setTimeLeft(59);
      localStorage.setItem(
        'restrictEmailDate',
        moment()
          .add(1, 'minutes')
          .toISOString()
      );
    }
    if (request.error && request.error.code === 403) {
      user.refetch();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.loaded, request.error]);

  const resendEmail = useCallback(() => {
    request.refetch();
  }, [request]);

  return (
    <>
      <Header>{t('signUpForm.emailConfirmTitle')}</Header>
      <AppInfo padding="0 0 30px">
        {t('signUpForm.emailConfirmMessage1')}{' '}
        <AddingBlack>{user.data.email}</AddingBlack>{' '}
        {t('signUpForm.emailConfirmMessage2')}
      </AppInfo>
      <ButtonWrapper>
        <ConfirmButton onClick={onFinish} textalign="center">
          {t('signUpForm.finish')}
        </ConfirmButton>
      </ButtonWrapper>
      {!request.fetching && (
        <>
          {timeLeft <= 0 && (
            <WrapperResend>
              <ResendEmail type="button" onClick={resendEmail}>
                {t('signUpForm.emailResend')}
              </ResendEmail>
            </WrapperResend>
          )}
          {!checkRepeatSendIsAvailable() && timeLeft > 0 && (
            <WrapperTimer>
              <ResendTimer>{t('signUpForm.linkSent')}</ResendTimer>
              <ResendTimer>
                {t('signUpForm.newLinkAvailableIn', {
                  time: moment()
                    .startOf('day')
                    .seconds(timeLeft)
                    .format('ss'),
                })}
              </ResendTimer>
            </WrapperTimer>
          )}
        </>
      )}
    </>
  );
};

ConfirmationEmail.propTypes = {
  user: PropTypes.shape({
    refetch: PropTypes.func,
    data: PropTypes.shape({
      email: PropTypes.string,
      id: PropTypes.string,
      mfaEnabled: PropTypes.bool,
      // eslint-disable-next-line react/forbid-prop-types
      roles: PropTypes.array,
      userStatus: PropTypes.string,
    }),
  }).isRequired,
};

export default ConfirmationEmail;
