import React from 'react';
import {
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion';
import SanitizeHtml from 'ui-kit/SanitizeHtml/SanitizeHtml';
import {
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItems,
  HeadBlock,
  IconWrapper,
  UpIcon,
  DownIcon,
  TextBlock,
} from './styled-ui';

const FaqAccordion = ({ title, description }) => (
  <AccordionItems>
    <AccordionItemState>
      {({ expanded }) => (
        <>
          <AccordionItemHeading $expanded={expanded}>
            <AccordionItemButton>
              <HeadBlock>{title}</HeadBlock>
              <IconWrapper>{expanded ? <UpIcon /> : <DownIcon />}</IconWrapper>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel $expanded={expanded}>
            <TextBlock>
              <SanitizeHtml html={description} />
            </TextBlock>
          </AccordionItemPanel>
        </>
      )}
    </AccordionItemState>
  </AccordionItems>
);

export default FaqAccordion;
