import React from 'react';
import Dot from './styled-ui';

const DotPreloader = ({ dotColor }) => (
  <div>
    <Dot dotColor={dotColor} />
    <Dot dotColor={dotColor} />
    <Dot dotColor={dotColor} />
  </div>
);

export default DotPreloader;
