import React from 'react';
import styled from 'styled-components';
import device from 'device';
import { ReactComponent as Menu } from 'assets/images/icons/header-menu.svg';
import { ReactComponent as Profile } from 'assets/images/icons/profile.svg';
import { ReactComponent as Verified } from 'assets/images/icons/verified-profile.svg';

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const HeaderGrid = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  @media ${device.mobileHeader} {
    height: ${props => (props.isOpen ? '100vh' : 'initial')};
    flex-direction: ${props => (props.isOpen ? 'column' : 'initial')};
    align-items: ${props => (props.isOpen ? 'flex-start' : 'center')};
    justify-content: ${props => (props.isOpen ? 'initial' : 'space-between')};
  }
  @media ${device.laptopL} {
    padding: 0 25px;
  }
  @media ${device.mobileHeader} {
    position: relative;
    padding: 0 15px;
  }
`;

export const WrapperCustomerInfo = styled.div`
  display: flex;
  flex: ${props => (props.isOpen ? 'initial' : '0.7')};
  justify-content: flex-end;
`;

export const MenuIcon = styled(Menu)`
  display: none;
  line {
    stroke: #fff;
  }
  @media ${device.mobileHeader} {
    display: inline;
    padding: 24px 0;
    height: 36px;
  }
`;

export const ProfileIcon = styled(({ isUserVerified, userView, ...props }) => (
  <Profile {...props} />
))`
  display: inline;
  margin-right: ${({ userView }) => !userView && '10px'};
  width: 22px;
  height: 22px;
  circle {
    fill: #c3dcff;
  }
  path {
    fill: ${({ isUserVerified, theme }) =>
      isUserVerified ? '#c3dcff' : theme.blueBg};
  }
  @media ${device.mobileHeader} {
    display: flex;
    justify-self: end;
    padding: ${({ userView }) => !userView && '24px 0'};
    path {
      fill: ${({ userView }) => !userView && '#c3dcff'};
    }
  }
`;

export const VerifiedIcon = styled(({ userView, ...props }) => (
  <Verified {...props} />
))`
  position: absolute;
  width: 12px;
  height: 12px;
  left: 17px;
  bottom: 0;
  @media ${device.mobileHeader} {
    fill: ${({ userView }) => !userView && '#fff'};
    bottom: ${({ userView }) => !userView && '24px'};
    path {
      fill: ${({ userView }) => !userView && '#fff'};
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  max-width: 480px;
  width: 100%;
  align-self: center;
  justify-self: center;
  @media (max-width: 390px) {
    display: none;
  }
`;
