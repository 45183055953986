import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import InformationContext, {
  initialContext as initialInformationContext,
} from 'utils/contexts/Information';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { fromLocaleNumber } from 'utils/number';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';

const UPDATE_TIMER = 20000;
const DEBOUNCE_TIMER = 400; // this debounce request after input typing
const IDLE_TIMER = 45 * 60 * 1000; // this idle time after which stop the calculation request
const OPERATION_STATUS = {
  OPERATION_DISABLED: 'OPERATION_DISABLED',
};

// eslint-disable-next-line react/prop-types
const ContextWrap = ({
  children,
  values: {
    currencyPair,
    calculation,
    paymentToken,
    providerType,
    orderType,
    promoCode,
  },
  validateForm,
  setIsCalculationLoaded,
  isDisplayCalculator,
}) => {
  const { t } = useTranslation();
  const [inputData, setInputData] = useState({
    currencyPair,
    calculation,
    promoCode,
  });
  const [timerIteration, setTimerIteration] = useState(Date.now());
  const [isStopExchangeRate, setIsStopExchangeRate] = useState(false);
  const [information, setInformation] = useState(initialInformationContext);
  const { isIdle } = useIdleTimer({ timeout: IDLE_TIMER });
  const isOperationDisabled =
    information?.data?.operationStatus?.status ===
    OPERATION_STATUS.OPERATION_DISABLED;

  useEffect(() => {
    const idx = setTimeout(() => {
      if (currencyPair.toCurrency !== inputData.currencyPair.toCurrency) {
        setInputData({ currencyPair, calculation, promoCode });
        setTimerIteration(Date.now());
        return;
      }

      if (promoCode !== inputData.promoCode) {
        setInputData({ currencyPair, calculation, promoCode });
        setTimerIteration(Date.now());
        return;
      }

      setInputData({ currencyPair, calculation, promoCode });

      if (typeof calculation.inputAsset === 'string') {
        const inputPrevNoSpaces = calculation.inputAsset.replace(/\s/g, '');
        const inputNextNoSpaces = inputData.calculation.inputAsset.replace(
          /\s/g,
          ''
        );
        if (inputPrevNoSpaces === inputNextNoSpaces) return;
      }
      setTimerIteration(Date.now());
    }, DEBOUNCE_TIMER);
    return () => {
      clearTimeout(idx);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation, currencyPair, promoCode]);

  useEffect(() => {
    const idleTimer = setInterval(() => {
      if (isIdle()) {
        setIsStopExchangeRate(true);
      }
    }, 10000);
    return () => {
      clearInterval(idleTimer);
    };
  }, [isIdle]);

  useEffect(() => {
    const idInterval = setInterval(() => {
      if (!isStopExchangeRate && !isOperationDisabled) {
        setTimerIteration(Date.now());
      }
    }, UPDATE_TIMER);
    return () => {
      clearInterval(idInterval);
    };
  }, [inputData, isStopExchangeRate, isOperationDisabled]);

  localStorage.setItem('input_asset', calculation.inputAsset);

  let missingProviderType;

  const requestData = {
    method: 'post',
    url: `${process.env.REACT_APP_ADMIN_URL}/exchange/calculation`,
    data: {
      promoCode: inputData.promoCode,
      currencyPair: {
        fromCurrency: inputData.currencyPair.fromCurrency,
        toCurrency: inputData.currencyPair.toCurrency,
      },
      calculation: {
        inputAsset: inputData.calculation
          ? fromLocaleNumber(inputData.calculation.inputAsset)
          : 0,
      },
      paymentInfo: { paymentToken },
      providerType: providerType || missingProviderType,
    },
  };

  const calcInformation = useAxiosState(
    isDisplayCalculator ? requestData : {},
    [
      timerIteration,
      paymentToken,
      orderType,
      providerType,
      inputData.currencyPair,
      inputData.calculation,
      inputData.promoCode,
    ]
  );

  useEffect(() => {
    if (calcInformation.loaded) {
      setInformation(calcInformation);
      setIsCalculationLoaded(true);
    } else if (!information) {
      setInformation(calcInformation);
    } else {
      setInformation({
        ...calcInformation,
        data: information.data,
      });
    }

    if (calcInformation.data && calcInformation.data.limit) {
      window.localStorage.setItem(
        'limit',
        JSON.stringify(calcInformation.data.limit)
      );
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcInformation]);

  return (
    <>
      <InformationContext.Provider value={information}>
        {children}
      </InformationContext.Provider>
      {isStopExchangeRate && (
        <DialogModal
          Component={Dialog}
          notification
          title={t('calculationView.idleTimer.stopExchangeRate')}
          cancelButtonName={t('calculationView.idleTimer.refreshPage')}
          hideModal={() => window.location.reload()}
          t={t}
        />
      )}
    </>
  );
};

export default ContextWrap;
