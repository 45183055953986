import styled from 'styled-components';
import device from 'device';

export const MerchantWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 2rem;
  th {
    text-align: left;
  }
  td {
    font-size: 14px;
  }

  @media ${device.mobile920} {
    margin: 0;
  }
`;

export const MerchantContainer = styled.div`
  width: ${({ width }) => width || '950px'};
  margin: 0 auto;

  @media ${device.mobile920} {
    width: 100%;
  }
`;

export const MerchantPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  border-radius: 12px;
  margin: 0 auto 200px;
  padding: 20px 40px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(16, 58, 121, 0.15);

  @media ${device.desktop1280} {
    padding: 20px 10px;
  }

  @media ${device.mobile920} {
    border-radius: 0;
    margin: 0 0 100px;
    box-shadow: none;
    overflow-x: scroll;
  }
`;

export const MerchantTitle = styled.div`
  font-size: 56px;
  font-family: 'Inter';
  font-weight: 500;
  text-align: center;
  margin: 24px 0 30px;

  @media ${device.mobile920} {
    font-size: 24px;
  }
`;

export const MerchantOptions = styled.div`
  display: flex;
  margin-bottom: 21px;
`;

export const MerchantApi = styled.div`
  font-weight: bold;
`;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`;

export const WrapperInputDiv = styled.div`
  display: flex;
  width: 100%;
`;

export const WraperInput = styled.div`
  display: block;
  width: 100%;

  input {
    margin-left: 5px;
  }
`;

export const WrapperButton = styled.button`
  display: flex;
  align-self: center;
  border: 1px solid ${props => props.theme.grayBorder};
  align-items: center;
  border-radius: 8px;
  height: 60px;
  padding: 0 24px;
  font-size: 18px;
  margin-left: 18px;
`;

export const WrapperMerchantMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 23px;

  button {
    height: 45px;
    max-width: fit-content;
    margin: 0 4px 8px 0;
    padding: 0 15px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 14px;
`;
