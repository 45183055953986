import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import LoadingPlaceholderQr from 'ui-kit/LoadingPlaceholder/qr';
import Wallet from 'components/PendingInstruction/address';
import { Instruction, Title } from 'components/PendingStatus/styled-ui';
import {
  AddressInfo,
  AddressTextInfo,
  IconFirst,
  IconSecond,
  QRCode,
  QrRectangle,
  WrapperQr,
} from 'components/PendingInstruction/styled-ui';
import {
  Wrapper,
  StepOne,
  StepTwo,
  Border,
  EripInstruction,
  EripNumberLabel,
} from './styled-ui';

const EripOperationStatus = ({
  inputAsset,
  fromCurrency,
  address,
  walletQrData,
  setInternalToken,
  t,
}) => {
  useEffect(() => {
    setInternalToken(address);
  }, [address]);

  return (
    <Wrapper>
      <Title padding="60px 0 40px 0">{t('bankAccountViewer.eripTitle')}</Title>
      <Instruction alignItems="start">
        <StepOne>
          <IconFirst />
          <Border />
        </StepOne>
        <AddressInfo margin="0">
          <AddressTextInfo marginLeft="0" borderLeft="none">
            <EripInstruction>
              <Trans
                i18nKey="bankAccountViewer.eripInstruction1"
                values={{
                  number: address,
                  value: inputAsset,
                  currency: fromCurrency,
                }}
              />
            </EripInstruction>
            <Wallet address={address} t={t} margin="20px 0 10px 25px" />
            <EripNumberLabel paddingLeft="25px">
              {t('pending.eripLabel')}
            </EripNumberLabel>
          </AddressTextInfo>
          <WrapperQr>
            <QrRectangle />
            <QRCode>
              {!walletQrData ? (
                <LoadingPlaceholderQr
                  options={{ width: '150px', height: '150px' }}
                />
              ) : (
                <img src={walletQrData} alt="" />
              )}
            </QRCode>
          </WrapperQr>
        </AddressInfo>
      </Instruction>
      <Instruction>
        <StepTwo>
          <IconSecond />
          {t('bankAccountViewer.eripInstruction2')}
        </StepTwo>
      </Instruction>
    </Wrapper>
  );
};

export default EripOperationStatus;
