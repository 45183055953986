import * as Yup from 'yup';

const today = new Date();

export default function validationSchema(t) {
  return Yup.object().shape({
    creationDateFrom: Yup.date()
      .nullable()
      .max(today, t('merchantPage.validation.dateFrom')),
    creationDateTo: Yup.date()
      .nullable()
      .min(Yup.ref('creationDateFrom'), t('merchantPage.validation.dateTo')),
  });
}
