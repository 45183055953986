import React, { useEffect, useState } from 'react';
import { useAxiosState } from 'utils/hooks/axiosHook';
import Spinner from 'ui-kit/Spinner';
import { loadWebSdk } from './launchWebSdk';
import { LivenessTitle, NextButton } from '../styled-ui';

const LIVENESS_ANSWER = {
  RIGHT: 'GREEN',
  WRONG: 'RED',
};

const LivenessCheck = ({ client, handleOnSubmit, isLoading, t }) => {
  const [sumsubToken, setSumsubToken] = useState('');
  const [messageTypes, setMessageTypes] = useState({
    loadedSdk: false,
    livenessCompleted: false,
    stepInitiated: false,
  });

  const launchWebSdk = accessToken => {
    const sumsubWebSdkInstance = window.snsWebSdk
      .init(accessToken, () => null)
      .withBaseUrl('https://api.cyberity.ru')
      .withConf({
        lang: localStorage.getItem('sumsub_lang') || 'ru',
      })
      .on('idCheck.livenessCompleted', payload => {
        const livenessCompleted =
          payload?.allowContinuing ||
          payload?.answer?.toUpperCase() === LIVENESS_ANSWER.RIGHT;

        setMessageTypes(prev => ({
          ...prev,
          livenessCompleted,
        }));
      })
      .on('idCheck.onStepInitiated', () => {
        setMessageTypes(prev => ({ ...prev, stepInitiated: true }));
      })
      .build();

    sumsubWebSdkInstance.launch('#cyberity-websdk-container');
  };

  useEffect(() => {
    loadWebSdk(() => {
      setMessageTypes(prev => ({ ...prev, loadedSdk: true }));
    });
  }, []);

  const sumsubTokenData = useAxiosState(
    {
      method: 'get',
      url: `${process.env.REACT_APP_KYC_URL}/kyc/sumsub/sdk/token?levelType=LIVENESS`,
    },
    []
  );

  useEffect(() => {
    if (sumsubTokenData?.data?.token) {
      if (sumsubToken !== sumsubTokenData.data.token) {
        setSumsubToken(sumsubTokenData.data.token);
      }
    }
  }, [sumsubTokenData?.data?.token]);

  useEffect(() => {
    if (sumsubToken && client && messageTypes.loadedSdk) {
      launchWebSdk(sumsubToken);
    }
  }, [sumsubToken, messageTypes.loadedSdk]);

  return (
    <>
      {messageTypes.stepInitiated && !messageTypes.livenessCompleted && (
        <LivenessTitle>{t('verification.sumSubVerifyIdentity')}</LivenessTitle>
      )}
      <div id="cyberity-websdk-container" />
      {messageTypes.livenessCompleted && (
        <NextButton unsetGrid type="button" onClick={handleOnSubmit}>
          {isLoading ? <Spinner /> : t('verification.doneButton')}
        </NextButton>
      )}
    </>
  );
};

export default LivenessCheck;
