export default [
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY',
    inputMask: '+375 (__) ___-__-__',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU',
    inputMask: '+7 (___) ___-__-__',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA',
    inputMask: '+380 (__) ___-__-__',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ',
    inputMask: '+994 __-___-__-__',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM',
    inputMask: '+374 __-___-___',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+7',
    code: 'KZ',
    inputMask: '+7 (___) ___-__-__',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG',
    inputMask: '+996 (___) ___-___',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD',
    inputMask: '+373 ____-____',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ',
    inputMask: '+992 __-___-____',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ',
    inputMask: '+998 __-___-____',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE',
    inputMask: '+995 (___) ___-___',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM',
    inputMask: '+993 _-___-____',
  },
];
