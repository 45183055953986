import styled from 'styled-components';
import device from '../../../device';

export const LabelCheckbox = styled.label`
  cursor: pointer;
  color: ${props => (props.error ? '#F92828' : props.theme.blackText)};
  display: flex;
  flex: 1;
  align-items: ${({ alignItems }) => alignItems || 'center'};
`;

export const Checkbox = styled.div`
  width: ${({ isMain }) => (isMain ? '30px' : '24px')};
  height: ${({ isMain }) => (isMain ? '30px' : '24px')};
  border: 2px solid #c5c5c5;
  border-radius: 2px;
  display: inline-block;
  min-width: ${({ isMain }) => (isMain ? '30px' : '24px')};
  box-sizing: border-box;
  margin-right: 10px;
`;

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked + ${Checkbox} {
    background: url(${props => props.theme.checkboxImage}) no-repeat center;
    animation: animation-checkbox 0.4s ease-in-out;
    border: 0;
    width: ${({ isMain }) => (isMain ? '30px' : '24px')};
    height: ${({ isMain }) => (isMain ? '30px' : '24px')};
    background-size: ${({ isMain }) => isMain && '30px'};
    border-radius: 0;
  }

  @keyframes animation-checkbox {
    0% {
      transform: scale(0.8);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const LabelText = styled.div`
  margin: 0;
  display: inline-block;
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
  color: ${props => (props.error ? '#F92828' : props.theme.blackText)};
  div {
    font-size: ${({ isMain }) => isMain && '18px'};
  }
`;
