import styled from 'styled-components';
import { TableHead } from '../MerchantBalancesTable/styled-ui';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const CreationDateHeading = styled(TableHead)`
  width: 130px;
`;

export const ClientIdHeading = styled(TableHead)`
  width: 308px;
`;

export const PairHeading = styled(TableHead)`
  width: 72px;
`;

export const InputHeading = styled(TableHead)`
  text-align: right !important;
  padding-left: 0;
`;

export const DirectionHeading = styled(TableHead)`
  padding: 0;
`;

export const FirstLevelTr = styled.tr`
  transform: translate(0, 38px);

  th {
    line-height: 140%;
    vertical-align: top;
  }
`;

export const SecondLevelTh = styled(TableHead)`
  padding-bottom: 8px;
`;

export const ControlWrapper = styled.div`
  height: 38px;
  display: flex;

  button {
    margin: 0 12px 0 0;
  }
`;

export const ButtonWrapper = styled(ControlWrapper)`
  margin: 16px 8px;
  padding-bottom: 1rem;

  button {
    font-size: 14px;
    width: fit-content;
  }
`;

export const ModalHeading = styled.div`
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 21px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 0;
`;

export const Input = styled.input`
  height: 38px;
  border: 1px solid ${({ error }) => (error ? '#f00' : '#dedede')};
  border-radius: 8px;
  margin: 10px 0;
  padding: 5px 10px;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #f00;
  margin: 0 auto 15px 3px;
  display: inline-flex;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%)' : 'none')};
`;
