import React from 'react';
import {
  LoadingTd,
  DateHeading,
  PageButton,
  PaginationContainer,
  StyledTable,
  FirstLevelTr,
  TableBody,
  TableHead,
  TableRow,
} from './styled-ui';
import MerchantBalancesTableRow from './TableRow';

const MerchantBalancesTable = ({
  balancesData,
  refetch,
  currentPage,
  setCurrentPage,
  t,
}) => {
  const totalPagesArr = [...Array(balancesData?.totalPages).keys()];

  return (
    <>
      <StyledTable>
        <thead>
          <FirstLevelTr>
            <TableHead>{t('merchantPage.id')}</TableHead>
            <TableHead>{t('merchantPage.webhookStatus')}</TableHead>
            <TableHead>{t('merchantPage.paymentType')}</TableHead>
            <TableHead>{t('merchantPage.currency')}</TableHead>
            <TableHead>{t('merchantPage.paymentAmount')}</TableHead>
            <TableHead>{t('merchantPage.paymentAddress')}</TableHead>
            <DateHeading>{t('merchantPage.paymentDate')}</DateHeading>
          </FirstLevelTr>
        </thead>
        <TableBody>
          {balancesData
            ? balancesData.content.map(order => (
                <MerchantBalancesTableRow
                  key={order.id}
                  order={order}
                  refetch={refetch}
                  t={t}
                />
              ))
            : [...Array(9).keys()].map(recordNumber => (
                <TableRow key={`balancesTr-${recordNumber}`}>
                  {[...Array(7).keys()].map(dataNumber => (
                    <LoadingTd key={`balancesTd-${dataNumber}`} />
                  ))}
                </TableRow>
              ))}
        </TableBody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <PaginationContainer>
                {balancesData
                  ? totalPagesArr.map(item => (
                      <PageButton
                        key={`page-${item}`}
                        onClick={() => {
                          setCurrentPage(item);
                          refetch();
                        }}
                        type="button"
                        isCurrent={item === currentPage}
                      >
                        {item + 1}
                      </PageButton>
                    ))
                  : null}
              </PaginationContainer>
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    </>
  );
};

export default MerchantBalancesTable;
