import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAxiosGetParamsSubmitingEffect } from 'utils/hooks/axiosHook';
import { restorePasswordInit } from 'utils/services/request/auth';
import { Button } from 'ui-kit/Button';
import { HOME } from 'constants/routings';
import RestorePasswordForm from 'components/Auth/RestorePassword/form';
import {
  EmailConfirmedWrapper,
  EmailSentWrapper,
  Header,
  RestorePasswordLabel,
  ButtonWrapper,
} from '../styled-ui';

const RestorePassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const initData = {
    email: '',
  };

  const stateSubmitingLoginForm = useAxiosGetParamsSubmitingEffect(
    restorePasswordInit
  );

  const onSubmitHandler = useCallback(
    (values, actions) =>
      stateSubmitingLoginForm.setFormAndSubmit({
        params: values,
        values: null,
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      if (stateSubmitingLoginForm.error) {
        const { actions } = stateSubmitingLoginForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        actions.setErrors({
          email: t('passwordForms.errors.somethingWentWrong'),
        });
      } else if (stateSubmitingLoginForm.data.status === 'OK') {
        setEmailSent(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm, stateSubmitingLoginForm.loaded]);

  return (
    <>
      {emailSent ? (
        <>
          <EmailSentWrapper>
            <EmailConfirmedWrapper />
          </EmailSentWrapper>
          <Header textalign="center">
            {t('passwordForms.titleRestorePassword')}
          </Header>
          <RestorePasswordLabel textalign="center">
            {t('passwordForms.restorePasswordLabel')}
          </RestorePasswordLabel>
          <ButtonWrapper>
            <Button
              type="button"
              onClick={() => history.push(HOME)}
              disabled={false}
            >
              {t('passwordForms.doneButton')}
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <RestorePasswordForm
          initialValues={initData}
          onSubmitHandler={onSubmitHandler}
          t={t}
        />
      )}
    </>
  );
};

export default RestorePassword;
