import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-kit/Button';
import SocialMediaLinks from 'components/SocialMediaLinks';
import { Title, Wrapper } from './styled-ui';

const ContentModalSocialMedia = ({ hideModal }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>{t('signUpForm.socialMediaModal.title')}</Title>
      <SocialMediaLinks />
      <Button onClick={hideModal} padding="10px">
        {t('signUpForm.socialMediaModal.button')}
      </Button>
    </Wrapper>
  );
};

export default ContentModalSocialMedia;
