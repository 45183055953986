import React, { useState, useContext } from 'react';
import SignUp from 'components/Auth/SignUp';
import ConfirmedEmail from 'components/Auth/SignUp/confirmedEmail';
import ConfirmationEmail from 'components/Auth/SignUp/confirmationEmail';
import RedirectWithQuery from 'components/RedirectWithQuery';
import UserContext from 'utils/contexts/User';
import {
  isUserAnonymous,
  isUserConfirmedEmail,
  isUserMFAEnabled,
} from 'utils/services/selectors/user';

const AuthWizard = ({ t }) => {
  const user = useContext(UserContext);
  const isAnonymous = isUserAnonymous(user);
  const isConfirmedEmail = isUserConfirmedEmail(user);
  const isMFAEnabled = isUserMFAEnabled(user);

  const [isEmailConfirmedFinished, setEmailConfirmation] = useState(false);
  if (!user.loaded && user.fetching && user.data) {
    return null;
  }

  const renderSignUpForm = () => {
    if (!isAnonymous && !isConfirmedEmail) {
      return <ConfirmationEmail user={user} t={t} />;
    }

    if (isEmailConfirmedFinished) {
      return <RedirectWithQuery to="/" />;
    }

    if (
      !isAnonymous &&
      isConfirmedEmail &&
      !isEmailConfirmedFinished &&
      !isMFAEnabled
    ) {
      return (
        <ConfirmedEmail t={t} setEmailConfirmation={setEmailConfirmation} />
      );
    }

    return <SignUp t={t} user={user} />;
  };

  return <div>{renderSignUpForm()}</div>;
};

export default AuthWizard;
