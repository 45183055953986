import React, { useState, useEffect, useContext, useRef } from 'react';
import device from 'device';
import UserContext from 'utils/contexts/User';
import { isUserAnonymous } from 'utils/services/selectors/user';
import Logo from 'components/Header/Logo';
import CustomerInformation from '../CustomerInformation/index';
import Menu from './Menu';
import {
  HeaderWrapper,
  HeaderGrid,
  WrapperCustomerInfo,
  MenuIcon,
} from './styled-ui';

const Header = ({ is404 }) => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const user = useContext(UserContext);
  const headerRef = useRef(null);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia(device.mobileHeader);
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const toggleNav = (_, isLogo) => {
    if (isNavVisible || isLogo) {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'initial';
      document.body.style.width = 'initial';
    } else if (isSmallScreen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    }
    if (isSmallScreen) {
      setNavVisibility(!isNavVisible);
    }
  };

  const isAnonymous = isUserAnonymous(user);
  return (
    <HeaderWrapper>
      <HeaderGrid
        ref={headerRef}
        className="Header"
        isOpen={isNavVisible}
        is404={is404}
      >
        <Logo
          isSmallScreen={isSmallScreen}
          isOpen={isNavVisible}
          toggleNav={toggleNav}
        />
        {(isNavVisible || !isSmallScreen) && (
          <Menu
            toggleNav={toggleNav}
            isOpen={isNavVisible}
            isSmallScreen={isSmallScreen}
            isAnonymous={isAnonymous}
          />
        )}
        {/* {isNavVisible && isSmallScreen && (
          <ActionButtonWrapper>
            <ButtonLinkTransparentSmall to={RoutePaths.SUPPORT}>
              {t('header.whitebirdMessage')}
            </ButtonLinkTransparentSmall>
          </ActionButtonWrapper>
        )} */}
        <WrapperCustomerInfo isOpen={isNavVisible}>
          {/* <LanguageSelector /> */}

          <CustomerInformation
            isSmallScreen={isSmallScreen}
            isNavVisible={isNavVisible}
          />
        </WrapperCustomerInfo>
        {!isNavVisible && <MenuIcon onClick={toggleNav} />}
      </HeaderGrid>
    </HeaderWrapper>
  );
};

export default Header;
