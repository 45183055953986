import styled from 'styled-components';

const Dot = styled.span`
  display: inline-block;
  background: ${({ dotColor }) => dotColor || '#666'};
  height: 8px;
  width: 8px;
  opacity: 0.3;
  border-radius: 50%;
  animation: moveit 1s infinite;
  margin-right: 2px;

  :nth-child(2) {
    animation-delay: 0.15s;
  }

  :nth-child(3) {
    animation-delay: 0.2s;
  }

  @keyframes moveit {
    35% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.8;
    }
  }
`;
export default Dot;
