import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import { Tooltip, TooltipText, CopyButton } from '../styled-ui';

const CopyTooltip = ({ copyText, t }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const handleCopy = () => {
    const icon = document.getElementById('copyIcon');
    icon.style.fill = '#1B39CE';
    setTimeout(() => {
      icon.style.fill = '#323233';
    }, 1000);
  };

  return (
    <CopyToClipboard text={copyText}>
      <CopyButton onClick={handleCopy}>
        <Tooltip
          width="20px"
          height="22px"
          onClick={() => setIsShowTooltip(true)}
          onMouseLeave={() => setIsShowTooltip(false)}
        >
          <CopyIcon />
          <TooltipText className="arrow">
            {t(isShowTooltip ? 'tooltip.copied' : 'tooltip.copy')}
          </TooltipText>
        </Tooltip>
      </CopyButton>
    </CopyToClipboard>
  );
};

export default CopyTooltip;
