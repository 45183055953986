import React, { useState, useEffect } from 'react';
import { Duration } from 'luxon';
import { relativeTimeWithPlural } from 'utils/dates';
import TimerViewer from './styled-ui';

export default function Countdown({ initialTimeLeft }) {
  const [initialTime, setInitialTime] = useState(initialTimeLeft);

  useEffect(() => {
    if (initialTime > 0) {
      setTimeout(() => {
        setInitialTime(initialTime - 1);
      }, 1000);
    }
  }, [initialTime]);

  const timeDuration = Duration.fromObject({ seconds: initialTime });
  const days = timeDuration.toFormat('d');
  const dateFormat =
    days > 0
      ? `d ${relativeTimeWithPlural(days, 'день_дня_дней', 'dd')} hh:mm:ss`
      : 'hh:mm:ss';
  return (
    <TimerViewer>
      {Duration.fromObject({ seconds: initialTime }).toFormat(dateFormat)}
    </TimerViewer>
  );
}
