import styled from 'styled-components';
import device from 'device';

export const InputWrapper = styled.div`
  padding: 10px;
  border: 1px solid
    ${({ isError, theme }) => (isError ? '#FF0200' : theme.grayBorder)};
  border-radius: 4px;
  position: relative;
`;

export const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  z-index: 1;
  font-size: 0.7rem;
  color: ${props => props.theme.linkColor};
  @media ${device.laptop} {
    font-size: 12px;
    top: -9px;
  }
`;

export const Input = styled.input.attrs(props => ({
  type: props.type || 'text',
  placeholder: props.placeholder || undefined,
}))`
  border: none;
  font-size: 18px;
  font-family: 'Inter';
  color: ${props => props.theme.blackText};
  width: ${({ inputHiddenText }) =>
    inputHiddenText ? 'calc(100% - 48px)' : '100%'};
  min-width: 45%;
  height: 32px;
  &::placeholder {
    color: ${props => props.theme.grayText};
  }
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export default InputWrapper;
