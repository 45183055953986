import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { changeEmail } from 'utils/services/request/auth';
import ApproveModal from 'components/Auth/UserProfile/ApproveModal';
import ConfirmChangeEmail from 'components/Auth/UserProfile/ApproveModal/ChangeEmail';
import {
  FormGroup,
  ButtonWrapper,
  EditButton,
  EditIconButton,
} from './styled-ui';

const changeEmailSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('profile.incorrectEmailFormat'))
      .required(t('profile.requiredField')),
  });

const ChangeEmail = ({ t, initialMail, isConfirmedEmail }) => {
  const [confirmModalStep, setConfirmModalStep] = useState(null);
  const [isInputActivated, setIsInputActivated] = useState(false);

  const onCloseModal = () => setConfirmModalStep(null);

  const stateSubmitingLoginForm = useAxiosSubmitingEffect(changeEmail);

  const onSubmitHandler = useCallback(
    (values, actions) =>
      stateSubmitingLoginForm.setFormAndSubmit({
        values: {
          ...values,
          email: values.email.toLowerCase(),
        },
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSuccessCallback = email => {
    setIsInputActivated(false);
    stateSubmitingLoginForm.form.actions.setFieldValue('email', email);
  };

  const onInputFocus = setFieldValue => () => {
    if (!isInputActivated) {
      setFieldValue('email', '');
      setIsInputActivated(true);
    }
  };

  const resetActiveInput = (setFieldValue, setErrors) => () => {
    setIsInputActivated(false);
    setFieldValue('email', initialMail);
    setErrors('email', '');
  };

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      const { actions } = stateSubmitingLoginForm.form;
      if (stateSubmitingLoginForm.error) {
        const { status, message } = stateSubmitingLoginForm.error;
        if (status === 'INVALID_CODE') {
          if (message === 'Secret must not be empty') {
            setConfirmModalStep(1);
          }
          if (message === 'Code has been sent. Try again later') {
            actions.setErrors({
              email: t('profile.tooManyRequest'),
            });
          }
        }
        if (status === 'INVALID_EMAIL') {
          actions.setErrors({
            email: t('signUpForm.errors.incorrectEmailFormat'),
          });
        }
        if (status === 'USER_EXISTS') {
          actions.setErrors({
            email: t('signUpForm.errors.userExists'),
          });
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmitingLoginForm.data.status === 'OK') {
        setConfirmModalStep(2);
        actions.setSubmitting(false);
        // setTimeout(() => setShowSuccessLabel(false), 5000);
        // actions.resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm.loaded]);

  return (
    <Formik
      initialValues={{
        email: initialMail,
        mfaSecret: '',
      }}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validationSchema={changeEmailSchema(t)}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        submitForm,
        values,
        setFieldValue,
        setErrors,
      }) => (
        <Form>
          <FormGroup isInputActivated={isInputActivated}>
            <Field
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              component={Input}
              readOnly={!isInputActivated}
              placeholder="newmail@mail.com"
              label={t('profile.inputLabels.email')}
              autoComplete="off"
              inputHiddenText
              isReducedPadding
              disabledSameColor
            />
            {errors.email && touched.email && (
              <Error isSupportPage top={50}>
                {errors.email}
              </Error>
            )}
            {!isInputActivated && isConfirmedEmail && (
              <EditButton onClick={onInputFocus(setFieldValue)}>
                <EditIconButton />
              </EditButton>
            )}
            {isInputActivated && (
              <ButtonWrapper>
                <Button type="submit" disabled={isSubmitting || !values.email}>
                  {t('profile.settings.changeEmailSave')}
                </Button>
                <CancelChangesButton
                  type="button"
                  onClick={resetActiveInput(setFieldValue, setErrors)}
                >
                  {t('profile.settings.changePasswordCancel')}
                </CancelChangesButton>
              </ButtonWrapper>
            )}
          </FormGroup>
          {confirmModalStep && (
            <ApproveModal
              initialValues={values}
              errors={errors}
              isSubmitting={isSubmitting}
              onSubmitHandler={submitForm}
              t={t}
              Component={ConfirmChangeEmail}
              onClose={onCloseModal}
              onSuccessCallback={onSuccessCallback}
              confirmModalStep={confirmModalStep}
              setConfirmModalStep={setConfirmModalStep}
              setFieldValue={setFieldValue}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ChangeEmail;
