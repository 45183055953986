import styled from 'styled-components';
import device from '../../device';

export default styled.div`
  color: ${props => props.theme.grayText};
  font-size: 14px;
  line-height: 17px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 16px;
  }
`;
