export const getFaqs = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/razdely-faqs/?${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});

export const getCommonFaqs = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_STRAPI_URL}/api/main-faqs/?${query}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});
