export const bootIntercom = () => {
  if (!process.env.REACT_APP_INTERCOM_ID) {
    return;
  }
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
  });
};

export const loginIntercom = email => {
  if (!process.env.REACT_APP_INTERCOM_ID) {
    return;
  }
  window.Intercom('update', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
    name: email,
    email,
  });
};

export const logoutIntercom = () => {
  if (!process.env.REACT_APP_INTERCOM_ID) {
    return;
  }
  window.Intercom('shutdown');
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
  });
};
