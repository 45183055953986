export const CARD_ATTACH_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const FRAME_EVENT_STATUS = {
  OK: 'OK',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

export const VALIDATION_CLIENT = {
  PHONE_NUMBER_SHOULD_BE_SETUP: 'Phone number should be setup for user',
  PHONE_WRONG_FORMAT: 'Phone number wrong format',
  GENDER_SHOULD_BE_SETUP: 'Gender should be setup for user',
  RESIDENCE_SHOULD_BE_SETUP: 'Residence country not setup',
  RESIDENCE_ERROR: 'User should be resident of Belarus or Russia',
};

export const CRYPTO_CARD_STATUS = {
  NEW: 'NEW',
  CARD_CREATING: 'CARD_CREATING',
  CARD_CREATED: 'CARD_CREATED',
  CARD_ACTIVATED: 'CARD_ACTIVATED',
  CARD_PIN_CREATED: 'CARD_PIN_CREATED',
  CARD_RBS_BIND: 'CARD_RBS_BIND',
  CARD_RBS_BIND_ERROR: 'CARD_RBS_BIND_ERROR',
};
