import React, { useEffect, useState } from 'react';
import { ModalContent } from 'ui-kit/Modal/Modal';
import { Input } from './styled-ui';

const RenameCardModal = ({ value, onChange, onClose, onSubmit, t }) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    setScrollY(window.scrollY);
  }, []);

  useEffect(() => {
    document.documentElement.classList.add('is-locked');

    return () => {
      document.documentElement.classList.remove('is-locked');
      if (scrollY && window.innerWidth <= 920) window.scrollTo(0, scrollY);
    };
  }, [scrollY]);

  return (
    <ModalContent
      mainBtnColor
      title={t('profile.cardActions.renameCardModalTitle')}
      acceptButtonName={t('profile.cardActions.renameCard')}
      acceptFunc={onSubmit}
      onClose={onClose}
      t={t}
    >
      <Input
        type="text"
        value={value || ''}
        onChange={onChange}
        placeholder={t('profile.cardActions.cardTitlePlaceholder')}
        maxLength={35}
      />
    </ModalContent>
  );
};

export default RenameCardModal;
