import React from 'react';
import { convertExponentialToDecimal } from 'utils/number';
import { getCurrencyValue } from 'utils/crypto';
import { CUSTOM_CHANGE } from 'utils/constants/supportMessageTopics';
import { SUPPORT } from 'constants/routings';
import { LimitInfo, LimitInfoLink, LimitText } from './styled-ui';

const LimitRate = ({ t, information }) =>
  information.limit.max >= information.limit.min &&
  information.limit.max &&
  information.limit.min && (
    <LimitInfo>
      <LimitText>
        {t('calculationView.limitInfo', {
          min: convertExponentialToDecimal(information.limit.min),
          minCurrency:
            getCurrencyValue(information?.currencyPair?.fromCurrency) ||
            getCurrencyValue(information?.currency),
          max: convertExponentialToDecimal(information.limit.max),
          maxCurrency:
            getCurrencyValue(information?.currencyPair?.fromCurrency) ||
            getCurrencyValue(information?.currency),
        })}
      </LimitText>
      <LimitInfoLink
        to={{ pathname: SUPPORT, search: `?topic=${CUSTOM_CHANGE}` }}
      >
        {t('calculationView.increaseLimitFull')}
      </LimitInfoLink>
    </LimitInfo>
  );

export default LimitRate;
