import styled from 'styled-components';
import device from 'device';

export const ItemList = styled.ul`
  display: flex;
  flex: 1.5;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  @media ${device.laptop} {
    align-items: ${props => (props.isOpen ? 'flex-start' : 'center')};
  }
  @media (max-width: 930px) {
    flex: 1.5;
  }
  @media ${device.mobileHeader} {
    flex-direction: column;
    align-items: flex-start;
    flex: initial;
    row-gap: 20px;
    margin: 20px 0 20px 15px;
  }
`;

export const Item = styled.li`
  display: inline;
  font-size: 1rem;
  white-space: nowrap;
  a {
    color: ${props => props.theme.fullWhite};
    text-decoration: none;
  }
  @media ${device.mobileHeader} {
    font-size: 24px;
    a {
      color: ${props => props.theme.fullWhite};
      font-weight: ${props => props.additional && 700};
    }
  }
`;

export const Nav = styled.div``;
