import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  getCurrentLocale,
  getLocaleFromPath,
  getPathnameWithoutRouteProps,
  isAvailableLocale,
} from 'utils/location';

const LocaleRouter = ({ Component, exact, path }) => {
  const locale = getCurrentLocale();

  const RenderRoute = props => {
    const { location } = props;

    if (isAvailableLocale(location.pathname)) {
      if (getLocaleFromPath(location.pathname) === locale) {
        return <Component {...props} />;
      }
      const pathname = `/${locale}${getPathnameWithoutRouteProps(location)}`;

      return <Redirect to={{ ...location, pathname }} />;
    }
    const pathname = `/${locale}${location.pathname}`;

    return <Redirect to={{ ...location, pathname }} />;
  };

  return <Route exact={exact} path={path} render={RenderRoute} />;
};

export default LocaleRouter;
