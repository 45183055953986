import styled from 'styled-components';
import device from '../../device';

const InputWrapper = styled.div`
  border: 1px solid ${props => (props.isError ? '#FF0200' : '#E2E2E2')};
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${props => (props.isReducedPadding ? '5px 10px' : '10px')};
  @media ${device.laptop} {
    border-radius: 6px;
    padding: ${props => (props.isReducedPadding ? '5px 10px' : '7px 15px')};
  }
  position: relative;
  > input {
    width: 100%;
    color: #1e1e28;
  }
`;

export default InputWrapper;
