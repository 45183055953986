const ACTIONS = {
  SETTINGS_CHANGE_PHONE_MFA: 'SETTINGS_CHANGE_PHONE_MFA',
  SETTINGS_CHANGE_PHONE: 'SETTINGS_CHANGE_PHONE',
  CRYPTO_CARD_CONFIRM_PHONE: 'CRYPTO_CARD_CONFIRM_PHONE',
  REGISTRATION_CLIENT: 'REGISTER_CLIENT',
  REGISTRATION_USER_PHONE: 'REGISTRATION_USER_PHONE',
  VERIFICATION_CONFIRM_PHONE: 'VERIFICATION_CONFIRM_PHONE',
  SUPPORT_WEB_FORM: 'SUPPORT_WEB_FORM',
};

export default ACTIONS;
