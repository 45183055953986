import styled from 'styled-components';
import device from 'device';

export const Rate = styled.div.withConfig({ displayName: 'Rate' })`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  height: 25px;
  line-height: 26px;
  padding-left: 10px;
  color: ${({ theme }) => theme.fullBlackText};
  @media ${device.mobile920} {
    padding-left: 0;
  }
`;

export const Individual = styled.div.withConfig({ displayName: 'Individual' })`
  color: #323233;
  display: flex;
  padding: 0 10px;
`;

export const Global = styled.div`
  color: ${({ theme }) => theme.fullBlackText};
`;

export const RatioValue = styled.span`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fullBlackText};
`;

export const EmptyBlock = styled.span`
  height: 25px;
`;
