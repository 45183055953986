import styled, { keyframes } from 'styled-components';

const BounceDelay = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const SpinnerGroup = styled.div`
  z-index: 1;
  width: 24px;
  text-align: center;
  > * {
    width: 8px;
    height: 8px;
    background-color: ${({ color }) => color || '#0f2f56'};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${BounceDelay} 1.4s infinite ease-in-out both;
    animation: ${BounceDelay} 1.4s infinite ease-in-out both;
  }
`;

export const Spinner1 = styled.div`
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
`;

export const Spinner2 = styled.div`
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
`;
