import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { rejectMerchantPayment } from 'utils/services/request/merchant';
import {
  CancelButton,
  StatusSpan,
  TableRow,
  TotalBalanceTd,
} from './styled-ui';

const MerchantBalancesTableRow = ({ order, refetch, t }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleRejectPayment = () => {
    setIsLoading(true);
    axios(rejectMerchantPayment)
      .then(() => {
        refetch();
      })
      .catch(() => setIsLoading(false));
  };
  return order ? (
    <TableRow key={order.id}>
      <td title={order.id}>{`...${order.id.substr(order.id.length - 4)}`}</td>
      <td>
        <StatusSpan status={order.status}>
          {t(`merchantPage.paymentStatuses.${order.status}`)}
        </StatusSpan>
      </td>
      <td>{t(`merchantPage.filterItems.types.${order.type}`)}</td>
      <td>{order.currency}</td>
      <TotalBalanceTd>{order.amount}</TotalBalanceTd>
      <td>{order.depositCryptoAddress}</td>
      <td>
        {DateTime.fromISO(order.creationDate).toFormat('dd.MM.yyyy HH:mm:ss')}
        {order.status === 'REQUEST' && (
          <CancelButton
            status="REMOVE"
            onClick={handleRejectPayment}
            disabled={isLoading}
          >
            {t('merchantPage.paymentCancel')}
          </CancelButton>
        )}
      </td>
    </TableRow>
  ) : null;
};

export default MerchantBalancesTableRow;
