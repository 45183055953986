import PropTypes from 'prop-types';
import React from 'react';
import { GroupStep, Step } from './styled-ui';

const Wizard = ({ step, hide }) => {
  return (
    <>
      {!hide && (
        <GroupStep data-step={step}>
          <Step htmlFor="step-1">Шаг 1</Step>
          <Step htmlFor="step-2">Шаг 2</Step>
          <Step htmlFor="step-3">Шаг 3</Step>
        </GroupStep>
      )}
    </>
  );
};

Wizard.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Wizard;
