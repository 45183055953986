import React from 'react';
import PropTypes from 'prop-types';
import {
  getBlockerCurrency,
  updateMerchantName,
  updateMerchantUploadUrl,
  updateMerchantValidationUrl,
  updateMerchantWebhookUrl,
} from 'utils/services/request/merchant';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getSeparatedPairs, getSortedPairs } from 'utils/crypto';
import Url from './Url';
import ShowName from '../ShowName';
import ApiKey from './ApiKey';
import CurrencyPairsTable from './CurrencyPairsTable';

export default function OptionsComponent({ merchantData, merchantRefetch, t }) {
  const { data: blockerData } = useAxiosStateWithRefetch(getBlockerCurrency);
  const sortedCurrencyPairs = getSortedPairs(blockerData);
  const separatedCurrencyPairs = getSeparatedPairs(sortedCurrencyPairs);
  return (
    <>
      <ApiKey
        merchantRefetch={merchantRefetch}
        apiKey={merchantData?.apiKey}
        label={t('merchantPage.key')}
      />
      <Url
        method={updateMerchantWebhookUrl}
        url={'' || merchantData?.webhookURL}
        merchantRefetch={merchantRefetch}
        label="webhookUrl"
        noValidation
      />
      <Url
        method={updateMerchantUploadUrl}
        url={'' || merchantData?.externalSumsubPhotosUploadUrl}
        merchantRefetch={merchantRefetch}
        label="uploadURL"
        noValidation
      />
      <ShowName
        label="showName"
        method={updateMerchantName}
        merchantRefetch={merchantRefetch}
        showName={merchantData?.showName}
      />
      <Url
        method={updateMerchantValidationUrl}
        merchantRefetch={merchantRefetch}
        label="externalValidationUrl"
        url={'' || merchantData?.externalOrderValidationApiUrl}
        enabled={!!merchantData?.externalOrderValidationEnabled}
        noValidation
      />
      <CurrencyPairsTable pairs={separatedCurrencyPairs} t={t} />
    </>
  );
}

OptionsComponent.propTypes = {
  t: PropTypes.func.isRequired,
  merchantData: PropTypes.shape(),
  merchantRefetch: PropTypes.func.isRequired,
};

OptionsComponent.defaultProps = {
  merchantData: null,
};
