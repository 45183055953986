export const SETTINGS = 'settings';
export const OPERATION_HISTORY = 'operations';
export const PAYMENT_METHODS = 'payments';
export const NOTIFICATIONS = 'notifications';
export const EXCHANGE = 'exchange';
export const REFERRAL_PROGRAM = 'referral';

export const PROFILE_TABS = [
  EXCHANGE,
  SETTINGS,
  OPERATION_HISTORY,
  PAYMENT_METHODS,
  NOTIFICATIONS,
  REFERRAL_PROGRAM,
];
