import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { parse } from 'query-string';
import { AUTHORIZE, HOME, USER_PROFILE } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import { PAYMENT_METHODS } from 'utils/constants/profileTabs';
import { SmallModal } from 'ui-kit/Modal/Modal';
import SignIn from 'components/Auth/SignIn';

const SignInModal = ({ history }) => {
  const [redirect, setRedirect] = useState(false);
  const [redirectToOAuth, setRedirectToOAuth] = useState(false);
  const user = useContext(UserContext);
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const isRedirectToProfile = !!queryParams?.from;
  const isRedirectToHome =
    !redirectToOAuth && !isRedirectToProfile && (user.data || redirect);

  const handleGoToOAuth = () => {
    if (
      queryParams?.grant_type ||
      queryParams?.response_type ||
      queryParams?.client_id
    ) {
      setRedirectToOAuth(true);
      history.push({
        pathname: AUTHORIZE,
        search: window.location.search,
      });
    }
  };

  return (
    <>
      {isRedirectToHome ? <Redirect to={HOME} /> : null}
      {isRedirectToProfile && user.data ? (
        <Redirect to={`${USER_PROFILE}?tab=${PAYMENT_METHODS}`} />
      ) : null}
      <SmallModal
        Component={SignIn}
        onClose={() => setRedirect(true)}
        handleGoToOAuth={handleGoToOAuth}
      />
    </>
  );
};

export default SignInModal;
