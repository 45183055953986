import {
  CARD_PAYMENT_SERVICE,
  CRYPTO_CARD,
  ERIP,
  SETTLEMENT,
} from 'utils/constants/paymentMethods';
import {
  NOTIFICATIONS,
  OPERATION_HISTORY,
  PAYMENT_METHODS,
  REFERRAL_PROGRAM,
  SETTINGS,
} from 'utils/constants/profileTabs';
import { hyphenationOfPrepositions } from 'utils/string';

const transationRu = {
  translation: {
    welcome: 'Привет',
    register: 'Регистрироваться',
    'customer-info': {
      signin: 'Войти',
      registration: 'Регистрация',
      exchange: 'Обменять',
      profile: 'Личный кабинет',
      signup: 'Регистрация',
      logout: 'Выход',
      verified: 'Верифицирован',
      'email-confirmation': 'Подтвердить почту',
      'not-verified': 'Не верифицирован',
      'status-email': 'Статус email:',
      'not-confirmed': 'Не подтверждён',
      twofa: 'Двухфакторная аутентификация',
      confirmed: 'Подтверждён',
      'exist-from-account': 'Выйти из аккаунта',
      changePassword: 'Смена пароля',
      userProfile: 'Кабинет пользователя',
      'pass-verification': 'Пройти верификацию',
      text: {
        'didnt-receive-email': 'Не пришло письмо для подтверждения?',
      },
      button: {
        'send-again': 'Отправить повторно',
      },
    },
    header: {
      menuItems: [
        { text: 'Вопросы-ответы' },
        { text: 'Новости' },
        { text: 'Документы' },
        { text: 'Поддержка' },
      ],
      whitebirdMessage: 'Сообщение в Whitebird',
      myAccount: 'Мой аккаунт',
      login: 'Войти',
      registration: 'Регистрация',
      logout: 'Выйти из аккаунта',
    },
    calculationView: {
      tonComment: 'Tag/Memo',
      mainTitle: 'Просто. Быстро. Крипто.',
      mainTitleDesc: 'Удобный и выгодный обмен криптовалют',
      vipRate: 'VIP курс если продаёте от 20 тыс. USDT',
      orderTypeSwitcher: {
        bankCard: 'Банковская карта',
        bankAccount: 'Расчетный счет',
      },
      sellTitle: 'Продаю',
      buyTitle: 'Получаю',
      addressPlaceholder: 'Добавить адрес криптокошелька',
      cardInput: {
        addCard: 'Добавить средство платежа',
        payBySettlement: 'Оплата через расчетный счет',
        payByErip: 'Оплата через ЕРИП',
        errorCardResponse: 'Ошибка при получении списка карт',
        removeCardTitle: 'Удаление карты',
        removeCardMessage: 'Вы действительно хотите удалить карту?',
        removeCardAccept: 'Удалить',
        infoApproveEmail: 'Подтвердить почту для привязки карты',
        infoApproveVerification: 'Пройти верификацию',
        infoVerificationInProgress: 'Верификация в процессе',
        infoStatusUnavailable: 'Недоступно в данном статусе',
        getCardTest: 'Пройти тест для проведения операции',
      },
      wtInput: {
        addWt: 'Добавить расчетный счет',
        removeWtTitle: 'Удаление счета',
        removeWtMessage: 'Вы действительно хотите удалить расчетный счет?',
        removeWtAccept: 'Удалить',
        infoStatusUnavailable: 'Недоступно в данном статусе',
      },
      increaseLimit: 'Расширить',
      increaseLimitFull: 'Расширить лимит',
      idleTimer: {
        stopExchangeRate:
          'Обновление курсов остановлено. Для обновления курсов обновите страницу.',
        refreshPage: 'Обновить страницу',
      },
      errors: {
        validationErrorOutDownOfLimit:
          'Минимальная сумма {{ value }} {{ currency }}',
        validationErrorOutUpOfLimit: 'Превышен лимит',
        validationErrorLimitExceeded: 'Превышен лимит',
        validationErrorMonthlyLimitCryptoCard:
          'Превышен месячный лимит по криптокарте',
        validationValueLessThanCommission:
          '{{ value }} {{ currency }} меньше комиссии сети',
        validationErrorWalletEmpty: 'Введите адрес',
        validationErrorInputAsserEmpty: 'Поле не должно быть пустым',
        validationErrorCardEmpty: 'Добавьте банковскую карту',
        validationErrorBankAccountEmpty: 'Добавьте расчетный счет',
        validationErrorWalletFormat: 'Адрес имеет неверный формат',
        noCurrencyLimits: 'По данной валютной паре не установлены лимиты',
        invalidCorporateCard: 'Сбой в работе с системными картами',
        invalidClientCard: 'Необходимо привязать карту',
        invalidCryptoAddress:
          'Указан неверный криптоадрес. В случае возникновения вопросов обратитесь в службу поддержки',
        validationUserVerification:
          'Операции {{ operation }} доступны только верифицированным пользователям',
        validationErrorProvider:
          'Карта не поддерживается. Убедитесь, что данные карты верны',
        responseErrors: {
          INVALID_SCAN_STATUS:
            'Инициализация сервиса, повторите попытку через несколько минут',
          INVALID_ACTUAL_RATE:
            'В данный момент курс по выбранной паре недоступен. Обратитесь в службу поддержки',
          INVALID_SPREAD:
            'Расчет средств невозможен. Обратитесь в службу поддержки',
          INVALID_CHANGE_TO_ADDRESS:
            'Расчет средств невозможен. Обратитесь в службу поддержки',
          INVALID_UTXO_SUM: 'Направление {{ operation }} временно недоступно',
          INVALID_INPUT_ADDRESS:
            'Направление {{ operation }} временно недоступно',
          INVALID_FIAT_BALANCE:
            'Направление {{ operation }} временно недоступно',
          INVALID_CRYPTO_BALANCE:
            'Направление {{ operation }} временно недоступно',
          INVALID_CORPORATE_CARD: 'Направление обмена временно недоступно',
          INVALID_CLIENT_CARD: 'Направление обмена временно недоступно',
          FROZEN_CRYPTO_ADDRESS:
            'Ваш внутренний адрес для пополнения криптовалюты заморожен. Обратитесь в службу поддержки',
          INVALID_EXTERNAL_RATE:
            'Не удалось рассчитать индивидуальный курс. Обратитесь в службу поддержки',
          FORCE_BLOCKED_CLIENT:
            'Выявлен высокий риск использования адреса для осуществления противоправной деятельности. Обратитесь в службу поддержки',
          BAD_REQUEST:
            'Произошла непредвиденная ошибка. Обратитесь в службу поддержки',
          INVALID_WT_STATUS: 'Операции по расчетным счетам заблокированы',
          INVALID_INTERNAL_CLIENT_BALANCE_AMOUNT:
            'Недостаточно средств на кошельке',
          INVALID_FIAT_PROVIDER_CURRENCY:
            'Операция для выбранной карты возможна только в RUB',
          NO_PAYMENT_TOKEN: 'Вы не добавили средство платежа',
        },
      },
      errorPaymentModal: {
        title: 'Для продолжения операции необходимо добавить карту!',
        addCardAccept: 'Добавить',
      },
      limitInfo:
        'минимум: {{ min }} {{ minCurrency }}, максимум: {{ max }} {{ minCurrency }} за операцию',
    },
    partners: {
      headerFirstLine: 'Меняйте легально.',
      headerSecondLine:
        'Whitebird — белорусская компания с легальной крипто-лицензией на внебиржевой обмен',
    },
    mainPageFeatures: {
      features: [
        {
          mainText: 'Покупайте в несколько кликов.',
          additionalText: 'Паспорт и селфи — всё, что нужно для регистрации',
        },
        {
          mainText: 'Переводите деньги прямо на карту Visa.',
          additionalText:
            'Больше не нужно думать о зачислениях, ордерах, выводе и прочих биржевых сложностях',
        },
        {
          mainText: 'Получайте мгновенную помощь от службы поддержки.',
          additionalText: 'Мы всегда на связи',
        },
      ],
    },
    faqPreview: {
      buttonFaqText: 'Другие вопросы',
      buttonWriteUsText: 'Напишите нам',
    },
    footerPreview: {
      title: 'Мы на связи',
      mailUs: 'Сообщение в Whitebird',
      addressInfo:
        'ЗАО «Уайт Бёрд»,\nУНП 591029489,\nРеспублика Беларусь,220030,\nг. Минск, ул. Свердлова, д. 11, пом. 328\nСвидетельство о государственной регистрации юридического лица\n№ 591029489, выдано Минским городским исполнительным комитетом 23.07.2024',
      contactInfo: {
        title: 'Контактная информация:',
        email: 'info@whitebird.io',
      },
      copyrightSign: '© 2020–2021 Whitebird',
      aboutWhiteBird:
        'ЗАО "Уайт Бёрд" правопреемник ООО "Уайт Бёрд", резидент Парка высоких технологий, осуществляющий свою деятельность в соответствии с Декретом Президента Республики Беларусь от 21 декабря 2017 г. № 8 «О развитии цифровой экономики» и иным законодательством Республики Беларусь.',
      ErnstAndYoung:
        'ЗАО "Уайт Бёрд" полностью соответствует требованиям «Положения о требованиях, которым должны соответствовать отдельные заявители для регистрации их в качестве резидентов Парка высоких технологий», что подтверждено, в том числе, независимыми специалистами ООО «Эрнст энд Янг» (Компания EY).',
      risk1:
        'Посещение интернет-сайта, приобретение и доставка пользователю конкретного товара (работы, услуги) могут быть незаконными на территории страны, где находится пользователь. Если это так, держатель карты не имеет права использовать свою платежную карту для совершения транзакции.',
      risk2:
        'Держатель карточки несёт ответственность за невыполнение законов своей страны при посещении данного Интернет-сайта и/или попытке приобрести товары (работы, услуги), если таковые запрещены законодательством страны, где он находится.',
      securityText:
        'Должностное лицо, ответственное за обеспечение защиты информации, в части защиты персональных данных, специалист по системному администрированию и информационной безопасности - sec@whitebird.io',
      footerLinks: [
        { label: 'Правила обращения с персональными данными' },
        { label: 'Политика обработки персональных данных' },
        { label: 'Порядок обеспечения конфиденциальности' },
        { label: 'Политика оператора  в отношении персональных данных' },
        { label: 'Общие условия реализации токенов' },
        { label: 'Управление конфликтами интересов' },
        { label: 'Уведомление о рисках' },
        { label: 'Публичная оферта' },
        { label: 'Разрешение споров' },
        { label: 'Информация о собраниях участников Общества' },
      ],
      links: [
        { label: 'Вопросы-ответы' },
        { label: 'Новости' },
        { label: 'Поддержка' },
      ],
    },
    modals: {
      twoFa: {
        title: 'Отключение 2FA',
        message:
          'Вы действительно хотите отключить двухэтапную аутентификацию? Отключение функции может сделать ваш аккаунт более уязвимым.',
        acceptText: 'Отключить',
      },
      codeLabel: 'Проверочный код',
      cancel: 'Отмена',
      yes: 'Да',
      errors: {
        notValid: 'Введенный вами код невалидный.',
        requiredField: 'Обязательное поле.',
      },
    },
    signInForm: {
      title: 'Вход в личный кабинет',
      passPlaceholder: 'Ваш пароль',
      passLabel: 'Пароль',
      forgotPassword: 'Забыли пароль?',
      signin: 'Войти',
      noAccountYet: 'Еще нет аккаунта?',
      register: 'Зарегистрируйтесь',
      errors: {
        incorrectEmailFormat: 'Адрес почты имеет неправильный формат.',
        requiredField: 'Обязательное поле.',
        passMin: 'Пароль должен содержать минимум {{ count }} символов.',
        passMax: 'Пароль должен содержать максимум {{ count }} символов.',
        accountSigninLimited:
          'Превышено количество попыток входа. Доступ к аккаунту временно ограничен',
        incorrectCreds: 'Вход не удался. Неправильный логин или пароль.',
      },
    },
    signUpForm: {
      socialMediaModal: {
        title: 'Подписывайтесь на наши социальные сети:',
        button: 'Понятно',
      },
      emailConfirmTitle: 'Подтверждение Email',
      emailConfirmMessage1: 'На email',
      emailConfirmMessage2:
        'отправлено письмо со ссылкой. Перейдите по ссылке из письма для подтверждения email.',
      linkSent: 'Письмо со ссылкой отправлено.',
      newLinkAvailableIn:
        '{{ time }} секунд до возможности отправления нового письма.',
      codeLabel: 'Проверочный код',
      emailConfirmAccept: 'Подтвердить Email',
      emailConfirmed: 'Email подтверждён',
      emailNotConfirmed: 'Email не подтверждён',
      twoFaDescription: 'Для обеспечения безопасности аккаунта настройте\n',
      twoFaDescription2: 'двухэтапную аутентификацию.',
      twoFaDescription3:
        'Двухэтапная аутентификация — дополнительный уровень защиты учетной записи. Кроме ввода пароля, нужно также ввести одноразовый код, который приходит на почту, телефон или через приложение.',
      continue: 'Продолжить',
      later: 'Пройти позже',
      finish: 'Завершить',
      emailResend: 'Письмо не пришло, отправить еще раз!',
      codeSent: 'Проверочный код отправлен.',
      newCodeAvailableIn:
        '{{ time }} секунд до возможности получения нового кода.',
      twoFaRule1:
        '1. Скачайте приложение Google Authenticator в <1>Google Play</1> (Android) или <3>App Store</3> (iPhone).',
      twoFaRule2: '2. Откройте приложение и выберите Настроить аккаунт.',
      twoFaRule3: '3. Нажмите Сканировать QR-код или Введите ключ настройки.',
      twoFaCodeInfo:
        '4. Введите подтверждающий код из приложения. Не нужно запоминать эти коды, так как они меняются после каждого входа.',
      twoFaIsOnLabel: 'Двухфакторная аутентификация включена',
      accountRegistered:
        'Ваш аккаунт зарегистрирован. Для получения полного доступа к операциям обмена, пожалуйста, пройдите верификацию.',
      skip: 'Пропустить',
      registerLabel: 'Регистрация аккаунта',
      partnerText: 'Для продолжения обмена на',
      passPlaceholder: 'Ваш пароль',
      passLabel: 'Пароль',
      agreementMain: 'Согласие с условиями регистрации',
      agreement1:
        'Регистрируясь в информационной системе Whitebird и создавая электронный личный кабинет, Вы соглашаетесь с условиями <1>Публичной оферты</1> (включая её неотъемлемые части: Общие условия реализации токенов, Правила обращения с персональными данными клиентов, Положение о поддержке пользователей)',
      agreement2:
        'Согласен(на) на получение информационных и рекламных сообщений от Whitebird.',
      createAccount: 'Создать аккаунт',
      alreadyHaveAccount: 'Уже есть аккаунт?',
      accountLogin: 'Войти в личный кабинет',
      errors: {
        codeExpire: 'Срок действия кода истек. Повторите отсылку кода еще раз.',
        invalidCode: 'Вы указали неверный код',
        requiredField: 'Обязательное поле.',
        userExists: 'Пользователь с указанным адресом уже существует',
        incorrectEmailFormat: 'Адрес почты имеет неправильный формат.',
        passMin: 'Пароль должен содержать минимум {{ count }} символов.',
        passMax: 'Пароль должен содержать максимум {{ count }} символов.',
        capsLockEnabled: 'Caps Lock включен',
      },
    },
    passwordForms: {
      titleChange: 'Смена пароля',
      titleRestore: 'Восстановление пароля',
      oldPassLabel: 'Старый пароль',
      newPassLabel: 'Новый пароль',
      repeatedPassLabel: 'Подтвердите новый пароль',
      buttonChangeLabel: 'Сменить пароль',
      buttonRestoreLabel: 'Восстановить пароль',
      codeLabel: 'Проверочный код',
      instructionLabel:
        'Введите адрес электронной почты вашей учетной записи на который мы отправим дальнейшие инструкции.',
      titleRestorePassword: 'Сообщение для сброса пароля отправлено',
      titleConfirmEmail: 'Почта подтверждена',
      titleConfirmEmailError: 'Почта не подтверждена',
      сonfirmEmailLabel:
        'Ваш почтовый ящик был успешно подтвержден. Чтобы продолжить пользоваться сайтом нажмите кнопку Готово.',
      сonfirmEmailLabelError:
        'Ваш почтовый ящик не был подтвержден. Чтобы продолжить пользоваться сайтом нажмите кнопку Готово.',
      restorePasswordLabel:
        'Сообщение было отправлено на указанный адрес. Следуйте инструкциям в сообщении, чтобы восстановить доступ',
      restorePasswordLabelText:
        'Чтобы войти в личный кабинет, используйте свой e-mail и новый пароль',
      newPasswordLabelText:
        'Для восстановления пароля придумайте новый пароль, который будет использован для входа в систему.',
      doneButton: 'Готово',
      titleSuccesfulChange: 'Пароль успешно изменен',
      linkToHome: 'Перейти на главную страницу',
      accountLogin: 'Войти в личный кабинет',
      submitCode: 'Подтвердить код',
      errors: {
        failedLink:
          'Ссылка недействительна. Создайте новый запрос на восстановление пароля.',
        codeExpire: 'Срок действия кода истек. Повторите отсылку кода еще раз.',
        invalidCode: 'Вы указали неверный код',
        requiredField: 'Обязательное поле.',
        userExists: 'Пользователь с указанным адресом уже существует',
        incorrectEmailFormat: 'Адрес почты имеет неправильный формат.',
        passMin: 'Пароль должен содержать минимум {{ count }} символов.',
        passMax: 'Пароль должен содержать максимум {{ count }} символов.',
        wrongPassword: 'Пароли не совпадают',
        capsLockEnabled: 'Caps Lock включен',
        failedOldPassword: 'Старый пароль указан неверно',
        somethingWentWrong:
          'Что-то пошло не так, повторите попытку или обратитесь в службу поддержки',
      },
    },
    exchangeRate: 'Курс {{ rate }}',
    blockchainFee: 'Комиссия блокчейн сети {{ value }}',
    serviceFee: 'Комиссия сервиса {{ value }}',
    converterCard: {
      fromCard: 'Отправить с карты',
      toCard: 'Отправить на карту',
      notVerified:
        'Только верифицированный пользователь может осуществлять привязку карты',
      notActiveCards: 'Нет активных карт.',
      addCard: 'Добавить',
      removeCardTitle: 'Удаление карты',
      removeCardMessage: 'Вы действительно хотите удалить карту?',
      removeCardAccept: 'Удалить',
      addNewCard: 'Добавить новую карту',
      errorAddingCard: 'Добавление новой карты запрещено',
      error: 'Ошибка!',
      invalidCorporateCard: 'Сбой в работе с системными картами',
      invalidClientCard: 'Необходимо привязать карту',
      individualRate: 'Выгодный курс для держателей карт Visa от Беларусбанк',
      youSale: 'Вы продаете:',
      youBuy: 'Вы покупаете:',
      infoInstruction:
        'Отправка с личного адреса на адрес обменника. Следуйте дальнейшим инструкциям.',
      receiveOnAddress: 'Получить на адрес',
      walletAddreess: 'Адрес кошелька',
      exchange: 'Обменять',
      confirm: 'Подтвердить заявку',
      errorInputAsserEmpty: 'Поле не должно быть пустое',
      linkToExchange: 'Перейти к обмену',
      loginToExchange: 'Войти, чтобы начать обмен',
    },
    bankAccountViewer: {
      onCard: 'НА КАРТУ',
      fromCard: 'С КАРТЫ',
      onBankAccount: 'НА РАСЧЕТНЫЙ СЧЕТ',
      fromBankAccount: 'С РАСЧЕТНОГО СЧЕТА',
      sell: 'ПРОДАЕТЕ',
      buy: 'ПОКУПАЕТЕ',
      title: 'Инструкции для обмена',
      invoice: 'Счет на оплату',
      uploadBankAccountRequisites: 'Скачать',
      onAddress: 'НА АДРЕС',
      hash: 'ХЭШ:\u0020',
      hidden: 'Скрыт.',
      confirmAddressAffiliation:
        'Да, я подтверждаю, что адрес принадлежит мне, и несу все риски в случае неверно указанного адреса',
      labelInstruction1:
        'Выберите банк, на счет которого, вам удобнее перевести деньги. Мы подготовим для вас счет на следующем шаге.',
      labelInstruction2: 'Подтвердите заявку',
      chooseBankAccount: 'Выберите банк',
      editBankAccount: 'Редактирование расчетного счета',
      bindBankaccount: 'Привязка расчетного счета',
      titleAddNewBankAccount: 'Добавление расчетного счета',
      descriptionAddNewBankAccount:
        'Чтобы добавить новый расчетный счет, отправьте сканы или фото договора банковского обслуживания с реквизитами <1>в службу поддержки</1> \n\n Как только наш оператор привяжет данные к вашему аккаунту, он появится в списке доступных счетов',
      descriptionActivateWt:
        'Чтобы добавить функцию оплаты через расчетный счет, обратитесь <1>в службу поддержки</1> \n\nКак только наш оператор активирует данную функцию, оплата через расчетный счет будет доступна.',
      buttonOk: 'ОК',
      eripTitle: 'Инструкция для перевода',
      eripNumber: 'ПЛАТËЖ ЕРИП № ',
      eripInstruction1:
        'Выберите <strong>Система "Расчёт" (ЕРИП) > Банковские, финансовые услуги > Криптобиржи, криптообменники > Whitebird.io</strong> или найдите по номеру услуги: <strong>5183701</strong>. \n Введите номер заявки: <strong>{{ number }}</strong> \n Совершите оплату <strong>{{ value }} {{ currency }}</strong>',
      eripInstruction2:
        'После совершения платежа в ЕРИП нажмите кнопку «Я перевёл»',
      publicOfferAlfaBank:
        'Подтверждаю, что ознакомлен и согласен с условиями \n <linkTag>Публичной оферты</linkTag> ЗАО «Альфа-Банк»',
      publicOffer: 'Публичной оферты',
    },
    pending: {
      title: 'Инструкции для обмена',
      instruction1:
        'Переведите {{ amount }} {{ currencyDescription }} со своего кошелька на адрес:',
      instruction2: 'После совершения перевода нажмите кнопку «Я перевёл».',
      checkboxText: 'Я использую сервис с отложенной выплатой.',
      checkboxHover: `<i>Не включайте эту опцию, если не уверены.\n\n Только для пользователей, которые выводят средства из сервисов с отложенной выплатой.</i>\n\n Некоторые майнинг-пулы и биржи выводят криптовалюту своих клиентов не сразу, а партиями несколько раз в сутки. Обычно мы ожидаем появления транзакции в мемпуле в течение 30 минут, по истечении которых она отклоняется.\n\n Чтобы избежать преждевременного отклонения заявки, поставьте галочку «Вывод с сервиса с отложенной выплатой», и мы увеличим время ожидания ваших средств. Как только мы получим средства, вам будет выставлена контр-оферта в соответствии с курсом на момент получения.`,
      errorLabel:
        'Важно! Не сохраняйте этот адрес. Используйте его только один раз и только для данной операции. \n\n' +
        ' Перевод криптовалюты в иной сети приведет к потере ваших средств. Сумма, указанная в заявке, должна поступить на адрес криптообменника одной транзакцией. Комиссия за перевод оплачивается Вами.',
      eripLabel:
        'Вы можете отсканировать QR-код приложением банка, чтобы не вводить \n реквизиты в ручную.',
      exchange: 'Обменять',
      confirm: 'Подтвердить заявку',
      done: 'Я перевёл',
      exchangeNumber: 'Заявка на обмен №{{ number }}',
      createdAt: 'Создана {{ operationDateLuxon }}',
      titleStatus: 'Статус заявки',
      updateStatus: 'Обновить статус',
      cancelAfter: 'Отмена заявки через: ',
      cancelOrder: 'Отменить заявку',
      submitOrder: 'Принять условия',
      termsUpdated: 'Внимание! Обновлены условия сделки',
      newTerms: 'Новые условия',
      stoppedOperation:
        'Операции обмена по данной валюте временно остановлены. Попробуйте позже',
      tempStopped: 'Операции обмена по данной валюте временно остановлены.',
      stoppedAllOperation:
        'Операции обмена временно приостановлены. Попробуйте позже.',
      recieveAnotherSum:
        'Мы получили от вас сумму, которая отличается от указанной в заявке. Произведен перерасчет и обновлены условия сделки.',
      notRecieve:
        'Мы не получили ваши средства вовремя. Произведен перерасчет и обновлены условия сделки.',
      labelIfCancel:
        'В случае отмены вы получите средства за вычетом комиссии сети или банка.',
      ratesWasUpdated:
        'Курсы были обновлены, пожалуйста проверьте условия заявки и повторите ваше действие',
      somethingWentWrong:
        'Что-то пошло не так, повторите попытку или обратитесь в службу поддержки',
      newExchange: 'Совершить новую операцию',
    },
    belarusBankPage: {
      boldText1:
        'Держатели платежных карт Беларусбанк Visa могут совершать обмен в сервисе Whitebird по еще более выгодному курсу.',
      boldText2:
        'Чтобы воспользоваться этим преимуществом, привяжите карту Беларусбанк Visa к своему аккаунту, и выберите ее в качестве платежного средства.',
      boldText3: 'Выгодный курс применится автоматически.',
      text:
        'В 2018 году Whitebird совместно с Беларусбанком начал готовить инфраструктуру для бесшовной интеграции платежных операций по картам Visa в процесс обмена криптовалют. Работа велась при участии платежного провайдера Assist, Банковского процессингового центра, а также платежной системы Visa.\n\nРезультатом совместных усилий стала возможность осуществления быстрых расчетов по картам Visa для пользователей Whitebird, а также уменьшение транзакционных издержек для клиентов Беларусбанк.',
    },
    cryptoCardPage: {
      button: 'Оформить криптокарту',
      faq: {
        title: 'Вопросы - ответы',
      },
      error: 'Произошла ошибка при загрузки данных, попробуйте позже',
    },
    support: {
      nameSurname: 'Имя Фамилия',
      introduce: 'Представьтесь',
      topic: 'Тема обращения',
      messageProblem: 'Опишите проблему',
      youMessage: 'Ваше сообщение',
      initialMessages: {
        vipRate: 'Хочу получить VIP курс при обмене более 20 тыс. USDT',
        increaseLimit: 'Хочу увеличить лимит до {{ val }} {{ cur }}',
      },
      validFormats: 'Допустимые форматы файлов: JPEG, JPG, PNG, PDF.',
      maxFiles: 'Количество файлов: не более 5.',
      maxSizes: 'Максимальный размер файла: 10 MB.',
      send: 'Отправить',
      titleSupport: 'Служба поддержки',
      requestSupport: 'Запрос в службу поддержки отправлен',
      checkboxText:
        'Нажимая на кнопку «Отправить», я даю свое согласие на обработку моих персональных данных для получения обратной связи в соответствии с',
      personalDataProcessingPolicy: 'Политикой обработки персональных данных',
      errors: {
        requiredField: 'Обязательное поле.',
        userExists: 'Пользователь с указанным адресом уже существует',
        incorrectEmailFormat: 'Адрес почты имеет неправильный формат.',
        passMin: 'Пароль должен содержать минимум {{ count }} символов.',
        passMax: 'Пароль должен содержать максимум {{ count }} символов.',
        nameMax: 'Имя должно состоять максимум из {{ count }} символов.',
        topicMax: 'Тема должна состоять максимум из {{ count }} символов.',
        messageMax: 'Сообщение не должно превышать {{ count }} символов.',
        filesMax: 'Возможно загрузить не более {{ count }} файлов',
        emailTrim: 'Адрес почты не должен иметь пробелов в начале или конце',
        capsLockEnabled: 'Caps Lock включен',
      },
    },
    noMatch: {
      titleCode: '404',
      message: 'Страница не найдена',
      infoMessage:
        'Запрашиваемая Вами страница не найдена. Пожалуйста, воспользуйтесь меню сайта или вернитесь на главную',
      redirectToHomePage: 'На главную страницу',
    },
    transfer: {
      title: 'Подтверждение операции',
      userName: 'Имя пользователя: {{ name }}',
      currency: 'Валюта:',
      inputAddress: 'С адреса:',
      outputAddress: 'На адрес:',
      total: 'Сумма:',
      confirm: 'Подтвердить',
      cancel: 'Отменить',
      error: {
        failed: 'Произошла ошибка. Попробуйте снова',
        notFound: 'Заявка на операцию не найдена',
      },
    },
    verification: {
      confirmPhoneTitle: 'Подтверждение \n номера телефона',
      confirmPhoneDescription:
        'Для прохождения верификации необходимо подтвердить номер телефона',
      countryFlag: 'Являюсь гражданином Республики Беларусь',
      residencePermitInBelarusPresent: 'Являюсь гражданином другой страны',
      exchangeInPersonalInterests:
        'Операции обмена совершаю в своих интересах и не представляю интересы третьих сторон',
      notUSTaxpayer: 'Не являюсь налогоплательщиком в США',
      addQuiz:
        'Гражданам Республики Беларусь необходимо пройти дополнительный опрос',
      residence:
        'Наличие вида на жительство в Республике Беларусь для иностранных граждан',
      chooseCitizenship: 'Выберите гражданство...',
      chooseDocumentType: 'Выберите тип документа: ',
      documentTypes: {
        passport: 'Паспорт',
        residentCard: 'Вид на жительство',
        idCard: 'ID карта',
      },
      document: 'Документ, удостоверяющий личность',
      documentType: 'Тип документа',
      nationality: 'Гражданство',
      identityDocId: 'Серия и номер',
      identityDocDate: 'Дата выдачи',
      identityDocIssuer: 'Кем выдан',
      placeOfBirth: 'Место рождения',
      country: 'Страна',
      region: 'Регион/Область',
      district: 'Район',
      city: 'Город/Населенный пункт',
      street: 'Улица',
      house: 'Дом',
      flat: 'Квартира',
      postCode: 'Почтовый индекс',
      residenceAsRegistered:
        'Подтверждаю, что место жительства и регистрации совпадает',
      supportingDocuments: 'Подтверждающие документы',
      skanOrPhoto:
        'Для подтверждения введенных данных прикрепите скан-копии или фото:',
      label1: '1) Разворот документа с фотографией и ФИО;',
      label2:
        '2) Разворот документа с информацией о месте рождения (в случае нахождения информации на отдельной странице);',
      label3: '3) Разворот документа с адресом регистрации.',
      validFormats: 'Загружать можно JPEG, JPG, PNG или PDF файлы.',
      maxSizes: 'Максимальный размер файла: 10 Мб.',
      titleGeneralInformation: 'Общие сведения',
      name: 'Имя',
      patronymic: 'Отчество',
      surname: 'Фамилия',
      birthday: 'Дата рождения',
      titleHome: 'Место жительства',
      chooseCountry: 'Выберите страну...',
      residenceAndRegistration:
        'Подтверждаю, что место жительства и регистрации совпадает',
      residenceDocument: 'Документ, подтверждающий место жительства',
      residenceConfirm:
        'Для подтверждения вашего фактического место жительства, пожалуйста, предоставьте один из следующих документов: извещение (счет) о размере платы за жилищно-коммунальные услуги, платы за пользование жилым помещением, подтверждения оплаты коммунальных платежей (газ, свет, воду и пр.), договор аренды жилого помещения.',
      otherInformation: 'Иные сведения',
      uploadDocument: 'Загрузка документа',
      photoWithDocument: 'Фото с документом',
      step3label1: '- Ваш взгляд должен быть направлен прямо в камеру;',
      step3label2:
        '- Пожалуйста, убедитесь, что каждая деталь документа хорошо видна;',
      step3label3:
        '- Пальцы не должны закрывать какую-либо важную информацию на документе;',
      step3label4: '- Не надевайте головные уборы, очки и не закрывайте лицо.',
      step3label5:
        '1. Документ не должен закрывать ваше лицо, а взгляд должен быть направлен прямо в камеру',
      step3label6: '2. Пальцы не должны закрывать информацию на документе',
      step3label7: '3. Пусть вас сфотографирует близкий человек или друг',
      quizLoadError: 'Ошибка загрузки теста',
      sumSubWizardTitle: 'Верификация пользователя',
      sumSubVerifyIdentity: 'Подтвердите свою личность',
      sumSubWizardDescription:
        'По закону, мы обязаны верифицировать и проверять наших клиентов. Прежде чем вы сможете совершать финансовые операции на Whitebird, пожалуйста, верифицируйте аккаунт.',
      sumSubWizardNext: 'Далее',
      addDocumentsButton: 'Добавить документ',
      limitMessage: 'Вы можете добавить не более 3-ёх изображений',
      buttonToBack: 'Вернуться в начало',
      doneButton: 'Готово',
      sendButton: 'Отправить',
      refreshButton: 'Сначала',
      documentsProcess: {
        success:
          'Ваши данные успешно получены. Если вы заметили неточное распознание - не волнуйтесь. Это будет исправлено вручную. Нажмите Готово для продолжения!',
        errorParse:
          'Не все данные корректно распознаны. Отправьте новые фотографии!',
        processingError:
          'Недостоверные данные. Попробуйте загрузить другие изображения!',
        unknownError: 'Произошла неизвестная ошибка!',
      },
      documentFields: {
        firstNameRu: 'Имя:',
        lastNameRu: 'Фамилия:',
        patronymicRu: 'Отчество:',
        firstName: 'Имя (англ.):',
        lastName: 'Фамилия: (англ.)',
        placeOfBirth: 'Место рождения:',
        birthDate: 'Дата рождения:',
        identityDocType: 'Тип документа:',
        identityDocIssueDate: 'Дата выдачи:',
        identityDocExpireDate: 'Срок действия:',
        identityDocNumber: 'Номер и серия:',
        identityDocIssuer: 'Кем выдан:',
        personalNumber: 'Идентификационный номер:',
        gender: 'Пол:',
        nationality: 'Национальность:',
      },
      startButton: 'Начать',
      testTitle: 'Подтверждение осведомленности',
      noticeTextAfterRegula: {
        title: 'Система уже проверяет ваши данные',
        subTitle: 'Статус автоматически изменится после завершения проверки',
        button: 'На главную',
      },
      testFormSuccess: 'Верификация успешно пройдена!',
      testDescription:
        'Для граждан Республики Беларусь необходимо пройти следующий тест:',
      finallyTitle: 'Ваши данные находятся в обработке',
      steps: {
        confirm: {
          title: 'Согласие',
          description: 'Ознакомьтесь с условиями',
        },
        liveness: {
          title: 'Селфи',
          description: 'Пройдите сканирование лица',
        },
        documents: {
          title: 'Документы',
          description: 'Верификация документа удостоверяющего личноcть',
        },
        test: {
          title: 'Тест',
          description: 'Прохождение теста',
        },
      },
      regula: {
        modes: {
          changeDocs: 'Смена документов',
          changeReg: 'Добавление регистрации',
          changeRegTitle: 'Загрузите документ с регистрацией',
          title:
            'Вам необходимо будет пройти сканирование лица и далее вы можете отправить ',
          titleDocs: 'новые документы',
          titleReg: 'фото документа с регистрацией',
        },
        liveness: {
          goButton: 'Старт',
          title: 'Время для селфи!',
          subTitle: 'До начала убедитесь, что выполнены следующие условия:',
          conditionFirst: 'Хорошее освещение',
          conditionSecond: 'Никаких аксессуаров: очков, маски, шляпы и т.д',
          conditionThird: 'Камера на уровне глаз',
        },
        documents: {
          registration:
            'Загрузите любой документ, подтверждающий вашу регистрацию, на котором будет указано ваше ФИО и место жительства',
          imageExpired:
            'Ваше изображение старше 3 месяцев. Сделайте новое изображение',
          title: 'Удостоверение личности',
          changeRegistration: `Если в паспорте не указано место регистрации (или место регистрации отличается от места жительства), то предоставьте один из документов:<br/>
            - квитанция об оплате коммунальных услуг (газ, вода, электричество и т.д.) не старше 3-х месяцев со дня представления к рассмотрению,<br/>
            - договор аренды/найма жилого помещения,<br/>
            - выписка из обслуживающего банка в надлежащем качестве, содержащая ФИО и фактическое место жительства не старше 3 месяцев со дня представления к рассмотрению,<br/>
            - иной документ, подтверждающий место регистрации/место жительства.<br/><br/>
            Обратите внимание, что мы не принимаем скриншоты, счета за мобильный телефон, медицинские счета, квитанции о покупках или страховые ведомости.`,
          condition:
            'Снимок должен быть: <br/> - <strong>светлым и чётким</strong> (хорошее качество),<br/>- <strong>необрезанным</strong> (видны все углы документа),<br/> - возраст изображения должен быть не старше <strong>3 месяцев</strong>.',
          label: 'Выберите страну выдачи документа',
          passport: {
            title: 'Сфотографируйте ваш паспорт.',
            description:
              'Загрузите полный разворот с фотографией, предыдущий разворот и страницу с пропиской',
            firstPage: 'Загрузите полный разворот документа с фотографией',
            secondPage: 'Загрузите предыдущий разворот документа',
            thirdPage: 'Загрузите разворот с пропиской',
          },
          residentCard: {
            title: 'Сфотографируйте ваш вид на жительство.',
            description:
              'Загрузите лицевую сторону и обратную сторону документа',
            firstPage: 'Загрузите лицевую сторону документа',
            secondPage: 'Загрузите обратную сторону документа',
            thirdPage: 'Загрузите документ с пропиской',
          },
          idCard: {
            title: 'Загрузите фотографию ID карты.',
            description:
              'Загрузите лицевую сторону и обратную сторону документа',
            firstPage: 'Загрузите лицевую сторону документа',
            secondPage: 'Загрузите обратную сторону документа',
            thirdPage: 'Загрузите документ с пропиской',
          },
        },
      },
    },
    pageTitle: {
      main: 'Главная',
      faq: 'Вопросы - ответы',
      support: 'Поддержка',
      documents: 'Документы',
      security: 'Безопасность',
      about: 'О сервисе',
      belarusbank: 'Получите выгодный курс с картами Беларусбанк Visa',
      dispute: 'Разрешение споров',
      media: 'Новости',
      cryptoCard: 'Карта Crypto',
      userProfile: 'Кабинет пользователя',
      information: 'Информация о собраниях участников Общества',
    },
    disputeInfoText:
      'В случае, если вы считаете, что ЗАО “Уайт Бёрд” своими действиями или бездействиями нарушило ваши права, вы можете обратиться за защитой своих нарушенных прав в судебные или иные уполномоченные органы, а также предложить урегулировать спор посредством медиации.',
    processingStatuses: {
      name: {
        request: 'Заявка',
        inProgress: 'В обработке',
        confirm: 'Завершена',
        reject: 'Отклонено',
        arest: 'Арест',
        changed: 'Обновлены условия сделки',
      },
      description: {
        request: 'Заявка принята',
        confirmation:
          'Заявка подтверждена. Ожидаем подтверждения оплаты от банка',
        confirmationBuy:
          'Заявка подтверждена. Ожидаем от вас поступления криптовалюты',
        confirmationCrypto: 'Заявка подтверждена',
        preparation:
          'Заявка на зачисление фиатных средств принята банком. Ожидаем поступления средств',
        preparationBuy:
          'Заявка на перевод фиатных средств передана в банк. Ожидайте поступления',
        processing:
          'Оплата прошла успешно. Осуществляется перевод криптовалюты',
        processingBuy: 'Оплата прошла успешно. Ожидайте поступления средств',
        pending: 'Транзакция по криптовалюте ожидает попадания в блок',
        selection: 'Транзакция по криптовалюте находится на подтверждении',
        crypConfirmation:
          'Транзакция по криптовалюте подтверждена. Средства зачислены на ваш кошелек',
        cryptConfirmationBuy:
          'Транзакция по криптовалюте подтверждена. Ожидайте поступления фиатных средств',
        rejection: 'Клиент отменил заявку',
        expiration: 'Истекло допустимое время ожидания',
        declined: 'Заявка отклонена',
        arrest:
          'Мы заблокировали обмен по вашей заявке. Финансовый контроль адреса криптокошелька выявил высокую степень риска',
        timeout: 'Время ожидания получения средств истекло',
        invalidAmount:
          'Получена сумма, которая отличается от указанной в заявке',
      },
    },
    statuses: {
      notVerified: 'Не верифицирован',
      pending: 'Верифицируется',
      verified: 'Верифицирован',
      frozen: 'Заморожен',
      merchant: 'Мерчант',
    },
    workTimeErrors: {
      AML_CURRENCY_DISABLED:
        'Сделки с резидентами Беларуси временно совершаются только в белорусских рублях',
      OPERATION_DAY_DISABLED:
        'Операции {{ operation }} временно не производятся',
      OPERATION_DISABLED: `По выбранной паре операции обмена временно не производятся`,
    },
    loading: {
      error: 'Ошибка при получении данных',
    },
    media: {
      title: 'Новости',
      source: 'Источник',
      datePublish: 'Дата публикации:',
      additionalMediaTitle: 'Другие статьи',
      additionalPosts: 'Другие публикации о нас',
      moveToExchange: 'Перейти к обмену',
      types: {
        all: 'Все',
        media: 'СМИ о нас',
        event: 'События',
        promotion: 'Акции',
      },
    },
    merchantPage: {
      title: 'Личный кабинет мерчанта',
      options: 'Свойства',
      key: 'API ключ',
      webhookUrl: 'Webhook URL',
      uploadURL: 'URL экспорта данных клиента',
      showName: 'Название для отображения клиенту',
      save: 'Сохранить',
      externalValidationUrl: 'URL внешней валидации',
      availablePairs: 'Доступные пары',
      buy: 'Покупка',
      sell: 'Продажа',
      balances: 'Балансы',
      operationLists: 'Список операций',
      number: 'Номер',
      creationDate: 'Дата создания',
      clientId: 'ID клиента',
      pair: 'Пара',
      inputAsset: 'Сумма поступл.',
      outputAsset: 'Сумма списания',
      direction: 'Направл.',
      transactionStatus: 'Статус транзакции',
      fiatTransactionStatus: 'фиатной',
      cryptoTransactionStatus: 'крипто',
      operationStatus: 'Статус операции',
      from: 'из',
      prevPage: 'Пред',
      nextPage: 'След',
      balancesList: 'История балансов',
      asset: 'Количество',
      currency: 'Валюта',
      changeType: 'Тип изменения',
      merchantId: 'ID мерчанта',
      total: 'Итоговый',
      balance: 'баланс',
      id: 'ID',
      webhookTableCaption: 'Управление веб хуками',
      webhookStatus: 'Статус',
      webhookType: 'Тип',
      webhookMessage: 'Текст сообщения',
      webhookCreationDate: 'Дата создания',
      webhookLastSentDate: 'Последняя отправка',
      webhookAction: 'Действие',
      webhooks: 'Веб-хуки',
      payments: 'Платежи',
      paymentsHeader: 'История платежей',
      newPaymentHeader: 'Новый платеж',
      paymentType: 'Тип',
      paymentDate: 'Дата',
      paymentAddress: 'Адрес',
      paymentAmount: 'Сумма',
      generatePayment: 'Сформировать платеж',
      paymentStatuses: {
        NEW: 'Новый',
        NOT_FOUND: 'Заявка',
        REJECTED: 'Отклонен',
        TIMEOUT: 'Время ожидания',
        EXPIRED: 'Истек',
        INVALID_AMOUNT: 'Неверная сумма',
        ERROR: 'Ошибка',
        AML_ERROR: 'Ошибка (AML)',
        ARREST: 'Арест',
        PENDING: 'В ожидании',
        SELECTED: 'В обработке',
        CONFIRMED: 'Завершен',
        REQUEST: 'Активный',
      },
      paymentCancel: 'Отмена',
      iSent: 'Я перевёл',
      paymentWarning:
        'Важно! Переводите всю сумму одной транзакцией. Комиссия за перевод оплачивается вами.',
      createPaymentError: 'Ошибка: у вас уже есть активный платёж',
      paymentRequisites: 'Реквизиты платежа',
      optionsPairs: 'Свойства и доступные пары',
      operations: 'Операции обмена',
      pairLabel: 'Валютная пара',
      resend: 'Отправить',
      credit: 'Допустимый минусовой баланс (суммарный кредит)',
      debt: 'Текущий минусовой баланс',
      addFilter: 'Фильтр',
      applyFilter: 'Применить',
      reportModalHeader: 'Отчёт',
      creationDateLabel: 'Дата создания',
      completionDateLabel: 'Дата завершения',
      orderTypesLabel: 'Тип операции',
      orderStatusesLabel: 'Статус операции',
      providerTypesLabel: 'Провайдер',
      fiatStatusesLabel: 'Статус фиатной транзакции',
      cryptoStatusesLabel: 'Статус крипто транзакции',
      fiatCurrenciesLabel: 'Фиатные валюты',
      cryptoCurrenciesLabel: 'Криптовалюты',
      report: 'Отчёт',
      downloadReport: 'Скачать отчёт',
      search: 'Поиск',
      resetFilter: 'Сбросить фильтр',
      validation: {
        utmKeyMin: 'Минимум {{ count }} символа',
        utmKeyMax: 'Максимум {{ count }} символов',
        dateFrom: 'Дата начала не может быть больше сегодняшней',
        dateTo: 'Дата окончания не может быть больше даты начала',
        invalidUrl: 'Неверный URL',
        invalidName: 'Недопустимое название',
      },
      filterItems: {
        types: {
          DEFAULT: 'Default',
          DEPOSIT: 'Deposit',
          WITHDRAWAL: 'Withdrawal',
          WT: 'WT',
        },
        providerTypes: {
          ASSIST: 'ASSIST',
          CA: 'CA',
          BGP: 'BGP',
          BEPAID: 'BEPAID',
          ALFA: 'ALFA',
        },
        statuses: {
          REQUEST: 'Заявка',
          PROCESSING: 'В обработке',
          PAUSED: 'Приостановлена',
          CHANGED: 'Изменена',
          CONFIRMED: 'Завершена',
          DECLINED: 'Отказано',
          EXPIRED: 'Истекла',
          REJECTED: 'Отклонена',
          ARREST: 'Арест',
        },
        fiatStatuses: {
          NEW: 'Заявка',
          REJECTED: 'Отклонена',
          TIMEOUT: 'Время ожидания',
          DECLINED: 'Отказано',
          ERROR: 'Ошибка',
          PENDING: 'В ожидании',
          PROCESSING: 'В обработке',
          APPROVED: 'Завершена',
        },
        cryptoStatuses: {
          NEW: 'Новая заявка',
          NOT_FOUND: 'Заявка',
          REJECTED: 'Отклонена',
          TIMEOUT: 'Время ожидания',
          INVALID_AMOUNT: 'Неверная сумма',
          ERROR: 'Ошибка',
          AML_ERROR: 'Ошибка (AML)',
          ARREST: 'Арест',
          PENDING: 'В ожидании',
          SELECTED: 'В обработке',
          CONFIRMED: 'Завершена',
        },
        fiatCurrencies: {
          USD: 'USD',
          RUB: 'RUB',
          BYN: 'BYN',
          EUR: 'EUR',
        },
        cryptoCurrencies: {
          BTC: 'BTC',
          ETH: 'ETH',
          USDT: 'USDT',
          TRX: 'TRX',
          USDT_TRC: 'USDT',
          TON: 'TON',
          USDT_TON: 'USDT',
          WBP: 'WBP',
        },
        changeTypes: {
          EXCHANGE_OPERATION: 'Операция обмена',
          MANUAL: 'Обновлено системой',
          EXCHANGE_REWARD: 'Вознаграждение',
        },
      },
    },
    filters: {
      currencies: 'Валюта',
      changeTypes: 'Тип изменения',
      creationDateFrom: 'Создано с',
      creationDateTo: 'Создано по',
      completionDateFrom: 'Совершено с',
      completionDateTo: 'Совершено по',
      types: 'Направление',
      providerTypes: 'Провайдер',
      statuses: 'Статус операции',
      fiatStatuses: 'Статус фиатной транзакции',
      cryptoStatuses: 'Статус криптотранзакции',
      fiatCurrencies: 'Фиат',
      cryptoCurrencies: 'Криптовалюты',
      utmKey: 'UTM метка',
      clientId: 'ID клиента',
    },
    assistErrors: {
      NOT_SUFFICIENT_FUNDS:
        'Операция отклонена банком. Недостаточно средств на карте.',
      DECLINED: 'Операция отклонена банком. Обратитесь в ваш банк.',
      AUTH_3D_SECURE_DECLINED:
        'Операция отклонена банком. Не введен либо некорректно введен 3D-код.',
      LIMIT_EXCEEDED: 'Операция отклонена банком. Превышен лимит операций.',
      CARD_EXPIRED: 'Операция отклонена банком. Истек срок действия карты.',
    },
    promocode: {
      inputPlaceholder: 'У меня есть промокод',
      applyButtonText: 'Применить',
      resetButtonText: 'Отменить',
      appliedPromo: 'Промокод {{ code }}',
      orderPromoInfo: 'Бонус {{ value }} по промокоду {{ code }}',
      orderServiceCommissionPromo:
        'Бонус от комиссии сервиса {{ value }} по промокоду {{ code }}',
      errors: {
        INVALID_PROMO_CODE: {
          title: 'Промокод недействителен',
          description: 'Код не существует или уже был использован',
        },
        INVALID_PROMO_CODE_CURRENCY: {
          title: 'Промокод для другой валюты',
          description: 'Выберите валюту, которая указана на коде',
        },
        INVALID_PROMO_CODE_DISCOUNT: {
          title: 'Недостаточная сумма для промокода',
          description: 'Увеличьте сумму обмена',
        },
        PROMO_CODE_USAGE_DISABLED: {
          title: 'Промокод применим только к первой сделке',
          description: 'Промокод уже был использован',
        },
        emptyField: 'Вы не ввели промокод',
        promoMin: 'Промокод должен содержать минимум {{ count }} символа.',
        promoMax: 'Промокод должен содержать максимум {{ count }} символов.',
        promoFormat: 'Промокод введен неверно',
      },
    },
    chargeRow: {
      included: 'Включены:',
      serviceFee: 'Комисcия сервиса {{ value }}',
      serviceFeeDescription:
        'В комиссию включены <strong>все</strong> расходы по процессингу карты, услуги банка и сервиса Whitebird.',
      blockchainFee: 'Комисcия сети блокчейн {{ value }}',
      blockchainFeeDescription: '',
      bonus: 'Бонус {{ value }}',
      serviceCommissionBonus: 'Бонус от комиссии сервиса {{ value }}',
    },
    exchangeDescription: {
      title: 'Обменивайте выгодно',
      buy: 'Покупка',
      sell: 'Продажа',
      onSum: 'На сумме {{ value }}',
      cardTitles: {
        commonExchange: 'Обычная \n Криптобиржа',
        whitebirdExchange: 'Криптообменник \n Whitebird',
      },
      buyCommonDescInfo:
        'Ввод фиата 3% - 3.5% \n Обмен 0.2% - 0.4% \n Вывод крипты 0.0001 - 0.0005 BTC \n Итого 3.2% - 4% + 0.0003 - 0.0005 BTC',
      sellCommonDescInfo:
        'Пополнение 0% \n Обмен 0.2% - 0.4% \n Вывод фиата 3% - 3.5% \n Итого 3.2% - 4%',
      buyWhitebirdDescInfo: 'Итого ~4.5% \n',
      sellWhitebirdDescInfo: 'Итого ~3.5% + комиссия блокчейна \n',
      whitebirdTip:
        'В комиссию включены все расходы по процессингу карты, услуги банка и сервиса Whitebird',
    },
    notifications: {
      clearAll: 'Очистить все',
      noNewNotifications: 'Нет новых уведомлений',
      noNotificationData: 'Нет уведомлений',
      allNotificationLink: 'Все уведомления',
    },
    profile: {
      buttons: {
        goVerify: 'Пройти верификацию',
        goChangeDocs: 'Заменить документы',
        goChangeReg: 'Заменить документ регистрации',
      },
      pageTabs: {
        exchange: 'Обменять',
        [SETTINGS]: 'Настройки',
        [OPERATION_HISTORY]: 'История операций',
        [PAYMENT_METHODS]: 'Способы оплаты',
        [NOTIFICATIONS]: 'Уведомления',
        [REFERRAL_PROGRAM]: 'Реферальная программа',
      },
      recommendText: 'Рекомендуем',
      newEmailMessage:
        '{{ time }} секунд до возможности отправления нового письма',
      requiredField: 'Обязательное поле',
      tooManyRequest: 'Пожалуйста, повторите позже',
      incorrectEmailFormat: 'Адрес почты имеет неправильный формат',
      operationsTable: {
        headings: {
          id: 'Номер',
          status: 'Статус',
          inputAsset: 'Сумма списания',
          outputAsset: 'Сумма поступления',
          operationDate: 'Время создания',
          dataMobile: 'Данные операции',
          sumMobile: 'Сумма',
        },
        additionalInfo: {
          date: 'Дата/Время создания: {{ value }}',
          writeOffAmount: 'Сумма списания: {{ value }}',
          receiptAmount: 'Сумма поступления: {{ value }}',
          hash: 'Хэш транзакции: {{ value }}',
          fee: 'Комиссия сервиса: {{ value }}',
          type: 'Тип операции: {{ value }}',
          card: 'Номер банковской карты: {{ value }}',
        },
        operations: {
          sellCrypto: 'Продажа криптовалюты',
          buyCrypto: 'Покупка криптовалюты',
        },
        statuses: {
          REQUEST: 'Заявка',
          PROCESSING: 'В обработке',
          PAUSED: 'Приостановлена',
          CHANGED: 'Изменена',
          CONFIRMED: 'Завершена',
          DECLINED: 'Отказано',
          EXPIRED: 'Истекла',
          REJECTED: 'Отклонена',
          ARREST: 'Арест',
        },
        empty: 'Нет операций',
        report: 'Сформировать выписку',
      },
      settings: {
        profile: 'Личный кабинет',
        turnOnMobileNotifications: 'Включить уведомления на телефон',
        addPersonalData: 'Необходимо заполнить персональные данные',
        addPhone: 'Добавьте номер телефона',
        privateData: 'Личные данные',
        accountLogin: 'Вход в аккаунт',
        notifications: 'Уведомления',
        changePassword: 'Изменить пароль',
        changePasswordSave: 'Сохранить',
        changePasswordCancel: 'Отмена',
        changePasswordSuccess: 'Пароль успешно изменен',
        changeEmailSave: 'Подтвердить',
        changeEmailSuccess: 'Email успешно изменен',
      },
      inputLabels: {
        email: 'E-mail',
        phone: 'Телефон',
        emailNotification: 'Получать уведомления на Email',
        phoneNotification: 'Получать уведомления на телефон',
        twoFa: 'Двухэтапная аутентификация',
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        code: 'Проверочный код',
      },
      modalLabels: {
        approveButton: 'Подтвердить',
        approveButtonEmail: 'Подтвердить Email',
        approveButtonPhone: 'Подтвердить телефон',
        decline2Fa: 'Отключение двухфакторной аутентификации',
        decline2FaDesc:
          'Вы действительно хотите отключить двухэтапную аутентификацию? Отключение функции может сделать ваш аккаунт более уязвимым.',
        confirmChangePassword: 'Подтвердите смену пароля',
        confirmChangeEmail: 'Подтвердите смену почты',
        twoFa: 'Двухфакторная аутентификация',
        twoFaCodeInfo:
          'Введите подтверждающий код из приложения аутентификации (Google Authenticator или любое другое мобильное приложение 2FA). Не нужно запоминать эти коды, так как они меняются после каждого входа.',
        confirmChangeEmailDescSecond:
          'На email <2>{{email}}</2> отправлено письмо с проверочным кодом. Введите код из письма для подтверждения email.',
        confirmChangeEmailWarning:
          'Внимание! После смены почты необходимо произвести новый вход в систему',
        confirmChangePhone: 'Подтвердите номер телефона',
        confirmChangePhoneDescSecond:
          'На телефон <2>{{phone}}</2> отправлено сообщение с проверочным кодом. Введите код из SMS для подтверждения телефона.',
        confirmChangePhoneWarning:
          'Внимание! После смены телефона необходимо произвести новый вход в систему',
        cancelAfter: 'Окно закроется через: ',
        cancelTitle: 'Упс! Что-то пошло не так',
        cancelText: 'Попробуйте снова',
      },
      phoneValidation: 'Неверный номер',
      countries: {
        BY: 'Беларусь',
        RU: 'Россия',
        UA: 'Украина',
        AZ: 'Азербайджан',
        AM: 'Армения',
        KZ: 'Казахстан',
        KG: 'Кыргызстан',
        MD: 'Молдавия',
        TJ: 'Таджикистан',
        UZ: 'Узбекистан',
        GE: 'Грузия',
        TM: 'Туркменистан',
      },
      paymentMethods: {
        [CARD_PAYMENT_SERVICE]: {
          title: 'Другие банковские карты',
          description: hyphenationOfPrepositions(
            'Добавьте свою банковскую карту к аккаунту, чтобы отправлять и получать на нее средства, а также убедиться, что все работает. Мы сделаем тестовый платеж, который сразу же вернется. Если карта сразу не появилась, <button>обновите страницу</button> через минуту.'
          ),
        },
        [SETTLEMENT]: {
          title: 'Расчетный счет',
          description: hyphenationOfPrepositions(
            'Добавьте расчетный счет в свой аккаунт, чтобы принимать на него средства. Для этого отправьте сканы или фото договора банковского обслуживания с реквизитами в <linkTag>службу поддержки</linkTag>. Как только мы обработаем полученные данные, расчетный счет появится в списке доступных методов получения средств. \n Минимальную сумму обмена для расчетного счета уточняйте в службе поддержки.'
          ),
        },
        [CRYPTO_CARD]: {
          title: 'Карта Crypto',
          description: hyphenationOfPrepositions(
            'Цифровая карта Mastercard Альфа Банка (Беларусь) для работы с криптовалютой на отдельном счёте. Карта оформляется онлайн за несколько минут и позволяет совершать любые онлайн-платежи.'
          ),
        },
        [ERIP]: {
          title: 'ЕРИП',
          description: hyphenationOfPrepositions(
            'Воспользуйтесь услугами ЕРИП для удобного, быстрого и выгодного пополнения своего аккаунта. Покупка криптовалюты доступна через все каналы обслуживания любого банка страны: интернет-банк, мобильный банк, инфокиоски, платежно-справочные терминалы, кассы, платежные сервисы, филиалы РУП «Белпочта».'
          ),
        },
      },
      cardsList: {
        ALFA: {
          title:
            '<strong>Карта VISA и MasterCard Альфа Банк (Беларусь):</strong>',
        },
        ASSIST_BELARUSBANK: {
          title: '<strong>Карта VISA Беларусбанк:</strong>',
        },
        ASSIST: {
          title:
            '<strong>Карта VISA</strong> Беларусь, Азербайджан, Армения, Казахстан, Кыргызстан, Молдова, Таджикистан, Туркменистан, Узбекистан, Грузия:',
        },
        BEPAID: {
          title: '<strong>Карты банков Российской Федерации:</strong>',
        },
        MTS: {
          title: '<strong>Выплаты на карты РФ:</strong>',
        },
      },
      cardActions: {
        attachedCards: 'Привязанные карты',
        attachCardAction: 'Привязать карту',
        attachAnotherCardAction: 'Привязать карту другого банка',
        attachAlfaCardAction: 'Привязать карту \n Альфа Банк (Беларусь)',
        attachCardManually: 'Привязать карту вручную',
        attachFailed: 'Не удалось привязать карту. \n Попробуйте ещё раз позже',
        attachCadrdExists: 'Данная карта уже существует',
        attachCadrdBindingError:
          'Не удалось привязать карту. \n Проверьте данные карты',
        attachCadrdPhoneError:
          'Не удалось привязать карту. \n Необходимо заполнить номер телефона в личном кабинете',
        renameCardModalTitle: 'Переименовать карту',
        cardTitlePlaceholder: 'Введите название карты',
        attachCardModalTitle: 'Добавить карту',
        attachCardTitlePlaceholder: 'Введите номер карты',
        attachCardWrongFormat: 'Неверный формат',
        removeCardModalTitle: 'Удаление карты',
        removeCardModalDesc: 'Вы действительно хотите удалить карту?',
        сomissionInformationBuy: 'Клиент продает криптовалюту - {{ value }}%',
        сomissionInformationSell:
          'Клиент покупает криптовалюту - от {{ value }}%',
        detailsCard: 'Баланс и реквизиты',
        renameCard: 'Переименовать',
        removeCard: 'Удалить',
        attachCard: 'Подтвердить',
        moreDetails: 'Подробнее',
        attachCardCancel: 'Отменить',
        isNotAvailable: 'Недоступно \n для данной операции',
        commisionInfo:
          'Минимальные размеры комиссий действуют для сумм $950 и выше (в эквиваленте). При покупке токенов отдельно взимается комиссия сети.',
        commissionInfoAlfa:
          'Комиссии по операциям покупки/продажи в пределах накопительного лимита в $10 000 в месяц (в эквиваленте).\n\nПри покупке токенов отдельно взимается комиссия сети. Ставки комиссии действуют в рамках акции Crypto Вайб с 07.10.2024г. по 30.11.2024г.',
        commissionInfoBelarusbank:
          'При покупке токенов отдельно взимается комиссия сети.',
        commissionInfoCA:
          'При покупке токенов отдельно взимается комиссия сети. Наличие комиссий за зачисление на расчетный счет следует уточнять в банке, открывшем расчетный счет.',
      },
      settlementActions: {
        attachSettlement: 'Отправить документы',
        removeSettlementModalTitle: 'Удаление счета',
        removeSettlementModalDesc:
          'Вы действительно хотите удалить расчетный счет?',
        removeSettlement: 'Удалить',
      },
      cryptoCardActions: {
        createCryptoCard: 'Выпустить карту Crypto',
        userDetails: {
          info:
            'Для инициирования выпуска карты необходимо \n заполнить недостающие данные:',
          gender: 'Пол',
          confirm: 'Подтвердить',
        },
        consentForm: {
          title: 'Согласие на передачу персональных \n данных',
          description:
            'В соответствии с Законом РБ о персональных данных мы обязаны получить согласие наших клиентов на такую передачу. Прежде чем вы сможете выпустить Цифровую криптокарту, подтвердите передачу своих персональных данных.',
          toggleText: 'Подтверждаю передачу персональных данных',
          confirm: 'Подтверждаю',
          cancel: 'Отменить',
        },
        error: {
          residence:
            'Невозможно оформить криптокарту. \n Оформить криптокарту могут только резиденты Беларуси и России',
          failure:
            'При привязке карты произошел сбой \n пройдите, пожалуйста, процедуру \n ручного добавления карты',
          unexpected:
            'Произошла непредвиденная ошибка. Обратитесь в службу поддержки',
        },
      },
      referralProgram: {
        title: 'Реферальная программа',
        subTitle:
          'Получайте бонусы за каждого друга, которого вы пригласите на Whitebird',
        button: 'Стать партнером',
        publicOfferTitle:
          'Чтобы стать партнером реферальной программы необходимо принять договор публичной оферты',
        termsPublicOffer:
          'Подтверждаю, что ознакомлен и согласен с условиями \n <linkTag>Публичной оферты</linkTag> реферальной программы',
        publicOffer: 'Публичной оферты',
        accept: 'Принять',
        referralCount: 'Количество рефералов: {{ count }}',
        balance: 'Баланс: {{ balance }} {{ currency }}',
        withdrawal: 'Вывод',
        withdrawalSuccess: 'Баланс успешно выведен',
        notVerified: 'Для вывода средств пройдите, пожалуйста, верификацию',
        identificationNumber:
          'Идентификационный номер налогоплательщика: {{ value }}',
        account: 'Расчетный счет: {{ value }}',
        bankAccountName: 'Банк: {{ value }}',
        bankAccountCode: 'SWIFT/БИК банка: {{ value }}',
        withdrawalDataForm: {
          addData: 'Внести данные для вывода средств',
          title: 'Необходимо заполнить данные',
          identificationNumber: 'ИНН (для нерезидентов РБ)',
          account: 'Расчетный счет',
          bankAccountName: 'Банк',
          bankAccountCode: 'SWIFT/БИК банка',
          placeholders: {
            identificationNumber: 'Введите ИНН',
            account: 'Введите расчетный счет',
            bankAccountName: 'Введите название банка',
            bankAccountCode: 'Введите код банка',
          },
          submitButton: 'Подтвердить',
          cancelButton: 'Отменить',
          errors: {
            requiredField: 'Обязательное поле',
            accountMin:
              'Расчетный счет должен содержать минимум {{ count }} символов.',
            invalidAccount: 'Расчетный счет имеет неверный формат',
          },
        },
        referralLink: 'Реферальная ссылка: {{ link }}',
        tabs: {
          fee: 'Начисления',
          withdrawal: 'Выводы',
        },
        table: {
          email: 'Email',
          userId: 'ID пользователя',
          orderNumber: 'ID операции',
          transactionDate: 'Дата транзакции',
          amount: 'Сумма вознаграждения',
          notFound: 'Операции не найдены',
        },
      },
    },
    tooltip: {
      copied: 'Скопировано',
      copy: 'Копировать',
    },
    errorModal: {
      title: 'Упс! Что-то пошло не так',
      titleClientError:
        'Неверно заполнены данные клиента. Обратитесь в службу поддержки',
      button: 'Обновить страницу',
      link: 'Обратитесь в службу поддержки',
    },
    documents: {
      buttons: {
        read: 'Читать',
        download: 'Скачать',
      },
    },
    cryptoVibeModal: {
      title: 'Снижение комиссии по карте Crypto',
      sell: 'Продажа крипты',
      buy: 'Покупка крипты',
      button: 'Узнать больше',
      checkboxTitle: 'Больше никаких напоминаний на сегодня',
    },
  },
};

export default transationRu;
