import React, { useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import UserContext from 'utils/contexts/User';
import { logout } from 'utils/services/request/auth';
import { getPathRoute } from 'utils/location';
import NotFoundLayout from 'components/Layouts/404';
import NoMatch from 'pages/NoMatch/index';
import Verification from 'pages/Verifications';
import { HOME, LOGOUT, VERIFICATIONS } from 'constants/routings';

const PrivateRoute = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  if (!user.data) {
    history.push(HOME);
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={getPathRoute(VERIFICATIONS)}
          component={Verification}
        />
        <Route exact path={getPathRoute(LOGOUT)} component={logout} />
        <NotFoundLayout>
          <Route component={NoMatch} />
        </NotFoundLayout>
      </Switch>
    </>
  );
};

export default PrivateRoute;
