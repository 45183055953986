import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info, DisputeWrapper } from './styled-ui';

export default () => {
  const { t } = useTranslation();
  return (
    <DisputeWrapper>
      <Info>{t('disputeInfoText')}</Info>
    </DisputeWrapper>
  );
};
