import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'ui-kit/InputRedesign';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import {
  Wrapper,
  WraperInput,
  WrapperInputDiv,
  WrapperButton,
} from 'components/Merchant/styled-ui';
import * as Yup from 'yup';
import Error from '../../../ui-kit/Error';

const Schema = t =>
  Yup.object().shape({
    showName: Yup.string().required(t('support.errors.requiredField')),
  });

const ShowName = ({ merchantRefetch, showName, method, label }) => {
  const { t } = useTranslation();
  const initialValues = {
    showName: showName || '',
  };
  const stateSubmitingForm = useAxiosSubmitingEffect(method);
  const onSubmitHandler = (values, actions) => {
    stateSubmitingForm.setFormAndSubmit({ values, actions });
  };

  useEffect(() => {
    if (stateSubmitingForm.loaded) {
      if (stateSubmitingForm.error) {
        const { actions } = stateSubmitingForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        actions.setFieldError(
          'showName',
          t('merchantPage.validation.invalidName')
        );
      } else {
        merchantRefetch();
      }
    }
  }, [stateSubmitingForm, stateSubmitingForm.loaded, merchantRefetch, t]);

  return (
    showName && (
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={Schema(t)}
        onSubmit={onSubmitHandler}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <Wrapper onSubmit={handleSubmit}>
              <WrapperInputDiv>
                <WraperInput>
                  <Field
                    id="showName"
                    name="showName"
                    onChange={handleChange}
                    value={values.showName}
                    setFieldValue={setFieldValue}
                    trimInput
                    component={Input}
                    inputHiddenText
                    label={t(`merchantPage.${label}`)}
                    autoComplete="off"
                  />
                  {errors.showName && touched.showName && (
                    <Error>{errors.showName}</Error>
                  )}
                </WraperInput>
                <WrapperButton type="submit">
                  {t('merchantPage.save')}
                </WrapperButton>
              </WrapperInputDiv>
            </Wrapper>
          );
        }}
      </Formik>
    )
  );
};

export default ShowName;
