import styled from 'styled-components';
import device from 'device';
import { Link } from 'react-router-dom';
import { ReactComponent as Info } from 'assets/images/icons/notification-info.svg';
import { ReactComponent as Close } from 'assets/images/icons/notification-close.svg';
import { ReactComponent as InfoGeneral } from 'assets/images/icons/notification-general.svg';

const handleColorType = (level, theme) => {
  switch (level) {
    case 'INFO':
      return theme.fullBlackText;
    case 'WARN':
      return theme.fullBlackText;
    case 'ERROR':
      return theme.notificatonErrorColor;
    default:
      return theme.fullBlackText;
  }
};

const handleColorIconType = level => {
  switch (level) {
    case 'INFO':
      return '#434A53';
    case 'WARN':
      return '#434A53';
    case 'ERROR':
      return '#FF4539';
    default:
      return '#434A53';
  }
};
export const CommonWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 55px;
  max-height: 420px;
  width: 420px;
  left: 0;
  right: 0;
  margin-left: auto;
  z-index: 99999999;
  @media ${device.mobileHeader} {
    top: 80px;
    padding-right: 10px;
  }
  @media (max-width: 492px) {
    width: unset;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 0;
  }
`;

export const NotificationWrapper = styled.div`
  overflow: auto;
  background: ${props => props.theme.fullWhite};
  border-radius: 8px;
  box-shadow: 0px 4px 26px rgba(0, 56, 200, 0.09),
    0px 22px 40px rgba(13, 24, 62, 0.1);
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const Notification = styled.div`
  display: flex;
  margin: ${({ isPage }) => (isPage ? '40px 0 0 0' : '20px 15px 0')};
  padding-bottom: ${({ isPage }) => (isPage ? '40px' : '15px')};
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  @media ${device.mobile920} {
    margin-top: ${({ isPage }) => isPage && '20px'};
    padding-bottom: ${({ isPage }) => isPage && '20px'};
  }
`;

export const NotificationInner = styled.div`
  display: flex;
`;

export const InfoIcon = styled(Info)`
  path {
    fill: ${({ level }) => handleColorIconType(level)};
  }
  margin-right: 7px;
  min-width: 21px;
`;

export const InfoGeneralIcon = styled(InfoGeneral)`
  margin-right: 7px;
  min-width: 21px;
`;

export const CloseIcon = styled(Close)`
  min-width: 21px;
  min-height: 21px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: auto;
  &:hover {
    circle {
      fill: #f8f8f8;
    }
    path {
      stroke: #2e2e2e;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 0.95rem;
  overflow: ${({ isPage }) => !isPage && 'hidden'};
  text-overflow: ${({ isPage }) => !isPage && 'ellipsis'};
  display: ${({ isPage }) => !isPage && '-webkit-box'};
  -webkit-line-clamp: ${({ isPage, isGeneral }) =>
    !isPage || (!isGeneral && '1')};
  -webkit-box-orient: ${({ isPage }) => !isPage && 'vertical'};
  color: ${({ isGeneral, level, theme }) =>
    isGeneral ? '#FF9900' : handleColorType(level, theme)};
`;
export const Text = styled.div`
  font-size: 0.85rem;
  color: ${({ isInfo, theme }) => (isInfo ? theme.errorColor : theme.grayText)};
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
`;

export const Time = styled.div`
  color: ${props => props.theme.grayTextLight};
  font-size: 0.75rem;
  margin-left: auto;
  margin-right: 0;
  min-width: ${({ isPage }) => isPage && 'max-content'};
`;

export const ClearAllButton = styled.button`
  box-shadow: 0px 4px 26px rgba(0, 56, 200, 0.09),
    0px 22px 40px rgba(13, 24, 62, 0.1);
  border-radius: 50px;
  font-size: 0.85rem;
  margin-top: 8px;
  font-family: 'Inter';
  background-color: ${props => props.theme.fullWhite};
  cursor: pointer;
  padding: 6px 12px;
  visibility: ${({ isHidden }) => isHidden && 'hidden'};
  color: ${props => props.theme.fullBlackText};
`;

export const NoNewNotifications = styled.div`
  min-height: 132px;
  display: flex;
  align-items: center;
  > p {
    margin: 0 auto;
    font-size: 0.95rem;
    color: #cacacb;
  }
`;

export const NotificationPageWrapper = styled.div`
  max-width: 650px;
  margin: auto;
`;

export const EmptyLabel = styled.h4`
  width: max-content;
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.5rem;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TextPage = styled.div`
  font-size: 0.95rem;
  color: ${props => props.theme.grayText};
  margin: 10px 0;
`;

export const TextPageWrapper = styled.div`
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkNotifications = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.fullBlackText};
  box-shadow: 0px 4px 26px rgba(0, 56, 200, 0.09),
    0px 22px 40px rgba(13, 24, 62, 0.1);
  border-radius: 50px;
  font-size: 0.85rem;
  margin-top: 8px;
  font-family: 'Inter';
  background-color: ${props => props.theme.fullWhite};
  padding: 6px 12px;
`;

export const NotificationLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #323233;
  font-size: 16px;
  line-height: 19px;
  margin: 20px 15px;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  background-color: rgba(239, 74, 64, 0.1);
`;

export const NotificationInnerLink = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 7px;
    fill: ${({ theme }) => theme.errorColor};
    path {
      fill: ${({ theme }) => theme.errorColor};
    }
  }
`;
