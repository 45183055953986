import React from 'react';
import PropTypes from 'prop-types';
import CopyTooltip from 'ui-kit/Tooltip/CopyTooltip';
import { Wallet, Wrapper } from './styled-ui';

const WalletInput = ({ address, t, margin }) => (
  <Wrapper margin={margin}>
    <Wallet>{address}</Wallet>
    <CopyTooltip t={t} copyText={address} />
  </Wrapper>
);

WalletInput.propTypes = {
  address: PropTypes.string.isRequired,
};

export default WalletInput;
