import React, { useState } from 'react';
import { PaginationContainer, PageLi, PageButton } from './styled-ui';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

// const range = (from, to) =>
//   Array.from({ length: to - from }, (_, i) => i + from);

const range2 = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({ totalRecords = 0, totalPages, onPageChanged }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageNeighbours = 1;

  const gotoPage = page => {
    const curPage = Math.max(0, Math.min(page, totalPages));

    setCurrentPage(curPage);
    onPageChanged(curPage);
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = evt => {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = evt => {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range2(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range2(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range2(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range2(1, totalPages);
  };

  if (!totalRecords) return null;

  if (totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <nav aria-label="Countries Pagination">
      <PaginationContainer>
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <PageLi key={index} className="page-item">
                <PageButton type="button" onClick={handleMoveLeft}>
                  <span>…</span>
                </PageButton>
              </PageLi>
            );

          if (page === RIGHT_PAGE)
            return (
              <PageLi key={index} className="page-item">
                <PageButton type="button" onClick={handleMoveRight}>
                  <span>…</span>
                </PageButton>
              </PageLi>
            );

          return (
            <PageLi key={index}>
              <PageButton
                type="button"
                isCurrent={currentPage === page}
                onClick={e => handleClick(page, e)}
              >
                {page}
              </PageButton>
            </PageLi>
          );
        })}
      </PaginationContainer>
    </nav>
  );
};

export default Pagination;
