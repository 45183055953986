import styled from 'styled-components';

const LoadingPlaceholderQr = styled.div`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  height: ${props => props.options.height};
  width: ${props => props.options.width};
  border-radius: 5px;
  margin: 25px;

  @keyframes placeHolderShimmer {
    0% {
      background-position: -1168px 0;
    }
    100% {
      background-position: 1168px 0;
    }
  }
`;

export default LoadingPlaceholderQr;
