import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, TextWrapper, Text, Img, Feature } from './styled-ui';

const pageFeatures = [
  {
    id: 'feature1',
    picture1x: 'feature1-1x.jpg',
    picture2x: 'feature1-2x.jpg',
    picture3x: 'feature1-3x.jpg',
    picture4x: 'feature1-4x.jpg',
  },
  {
    id: 'feature2',
    pictureMobile1x: 'feature2mobile-1x.jpg',
    pictureMobile2x: 'feature2mobile-2x.jpg',
    picture1x: 'feature2-1x.jpg',
    picture2x: 'feature2-2x.jpg',
    picture3x: 'feature2-3x.jpg',
    picture4x: 'feature2-4x.jpg',
  },
  {
    id: 'feature3',
    pictureMobile1x: 'feature3mobile-1x.jpg',
    pictureMobile2x: 'feature3mobile-2x.jpg',
    picture1x: 'feature3-1x-v2.jpg',
    picture2x: 'feature3-2x-v2.jpg',
    picture3x: 'feature3-3x-v2.jpg',
    picture4x: 'feature3-4x-v2.jpg',
  },
];

const getPicturePath = index => {
  const currentPicture = pageFeatures[index];
  const isRetina =
    (window.matchMedia &&
      (window.matchMedia(
        'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)'
      ).matches ||
        window.matchMedia(
          'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)'
        ).matches)) ||
    (window.devicePixelRatio &&
      window.devicePixelRatio >= 2 &&
      /(iPad|iPhone|iPod)/g.test(navigator.userAgent));
  const improvingCoefficient = isRetina ? 1 : 0;

  if (window.innerWidth <= 768)
    return `/assets/images/features/${currentPicture[
      `pictureMobile${1 + improvingCoefficient}x`
    ] || currentPicture[`picture${1 + improvingCoefficient}x`]}`;
  if (window.innerWidth <= 1920)
    return `/assets/images/features/${
      currentPicture[`picture${2 + improvingCoefficient}x`]
    }`;
  if (window.innerWidth <= 3840)
    return `/assets/images/features/${
      currentPicture[`picture${3 + improvingCoefficient}x`]
    }`;
  return `/assets/images/features/${currentPicture.picture4x}`;
};

const Features = () => {
  const { t } = useTranslation();
  const renderCardInfo = ({ id }, index) => (
    <Feature key={id}>
      <Img orderFlex={index % 2 === 0} src={getPicturePath(index)} alt={id} />
      <TextWrapper>
        <Text mainText>{t(`mainPageFeatures.features.${index}.mainText`)}</Text>
        <Text>{t(`mainPageFeatures.features.${index}.additionalText`)}</Text>
      </TextWrapper>
    </Feature>
  );

  return (
    <Wrapper>
      {pageFeatures.map((card, index) => renderCardInfo(card, index))}
    </Wrapper>
  );
};

export default Features;
