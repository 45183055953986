import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import { WrapContent, WrapHeader, WrapperPage } from './styled-ui';

const DashboardLayout = ({ children }) => (
  <WrapperPage>
    <WrapHeader>
      <Header />
    </WrapHeader>
    <WrapContent>{children}</WrapContent>
    <Footer />
  </WrapperPage>
);

export default DashboardLayout;
