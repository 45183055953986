import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field } from 'formik';
import CountryCodeSelect from 'ui-kit/CountryCodeSelect';
import PhoneInput from 'ui-kit/PhoneInput';
import Error from 'ui-kit/Error';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import ModalContext from 'utils/contexts/Modal';
import { ERROR_MESSAGES, ERROR_STATUSES } from 'utils/constants/errors';
import countryCodes from 'utils/constants/countryCodes';
import { useAxiosSubmitingEffectWithHeaders } from 'utils/hooks/axiosHook';
import { changePhone, changePhoneForRus } from 'utils/services/request/auth';
import { COUNTRY_CODES } from 'utils/countryCodes';
import ACTIONS from 'utils/constants/recaptcha';
import ApproveModal from 'components/Auth/UserProfile/ApproveModal';
import ConfirmChangePhone from 'components/Auth/UserProfile/ApproveModal/ChangePhone';
import validationPhoneScheme from 'components/Auth/validation';
import { getInitialCountryCode, getInputMask } from 'components/Auth/helpers';
import {
  FormGroup,
  ButtonWrapper,
  EditButton,
  EditIconButton,
} from './styled-ui';

const ChangePhone = ({
  t,
  initialPhone,
  user,
  isUserNotHavePhone,
  isConfirmedEmail,
}) => {
  const { openModal } = useContext(ModalContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [confirmModalStep, setConfirmModalStep] = useState(null);
  const [isInputActivated, setIsInputActivated] = useState(false);
  const [countryPhoneCode, setCountryPhoneCode] = useState('');
  const onCloseModal = () => setConfirmModalStep(null);

  const stateSubmitingPhoneForm = useAxiosSubmitingEffectWithHeaders(
    countryPhoneCode === COUNTRY_CODES.BY ? changePhone : changePhoneForRus
  );

  const onSubmitHandler = useCallback(
    async (values, actions) => {
      setCountryPhoneCode(values.countryCode);
      if (!process.env?.REACT_APP_RECAPTCHA_KEY || !executeRecaptcha) {
        stateSubmitingPhoneForm.setFormAndSubmit({
          values,
          actions,
        });
        return;
      }
      const token = await executeRecaptcha(ACTIONS.SETTINGS_CHANGE_PHONE);
      stateSubmitingPhoneForm.setFormAndSubmit({
        headers: {
          recaptcha: token,
        },
        values,
        actions,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  );

  const onSuccessCallback = ({ phone }) => {
    setIsInputActivated(false);
    // eslint-disable-next-line no-param-reassign
    user.refetch();
    window.scrollTo(0, 0);
    stateSubmitingPhoneForm.form.actions.setFieldValue('phone', phone);
  };

  const onInputFocus = (setFieldValue, countryCode) => () => {
    if (!isInputActivated) {
      const codeItem = countryCodes.find(it => countryCode === it.code);
      setFieldValue('phone', codeItem?.dial_code || '');
      setIsInputActivated(true);
    }
  };

  const resetActiveInput = (setFieldValue, setErrors, setTouched) => () => {
    setIsInputActivated(false);
    setFieldValue('phone', initialPhone || '');
    setFieldValue('countryCode', getInitialCountryCode(initialPhone));
    setErrors('phone', '');
    setTouched({});
  };

  useEffect(() => {
    if (stateSubmitingPhoneForm.loaded) {
      const { actions } = stateSubmitingPhoneForm.form;
      if (stateSubmitingPhoneForm.error) {
        const { status, message } = stateSubmitingPhoneForm.error;
        if (status === ERROR_STATUSES.INVALID_RECAPTCHA) {
          openModal({
            isError: true,
            message: t('errorModal.title'),
          });
        }
        if (status === ERROR_STATUSES.INVALID_CODE) {
          if (message === ERROR_MESSAGES.SECRET_MUST_NOT_BE_EMPTY) {
            setConfirmModalStep(1);
          }
          if (message === ERROR_MESSAGES.CODE_HAS_BEEN_SENT) {
            actions.setErrors({
              phone: t('profile.tooManyRequest'),
            });
          }
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (
        stateSubmitingPhoneForm.data.status === 'OK' &&
        countryPhoneCode === COUNTRY_CODES.BY
      ) {
        setConfirmModalStep(2);
        actions.setSubmitting(false);
        // setTimeout(() => setShowSuccessLabel(false), 5000);
        // actions.resetForm();
      } else if (
        stateSubmitingPhoneForm?.data &&
        countryPhoneCode !== COUNTRY_CODES.BY
      ) {
        actions.setSubmitting(false);
        onSuccessCallback(stateSubmitingPhoneForm.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingPhoneForm.loaded]);

  return (
    <Formik
      initialValues={{
        countryCode: getInitialCountryCode(initialPhone),
        phone: initialPhone || '',
        mfaSecret: '',
      }}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validationSchema={validationPhoneScheme(t)}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        submitForm,
        values,
        setFieldValue,
        setErrors,
        setTouched,
      }) => (
        <Form>
          <FormGroup isInputActivated={isInputActivated}>
            <Field
              id="countryCode"
              type="text"
              name="countryCode"
              onChange={handleChange}
              onBlur={handleBlur}
              component={CountryCodeSelect}
              isDisabled={!isInputActivated}
              t={t}
            />
            <Field
              id="phone"
              type="text"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              resetInput={resetActiveInput(
                setFieldValue,
                setErrors,
                setTouched
              )}
              component={PhoneInput}
              placeholder={getInputMask(values.countryCode)}
              label={t('profile.inputLabels.phone')}
              autoComplete="off"
              readOnly={!isInputActivated}
              isUserNotHavePhone={isUserNotHavePhone && !isInputActivated}
            />
            {errors.phone && touched.phone && (
              <Error isSupportPage top={50}>
                {errors.phone}
              </Error>
            )}
            {isUserNotHavePhone && !isInputActivated && (
              <Error isSupportPage top={50}>
                {t('profile.requiredField')}
              </Error>
            )}
            {!isInputActivated && isConfirmedEmail && (
              <EditButton
                onClick={onInputFocus(setFieldValue, values.countryCode)}
              >
                <EditIconButton />
              </EditButton>
            )}
            {isInputActivated && (
              <ButtonWrapper>
                <Button type="submit" disabled={isSubmitting || !values.phone}>
                  {t('profile.settings.changeEmailSave')}
                </Button>
                <CancelChangesButton
                  type="button"
                  onClick={resetActiveInput(
                    setFieldValue,
                    setErrors,
                    setTouched
                  )}
                >
                  {t('profile.settings.changePasswordCancel')}
                </CancelChangesButton>
              </ButtonWrapper>
            )}
          </FormGroup>
          {confirmModalStep && (
            <ApproveModal
              initialValues={values}
              errors={errors}
              isSubmitting={isSubmitting}
              onSubmitHandler={submitForm}
              t={t}
              Component={ConfirmChangePhone}
              onClose={onCloseModal}
              onSuccessCallback={onSuccessCallback}
              confirmModalStep={confirmModalStep}
              setConfirmModalStep={setConfirmModalStep}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ChangePhone;
