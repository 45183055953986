import styled from 'styled-components';
import device from 'device';
import { Button } from 'ui-kit/Button';

export const Wrapper = styled.div`
  background: ${props => props.theme.blueBg};
  height: 100vh;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  padding: 30px;
  > img {
    height: 60px;
    @media ${device.desktop} {
      height: 52px;
    }
    @media ${device.laptopXL} {
      height: 45px;
    }
    @media ${device.laptop} {
      height: auto;
    }
    @media (max-width: 320px) {
      width: 50vw;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 24px;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Title = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 20px;

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 28px;
  }
  @media ${device.mobileM} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Content = styled.div`
  padding: 15px;
  border-radius: 8px;
  background: ${props => props.theme.grayBg};
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UserName = styled.div`
  white-space: pre-line;
`;

export const Text = styled.div`
  width: 100px;
  white-space: nowrap;
`;

export const Value = styled.div`
  margin-left: 10px;
  white-space: nowrap;

  & div span {
    font-weight: 400;
  }

  @media ${device.mobile920} {
    width: 80%;
    word-break: break-word;
    white-space: pre-line;
  }

  @media ${device.tablet} {
    width: 80%;
    margin-left: 0;
    word-break: break-word;
    white-space: pre-line;
  }

  @media ${device.mobileL} {
    width: 65%;
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin-top: ${({ isError }) => (isError ? '10px' : '40px')};
  height: 58px;
  white-space: nowrap;

  @media ${device.mobileL} {
    grid-template-columns: 1fr;
    height: 130px;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid #c5c5c5;
  background-color: ${props => props.theme.fullWhite};
  color: ${props => props.theme.blackText};

  &:hover {
    background-color: unset;
    color: unset;
  }

  &:disabled {
    background-color: #e2e2e2;
    opacity: 0.5;
  }
`;

export const SubmitButton = styled(Button)``;

export const ErrorInfo = styled.div`
  font-size: 0.8rem;
  padding-top: 10px;
  text-align: center;
  color: ${({ theme }) => theme.inputErrorColor};
  max-width: 100%;
`;
