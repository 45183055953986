export const generateReferralLink = `${process.env.REACT_APP_EXCHANGE_URL}/referrer/generate`;

export const getReferrer = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/referrer/get`,
};

export const getTransactions = ({ page, filters }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_ACCOUNTING_URL}/client/transactions?page=${page}&size=20&sort=creationDate,desc`,
  data: filters || {},
});

export const withdrawBalance = `${process.env.REACT_APP_EXCHANGE_API_URL}/referrer/withdrawal`;

export const getReferralCount = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/referrer/referral-count`,
};

export const updateReferralCurentAccount = `${process.env.REACT_APP_EXCHANGE_URL}/referrer/account`;
