import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME } from 'constants/routings';
import { SmallModal } from 'ui-kit/Modal/Modal';
import AuthWizard from 'components/Auth/wizzard';

const SignupModal = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  return (
    <>
      {redirect ? <Redirect to={HOME} /> : null}
      <SmallModal
        Component={AuthWizard}
        onClose={() => setRedirect(true)}
        t={t}
      />
    </>
  );
};

export default SignupModal;
