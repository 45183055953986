import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox';
import { GroupWrapper, CheckItemsWrapper, GroupLabel } from './styled-ui';

const CheckboxGroup = ({ items, groupLabel, groupName, form }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = form;
  const handleChange = (fieldName, checked) =>
    setFieldValue(
      groupName,
      checked
        ? values[groupName].filter(value => value !== fieldName)
        : [...values[groupName], fieldName]
    );

  return (
    <GroupWrapper>
      <GroupLabel>{groupLabel}</GroupLabel>
      <CheckItemsWrapper>
        {items.map(({ label, value }) => {
          const isChecked = !!values[groupName]?.find(item => item === value);
          return (
            <Checkbox
              key={`${groupName}-${value}`}
              onChange={() => handleChange(value, isChecked)}
              type="checkbox"
              label={t(`merchantPage.filterItems.${groupName}.${label}`)}
              isChecked={isChecked}
            />
          );
        })}
      </CheckItemsWrapper>
    </GroupWrapper>
  );
};

CheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  groupLabel: PropTypes.string,
  groupName: PropTypes.string,
  form: PropTypes.shape({
    values: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

CheckboxGroup.defaultProps = {
  items: [],
  groupLabel: '',
  groupName: '',
};

export default CheckboxGroup;
