/* eslint-disable no-unreachable */
import React, { useContext } from 'react';
import InformationContext from 'utils/contexts/Information';
import LoadingPlaceholder from 'ui-kit/LoadingPlaceholder';
import { localeNumberFromCustomNumber } from 'utils/number';
import {
  getFiatCurrencyFromPair,
  getCryptoCurrencyFromPair,
  getCurrencyValue,
} from 'utils/crypto';
import { Global, Rate, RatioValue, EmptyBlock } from './styled-ui';

// eslint-disable-next-line consistent-return
const ExchangeRate = () => {
  const axiosInformation = useContext(InformationContext);
  const information = axiosInformation.data;
  const { error } = axiosInformation;

  if (!information && !error) {
    return (
      <LoadingPlaceholder
        options={{
          flex: 1,
          height: '25px',
          maxwidth: '250px',
          notChangeMaxWidth: true,
        }}
      />
    );
  }

  if (error) {
    return <EmptyBlock />;
  }

  if (!information.rate) {
    return (
      <LoadingPlaceholder
        options={{
          flex: 1,
          height: '25px',
          maxwidth: '250px',
          notChangeMaxWidth: true,
        }}
      />
    );
  }

  if (information.rate && information.rate.plainRatio) {
    return (
      <Rate>
        <Global>
          1{' '}
          {getCurrencyValue(
            getCryptoCurrencyFromPair(information.currencyPair)
          )}{' '}
          ≈{` `}
          <RatioValue>
            {localeNumberFromCustomNumber(
              information.rate.plainRatio,
              getCryptoCurrencyFromPair(information.currencyPair)
            )}
            {` `}
            {getFiatCurrencyFromPair(information.currencyPair)}
          </RatioValue>
        </Global>
      </Rate>
    );
  }
};

export default ExchangeRate;
