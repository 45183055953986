import { logoutIntercom } from '../../intercom';

export const setToken = data => {
  if (data && localStorage) {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
  }
};

export const getAccessToken = () => {
  return localStorage ? localStorage.getItem('access_token') : '';
};

export const getRefreshToken = () => {
  return localStorage ? localStorage.getItem('refresh_token') : '';
};

export const clearToken = () => {
  logoutIntercom();
  if (localStorage) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('restrictEmailDate');
  }
};
