export const UTM_LABEL_TYPES = [
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  'utm_campaign',
  'entry_point',
  'browser_referrer',
  'refid',
];

export const CURRENCY_KEY_TYPES = ['cur_from', 'cur_to'];
