import React, { useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { changePassword } from 'utils/services/request/auth';
import UserContext from 'utils/contexts/User';
import ApproveModal from 'components/Auth/UserProfile/ApproveModal';
import ConfirmChangePassword from 'components/Auth/UserProfile/ApproveModal/ChangePassword';
import {
  FormGroup,
  ButtonWrapper,
  WrapEye,
  OpenEyeIcon,
  CloseEyeIcon,
  OnSuccessText,
} from './styled-ui';

const changePasswordSchema = t =>
  Yup.object().shape({
    oldPassword: Yup.string().required(t('passwordForms.errors.requiredField')),
    newPassword: Yup.string()
      .required(t('passwordForms.errors.requiredField'))
      .min(12, t('passwordForms.errors.passMin', { count: 12 }))
      .max(128, t('passwordForms.errors.passMax', { count: 128 })),
  });

const ChangePassword = ({ t }) => {
  const [isShowOldPassword, setShowOldPassword] = useState(false);
  const [isShowNewPassword, setShowNewPassword] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isShowSuccessLabel, setIsShowSuccessLabel] = useState(false);
  const user = useContext(UserContext);

  const onCloseModal = () => setConfirmModal(false);

  const stateSubmitingLoginForm = useAxiosSubmitingEffect(changePassword);

  const onSubmitHandler = (values, actions) => {
    if (user?.data?.mfaEnabled) {
      setConfirmModal(true);
    } else {
      stateSubmitingLoginForm.setFormAndSubmit({
        values,
        actions,
      });
    }
  };

  const handleShowSuccessLabel = () => {
    setIsShowSuccessLabel(true);
    setTimeout(() => setIsShowSuccessLabel(false), 5000);
  };

  useEffect(() => {
    if (stateSubmitingLoginForm.loaded) {
      const { actions } = stateSubmitingLoginForm.form;
      if (stateSubmitingLoginForm.error) {
        if (stateSubmitingLoginForm.error.status === 'BAD_REQUEST') {
          const { fields } = stateSubmitingLoginForm.error;
          if (fields && fields.length) {
            const foundInvalidPass = fields.find(
              e => e.message === 'INVALID_PASSWORD'
            );
            if (foundInvalidPass) {
              actions.setErrors({
                oldPassword: t('passwordForms.errors.failedOldPassword'),
              });
            }
          }
        }

        if (stateSubmitingLoginForm.error.status === 'BAD_CREDENTIALS') {
          actions.setErrors({
            oldPassword: t('passwordForms.errors.failedOldPassword'),
          });
        }

        if (stateSubmitingLoginForm.error.status === 'INVALID_CODE') {
          if (
            stateSubmitingLoginForm.error.message === 'Secret must not be empty'
          ) {
            if (confirmModal) {
              actions.setErrors({
                mfaSecret: t('passwordForms.errors.requiredField'),
              });
            } else {
              setConfirmModal(true);
            }
          }
          if (stateSubmitingLoginForm.error.message === 'Invalid code') {
            actions.setErrors({
              mfaSecret: t('passwordForms.errors.invalidCode'),
            });
          }
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmitingLoginForm.data.status === 'OK') {
        handleShowSuccessLabel();
        actions.resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmitingLoginForm.loaded]);

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        mfaSecret: '',
      }}
      onSubmit={onSubmitHandler}
      validateOnChange={false}
      validationSchema={changePasswordSchema(t)}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        submitForm,
        resetForm,
        values,
      }) => (
        <Form>
          <FormGroup>
            <WrapEye>
              {isShowOldPassword ? (
                <OpenEyeIcon onClick={() => setShowOldPassword(false)} />
              ) : (
                <CloseEyeIcon onClick={() => setShowOldPassword(true)} />
              )}
            </WrapEye>
            <Field
              id="oldPassword"
              type={isShowOldPassword ? 'text' : 'password'}
              name="oldPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              component={Input}
              placeholder="********"
              label={t('passwordForms.oldPassLabel')}
              autoComplete="off"
              inputHiddenText
              isReducedPadding
            />
            {errors.oldPassword && touched.oldPassword && (
              <Error isSupportPage top={50}>
                {errors.oldPassword}
              </Error>
            )}
          </FormGroup>
          <FormGroup>
            <WrapEye>
              {isShowNewPassword ? (
                <OpenEyeIcon onClick={() => setShowNewPassword(false)} />
              ) : (
                <CloseEyeIcon onClick={() => setShowNewPassword(true)} />
              )}
            </WrapEye>
            <Field
              id="newPassword"
              type={isShowNewPassword ? 'text' : 'password'}
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              component={Input}
              placeholder="********"
              label={t('passwordForms.newPassLabel')}
              autoComplete="off"
              inputHiddenText
              isReducedPadding
            />
            {errors.newPassword && touched.newPassword && (
              <Error isSupportPage top={50}>
                {errors.newPassword}
              </Error>
            )}
            {values.newPassword && (
              <ButtonWrapper>
                <Button type="submit" disabled={isSubmitting}>
                  {t('profile.settings.changePasswordSave')}
                </Button>
                <CancelChangesButton type="button" onClick={resetForm}>
                  {t('profile.settings.changePasswordCancel')}
                </CancelChangesButton>
              </ButtonWrapper>
            )}
          </FormGroup>
          {confirmModal && (
            <ApproveModal
              initialValues={values}
              errors={errors}
              isSubmitting={isSubmitting}
              onSubmitHandler={submitForm}
              t={t}
              Component={ConfirmChangePassword}
              onClose={onCloseModal}
              handleReset={resetForm}
              showSuccessLabel={handleShowSuccessLabel}
            />
          )}
          {isShowSuccessLabel && (
            <OnSuccessText>
              {t('profile.settings.changePasswordSuccess')}
            </OnSuccessText>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
