import { useState, useEffect } from 'react';

const EVENT_KEY_DOWN = 'keydown';
const EVENT_KEY_UP = 'keyup';

const ReactIsCapsLockActive = props => {
  const [isCapsLockActive, setCapsLockActive] = useState(false);

  const wasCapsLockActivated = event => {
    if (
      event.getModifierState &&
      event.getModifierState('CapsLock') &&
      isCapsLockActive === false
    ) {
      setCapsLockActive(true);
    }
  };

  const wasCapsLockDeactivated = event => {
    if (
      event.getModifierState &&
      !event.getModifierState('CapsLock') &&
      isCapsLockActive === true
    ) {
      setCapsLockActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
    document.addEventListener(EVENT_KEY_UP, wasCapsLockDeactivated);
    return () => {
      document.removeEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
      document.removeEventListener(EVENT_KEY_UP, wasCapsLockDeactivated);
    };
  });

  return props.children(isCapsLockActive);
};

export default ReactIsCapsLockActive;
