import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'ui-kit/InputRedesign';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import * as Yup from 'yup';
import Error from 'ui-kit/Error';
import {
  Wrapper,
  WraperInput,
  WrapperButton,
  WrapperInputDiv,
} from 'components/Merchant/styled-ui';

const UrlSchema = t =>
  Yup.object().shape({
    url: Yup.string()
      .required(t('support.errors.requiredField'))
      .matches(
        /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
        t('merchantPage.validation.invalidUrl')
      ),
  });

const Url = ({
  merchantRefetch,
  url,
  method,
  label,
  enabled,
  noValidation,
}) => {
  const { t } = useTranslation();
  const initialValues = {
    url,
    enabled,
  };

  const stateSubmitingForm = useAxiosSubmitingEffect(method);
  const onSubmitHandler = (values, actions) => {
    stateSubmitingForm.setFormAndSubmit({ values, actions });
  };

  useEffect(() => {
    if (stateSubmitingForm.loaded) {
      if (stateSubmitingForm.error) {
        const { actions } = stateSubmitingForm.form;
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        actions.setFieldError('url', 'Недопустимое значение');
      } else {
        merchantRefetch();
      }
    }
  }, [stateSubmitingForm, stateSubmitingForm.loaded, merchantRefetch]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={!noValidation && UrlSchema(t)}
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Wrapper onSubmit={handleSubmit}>
          <WrapperInputDiv>
            <WraperInput>
              <Field
                id="url"
                name="url"
                onChange={handleChange}
                value={values.url}
                setFieldValue={setFieldValue}
                trimInput
                component={Input}
                inputHiddenText
                label={t(`merchantPage.${label}`)}
                autoComplete="off"
              />
              {errors.url && touched.url && <Error>{errors.url}</Error>}
            </WraperInput>
            <WrapperButton type="submit">
              {t('merchantPage.save')}
            </WrapperButton>
          </WrapperInputDiv>
        </Wrapper>
      )}
    </Formik>
  );
};

export default Url;
