import { createContext } from 'react';

const initialContext = {
  modal: {
    open: false,
    message: '',
    isError: false,
  },
  openModal: () => {},
  closeModal: () => {},
};

export default createContext(initialContext);
