import styled from 'styled-components';

export const DirectionTd = styled.td`
  color: ${({ theme, children }) =>
    children === 'BUY' ? theme.green : theme.inputErrorColor};
  padding: 0 !important;
  text-align: center;
`;

export const CryptoStatusTd = styled.td`
  color: ${({ theme, status }) =>
    (status === 'APPROVED' && theme.green) ||
    (status === 'REJECTED' && theme.inputErrorColor) ||
    (status === 'TIMEOUT' && theme.orange)};
`;

export const FiatStatusTd = styled.td`
  color: ${({ theme, status }) =>
    (status === 'CONFIRMED' && theme.green) ||
    (status === 'REJECTED' && theme.inputErrorColor) ||
    (status === 'TIMEOUT' && theme.orange)};
`;

export const OperationStatusTd = styled.td`
  color: ${({ theme, status }) =>
    (status === 'CONFIRMED' && theme.green) ||
    (status === 'REJECTED' && theme.inputErrorColor) ||
    (status === 'EXPIRED' && theme.orange)};
`;

export const IoAssetTd = styled.td`
  text-align: right;
  padding-right: 15px;
`;
