import { getIn } from 'formik';
import { DateTime } from 'luxon';

const errorEmptyString = 'Обязательное поле';
const errorYoungPerson = 'Вы должны быть старше 18 лет';
const errorEmptyFile = 'Необходимо загрузить файл';
const errorMaxFiveCountFiles = 'Возможно загрузить только 5 файлов';
const errorMaxOneCountFiles = 'Возможно загрузить только 1 файл';
const errorDateFormat = 'Введите в формате дд.мм.гггг.';
const errorMaxLength = 'Максимальное число символов 255';

const isDate18orMoreYearsOld = date => {
  const d = DateTime.fromFormat(date, 'dd.MM.yyyy');
  return new Date(d.year + 18, d.month - 1, d.day) <= new Date();
};

export const checkStep1 = values => {
  let errors = {};

  if (!getIn(values, 'name')) {
    errors = {
      ...errors,
      name: errorEmptyString,
    };
  }

  if (getIn(values, 'name') && getIn(values, 'name').length > 255) {
    errors = {
      ...errors,
      name: errorMaxLength,
    };
  }

  if (!getIn(values, 'surname')) {
    errors = {
      ...errors,
      surname: errorEmptyString,
    };
  }

  if (getIn(values, 'surname') && getIn(values, 'surname').length > 255) {
    errors = {
      ...errors,
      surname: errorMaxLength,
    };
  }

  if (getIn(values, 'patronymic') && getIn(values, 'patronymic').length > 255) {
    errors = {
      ...errors,
      patronymic: errorMaxLength,
    };
  }

  if (
    !getIn(values, 'residenceAsRegistered') &&
    getIn(values, 'residenceConfirmationDocs').length === 0
  ) {
    errors = {
      ...errors,
      residenceConfirmationDocs: errorEmptyFile,
    };
  }

  if (getIn(values, 'residenceConfirmationDocs').length > 5) {
    errors = {
      ...errors,
      residenceConfirmationDocs: errorMaxFiveCountFiles,
    };
  }

  if (
    getIn(values, 'birthInfo.date') &&
    !DateTime.fromFormat(values.birthInfo.date, 'dd.MM.yyyy').isValid
  ) {
    errors = {
      ...errors,
      birthInfo: {
        date: errorDateFormat,
      },
    };
  }

  if (!getIn(values, 'birthInfo.date')) {
    errors = {
      ...errors,
      birthInfo: {
        date: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'birthInfo.date') &&
    !isDate18orMoreYearsOld(getIn(values, 'birthInfo.date'))
  ) {
    errors = {
      ...errors,
      birthInfo: {
        date: errorYoungPerson,
      },
    };
  }

  if (!getIn(values, 'residence.country')) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        country: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'residence.country') &&
    getIn(values, 'residence.country').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        country: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'residence.city')) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        city: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'residence.city') &&
    getIn(values, 'residence.city').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        city: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'residence.street')) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        street: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'residence.street') &&
    getIn(values, 'residence.street').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        street: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'residence.house')) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        house: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'residence.house') &&
    getIn(values, 'residence.house').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        house: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'residence.postCode') &&
    getIn(values, 'residence.postCode').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        postCode: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'residence.district') &&
    getIn(values, 'residence.district').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        district: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'residence.region') &&
    getIn(values, 'residence.region').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        region: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'residence.flat') &&
    getIn(values, 'residence.flat').length > 255
  ) {
    errors = {
      ...errors,
      residence: {
        ...errors.residence,
        flat: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'exchangeInPersonalInterests')) {
    errors = {
      ...errors,
      exchangeInPersonalInterests: 'invalid',
    };
  }
  if (!getIn(values, 'notUSTaxpayer')) {
    errors = {
      ...errors,
      notUSTaxpayer: 'invalid',
    };
  }

  return errors;
};

export const checkStep2 = values => {
  let errors = {};

  if (!getIn(values, 'identityConfirmationDocType')) {
    errors = {
      ...errors,
      identityConfirmationDocType: errorEmptyString,
    };
  }

  if (
    !getIn(values, 'identityConfirmationDocs') ||
    getIn(values, 'identityConfirmationDocs').length === 0
  ) {
    errors = {
      ...errors,
      identityConfirmationDocs: errorEmptyFile,
    };
  }

  if (
    getIn(values, 'identityConfirmationDocs') &&
    getIn(values, 'identityConfirmationDocs').length > 5
  ) {
    errors = {
      ...errors,
      identityConfirmationDocs: errorMaxFiveCountFiles,
    };
  }

  if (!getIn(values, 'identityDocId')) {
    errors = {
      ...errors,
      identityDocId: errorEmptyString,
    };
  }

  if (
    getIn(values, 'identityDocId') &&
    getIn(values, 'identityDocId').length > 255
  ) {
    errors = {
      ...errors,
      identityDocId: errorMaxLength,
    };
  }

  if (
    getIn(values, 'identityDocDate') &&
    !DateTime.fromFormat(values.identityDocDate, 'dd.MM.yyyy').isValid
  ) {
    errors = {
      ...errors,
      identityDocDate: errorDateFormat,
    };
  }

  if (!getIn(values, 'identityDocDate')) {
    errors = {
      ...errors,
      identityDocDate: errorEmptyString,
    };
  }

  if (!getIn(values, 'identityDocIssuer')) {
    errors = {
      ...errors,
      identityDocIssuer: errorEmptyString,
    };
  }

  if (
    getIn(values, 'identityDocIssuer') &&
    getIn(values, 'identityDocIssuer').length > 255
  ) {
    errors = {
      ...errors,
      identityDocIssuer: errorMaxLength,
    };
  }

  if (!getIn(values, 'birthInfo.country')) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        country: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'birthInfo.country') &&
    getIn(values, 'birthInfo.country').length > 255
  ) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        country: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'birthInfo.region') &&
    getIn(values, 'birthInfo.region').length > 255
  ) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        region: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'birthInfo.city')) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        city: errorEmptyString,
      },
    };
  }

  if (
    getIn(values, 'birthInfo.city') &&
    getIn(values, 'birthInfo.city').length > 255
  ) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        city: errorMaxLength,
      },
    };
  }

  if (
    getIn(values, 'birthInfo.district') &&
    getIn(values, 'birthInfo.district').length > 255
  ) {
    errors = {
      ...errors,
      birthInfo: {
        ...errors.birthInfo,
        district: errorMaxLength,
      },
    };
  }

  if (!getIn(values, 'nationality')) {
    errors = {
      ...errors,
      nationality: errorEmptyString,
    };
  }

  if (
    !getIn(values, 'identityConfirmationDocType') ||
    getIn(values, 'identityConfirmationDocType') === null
  ) {
    errors = {
      ...errors,
      identityConfirmationDocType: errorEmptyString,
    };
  }

  return errors;
};

export const checkStep3 = values => {
  let errors = {};
  const questions = JSON.parse(window.localStorage.getItem('quiz'));

  if (
    getIn(values, 'questions.question0') !== `${questions[0]}` ||
    getIn(values, 'questions.question0') === null ||
    getIn(values, 'questions.question0') === undefined
  ) {
    errors = {
      ...errors,
      questions: {
        question0: 'invalid',
      },
    };
  }

  if (
    getIn(values, 'questions.question1') !== `${questions[1]}` ||
    getIn(values, 'questions.question1') === null
  ) {
    errors = {
      ...errors,
      questions: {
        ...errors.questions,
        question1: 'invalid',
      },
    };
  }

  if (
    getIn(values, 'questions.question2') !== `${questions[2]}` ||
    getIn(values, 'questions.question2') === null
  ) {
    errors = {
      ...errors,
      questions: {
        ...errors.questions,
        question2: 'invalid',
      },
    };
  }

  if (
    getIn(values, 'questions.question3') !== `${questions[3]}` ||
    getIn(values, 'questions.question3') === null
  ) {
    errors = {
      ...errors,
      questions: {
        ...errors.questions,
        question3: 'invalid',
      },
    };
  }

  if (
    getIn(values, 'questions.question4') !== `${questions[4]}` ||
    getIn(values, 'questions.question4') === null
  ) {
    errors = {
      ...errors,
      questions: {
        ...errors.questions,
        question4: 'invalid',
      },
    };
  }

  return errors;
};

export const checkStep4 = values => {
  let errors = {};

  if (
    !getIn(values, 'photoConfirmationDocs') ||
    getIn(values, 'photoConfirmationDocs').length === 0
  ) {
    errors = {
      ...errors,
      photoConfirmationDocs: errorEmptyFile,
    };
  }

  if (getIn(values, 'photoConfirmationDocs').length > 1) {
    errors = {
      ...errors,
      photoConfirmationDocs: errorMaxOneCountFiles,
    };
  }

  return errors;
};
