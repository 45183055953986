import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CustomDateInput from 'ui-kit/CustomField/DateInput';
import CheckboxGroup from 'components/CheckboxGroup';
import {
  orderTypes,
  cryptoCurrencies,
  cryptoStatuses,
  fiatCurrencies,
  fiatStatuses,
  orderStatuses,
  providerTypes,
} from 'constants/filters';
import validationSchema from './validation';
import {
  CategoryLabel,
  DatesWrapper,
  ModalHeading,
  Splitter,
  ModalWrapper,
} from './styled-ui';

const FiltersModal = ({ t, setFilter, resetFilters, refetch, filters }) => (
  <ModalWrapper>
    <ModalHeading>
      <span>{t('merchantPage.addFilter')}</span>
      <button type="button" onClick={resetFilters}>
        {t('merchantPage.resetFilter')}
      </button>
    </ModalHeading>
    <Formik
      initialValues={filters}
      validationSchema={validationSchema(t)}
      validateOnChange
    >
      {form => {
        const { values } = form;
        const customSetFieldValue = (name, value) => {
          setFilter({ ...values, [name]: value });
          refetch();
        };
        const customForm = { ...form, setFieldValue: customSetFieldValue };
        return (
          <>
            <CategoryLabel>{t('merchantPage.creationDateLabel')}</CategoryLabel>
            <DatesWrapper>
              <CustomDateInput
                field={{
                  name: 'creationDateFrom',
                  value: values.creationDateFrom,
                }}
                form={customForm}
                type="date"
                id="creationDateFrom"
                maxWidth="132px"
                pickerLeft="-19px"
              />
              <Splitter>-</Splitter>
              <CustomDateInput
                field={{
                  name: 'creationDateTo',
                  value: values.creationDateTo,
                }}
                form={customForm}
                type="date"
                id="creationDateTo"
                maxWidth="132px"
                pickerLeft="-140px"
              />
            </DatesWrapper>
            <CategoryLabel>
              {t('merchantPage.completionDateLabel')}
            </CategoryLabel>
            <DatesWrapper>
              <CustomDateInput
                field={{
                  name: 'completionDateFrom',
                  value: values.completionDateFrom,
                }}
                form={customForm}
                type="date"
                id="completionDateFrom"
                maxWidth="130px"
                pickerLeft="-19px"
              />
              <Splitter>-</Splitter>
              <CustomDateInput
                field={{
                  name: 'completionDateTo',
                  value: values.completionDateTo,
                }}
                form={customForm}
                type="date"
                id="completionDateTo"
                maxWidth="130px"
                pickerLeft="-140px"
              />
            </DatesWrapper>
            <CheckboxGroup
              items={orderTypes}
              form={customForm}
              groupLabel={t('merchantPage.orderTypesLabel')}
              groupName="types"
            />
            <CheckboxGroup
              items={orderStatuses}
              form={customForm}
              groupLabel={t('merchantPage.orderStatusesLabel')}
              groupName="statuses"
            />
            <CheckboxGroup
              items={providerTypes}
              form={customForm}
              groupLabel={t('merchantPage.providerTypesLabel')}
              groupName="providerTypes"
            />
            <CheckboxGroup
              items={fiatStatuses}
              form={customForm}
              groupLabel={t('merchantPage.fiatStatusesLabel')}
              groupName="fiatStatuses"
            />
            <CheckboxGroup
              items={cryptoStatuses}
              form={customForm}
              groupLabel={t('merchantPage.cryptoStatusesLabel')}
              groupName="cryptoStatuses"
            />
            <CheckboxGroup
              items={fiatCurrencies}
              form={customForm}
              groupLabel={t('merchantPage.fiatCurrenciesLabel')}
              groupName="fiatCurrencies"
            />
            <CheckboxGroup
              items={cryptoCurrencies}
              form={customForm}
              groupLabel={t('merchantPage.cryptoCurrenciesLabel')}
              groupName="cryptoCurrencies"
            />
          </>
        );
      }}
    </Formik>
  </ModalWrapper>
);

FiltersModal.propTypes = {
  setFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};

FiltersModal.defaultProps = {};

export default FiltersModal;
