import React, { Suspense, lazy, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import Home from 'pages/Home';
import CardFeatures from 'components/CardFeatures';
// import ExchangeDescription from 'components/ExchangeDescription';
import Features from 'components/Features';
import Faq from 'components/FaqFeatures';
import Footer from 'components/Footer';
import Header from 'components/Header';
import MediaPreloader from 'ui-kit/LoadingPlaceholder/media';
import { HomeContainer } from './styled-ui';

const Blog = lazy(() => import('components/Blog'));

const HomeLayout = () => {
  const [noActiveOrder, setNoActiveOrder] = useState(false);

  // const { t } = useTranslation();

  return (
    <>
      <HomeContainer isOrderView={!noActiveOrder}>
        <Header />
        <Home setNoActiveOrder={setNoActiveOrder} />
      </HomeContainer>
      {/* {(!accessToken || noActiveOrder) && <WhyWhiteBird />} */}
      {noActiveOrder && (
        <>
          {/* <ExchangeDescription t={t} /> */}
          <CardFeatures />
          <Features />
          <Faq />
          <Suspense fallback={<MediaPreloader isMainPage />}>
            <Blog isMainPage />
          </Suspense>
        </>
      )}
      <Footer />
    </>
  );
};

export default HomeLayout;
