import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import {
  getValidFractionalPartOfNumber,
  getValidIntegerPartOfNumber,
  localeNumberFromCustomNumber,
  localeNumberFromCustomNumberConverter,
  maxFullValueForCurrency,
} from 'utils/number';
import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow-down.svg';
import {
  Input,
  LabelDropdownInput,
  LabelWrapper,
  ValuesWrapper,
  WrapperDropdownInput,
} from './styled-ui';
import customStyle from './select-style';

const DropdownInput = ({
  name,
  value,
  label,
  selectOptions,
  selectName,
  setFieldValue,
  currencyType,
  disabled,
  setCurrencyPair,
  isShowLimitRate,
  ErrorComponent,
  isLimitErrorBottom,
}) => {
  const getInputValue =
    selectName !== 'currencyPair.toCurrency' && value
      ? value?.replace(/\s/g, '')
      : '';
  const getInputCurrency =
    selectName !== 'currencyPair.toCurrency' && currencyType
      ? currencyType
      : '';
  const [maxLength, setMaxLength] = useState(getInputValue?.length);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const isIntegerValid = getValidIntegerPartOfNumber(
    getInputValue,
    getInputCurrency
  );
  const isFractionalValid = getValidFractionalPartOfNumber(
    getInputValue,
    getInputCurrency
  );

  useEffect(() => {
    // relevant when saving settings with large values and switching currencies,
    // if true, then disabled input, ONLY DELETE (isInputDisabled === true),
    // until it passes the check for the presence of the number of characters before and after the comma.
    if (isIntegerValid || isFractionalValid) {
      setIsInputDisabled(true);
    } else {
      setIsInputDisabled(false);
    }
  }, [isIntegerValid, isFractionalValid]);

  useEffect(() => {
    if (!isIntegerValid || !isFractionalValid) {
      setMaxLength(maxFullValueForCurrency[getInputCurrency]?.length);
    }

    if (isInputDisabled) {
      setMaxLength(getInputValue?.length);
    }
  }, [
    isInputDisabled,
    getInputValue,
    getInputCurrency,
    isIntegerValid,
    isFractionalValid,
  ]);

  const handleBlur = event => {
    const {
      target: { value: valueBlur },
    } = event;
    setFieldValue(
      name,
      localeNumberFromCustomNumber(valueBlur, currencyType),
      false
    );
  };

  const onChange = event => {
    const {
      target: { value: eValue },
    } = event;
    const updValue = eValue.replace(/^[\D,.]+|[^\d,.]/g, '');
    const { inputValue, isInputLimit } = localeNumberFromCustomNumberConverter(
      updValue,
      currencyType
    );
    if (!isInputLimit || isInputDisabled) {
      localStorage.setItem(
        'input_asset',
        localeNumberFromCustomNumber(inputValue, currencyType) || 0
      );
      setFieldValue(name, inputValue || '');
    }
  };

  const onChangeSelect = option => {
    setCurrencyPair(selectName, option.value);
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <WrapperDropdownInput isShowLimitRate={isShowLimitRate}>
      <LabelWrapper>
        <LabelDropdownInput>{label}</LabelDropdownInput>
        {ErrorComponent && !isLimitErrorBottom && <ErrorComponent />}
      </LabelWrapper>
      <ValuesWrapper>
        <Input
          name={name}
          value={value}
          onBlur={handleBlur}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          inputMode="decimal"
          borderAuto
          isCalcInput
        />

        <Select
          name={selectName}
          value={selectOptions?.find(it => it.value === currencyType)}
          onChange={onChangeSelect}
          options={selectOptions}
          styles={customStyle()}
          error={false}
          isSearchable={false}
          components={{ DropdownIndicator }}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              borderColor: '1px solid red',
            },
          })}
        />
      </ValuesWrapper>
      {ErrorComponent && isLimitErrorBottom && <ErrorComponent />}
    </WrapperDropdownInput>
  );
};

DropdownInput.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default DropdownInput;
