// eslint-disable-next-line import/prefer-default-export
export const getOrdersHistoryByUser = ({ page = 0 }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/current/paged?page=${page}&size=10&sort=creationDate,desc`,
  data: {},
});

export const getOrdersDetails = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/orders/current`,
};
