import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MerchantBalancesTable from 'components/MerchantBalancesTable';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getMerchantDebt } from 'utils/services/request/merchant';
import { currencies, currenciesLogo, NETWORK_TYPES } from 'utils/constant';
import { getCurrencyValue } from 'utils/crypto';
import {
  BalanceLabel,
  BalancesHeader,
  BalancesList,
  BalanceWrapper,
  CreditContainer,
  CryptoNetwork,
  LoadingBalance,
} from './styled-ui';

export default function BalancesComponent({
  merchantData,
  merchantRefetch,
  t,
}) {
  useEffect(() => {
    merchantRefetch();
  }, []);
  const { data: currentBalanceLimit } = useAxiosState(getMerchantDebt);

  const printBalances = currency => {
    const balance = merchantData?.balances[currency];
    return (
      <BalanceWrapper>
        <BalanceLabel>
          {currenciesLogo[currency]}
          <div>
            <div>{getCurrencyValue(currency)}</div>
            {NETWORK_TYPES[currency] && (
              <CryptoNetwork>{NETWORK_TYPES[currency]}</CryptoNetwork>
            )}
          </div>
        </BalanceLabel>
        <div>{balance ?? '0.00'}</div>
      </BalanceWrapper>
    );
  };

  return (
    <>
      <BalancesList>
        {!merchantData?.balances &&
          [...Array(7).keys()].map(dataNumber => (
            <LoadingBalance key={`LoadingBalance-${dataNumber}`} />
          ))}
        {merchantData?.balances && currencies.map(printBalances)}
      </BalancesList>
      {merchantData?.minBalanceLimit.enabled && (
        <CreditContainer>
          <div>
            <span>{t('merchantPage.credit')}</span>
            <span>{merchantData?.minBalanceLimit.valueInUSD} USD</span>
          </div>
        </CreditContainer>
      )}
      {currentBalanceLimit && (
        <CreditContainer>
          <div>
            <span>{t('merchantPage.debt')}</span>
            <span>{currentBalanceLimit} USD</span>
          </div>
        </CreditContainer>
      )}
      <BalancesHeader>{t('merchantPage.balancesList')}</BalancesHeader>
      <MerchantBalancesTable t={t} />
    </>
  );
}

BalancesComponent.propTypes = {
  t: PropTypes.func.isRequired,
  merchantData: PropTypes.shape({}),
};

BalancesComponent.defaultProps = {
  merchantData: null,
};
