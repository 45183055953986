import axios from 'axios';
import {
  clearToken,
  getAccessToken,
  getRefreshToken,
  setToken,
} from 'utils/services/request/localStorage';
import { loginFormOptions } from './services/request/auth';
import { HOME } from '../constants/routings';
import { getLocaleFromPath } from './location';

export const updateAxiosDefault = () => {
  const token = getAccessToken();
  return !!token;
};

export const removeToken = () => {
  window.localStorage.removeItem('auth_token');
};

axios.interceptors.request.use(
  request => {
    const token = getAccessToken();
    if (
      token &&
      request.url !== `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`
    ) {
      // eslint-disable-next-line no-param-reassign
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    request.headers.common['User-Language'] = getLocaleFromPath(
      window.location.pathname
    );
    return request;
  },
  error => {
    Promise.reject(error);
  }
);
let isRefreshing = false;
let failedQueue: {
  resolve: (value?: unknown) => void,
  reject: (reason?: any) => void,
}[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const axiosResponseInterceptorError = axiosInstance => error => {
  const token = getRefreshToken();
  const originalRequest = error.config;

  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !token) {
    return Promise.reject(error);
  }

  if (error.response.status === 401 && originalRequest._retry) {
    return Promise.reject(error);
  }

  if (
    error.response.status === 401 &&
    originalRequest.url ===
      `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`
  ) {
    return Promise.reject(error);
  }

  if (
    error.response.status === 401 &&
    originalRequest.url.includes(process.env.REACT_APP_STRAPI_URL)
  ) {
    return Promise.reject(error);
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    })
      .then(refreshedToken => {
        originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
        return axios(originalRequest);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
  originalRequest._retry = true;
  isRefreshing = true;

  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', token || '');
  return (
    axios
      .post(
        `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`,
        formData,
        loginFormOptions
      )
      .then(response => {
        setToken(response.data);
        // eslint-disable-next-line no-param-reassign
        error.response.config.headers.Authorization = `Bearer ${response.data.access_token}`;
        processQueue(null, response.data.access_token);
        return Promise.resolve(axiosInstance(originalRequest));
      })
      // tslint:disable-next-line: no-shadowed-variable
      .catch(errorP => {
        processQueue(errorP, null);
        clearToken();
        window.location.replace(HOME);
        return Promise.reject(errorP);
      })
      .finally(() => {
        isRefreshing = false;
      })
  );
};
