import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  grid-column: 1 / -1;

  @media ${device.tablet} {
    padding: 0 20px 0 10px;
  }
`;

export const StepOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-right: 0;
  }
`;

export const StepTwo = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 25px;
  }
`;

export const Border = styled.div`
  background: #dcdcdc;
  height: 180px;
  margin: 20px 0;
  width: 1px;

  @media ${device.mobile920} {
    height: 200px;
  }

  @media ${device.tablet} {
    height: 130px;
  }

  @media (max-width: 570px) {
    height: 150px;
  }

  @media (max-width: 470px) {
    height: 200px;
  }
`;

export const EripInstruction = styled.div`
  margin-left: 25px;
  white-space: pre-line;

  @media ${device.tablet} {
    margin-left: 10px;
  }
`;

export const EripNumberLabel = styled.div`
  color: #676767;
  font-size: 14px;
  padding-bottom: 20px;
  padding-left: ${({ paddingLeft }) => paddingLeft || '30px'};
  display: flex;
  white-space: pre-line;

  @media ${device.laptop} {
    white-space: normal;
  }

  @media ${device.tablet} {
    padding-left: 10px;
  }
`;
