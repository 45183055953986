import React, { useEffect, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import {
  changeEmail,
  changeEmailVerify,
  logout,
} from 'utils/services/request/auth';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import TimerStatus from 'components/TimerStatus';
import {
  ModalBodyWrapper,
  CancelWrapper,
  ModalTitle,
  ModalLabel,
  ModalDesc,
  ButtonWrapper,
  EmailValue,
  CheckEmailIcon,
  TimerWrapper,
} from './styled-ui';

const validationScheme = t =>
  Yup.object().shape({
    mfaSecret: Yup.string().required(t('profile.requiredField')),
  });

const validationEmailScheme = t =>
  Yup.object().shape({
    code: Yup.string().required(t('profile.requiredField')),
  });

const ChangeEmailForm = ({
  t,
  initialValues,
  hideModal,
  onSuccessCallback,
  confirmModalStep,
  setConfirmModalStep,
  setFieldValue,
}) => {
  const stateSubmittingMfa = useAxiosSubmitingEffect(changeEmail);
  const stateSubmittingEmailVerify = useAxiosSubmitingEffect(changeEmailVerify);

  const onSubmitHandler = useCallback(
    (values, actions) =>
      stateSubmittingMfa.setFormAndSubmit({
        values: {
          ...values,
          email: values.email.toLowerCase(),
        },
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSubmitVerifyHandler = useCallback(
    (values, actions) =>
      stateSubmittingEmailVerify.setFormAndSubmit({
        values,
        actions,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (stateSubmittingMfa.loaded) {
      const { actions } = stateSubmittingMfa.form;
      if (stateSubmittingMfa.error) {
        if (stateSubmittingMfa.error.status === 'INVALID_CODE') {
          if (stateSubmittingMfa.error.message === 'Invalid code') {
            actions.setErrors({
              mfaSecret: t('passwordForms.errors.invalidCode'),
            });
          }
          if (
            stateSubmittingMfa.error.message ===
            'Code has been sent. Try again later'
          ) {
            actions.setErrors({
              mfaSecret: t('profile.tooManyRequest'),
            });
          }
        }
        if (stateSubmittingMfa.error.status === 'USER_EXISTS') {
          actions.setErrors({
            mfaSecret: t('signUpForm.errors.userExists'),
          });
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmittingMfa.data.status === 'OK') {
        setConfirmModalStep(2);
        // hideModal();
        // onSuccessCallback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingMfa.loaded]);

  useEffect(() => {
    if (stateSubmittingEmailVerify.loaded) {
      const { actions } = stateSubmittingEmailVerify.form;
      if (stateSubmittingEmailVerify.error) {
        if (stateSubmittingEmailVerify.error.status === 'BAD_REQUEST') {
          actions.setErrors({
            code: t('passwordForms.errors.invalidCode'),
          });
        }
        actions.setSubmitting(false);
        actions.setStatus(undefined);
      } else if (stateSubmittingEmailVerify.data) {
        hideModal();
        logout();
        onSuccessCallback(stateSubmittingEmailVerify?.data?.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSubmittingEmailVerify.loaded]);

  const handleOpenCancelModal = () => {
    setFieldValue('email', '');
    setConfirmModalStep(3);
  };

  const getStepChangeEmailModal = step => {
    switch (step) {
      case 1:
        return (
          <ModalBodyWrapper>
            <ModalLabel>{t('profile.modalLabels.twoFa')}</ModalLabel>
            <ModalDesc lastChild>
              {t('profile.modalLabels.twoFaCodeInfo')}
            </ModalDesc>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmitHandler}
              validateOnChange={false}
              validationSchema={validationScheme(t)}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <Field
                    type="text"
                    name="mfaSecret"
                    component={Input}
                    placeholder="******"
                    label={t('profile.inputLabels.code')}
                  />
                  {errors.mfaSecret && (
                    <Error isSupportPage>{errors.mfaSecret}</Error>
                  )}
                  <ButtonWrapper>
                    <Button type="submit" disabled={isSubmitting}>
                      {t('profile.modalLabels.approveButton')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </ModalBodyWrapper>
        );
      case 2:
        return (
          <ModalBodyWrapper>
            <TimerWrapper>
              <TimerStatus
                operationDate={moment(new Date()).format()}
                t={t}
                isChangeEmailModal
                setDisabled={handleOpenCancelModal}
              />
            </TimerWrapper>
            <ModalLabel>
              {t('profile.modalLabels.confirmChangeEmail')}
            </ModalLabel>
            <ModalDesc>
              <Trans
                t={t}
                i18nKey="profile.modalLabels.confirmChangeEmailDescSecond"
              >
                {/* eslint-disable no-irregular-whitespace */}
                На email{' '}
                <EmailValue>{{ email: initialValues.email }}</EmailValue>{' '}
                отправлено письмо с проверочным кодом. Введите код из письма для
                подтверждения email
              </Trans>
            </ModalDesc>
            <ModalDesc highlighted>
              {t('profile.modalLabels.confirmChangeEmailWarning')}
            </ModalDesc>
            <Formik
              initialValues={{ code: '' }}
              onSubmit={onSubmitVerifyHandler}
              validateOnChange={false}
              validationSchema={validationEmailScheme(t)}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <Field
                    type="text"
                    name="code"
                    component={Input}
                    placeholder="******"
                    label={t('profile.inputLabels.code')}
                  />
                  {errors.code && <Error isSupportPage>{errors.code}</Error>}
                  <ButtonWrapper>
                    <Button type="submit" disabled={isSubmitting}>
                      <CheckEmailIcon />
                      {t('profile.modalLabels.approveButtonEmail')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </ModalBodyWrapper>
        );
      case 3:
        return (
          <CancelWrapper>
            <ModalTitle>{t('profile.modalLabels.cancelTitle')}</ModalTitle>
            <ModalDesc highlighted>
              {t('profile.modalLabels.cancelText')}
            </ModalDesc>
          </CancelWrapper>
        );
      default:
        return null;
    }
  };

  return getStepChangeEmailModal(confirmModalStep);
};

export default ChangeEmailForm;
