export const getUser = {
  method: 'get',
  url: `${process.env.REACT_APP_AUTH_URL}/user/me`,
};

export const setGender = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/gender`,
};

export const getUserStatus = {
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client `,
};

export const getCurrencies = {
  method: 'get',
  url: `${process.env.REACT_APP_ADMIN_URL}/currencies`,
};

export const changeMFAStatus = {
  method: 'post',
  url: `${process.env.REACT_APP_AUTH_URL}/user/mfa`,
};

export const getQrSystemWallet = query => ({
  method: 'get',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/qr/address?${query}`,
});

export const MFAStatusUrl = `${process.env.REACT_APP_AUTH_TOKEN_URL}/oauth/token`;
