import React from 'react';
import CardView from 'components/Exchanger/Card/view';
import CommissionInfo from 'components/CommissionInfo';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { localeNumberFromCustomNumber } from 'utils/number';
import {
  getFiatCurrencyFromPair,
  getCryptoCurrencyFromPair,
  isCrypto,
  getCurrencyValue,
} from 'utils/crypto';
import { hyphenationOfPrepositions } from 'utils/string';
import BankAccountView from 'components/ExchangeOperationStatus/view';
import OrderPromoInfo from 'components/Exchanger/PromocodeRow/OrderPromoInfo';
import CopyTooltip from 'ui-kit/Tooltip/CopyTooltip';
import Checkbox from 'ui-kit/Checkbox';
import { ALFA } from 'utils/constants/paymentMethods';
import {
  currencies,
  CONFIRMED,
  DECLINED,
  EXPIRED,
  PROCESSING,
  REJECTED,
  REQUEST,
  CHANGED,
  PAUSED,
  NETWORK_TYPES,
  currenciesLogo,
} from 'utils/constant';
import {
  CryptoAddress,
  CryptoAddressWrapper,
  ExchangeOperation,
  ExchangeRatePrice,
  Price,
  Row,
  Title,
  LabelText,
  LabelWrapper,
  ArrowIcon,
  Charges,
  HashTransaction,
  HashText,
  HashContent,
  HashValue,
  EripNumber,
  CheckboxText,
  CheckboxWrapper,
  CheckBoxAddressText,
  CryptoNetwork,
} from './styled-ui';

const START_ROW = 1;
const COLUMN_LEFT = 1;
const COLUMN_RIGHT = 2;

const ExchangeOperationStatus = ({
  currencyPair,
  calculation,
  paymentTokenId,
  status,
  address,
  ratio,
  blockchainFee,
  serviceFee,
  isSettlementOrder,
  feePaymentEnabledByClient,
  isOrderUpdated,
  isNext,
  bgColor,
  promoCode,
  provider,
  exchangeOperationStatusesData,
  isEripOrder,
  eripNumber,
  addressChecked,
  setAddressChecked,
  publicOfferChecked,
  setPublicOfferChecked,
}) => {
  const { t } = useTranslation();
  const cryptoBuy = isCrypto(currencyPair.fromCurrency);
  const isDisplayAddressCheckbox = ![REJECTED, EXPIRED].includes(status);
  const isDisplayPublicOfferCheckbox =
    !cryptoBuy && status === REQUEST && provider === ALFA;

  const getCurrencyLabel = currency => (
    <LabelWrapper>
      {currenciesLogo[currency]}
      <LabelText>{getCurrencyValue(currency)}</LabelText>
      {NETWORK_TYPES[currency] && (
        <CryptoNetwork>{NETWORK_TYPES[currency]}</CryptoNetwork>
      )}
    </LabelWrapper>
  );

  const currencyRow = cryptoBuy ? START_ROW + 1 : START_ROW + 2;
  const creditCardTitle = cryptoBuy
    ? t('bankAccountViewer.onCard')
    : t('bankAccountViewer.fromCard');
  const bankAccountTitle = cryptoBuy
    ? t('bankAccountViewer.onBankAccount')
    : t('bankAccountViewer.fromBankAccount');

  const creditCardColumn = cryptoBuy ? COLUMN_RIGHT : COLUMN_LEFT;

  return (
    <>
      <ExchangeOperation bgColor={bgColor} isNext={isNext}>
        <Row column={COLUMN_LEFT} row={START_ROW} area="a">
          <Title>{t('bankAccountViewer.sell')}</Title>
          <Price
            animationUpdate={status === CHANGED && isOrderUpdated && isNext}
          >
            {localeNumberFromCustomNumber(
              calculation.inputAsset,
              currencyPair.fromCurrency
            )}{' '}
            {getCurrencyLabel(currencyPair.fromCurrency)}
          </Price>
          <Row column={COLUMN_LEFT} row={currencyRow}>
            <ExchangeRatePrice
              animationUpdate={status === CHANGED && isOrderUpdated && isNext}
            >
              {t('exchangeRate', {
                rate: `1 ${getCurrencyValue(
                  getCryptoCurrencyFromPair(currencyPair)
                )} = ${localeNumberFromCustomNumber(
                  ratio,
                  getCryptoCurrencyFromPair(currencyPair)
                )} ${getFiatCurrencyFromPair(currencyPair)}`,
              })}
            </ExchangeRatePrice>
          </Row>
        </Row>

        <Row area="c">
          <ArrowIcon />
        </Row>

        <Row column={COLUMN_RIGHT} row={START_ROW} area="d">
          <Title>{t('bankAccountViewer.buy')}</Title>
          <Price
            animationUpdate={status === CHANGED && isOrderUpdated && isNext}
          >
            {localeNumberFromCustomNumber(
              calculation.outputAsset,
              currencyPair.toCurrency
            )}{' '}
            {getCurrencyLabel(currencyPair.toCurrency)}
          </Price>
          <Charges>
            {promoCode && <OrderPromoInfo t={t} {...promoCode} />}
            {feePaymentEnabledByClient && (
              <CommissionInfo
                t={t}
                value={blockchainFee}
                titleKey="blockchainFee"
              />
            )}
            <CommissionInfo t={t} value={serviceFee} titleKey="serviceFee" />
          </Charges>
        </Row>

        {isEripOrder && eripNumber && (
          <Row column={COLUMN_LEFT} row={START_ROW + 1} area="b">
            <Title>{t('bankAccountViewer.eripNumber')}</Title>
            <EripNumber>{eripNumber}</EripNumber>
          </Row>
        )}

        {!isSettlementOrder && !isEripOrder && !cryptoBuy && (
          <Row column={creditCardColumn} row={START_ROW + 1} area="b">
            <Price>
              <CardView
                provider={provider}
                paymentTokenId={paymentTokenId}
                label={creditCardTitle}
              />
            </Price>
          </Row>
        )}

        <Row area="c" />

        {!isSettlementOrder && !isEripOrder && cryptoBuy ? (
          <Row area="e">
            <Price>
              <CardView
                provider={provider}
                paymentTokenId={paymentTokenId}
                label={creditCardTitle}
              />
            </Price>
          </Row>
        ) : (
          cryptoBuy && (
            <Row area="e">
              <BankAccountView
                provider={provider}
                paymentTokenId={paymentTokenId}
                label={bankAccountTitle}
              />
            </Row>
          )
        )}

        {!cryptoBuy ? (
          <Row column={COLUMN_RIGHT} row={START_ROW + 1} area="e" isAddressInfo>
            <Title>{t('bankAccountViewer.onAddress')}</Title>
            <CryptoAddressWrapper>
              <CryptoAddress>
                {address || t('bankAccountViewer.hidden')}
              </CryptoAddress>
            </CryptoAddressWrapper>
            {isDisplayAddressCheckbox && (
              <Checkbox
                checked={status === REQUEST ? addressChecked : true}
                onChange={() => setAddressChecked(!addressChecked)}
                disabled={status !== REQUEST}
              >
                <CheckBoxAddressText>
                  {hyphenationOfPrepositions(
                    t('bankAccountViewer.confirmAddressAffiliation')
                  )}
                </CheckBoxAddressText>
              </Checkbox>
            )}
          </Row>
        ) : null}

        {exchangeOperationStatusesData?.cryptoTxHash && (
          <HashTransaction area="f">
            <HashText>{t('bankAccountViewer.hash')}</HashText>
            <HashContent>
              <HashValue>
                {exchangeOperationStatusesData?.cryptoTxHash}
              </HashValue>
              <CopyTooltip
                t={t}
                copyText={exchangeOperationStatusesData?.cryptoTxHash}
              />
            </HashContent>
          </HashTransaction>
        )}
      </ExchangeOperation>
      {isDisplayPublicOfferCheckbox && (
        <CheckboxWrapper>
          <Checkbox
            checked={publicOfferChecked}
            onChange={() => setPublicOfferChecked(!publicOfferChecked)}
          >
            <CheckboxText>
              <Trans
                i18nKey="bankAccountViewer.publicOfferAlfaBank"
                t={t}
                components={{
                  linkTag: (
                    <a
                      href="https://www.alfabank.by/upload/docs/bank/dogovor-perevoda.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('bankAccountViewer.publicOffer')}
                    </a>
                  ),
                }}
              />
            </CheckboxText>
          </Checkbox>
        </CheckboxWrapper>
      )}
    </>
  );
};

ExchangeOperationStatus.propTypes = {
  status: PropTypes.oneOf([
    REQUEST,
    PROCESSING,
    CONFIRMED,
    REJECTED,
    EXPIRED,
    DECLINED,
    CHANGED,
    PAUSED,
  ]).isRequired,
  currencyPair: PropTypes.shape({
    fromCurrency: PropTypes.oneOf(currencies).isRequired,
    toCurrency: PropTypes.oneOf(currencies).isRequired,
  }).isRequired,
  calculation: PropTypes.shape({
    inputAsset: PropTypes.number.isRequired,
    outputAsset: PropTypes.number.isRequired,
  }).isRequired,
  ratio: PropTypes.number.isRequired,
  address: PropTypes.string,
  paymentTokenId: PropTypes.string.isRequired,
};

ExchangeOperationStatus.defaultProps = {
  address: '',
};

export default ExchangeOperationStatus;
