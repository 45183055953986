import { FormGroup } from 'components/Verification/styled-ui';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import * as Yup from 'yup';
import {
  FormGrid,
  Header,
  RestorePasswordLabel,
  ButtonWrapper,
} from '../styled-ui';

const restorePasswordSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('passwordForms.errors.incorrectEmailFormat'))
      .required(t('passwordForms.errors.requiredField')),
  });

const RestorePasswordForm = ({ initialValues, onSubmitHandler, t }) => {
  return (
    <>
      <Header>{t('passwordForms.titleRestore')}</Header>
      <RestorePasswordLabel>
        {t('passwordForms.instructionLabel')}
      </RestorePasswordLabel>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validateOnChange={false}
        validationSchema={restorePasswordSchema(t)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormGrid onSubmit={handleSubmit}>
            <FormGroup>
              <Field
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                component={Input}
                placeholder="yourmail@mail.com "
                label="Email"
                autoComplete="email"
                autoFocus
              />
              {errors.email && touched.email && errors.email !== '.' && (
                <Error>{errors.email}</Error>
              )}
            </FormGroup>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitting}>
                {t('passwordForms.buttonRestoreLabel')}
              </Button>
            </ButtonWrapper>
          </FormGrid>
        )}
      </Formik>
    </>
  );
};

RestorePasswordForm.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default RestorePasswordForm;
