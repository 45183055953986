import { createContext } from 'react';

export const initialContext = {
  loaded: false,
  fetching: false,
  data: null,
  error: null,
};

export default createContext(initialContext);
