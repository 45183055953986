import styled from 'styled-components';

export const NewPaymentContainer = styled.div`
  min-height: 178px;
  border: 1px solid ${({ theme }) => theme.grayBorder};
  border-radius: 8px;
`;

export const NewPaymentLabel = styled.div`
  color: ${({ theme }) => theme.blackText};
  font-size: 28px;
  font-weight: 500;
  margin: 24px 55px 0;
`;

export const PaymentsHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  margin: 28px 0 40px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 55px;
`;

export const DropdownContainer = styled.div`
  width: 300px;
`;

export const ButtonContainer = styled.div`
  width: 288px;
  height: 48px;
`;

export const ErrorMessage = styled.span`
  display: flex;
  margin-top: 8px;
  justify-content: end;
  font-size: 14px;
  color: ${({ theme }) => theme.inputErrorColor};
`;
