import React from 'react';
import { DateTime } from 'luxon';
import { getPairLabel } from 'utils/crypto';
import {
  DirectionTd,
  CryptoStatusTd,
  FiatStatusTd,
  OperationStatusTd,
  IoAssetTd,
} from './styled-ui';
import { TableRow } from '../MerchantBalancesTable/styled-ui';

const MerchantOperationTableRow = ({ order, t }) => {
  return (
    <TableRow key={order.id}>
      <td>{order.number}</td>
      <td>
        {DateTime.fromISO(order.creationDate).toFormat('dd.MM.yyyy HH:mm:ss')}
      </td>
      <td>{order.client.clientId}</td>
      <td>{getPairLabel(order.exchangeOperation.currencyPair)}</td>
      <IoAssetTd>{order.exchangeOperation.inputAsset}</IoAssetTd>
      <IoAssetTd>{order.exchangeOperation.outputAsset}</IoAssetTd>
      <DirectionTd>{order.exchangeType}</DirectionTd>
      <CryptoStatusTd status={order.fiatTransaction.status}>
        {t(
          `merchantPage.filterItems.fiatStatuses.${order.fiatTransaction.status}`
        )}
      </CryptoStatusTd>
      <FiatStatusTd status={order.cryptoTransaction.status}>
        {t(
          `merchantPage.filterItems.cryptoStatuses.${order.cryptoTransaction.status}`
        )}
      </FiatStatusTd>
      <OperationStatusTd status={order.status}>
        {t(`merchantPage.filterItems.statuses.${order.status}`)}
      </OperationStatusTd>
    </TableRow>
  );
};

export default MerchantOperationTableRow;
