import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { Button } from 'ui-kit/Button';
import CustomCheckbox from 'ui-kit/CustomField/Checkbox';
import {
  Title,
  HeaderGrid,
  HeaderDescription,
  FormGrid,
  FullRow,
} from './styled-ui';

const ConfirmForm = ({ callbackProps }) => {
  const { t } = useTranslation();
  const initialValues = {
    exchangeInPersonalInterests: false,
    notUSTaxpayer: false,
  };

  const sendConfirmForm = useCallback(() => {
    if (callbackProps.nextPage) {
      callbackProps.nextPage();
    }
  }, [callbackProps]);

  const validate = values => {
    const errors = {};
    const { exchangeInPersonalInterests, notUSTaxpayer } = values;
    if (!exchangeInPersonalInterests || !notUSTaxpayer) {
      errors.global = '';
    }
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={sendConfirmForm}
        validate={validate}
        isInitialValid={false}
      >
        {({ handleSubmit, isValid }) => (
          <div>
            <Title>{t('verification.sumSubWizardTitle')}</Title>
            <HeaderGrid>
              <HeaderDescription>
                {t('verification.sumSubWizardDescription')}
              </HeaderDescription>
            </HeaderGrid>
            <FormGrid autoComplete="off" onSubmit={handleSubmit}>
              <FullRow>
                <Field
                  id="exchangeInPersonalInterests"
                  name="exchangeInPersonalInterests"
                  component={CustomCheckbox}
                  type="checkbox"
                  label={t('verification.exchangeInPersonalInterests')}
                />
              </FullRow>
              <FullRow>
                <Field
                  id="notUSTaxpayer"
                  name="notUSTaxpayer"
                  component={CustomCheckbox}
                  type="checkbox"
                  label={t('verification.notUSTaxpayer')}
                />
              </FullRow>
              <Button
                width="200px"
                padding="10px"
                type="submit"
                disabled={!isValid}
              >
                {t('verification.sumSubWizardNext')}
              </Button>
            </FormGrid>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ConfirmForm;
