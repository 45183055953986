import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Wizard from 'ui-kit/Wizard';
import { getClientTestingNeeded } from 'utils/user';
import {
  FormGrid,
  HeaderDescription,
  HeaderGrid,
  NextButton,
  Title,
} from '../styled-ui';

export default class SumSubKYCWizard extends React.Component {
  static Page = ({ children }) => children;

  static getDerivedStateFromProps({ initialValues }, state) {
    return {
      ...state,
      values: initialValues,
    };
  }

  constructor(props) {
    super(props);
    const { initialPage } = this.props;
    this.state = {
      page: initialPage,
      values: props.initialValues,
    };
  }

  changePage = page =>
    this.setState(state => ({
      // eslint-disable-next-line react/destructuring-assignment
      page: Math.min(state.page, page),
    }));

  next = values =>
    this.setState(state => ({
      // eslint-disable-next-line react/destructuring-assignment
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

  throwOne = values =>
    this.setState(state => ({
      // eslint-disable-next-line react/destructuring-assignment
      page: Math.min(state.page + 2, this.props.children.length - 1),
      values,
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));

  validate = values => {
    const { children } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { onSubmit, children } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (!isLastPage) {
      if (page === 0) {
        this.throwOne(values);
      } else {
        this.next(values);
      }
    }
    onSubmit(values, bag, page);
  };

  render() {
    const { children, areQuestionsLoaded, t, client } = this.props;
    const { page, values: initialValues } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isUserTestingRequired = getClientTestingNeeded(client);
    const isLastPage =
      (page === React.Children.count(children) - 1 && isUserTestingRequired) ||
      (page === React.Children.count(children) - 1 && !isUserTestingRequired);
    const checkIsDisabledNext = (values, nextPage) => {
      const { exchangeInPersonalInterests, notUSTaxpayer } = values;
      return nextPage === 0 && (!exchangeInPersonalInterests || !notUSTaxpayer);
    };
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validate={this.validate}
        onSubmit={this.handleSubmit}
        validateOnChange={page !== 1}
      >
        {({ values, handleSubmit }) => {
          return (
            <>
              <Wizard step={page} hide change={this.changePage} />
              {page === 0 && (
                <>
                  <Title>{t('verification.sumSubWizardTitle')}</Title>
                  <HeaderGrid>
                    <HeaderDescription>
                      {t('verification.sumSubWizardDescription')}
                    </HeaderDescription>
                  </HeaderGrid>
                </>
              )}
              {page === 1 && (
                <>
                  <Title>Подтверждение осведомленности</Title>
                  <HeaderGrid>
                    <HeaderDescription>
                      Для граждан Республики Беларусь необходимо пройти
                      следующий тест:
                    </HeaderDescription>
                  </HeaderGrid>
                </>
              )}
              <FormGrid
                onSubmit={handleSubmit}
                autoComplete="off"
                isLastPage={isLastPage}
              >
                {activePage}
                {!isLastPage && (
                  <NextButton
                    type="submit"
                    disabled={
                      checkIsDisabledNext(values, page) ||
                      (!areQuestionsLoaded && page === 1)
                    }
                  >
                    {t('verification.sumSubWizardNext')}
                  </NextButton>
                )}
              </FormGrid>
            </>
          );
        }}
      </Formik>
    );
  }
}

SumSubKYCWizard.propTypes = {
  initialValues: PropTypes.shape({
    country: PropTypes.string,
    countryFlag: PropTypes.bool.isRequired,
    exchangeInPersonalInterests: PropTypes.bool.isRequired,
    notUSTaxpayer: PropTypes.bool.isRequired,
    email: PropTypes.string,
    firstStageConfirmed: PropTypes.bool.isRequired,
    secondStageConfirmed: PropTypes.bool.isRequired,
    thirdStageConfirmed: PropTypes.bool,
    testingCompleted: PropTypes.bool,
    residencePermitInBelarusPresent: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialPage: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
