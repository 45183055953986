import React, { useState } from 'react';
import MerchantOperationTable from 'components/MerchantOperationTable';
import MerchantWebhooksTable from 'components/MerchantWebhooksTable';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import {
  getMerchantDetails,
  getMerchantWebhooks,
} from 'utils/services/request/merchant';
import { useTranslation } from 'react-i18next';
import {
  MerchantPanel,
  MerchantWrapper,
  MerchantTitle,
  WrapperMerchantMenu,
  MerchantContainer,
} from './styled-ui';
import { Button } from '../../ui-kit/Button';
import OptionsComponent from './OptionsComponent';
import BalancesComponent from './BalancesComponent';
import PaymentsComponent from './PaymentsComponent';

const Merchant = () => {
  const { t } = useTranslation();
  const {
    data: merchantData,
    refetch: merchantRefetch,
  } = useAxiosStateWithRefetch(getMerchantDetails);
  const { data: webhooksData } = useAxiosStateWithRefetch(
    getMerchantWebhooks()
  );
  const [activeTab, setActiveTab] = useState('OPTIONS');

  return (
    <MerchantWrapper>
      <MerchantPanel>
        <MerchantContainer
          width={
            (activeTab === 'OPERATIONS' && '1200px') ||
            (activeTab === 'PAYMENTS' && '1052px') ||
            ''
          }
        >
          <MerchantTitle>{t('merchantPage.title')}</MerchantTitle>
          <WrapperMerchantMenu>
            <Button
              type="button"
              onClick={() => setActiveTab('OPTIONS')}
              secondary={activeTab !== 'OPTIONS'}
            >
              {t('merchantPage.optionsPairs')}
            </Button>
            <Button
              type="button"
              onClick={() => setActiveTab('BALANCES')}
              secondary={activeTab !== 'BALANCES'}
            >
              {t('merchantPage.balances')}
            </Button>
            <Button
              type="button"
              onClick={() => setActiveTab('OPERATIONS')}
              secondary={activeTab !== 'OPERATIONS'}
            >
              {t('merchantPage.operations')}
            </Button>
            <Button
              type="button"
              onClick={() => setActiveTab('WEBHOOKS')}
              secondary={activeTab !== 'WEBHOOKS'}
            >
              {t('merchantPage.webhooks')}
            </Button>
            <Button
              type="button"
              onClick={() => setActiveTab('PAYMENTS')}
              secondary={activeTab !== 'PAYMENTS'}
            >
              {t('merchantPage.payments')}
            </Button>
          </WrapperMerchantMenu>
          {(activeTab === 'OPTIONS' && (
            <OptionsComponent
              merchantData={merchantData}
              merchantRefetch={merchantRefetch}
              t={t}
            />
          )) ||
            (activeTab === 'BALANCES' && (
              <BalancesComponent
                merchantData={merchantData}
                merchantRefetch={merchantRefetch}
                t={t}
              />
            )) ||
            (activeTab === 'OPERATIONS' && (
              <MerchantOperationTable t={t} merchantData={merchantData} />
            )) ||
            (activeTab === 'WEBHOOKS' && (
              <MerchantWebhooksTable webhooksData={webhooksData} t={t} />
            )) ||
            (activeTab === 'PAYMENTS' && (
              <PaymentsComponent merchantData={merchantData} t={t} />
            ))}
        </MerchantContainer>
      </MerchantPanel>
    </MerchantWrapper>
  );
};

export default Merchant;
