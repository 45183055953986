import styled from 'styled-components';
import device from 'device';

export const InformationWrapper = styled.div`
  max-width: 850px;
  padding: 0 50px 50px;
  margin: 0 auto;
  @media (max-width: 845px) {
    padding: 0 25px 25px;
  }
`;

export const Info = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: #323233;
  background: #ffffff;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const MediaLoader = styled.div`
  padding: 25px 0 65px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
