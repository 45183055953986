export const verificationDocumentsUrl = `${process.env.REACT_APP_WBDEVEL_URL}/kyc/regula/process`;

export const verificationDocumentsComplete = `${process.env.REACT_APP_WBDEVEL_URL}/kyc/regula/complete`;

export const confirmFormRequestUrl = `${process.env.REACT_APP_LIVENESS_URL}/api/v2/kyc/client/crypto-test`;

export const getLivenessComplete = `${process.env.REACT_APP_LIVENESS_URL}/api/v2/liveness`;

export const testComplete = {
  method: 'post',
  url: `${process.env.REACT_APP_EXCHANGE_URL}/client/testing/complete`,
};

export const verificationDocuments = {
  method: 'post',
  url: verificationDocumentsUrl,
};

export const getVerificationStatus = {
  method: 'get',
  url: `${process.env.REACT_APP_KYC_URL}/kyc`,
};

export const getVerificationTest = {
  method: 'get',
  url: `${process.env.REACT_APP_KYC_URL}/verification-test`,
};
