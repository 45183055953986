import styled from 'styled-components';
import device from 'device';

import { ReactComponent as BY } from 'assets/images/phone/BY.svg';
import { ReactComponent as RU } from 'assets/images/phone/RU.svg';
import { ReactComponent as AM } from 'assets/images/phone/AM.svg';
import { ReactComponent as AZ } from 'assets/images/phone/AZ.svg';
import { ReactComponent as GE } from 'assets/images/phone/GE.svg';
import { ReactComponent as KG } from 'assets/images/phone/KG.svg';
import { ReactComponent as KZ } from 'assets/images/phone/KZ.svg';
import { ReactComponent as TJ } from 'assets/images/phone/TJ.svg';
import { ReactComponent as MD } from 'assets/images/phone/MD.svg';
import { ReactComponent as TM } from 'assets/images/phone/TM.svg';
import { ReactComponent as UA } from 'assets/images/phone/UA.svg';
import { ReactComponent as UZ } from 'assets/images/phone/UZ.svg';
import { ReactComponent as ChoosenPhoneIcon } from 'assets/images/icons/choosen-select-option.svg';

export const FlagBY = styled(BY)``;
export const FlagRU = styled(RU)``;
export const FlagAM = styled(AM)``;
export const FlagAZ = styled(AZ)``;
export const FlagGE = styled(GE)``;
export const FlagKG = styled(KG)``;
export const FlagKZ = styled(KZ)``;
export const FlagTJ = styled(TJ)``;
export const FlagMD = styled(MD)``;
export const FlagTM = styled(TM)``;
export const FlagUA = styled(UA)``;
export const FlagUZ = styled(UZ)``;
export const ChoosenMark = styled(ChoosenPhoneIcon)``;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 10px;
  }
`;

export const LabelText = styled.span`
  font-size: 1.1rem;
  padding-right: 5px;
  color: ${({ theme }) => theme.fullBlackText};

  @media ${device.mobile920} {
    font-size: 1rem;
`;
