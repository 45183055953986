import styled from 'styled-components';

export const SwitcherWrapper = styled.div`
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Switcher = styled.div`
  background-color: ${props => props.theme.lightBlue};
  margin: 0 auto;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1150px) {
    width: auto;
    max-width: 800px;
    margin: 25px;
  }
  > button {
    font-size: 0.96rem;
    @media (max-width: 1150px) {
      flex-basis: 50%;
    @media (max-width: 550px) {
      flex-basis: 100%;
    }
  }
`;
