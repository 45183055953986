import React from 'react';
import { useTranslation } from 'react-i18next';
import { LargeButtonLink } from 'ui-kit/Button';
import { HOME } from 'constants/routings';
import {
  ErrorCode,
  ErrorGrid,
  ErrorInfo,
  ErrorMessage,
  MainImageNotFound,
  Relative,
  ErrorWrapper,
} from './styled-ui';

const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <Relative>
      <ErrorWrapper>
        <ErrorGrid>
          <ErrorCode>{t('noMatch.titleCode')}</ErrorCode>
          <ErrorMessage>{t('noMatch.message')}</ErrorMessage>
          <ErrorInfo>{t('noMatch.infoMessage')}</ErrorInfo>
          <LargeButtonLink to={HOME}>
            {t('noMatch.redirectToHomePage')}
          </LargeButtonLink>
        </ErrorGrid>
      </ErrorWrapper>
      <MainImageNotFound />
    </Relative>
  );
};

export default NoMatch;
