import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'query-string';
import UserContext from 'utils/contexts/User';
import HeaderLayout from 'components/Header';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { confirmationEmailFormOptions } from 'utils/services/request/auth';
import { HOME, USER_PROFILE, SIGN_IN } from 'constants/routings';
import { SETTINGS } from 'utils/constants/profileTabs';
import { pushAnalyticsEvent } from 'components/App/analytics';
import {
  EmailConfirmedWrapper,
  EmailSentWrapper,
  Header,
  ConfirmEmailLabel,
  ButtonWrapperConfirm,
  WrapperConfirm,
  SmallModalConfirm,
  ModalWrapper,
  ConfirmLink,
  AppInfo,
  WrapperLink,
  SupportLinkInline,
  InfoIcon,
  InnerLink,
} from '../styled-ui';

const ConfirmEmail = () => {
  const location = useLocation();
  const history = useHistory();
  const { code } = parse(location.search);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const user = useContext(UserContext);
  const calcInformation = useAxiosState(confirmationEmailFormOptions({ code }));
  useEffect(() => {
    if (user?.data?.userStatus !== 'EMAIL_VERIFIED') {
      if (!calcInformation.fetching && calcInformation.loaded) {
        if (calcInformation.data) {
          user.data.userStatus = 'EMAIL_VERIFIED';
          setError(false);
          pushAnalyticsEvent('registration_step2');
        } else {
          setError(true);
        }
      }
    }
  }, [calcInformation, setError, user]);

  if (!user.data) {
    history.push(SIGN_IN);
  }

  return (
    <WrapperConfirm>
      <HeaderLayout />
      <ModalWrapper>
        <SmallModalConfirm>
          <EmailSentWrapper>
            <EmailConfirmedWrapper />
          </EmailSentWrapper>
          <Header textalign="center">
            {error
              ? t('passwordForms.titleConfirmEmailError')
              : t('passwordForms.titleConfirmEmail')}
          </Header>
          {error ? (
            <ConfirmEmailLabel textalign="center">
              {t('passwordForms.сonfirmEmailLabelError')}
            </ConfirmEmailLabel>
          ) : (
            <AppInfo padding="0 0 30px" textalign="center">
              {t('signUpForm.twoFaDescription')}
              <WrapperLink>
                <SupportLinkInline
                  to={{
                    pathname: USER_PROFILE,
                    search: `?tab=${SETTINGS}`,
                  }}
                >
                  {t('signUpForm.twoFaDescription2')}
                </SupportLinkInline>
                <InfoIcon />
                <InnerLink>{t('signUpForm.twoFaDescription3')}</InnerLink>
              </WrapperLink>
            </AppInfo>
          )}
          <ButtonWrapperConfirm>
            <ConfirmLink to={HOME} textalign="center">
              {t('passwordForms.doneButton')}
            </ConfirmLink>
          </ButtonWrapperConfirm>
        </SmallModalConfirm>
      </ModalWrapper>
    </WrapperConfirm>
  );
};

export default ConfirmEmail;
