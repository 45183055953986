import blueTheme from 'components/App/themes/blue';

export const stylesRegulaComponent = {
  fontFamily: 'Inter',
  onboardingScreenStartButtonBackground: blueTheme.blueBg,
  onboardingScreenStartButtonBackgroundHover: blueTheme.blueBg,
  retryScreenRetryButtonBackground: blueTheme.blueBg,
  retryScreenRetryButtonBackgroundHover: blueTheme.blueBg,
  cameraScreenStrokeNormal: 'none',
};

export const requiredFields = [
  'firstNameRu',
  'lastNameRu',
  'patronymicRu',
  'firstName',
  'lastName',
  'placeOfBirth',
  'birthDate',
  'identityDocType',
  'identityDocIssueDate',
  'identityDocExpireDate',
  'identityDocNumber',
  'identityDocIssuer',
  'gender',
  'nationality',
];

export const checkFields = (data, isChangeRegistration) => {
  return (
    isChangeRegistration ||
    requiredFields.every(field => data[field]?.length > 0)
  );
};
