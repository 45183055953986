import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import axios from 'axios';
import DotPreloader from 'ui-kit/DotPreloader';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';
import ModalContext from 'utils/contexts/Modal';
import ClientContext from 'utils/contexts/Client';
import { useAxiosStateWithRefetchNoFirstCall } from 'utils/hooks/axiosHook';
import { changeCardName } from 'utils/services/request/card';
import { convertCardNumber, getCardsCommissionByProvider } from 'utils/number';
import {
  CARD_TYPES,
  MTS,
  BELARUSBANK,
  ASSIST_BELARUSBANK,
  ALFA,
  ASSIST,
} from 'utils/constants/paymentMethods';
import { ATTACH_CARD } from 'constants/routings';
import { pushAnalyticsEvent } from 'components/App/analytics';
import RenameCardModal from 'components/Auth/UserProfile/PaymentMethodList/RenameCardModal';
import CardsCommissionInfo from 'components/Auth/UserProfile/PaymentMethodList/CardsCommissionInfo';
import {
  AttachCardButton,
  Button,
  ButtonsWrapper,
  CardLabel,
  CardName,
  CardNumber,
  CardRow,
  CardWrapper,
  LoadWrapper,
  MastercardLogo,
  VisaLogo,
  CardsWrapper,
  CardsTitleWrapper,
  CardsInfoWrapper,
} from '../styled-ui';

const getCardIconByType = cardType => {
  switch (cardType) {
    case CARD_TYPES.MASTERCARD:
      return <MastercardLogo />;
    case CARD_TYPES.VISA:
      return <VisaLogo />;
    default:
      return null;
  }
};

const CardManagement = ({
  t,
  cardsInformation,
  cards,
  commissionInformation,
}) => {
  const history = useHistory();
  const [selectedProviderType, setSelectedProviderType] = useState({});
  const [cardToRemove, setCardToRemove] = useState({
    open: false,
    id: '',
    remove: () => {},
  });
  const [cardToRename, setCardToRename] = useState({
    open: false,
    id: '',
    providerType: '',
  });
  const [cardName, setCardName] = useState('');
  const { openModal } = useContext(ModalContext);
  const client = useContext(ClientContext);

  const isDisplayMtsCards = client?.data?.internalBalanceEnabled;

  const attachCard = useAxiosStateWithRefetchNoFirstCall(
    selectedProviderType.attachCardRequest
  );

  const deleteUserCard = () => {
    setCardToRemove(prev => ({ ...prev, open: false }));

    return axios
      .post(cardToRemove.remove(cardToRemove.id))
      .then(cardsInformation.refetch)
      .catch(() =>
        openModal({
          isError: true,
          message: t('errorModal.title'),
        })
      );
  };

  useEffect(() => {
    if (attachCard?.data?.url) {
      window.location.assign(`${attachCard.data.url}`);
    } else if (attachCard?.error) {
      openModal({
        isError: true,
        message: t('profile.cardActions.attachFailed'),
      });
    }
  }, [attachCard.data, attachCard?.error]);

  const handleAttachCard = provider => {
    if (provider.id === MTS) {
      history.push(ATTACH_CARD);
    } else {
      attachCard.fetch();
    }
    setSelectedProviderType(provider);
    pushAnalyticsEvent('attach_card_click');
  };

  const handleRenameCard = (id, providerType, name) => {
    setCardToRename({
      open: true,
      id,
      providerType: providerType === ASSIST_BELARUSBANK ? ASSIST : providerType,
      name,
    });
    setCardName(name);
  };

  const handleSetCardName = async () => {
    setCardToRename(prev => ({ ...prev, open: false }));
    await axios.put(
      changeCardName(cardToRename?.id, cardToRename?.providerType),
      { name: cardName }
    );
    await cardsInformation.refetch();
  };

  const filteredCardsByProvider = !isDisplayMtsCards
    ? cards.filter(card => card.id !== MTS)
    : cards;

  const attachedCards = cardsInformation.data?.map(payment => ({
    ...payment,
    providerType:
      payment.bank === BELARUSBANK ? ASSIST_BELARUSBANK : payment.providerType,
  }));

  const isAttachedCards = provider => {
    return !!attachedCards.filter(card => card.providerType === provider)
      .length;
  };

  const getCommissionInfoText = provider => {
    switch (provider) {
      case ALFA:
        return 'profile.cardActions.commissionInfoAlfa';
      case ASSIST_BELARUSBANK:
        return 'profile.cardActions.commissionInfoBelarusbank';
      case MTS:
        return null;
      default:
        return 'profile.cardActions.commisionInfo';
    }
  };

  return (
    <>
      {filteredCardsByProvider?.map(payment => {
        const commission = getCardsCommissionByProvider(
          commissionInformation,
          payment.id
        );
        return (
          <CardsWrapper key={payment.id}>
            <CardsTitleWrapper>
              <Trans i18nKey={`profile.cardsList.${payment.id}.title`} t={t} />
            </CardsTitleWrapper>
            <CardsInfoWrapper>
              {commission && (
                <CardsCommissionInfo
                  t={t}
                  commissionInformation={commission}
                  text={getCommissionInfoText(payment.id)}
                />
              )}
              <AttachCardButton
                type="button"
                disabled={attachCard.fetching}
                onClick={() => handleAttachCard(payment)}
              >
                {t('profile.cardActions.attachCardAction')}
              </AttachCardButton>
            </CardsInfoWrapper>
            {isAttachedCards(payment.id) && (
              <CardLabel>{t('profile.cardActions.attachedCards')}</CardLabel>
            )}
            {cardsInformation.fetching && (
              <LoadWrapper>
                <DotPreloader dotColor="#0069ff" />
              </LoadWrapper>
            )}
            {attachedCards
              ?.filter(it => it.providerType === payment.id)
              .map(({ number, brand, id, providerType, name }) => (
                <CardRow key={id}>
                  <CardWrapper>
                    <CardNumber>
                      {getCardIconByType(brand)}
                      {convertCardNumber(number)}
                    </CardNumber>
                    <CardName>{name}</CardName>
                  </CardWrapper>
                  <ButtonsWrapper>
                    <Button
                      type="button"
                      onClick={() => handleRenameCard(id, providerType, name)}
                    >
                      {t(`profile.cardActions.renameCard`)}
                    </Button>
                    <Button
                      color="red"
                      type="button"
                      onClick={() => {
                        setCardToRemove({
                          open: true,
                          id,
                          remove: payment.getRemoveCardUrl,
                        });
                      }}
                    >
                      {t(`profile.cardActions.removeCard`)}
                    </Button>
                  </ButtonsWrapper>
                </CardRow>
              ))}
          </CardsWrapper>
        );
      })}

      {cardToRemove.open && (
        <DialogModal
          Component={Dialog}
          title={t('profile.cardActions.removeCardModalTitle')}
          message={t('profile.cardActions.removeCardModalDesc')}
          acceptButtonName={t('profile.cardActions.removeCard')}
          acceptFunc={() => deleteUserCard()}
          onClose={() => setCardToRemove(prev => ({ ...prev, open: false }))}
          t={t}
        />
      )}
      {cardToRename.open && (
        <RenameCardModal
          t={t}
          value={cardName}
          onChange={e => setCardName(e.target.value)}
          onClose={() => setCardToRename(prev => ({ ...prev, open: false }))}
          onSubmit={() => handleSetCardName()}
        />
      )}
    </>
  );
};

export default CardManagement;
