/* eslint-disable react/no-unescaped-entities */
import Support from 'components/Footer/support';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { hyphenationOfPrepositions } from 'utils/string';
import { DISPUTE, INFORMATION } from 'constants/routings';
import SocialMediaLinks from 'components/SocialMediaLinks';
import Logos from 'components/Logos';
import {
  ContainerGrid,
  Footer as FooterBlock,
  Info,
  ContactsWrapper,
  ContactInfo,
  InfoWrapper,
  InfoLinks,
  InfoLinkItem,
  Wrapper,
} from './styled-ui';

const links = [
  {
    id: 'clients-personal-data-handling',
    link: '/assets/files/clients-personal-datahandling_07_2024.pdf',
    blank: true,
  },
  {
    id: 'personal-data-processing-policy',
    link: '/assets/files/personal-data-processing-policy_03_2024.pdf',
    blank: true,
  },
  {
    id: 'confidentiality-data-processing-policy',
    link: '/assets/files/confidentiality-data-processing-policy_07_2024.pdf',
    blank: true,
  },
  {
    id: 'user-support-statement',
    link: '/assets/files/user-support-statement_07_2024.pdf',
    blank: true,
  },
  {
    id: 'tokens-sale-general-conditions',
    link: '/assets/files/tokens-sale-generalconditions_07_2024.pdf',
    blank: true,
  },
  {
    id: 'conflict-of-interest-statement',
    link: '/assets/files/conflict-of-interest-statement_07_2024.pdf',
    blank: true,
  },
  {
    id: 'risks-notification',
    link: '/assets/files/risks-notification_07_2024.pdf',
    blank: true,
  },
  {
    id: 'public-offer',
    link: '/assets/files/public-offer_07_2024.pdf',
    blank: true,
  },
  {
    id: 'dispute',
    link: DISPUTE,
  },
  {
    id: 'information',
    link: INFORMATION,
  },
];

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Support />
      <Logos />
      <FooterBlock>
        <ContainerGrid>
          <ContactsWrapper>
            <ContactInfo margin="0 0 25px 0">
              {t(`footerPreview.addressInfo`)}
            </ContactInfo>
            <ContactInfo>{t(`footerPreview.contactInfo.title`)}</ContactInfo>
            <ContactInfo margin="0 0 25px 0 ">
              {t(`footerPreview.contactInfo.email`)}
            </ContactInfo>
            <ContactInfo margin="auto 0 25px 0 ">
              © 2020–{moment().format('YYYY')} Whitebird
            </ContactInfo>
            <SocialMediaLinks />
          </ContactsWrapper>
          <InfoWrapper>
            <Info>
              {hyphenationOfPrepositions(t(`footerPreview.aboutWhiteBird`))}
            </Info>
            <Info>
              {hyphenationOfPrepositions(t(`footerPreview.ErnstAndYoung`))}
            </Info>
            <Info>{hyphenationOfPrepositions(t(`footerPreview.risk1`))}</Info>
            <Info>{hyphenationOfPrepositions(t(`footerPreview.risk2`))}</Info>
            <Info>
              {hyphenationOfPrepositions(t(`footerPreview.securityText`))}
            </Info>
          </InfoWrapper>
          <InfoLinks>
            {links.map((it, index) => (
              <InfoLinkItem key={it.id}>
                <Link target={it.blank ? '_blank' : ''} to={it.link}>
                  {t(`footerPreview.footerLinks.${index}.label`)}
                </Link>
              </InfoLinkItem>
            ))}
          </InfoLinks>
        </ContainerGrid>
      </FooterBlock>
    </Wrapper>
  );
};

export default Footer;
