import statuses from 'utils/constants/statutes';
import { pathOr } from 'ramda';

export const isUserAnonymous = userState => {
  if (!userState.loaded || userState.fetching) {
    return true;
  }
  if (userState.error) {
    return true;
  }
  return false;
};

export const isUserConfirmedEmail = userState => {
  if (userState.data) {
    if (userState.data.userStatus !== 'NEW') {
      return true;
    }
  }

  return false;
};

export const isUserVerificated = status => {
  if (status !== 'NOT_VERIFIED') {
    return true;
  }
  return false;
};

export const isUserMFAEnabled = userState => {
  if (userState.data) {
    return userState.data.mfaEnabled;
  }

  return false;
};

export const getUserRole = (data, role) => {
  const userRoles = pathOr([], ['data', 'roles'], data);
  return !!userRoles.find(item => item.name === role);
};

export const userStatus = status => {
  return statuses[status];
};
