const PROFILE_TABS_KEYS = [
  'balances',
  'balancesHistory',
  'operations',
  'payments',
  'providers',
  'notifications',
  'notificationsPages',
];

export default PROFILE_TABS_KEYS;
