/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { head } from 'ramda';
import { Input } from './styled-ui';

const CodeInput = ({ id, field, setFieldValue, ...props }) => {
  const { name, value, ...fieldProps } = field;

  const ref = useRef(null);

  useEffect(() => {
    const popNext = (el, data) => {
      setFieldValue(el.id, data[0]);
      const updData = data.substring(1);
      if (el.nextElementSibling && updData.length) {
        popNext(el.nextElementSibling, updData);
      }
    };

    const splitNumber = e => {
      const data = e.data || e.target.value;
      if (!data) return;
      if (data.length === 1) return;

      popNext(e.target, data);
    };

    // Shift, Tab, CMD, Option, Control.
    const missKeyCodes = [16, 9, 224, 18, 17];
    const handleKeyUp = event => {
      const numberValue = parseInt(event.target.value, 10);
      if (missKeyCodes.includes(event.keyCode)) {
        return;
      }

      if (
        // Backspace and left arrow
        [8, 37].includes(event.keyCode) &&
        event.target.previousElementSibling &&
        event.target.previousElementSibling.tagName === 'INPUT'
      ) {
        event.target.previousElementSibling.focus();
      } else if (
        event.keyCode !== 8 &&
        event.target.nextElementSibling &&
        Number.isInteger(numberValue)
      ) {
        event.target.nextElementSibling.focus();
      }

      if (event.target.value.length > 1) {
        splitNumber(event);
      }
    };

    const handleFocus = event => {
      if (event.target.id === 'otc-1') return;

      if (event.target.previousElementSibling.value === '') {
        event.target.previousElementSibling.focus();
      }
    };

    const handleKeyDown = event => {
      if (Number.isNaN(Number(event.key)) && ![8, 37].includes(event.keyCode)) {
        event.stopPropagation();
        event.preventDefault();
        return false;
      }
      return true;
    };

    const element = ref.current;
    element.addEventListener('keyup', handleKeyUp);
    element.addEventListener('input', splitNumber);
    element.addEventListener('focus', handleFocus);
    element.addEventListener('keydown', handleKeyDown);

    return () => {
      element.removeEventListener('keyup', handleKeyUp);
      element.removeEventListener('input', splitNumber);
      element.removeEventListener('focus', handleFocus);
      element.removeEventListener('keydown', handleKeyDown);
    };
  }, [setFieldValue]);

  const onChange = event => {
    const {
      target: { value: inputValue },
    } = event;
    setFieldValue(name, head(inputValue) || '');
  };

  return (
    <>
      <Input
        autoComplete={id === 'otc-1' ? 'one-time-code' : 'off'}
        id={id}
        name={name}
        value={value === null ? '' : value}
        {...props}
        {...fieldProps}
        ref={ref}
        onChange={onChange}
      />
    </>
  );
};

export default CodeInput;
