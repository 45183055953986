import React, { useState, useContext } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { HOME } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import { SmallModal } from 'ui-kit/Modal/Modal';
import RestorePassword from 'components/Auth/RestorePassword';
import RestorePasswordActivated from 'components/Auth/RestorePassword/RestorePasswordActivated';

const RestorePasswordModal = () => {
  const [redirect, setRedirect] = useState(false);
  const user = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const query = parse(location.search);

  return (
    <>
      {user.data || redirect ? <Redirect to={history.goBack()} /> : null}
      {query?.token ? (
        <SmallModal
          Component={RestorePasswordActivated}
          onClose={() => history.push(HOME)}
        />
      ) : (
        <SmallModal
          Component={RestorePassword}
          onClose={() => setRedirect(true)}
        />
      )}
    </>
  );
};

export default RestorePasswordModal;
