import styled from 'styled-components';
import device from 'device';

export const StepsWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  display: flex;
  width: 100%;
  text-align: initial;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const StepsItemTail = styled.div`
  position: absolute;
  top: 0px;
  inset-inline-start: 0;
  width: 100%;
  margin-left: 58px;
  padding: 3.5px 24px;
  box-sizing: border-box;
  &:after {
    display: inline-block;
    width: 100%;
    height: 1px;
    border-radius: 1px;
    margin-top: 0px;
    transition: background 0.3s;
    content: '';
    background-color: ${props =>
      props.type === 'finish' ? props.theme.blue : props.theme.grayTextLight};
  }
  @media ${device.tablet} {
    display: block;
    position: absolute;
    top: 0;
    inset-inline-start: 1px;
    width: 1px;
    height: 100%;
    margin: 0px;
    &:after {
      width: 1px;
      height: 100%;
      margin-top: 43px;
    }
  }
`;

export const StepsItem = styled.div`
  overflow: visible;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  flex: 1 1;
  vertical-align: top;
    &:last-child {
        flex: none;
        div${StepsItemTail} {
            display: none;
        }
    }
  }
  @media ${device.tablet} {
    overflow: hidden;
  }
`;

export const StepsItemContainer = styled.div`
  outline: none;
  align-items: flex-start;
  position: relative;
  @media ${device.tablet} {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
  }
`;

export const StepsItemIcon = styled.div`
  z-index: 10;
  display: inline-block;
  margin-left: 42px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border: 1px;
  border-style: solid;
  border-radius: 32px;
  border-color: ${props =>
    ['process', 'finish'].includes(props.type)
      ? props.theme.blueType2
      : props.theme.dimgray};
  transition: background-color 0.3s, border-color 0.3s;
  box-sizing: border-box;
  background-color: ${props =>
    props.type === 'process' ? props.theme.blueType2 : props.theme.fullWhite};
  @media ${device.tablet} {
    margin: 8px 8px 0px;
  }
`;

export const StepsIcon = styled.div`
  position: relative;
  top: 0;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: ${props =>
    ['process', 'finish'].includes(props.type)
      ? props.theme.fullWhite
      : props.theme.dimgray};
`;

export const StepsItemContent = styled.div`
  box-sizing: border-box;
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
  @media ${device.tablet} {
    flex: 1 1;
    div {
      display: block;
      text-align: left;
    }
  }
`;

export const StepsItemTitle = styled.div`
  position: relative;
  display: inline-block;
  padding-right: 0px;
  color: ${props => props.theme.blackText};
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
`;

export const StepsItemDescription = styled.div`
  color: ${props =>
    props.type === 'process' ? props.theme.darkgray : props.theme.dimgray};
  text-align: center;
  padding-bottom: 12px;
`;
