import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import CustomCheckbox from 'ui-kit/CustomField/Checkbox';
import { FullRow } from '../styled-ui';

const Step1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <FullRow>
        <Field
          id="exchangeInPersonalInterests"
          name="exchangeInPersonalInterests"
          component={CustomCheckbox}
          type="checkbox"
          label={t('verification.exchangeInPersonalInterests')}
        />
      </FullRow>

      <FullRow>
        <Field
          id="notUSTaxpayer"
          name="notUSTaxpayer"
          component={CustomCheckbox}
          type="checkbox"
          label={t('verification.notUSTaxpayer')}
        />
      </FullRow>
    </>
  );
};

export default Step1;
