/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { getUserPaymentRecommended } from 'utils/services/request/card';
import {
  ERIP,
  PAYMENT_STATUS,
  SETTLEMENT,
  ORDER_TYPES,
  CARD_TYPES,
} from 'utils/constants/paymentMethods';
import { convertCardNumber } from 'utils/number';
import { VERIFICATIONS, SIGN_UP, USER_PROFILE } from 'constants/routings';
import { PAYMENT_METHODS } from 'utils/constants/profileTabs';
import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow-down.svg';
import { isCrypto } from 'utils/crypto';
import { FIAT_CURRENCIES } from 'utils/constant';
import LoadingPlaceholder from 'ui-kit/LoadingPlaceholder';
import {
  SelectWrapper,
  Wrapper,
  SelectOption,
  MastercardIcon,
  VisaIcon,
  SelectOptionText,
  NoAttachedCards,
  NoAttachedCardsLink,
  NoCardText,
  AddButton,
  AttachCardLink,
  ChoosenMark,
  SettlementIcon,
  EripIcon,
  CardNotAvailable,
  SelectOptionContent,
  CardName,
  CardContent,
} from './styled-ui';

import customStyle from './select-style';

const getCardIconByType = cardTypes => {
  switch (cardTypes) {
    case CARD_TYPES.MASTERCARD:
      return <MastercardIcon />;
    case CARD_TYPES.VISA:
      return <VisaIcon />;
    case CARD_TYPES.ERIP:
      return <EripIcon />;
    case CARD_TYPES.MIR:
      return null;
    case null:
      return <MastercardIcon />;
    default:
      return <SettlementIcon />;
  }
};

const getCardInputInfoNotVerifiedUser = (userStatus, t) => {
  switch (userStatus) {
    case 'NEW':
      return (
        <NoAttachedCardsLink to={SIGN_UP}>
          <NoCardText>
            {t('calculationView.cardInput.infoApproveEmail')}
          </NoCardText>
        </NoAttachedCardsLink>
      );
    case 'NOT_VERIFIED':
      return (
        <NoAttachedCardsLink to={VERIFICATIONS}>
          <NoCardText>
            {t('calculationView.cardInput.infoApproveVerification')}
          </NoCardText>
        </NoAttachedCardsLink>
      );
    case 'PENDING':
      return (
        <NoCardText>
          {t('calculationView.cardInput.infoVerificationInProgress')}
        </NoCardText>
      );
    case 'FROZEN':
      return (
        <NoCardText>
          {t('calculationView.cardInput.infoStatusUnavailable')}
        </NoCardText>
      );
    case 'ARREST':
      return (
        <NoCardText>
          {t('calculationView.cardInput.infoStatusUnavailable')}
        </NoCardText>
      );
    default:
      return null;
  }
};

const getAddCardOrTest = (isTestRequired, t) =>
  isTestRequired ? (
    <NoAttachedCardsLink to={VERIFICATIONS}>
      <NoCardText>{t('calculationView.cardInput.getCardTest')}</NoCardText>
    </NoAttachedCardsLink>
  ) : (
    <NoCardText>
      <AddButton>{t('calculationView.cardInput.addCard')}</AddButton>
    </NoCardText>
  );

const CardSelect = ({
  allPaymentsInformation,
  selectName,
  selectValue,
  setCardInformation,
  isUserVerified,
  userStatus,
  isTestingRequired,
  fromCurrency,
  toCurrency,
  setPaymentId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const getRecommendedPayMethods = useAxiosState(getUserPaymentRecommended);

  const addUserCardHandler = isVerified => () => {
    if (!isVerified) return;
    history.push({
      pathname: USER_PROFILE,
      search: `?tab=${PAYMENT_METHODS}`,
    });
  };

  const onChangeCard = option => {
    setPaymentId(option.value);
    if (option.value === ORDER_TYPES.SETTLEMENT) {
      setCardInformation({ id: option.value, providerType: SETTLEMENT });
    }
    if (option.value === ORDER_TYPES.ERIP) {
      setCardInformation({ id: option.value, providerType: ERIP });
    }
    const { id, providerType } =
      allPaymentsInformation.data.find(it => it.id === option.value) || {};
    if (providerType && id) {
      setCardInformation({ id, providerType });
    }
  };

  if (!allPaymentsInformation.loaded || allPaymentsInformation.fetching) {
    return (
      <Wrapper>
        <LoadingPlaceholder options={{ height: '46px', position: 'initial' }} />
      </Wrapper>
    );
  }

  if (!allPaymentsInformation.data || allPaymentsInformation.error) {
    if (
      allPaymentsInformation.error &&
      [400, 500].includes(allPaymentsInformation.error.code)
    ) {
      return (
        <Wrapper>
          <SelectWrapper>
            <NoAttachedCards>
              <NoCardText errorColor>
                {t('calculationView.cardInput.errorCardResponse')}
              </NoCardText>
            </NoAttachedCards>
          </SelectWrapper>
        </Wrapper>
      );
    }
    return null;
  }

  const { brand, number, id, providerType, status, name } =
    allPaymentsInformation.data.find(it => it.id === selectValue) || {};

  const getPaymentCardStatus = paymentStatus =>
    paymentStatus === PAYMENT_STATUS.DIRECTION_DISABLED ||
    paymentStatus === PAYMENT_STATUS.CURRENCY_DISABLED;

  const isProviderRecommended = provider => {
    const getPayMethod = getRecommendedPayMethods?.data?.find(
      method => method.name === provider
    );

    return getPayMethod?.recommended;
  };

  const ValueComponent = options => {
    const {
      brand,
      number,
      orderType,
      isValueOption,
      id,
      status,
      providerType,
      name,
    } = options;
    switch (orderType) {
      case ORDER_TYPES.CARD:
        return (
          <AttachCardLink onClick={addUserCardHandler(isUserVerified)}>
            <SelectOptionContent>
              <SelectOptionText>
                {t('calculationView.cardInput.addCard')}
              </SelectOptionText>
            </SelectOptionContent>
            {!isValueOption && <ChoosenMark />}
          </AttachCardLink>
        );
      case ORDER_TYPES.SETTLEMENT:
        return (
          <SelectOption>
            <SelectOptionContent>
              <SelectOptionText>
                {getCardIconByType(SETTLEMENT)}
                {t('calculationView.cardInput.payBySettlement')}
              </SelectOptionText>
            </SelectOptionContent>
            {!isValueOption && <ChoosenMark />}
          </SelectOption>
        );
      case ORDER_TYPES.ERIP: {
        const isEripOptionDisabled =
          fromCurrency !== FIAT_CURRENCIES.BYN || !isProviderRecommended(ERIP);
        return (
          <SelectOption isDisabled={isEripOptionDisabled}>
            <SelectOptionContent>
              <SelectOptionText
                isIconDisabled={isEripOptionDisabled}
                isDisabled={isEripOptionDisabled}
              >
                {getCardIconByType(ERIP)}
                {t('calculationView.cardInput.payByErip')}
              </SelectOptionText>
            </SelectOptionContent>
            {!isValueOption && <ChoosenMark />}
          </SelectOption>
        );
      }
      default:
        return (
          <SelectOption isDisabled={getPaymentCardStatus(status)}>
            <SelectOptionContent>
              <CardContent>
                <SelectOptionText isDisabled={getPaymentCardStatus(status)}>
                  {getCardIconByType(brand)}
                  {providerType === SETTLEMENT
                    ? number
                    : convertCardNumber(number)}
                </SelectOptionText>
                {providerType !== SETTLEMENT && (
                  <CardName isVisible={!getPaymentCardStatus(status)}>
                    {name}
                  </CardName>
                )}
              </CardContent>
              {getPaymentCardStatus(status) && (
                <CardNotAvailable
                  isSelectedCard={id === selectValue || isValueOption}
                >
                  {t('profile.cardActions.isNotAvailable')}
                </CardNotAvailable>
              )}
            </SelectOptionContent>
            {!isValueOption && <ChoosenMark />}
          </SelectOption>
        );
    }
  };

  const selectOrders = {
    [ORDER_TYPES.ERIP]: {
      value: ORDER_TYPES.ERIP,
      label: ValueComponent({ orderType: ORDER_TYPES.ERIP }),
      isDisabled:
        fromCurrency !== FIAT_CURRENCIES.BYN || !isProviderRecommended(ERIP),
    },
    [ORDER_TYPES.CARD]: {
      value: ORDER_TYPES.CARD,
      label: ValueComponent({ orderType: ORDER_TYPES.CARD }),
    },
    [ORDER_TYPES.SETTLEMENT]: {
      value: ORDER_TYPES.SETTLEMENT,
      label: ValueComponent({ orderType: ORDER_TYPES.SETTLEMENT }),
    },
  };

  const filterCardsResultByCurrency = (cards = [], toCurrency) => {
    const isToCurrencyCrypto = isCrypto(toCurrency);
    const filteredCards = isToCurrencyCrypto
      ? cards.filter(it => it.providerType !== SETTLEMENT)
      : cards;

    const mappedCards = filteredCards.map(card => ({
      label: ValueComponent(card),
      value: card.id,
      isDisabled: getPaymentCardStatus(card.status),
    }));

    if (isToCurrencyCrypto) {
      // mappedCards.push(selectOrders[ORDER_TYPES.ERIP]);
      mappedCards.push(selectOrders[ORDER_TYPES.SETTLEMENT]);
    }
    mappedCards.push(selectOrders[ORDER_TYPES.CARD]);
    return mappedCards;
  };

  const selectOptions = [
    {
      label: t('profile.cardActions.attachedCards'),
      options: filterCardsResultByCurrency(
        allPaymentsInformation.data,
        toCurrency
      ),
    },
  ];

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    );
  };

  const selectCurrentValue = {
    label: ValueComponent({
      brand,
      number,
      isValueOption: true,
      orderType: selectValue,
      providerType,
      id,
      status,
      name,
    }),
    value: id || selectValue,
  };

  return (
    <Wrapper>
      <SelectWrapper>
        {isTestingRequired || !isUserVerified || !selectValue ? (
          <NoAttachedCards
            type="button"
            onClick={!isTestingRequired && addUserCardHandler(isUserVerified)}
          >
            {isUserVerified
              ? getAddCardOrTest(isTestingRequired, t)
              : getCardInputInfoNotVerifiedUser(userStatus, t)}
          </NoAttachedCards>
        ) : (
          <Select
            name={selectName}
            value={selectCurrentValue}
            components={{ DropdownIndicator }}
            onChange={onChangeCard}
            options={selectOptions}
            isOptionDisabled={selectOptions => selectOptions.isDisabled}
            styles={customStyle()}
            error={false}
            isSearchable={false}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                borderColor: '1px solid red',
              },
            })}
          />
        )}
      </SelectWrapper>
    </Wrapper>
  );
};

CardSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allPaymentsInformation: PropTypes.any.isRequired,
};

export default CardSelect;
