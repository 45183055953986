import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputField, InputWrapper, Wrapper } from './styled-ui';

const DocumentDetails = ({ fields }) => {
  const { t } = useTranslation();

  return (
    <div>
      {fields && (
        <Wrapper>
          <InputWrapper>
            {t('verification.documentFields.firstNameRu')}
            <InputField>{fields.firstNameRu || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.lastNameRu')}
            <InputField>{fields.lastNameRu || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.patronymicRu')}
            <InputField>{fields.patronymicRu || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.firstName')}
            <InputField>{fields.firstName || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.lastName')}
            <InputField>{fields.lastName || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.placeOfBirth')}
            <InputField>{fields.placeOfBirth || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.birthDate')}
            <InputField>{fields.birthDate || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.identityDocType')}
            <InputField>{fields.identityDocType || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.identityDocIssueDate')}
            <InputField>{fields.identityDocIssueDate || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.identityDocExpireDate')}
            <InputField>{fields.identityDocExpireDate || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.identityDocNumber')}
            <InputField>{fields.identityDocNumber || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.identityDocIssuer')}
            <InputField>{fields.identityDocIssuer || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.personalNumber')}
            <InputField>{fields.personalNumber || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.gender')}
            <InputField>{fields.gender || ''}</InputField>
          </InputWrapper>
          <InputWrapper>
            {t('verification.documentFields.nationality')}
            <InputField>{fields.nationality || ''}</InputField>
          </InputWrapper>
        </Wrapper>
      )}
    </div>
  );
};

export default DocumentDetails;
