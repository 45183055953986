import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info, InfoGray, Wrapper, CardsWrapper, CardImg } from './styled-ui';

const BelarusbankComponent = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CardsWrapper>
        <CardImg src="/assets/images/bbCard.png" />
      </CardsWrapper>
      <Info>{t('belarusBankPage.boldText1')}</Info>
      <Info>{t('belarusBankPage.boldText2')}</Info>
      <Info>{t('belarusBankPage.boldText3')}</Info>
      <InfoGray>{t('belarusBankPage.text')}</InfoGray>
    </Wrapper>
  );
};

export default BelarusbankComponent;
