import React from 'react';
import PropTypes from 'prop-types';
import CurrencyPairsTableRow from './TableRow';
import {
  CurrencyPairsLabel,
  CurrencyPairsTableContainer,
  CustomTable,
  CustomTr,
} from './styled-ui';

export default function CurrencyPairsTable({ pairs, t }) {
  return (
    <CurrencyPairsTableContainer>
      <CurrencyPairsLabel>
        {t('merchantPage.availablePairs')}
      </CurrencyPairsLabel>
      <CustomTable>
        <thead>
          <tr>
            <td>{t('merchantPage.pairLabel')}</td>
            <td>{t('merchantPage.buy')}</td>
            <td>{t('merchantPage.sell')}</td>
          </tr>
        </thead>
        <tbody>
          {pairs.buy.length ? (
            pairs.buy.map((item, index) => (
              <CurrencyPairsTableRow
                key={`currencyPair${index}`}
                buyPair={item}
                sellPair={pairs.sell[index]}
              />
            ))
          ) : (
            <CustomTr>
              <td colSpan={3}>loading...</td>
            </CustomTr>
          )}
        </tbody>
      </CustomTable>
    </CurrencyPairsTableContainer>
  );
}

CurrencyPairsTable.propTypes = {
  pairs: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};
