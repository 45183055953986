import { Link } from 'react-router-dom';
import { ReactComponent as WriteUs } from 'assets/images/write-us.svg';
import device from 'device';
import styled, { keyframes } from 'styled-components';
import {
  AccordionItemHeading as AccordionItemHead,
  AccordionItemPanel as AccordionItemPan,
  AccordionItem,
  Accordion,
} from 'react-accessible-accordion';
import { ReactComponent as PlusDisabled } from 'assets/images/icons/PlusDisabledFaq.svg';
import { ReactComponent as Minus } from 'assets/images/icons/MinusFaq.svg';

export const RestrictWidth = styled.div`
  max-width: 3860px;
  margin: 0 auto;
  width: 65%;
  @media ${device.laptopL} {
    width: 70%;
  }
  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
    row-gap: 50px;
    margin: 0 auto 70px;
  }
`;

export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const MainBlock = styled.h2`
  margin: 0 0 79px 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 2.7rem;
  @media ${device.desktop1280} {
    font-size: 2.1rem;
  }
  @media ${device.mobile920} {
    font-size: 2.2rem;
  }
  color: ${props => props.theme.blackText};
  @media ${device.laptopL} {
    text-align: center;
    margin: 0 0 67px 0;
  }
  @media ${device.laptop} {
    text-align: left;
    margin: 0 0 50px 0;
  }
`;

export const QuestionWrapper = styled.div`
  display: block;
`;

export const HeadBlock = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  color: ${props => props.theme.blackText};
  @media ${device.laptopL} {
    font-size: 18px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const TextBlock = styled.div`
  margin-bottom: 50px;
  font-size: 22px;
  color: ${props => props.theme.grayText};
  @media ${device.laptopL} {
    font-size: 15px;
    margin-bottom: 30px;
  }
  @media ${device.laptop} {
    font-size: 14px;
    margin-bottom: 30px;
    display: ${props => props.display || ''};
  }
  > p {
    margin: 0;
  }
  ul {
    margin-left: 2em;
    @media ${device.mobileL} {
      margin-left: 1em;
    }
  }
  figure {
    width: auto !important;
    margin: 0;
  }
  table {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid;
    border-collapse: collapse;
  }
  table td {
    padding: 10px;
  }
  img {
    max-width: 800px;
  }
  @media (max-width: 540px) {
    figure {
      overflow-x: auto;
    }
    img {
      max-width: 300px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
`;

export const ButtonFaq = styled(Link)`
  color: ${props => props.theme.btnColor};
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
}
`;

export const ButtonWriteUsText = styled(Link)`
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  color: ${props => props.theme.btnColor};
  text-decoration: none;
  align-items: center;
`;

export const WriteUsIcon = styled(WriteUs)`
  margin-left: 10px;
  @media ${device.laptopL} {
    width: 28px;
    height: 24px;
    margin-left: 10px;
  }
  @media ${device.laptop} {
    padding-left: 20px;
    margin 0;
    width: 38px;
    height: 33px;
  }
`;

export const AccordionItemHeading = styled(AccordionItemHead)`
  background: ${({ $expanded }) => ($expanded ? '#f6f7f8' : '#ffffff')};
  border-bottom: ${({ $expanded }) =>
    $expanded ? 'none' : '2px solid #f6f7f8'};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  &:hover {
    > div > div > svg {
      line {
        stroke: #2b2c2e;
      }
    }
    background: #f6f7f8;
    border-radius: 8px;
    border-bottom: ${({ $expanded }) => ($expanded ? 0 : '2px solid #fff')};
    border-bottom-left-radius: ${({ $expanded }) => ($expanded ? 0 : '8px')};
    border-bottom-right-radius: ${({ $expanded }) => ($expanded ? 0 : '8px')};
  }
  > div {
    padding: 26px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:focus {
      outline: 0;
    }
    @media ${device.laptop} {
      font-size: 14px;
    }
  }
`;

export const AccordionItems = styled(AccordionItem)`
  font-size: 18px;
  background: #ffffff;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const AccordionWrapper = styled(Accordion)`
  margin-bottom: 50px;
  @media ${device.laptop} {
    margin-bottom: 25px;
  }
`;

const FadeIn = keyframes`
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
`;
export const AccordionItemPanel = styled(AccordionItemPan)`
  font-size: 18px;
  background: ${({ $expanded }) => ($expanded ? '#f6f7f8' : ' #fff')};
  border-radius: 12px;
  padding: 24px;
  animation: ${FadeIn} 0.35s ease;
  border-top-left-radius: ${({ $expanded }) => ($expanded ? 0 : '8px')};
  border-top-right-radius: ${({ $expanded }) => ($expanded ? 0 : '8px')};
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const DownIcon = styled(PlusDisabled)``;

export const UpIcon = styled(Minus)``;
