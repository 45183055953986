import React, { useContext, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ThemeContext } from 'styled-components';
import { WrapInfo } from 'components/Verification/styled-ui';
import {
  DeleteIcon,
  Thumb,
  ThumbImage,
  ThumbInner,
  ThumbsContainer,
  ThumbWrapper,
  Upload,
  UploadContainer,
} from './styled-ui';

const CustomFileInput = props => {
  const themeContext = useContext(ThemeContext);
  const { field, form, accept, propFiles } = props;
  const { name } = field;

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    noClick: true,
    maxSize: 10000000,
    onDrop: acceptedFiles => {
      const filesUpload = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(files.concat(filesUpload));
      form.setFieldValue(field.name, files.concat(filesUpload));
    },
  });

  const deleteImage = index => {
    files.splice(index, 1);
    setFiles(files);
    form.setFieldValue(field.name, files);
  };

  useEffect(() => {
    if (propFiles?.length === 0) {
      setFiles([]);
    }
  }, [propFiles]);

  const thumbs = files.map((file, index) => (
    <ThumbWrapper key={index}>
      <DeleteIcon onClick={() => deleteImage(index)} />

      <Thumb key={file.name}>
        <ThumbInner>
          <ThumbImage src={file.preview} alt="" />
        </ThumbInner>
      </Thumb>
    </ThumbWrapper>
  ));

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <UploadContainer {...getRootProps({ className: 'dropzone' })}>
      <input id={name} name={name} {...getInputProps()} />
      <img src={themeContext.uploadImage} width="40px" height="40px" alt="" />
      <WrapInfo>
        <Upload onClick={open}> Загрузите файл{'\n'} </Upload>или перетащите
        сюда
      </WrapInfo>
      <ThumbsContainer>{thumbs}</ThumbsContainer>
    </UploadContainer>
  );
};

export default CustomFileInput;
