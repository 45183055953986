import styled from 'styled-components';
import device from '../../device';

export const RadioSpan = styled.span`
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 2px solid #c8ccd4;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
  color: ${props => (props.error ? '#F92828' : props.theme.errorBtnColor)};

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2.2px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: ${props =>
      props.error ? '#F92828' : props.theme.errorBtnColor};
    transform: scale(0);
    transition: all 0.2s ease;
    opacity: 0.08;
    pointer-events: none;
  }
`;

export const Label = styled.label`
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 25px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
  padding-bottom: 20px;
  display: block;
  color: ${props => (props.error ? '#F92828' : props.theme.grayText)};

  &:hover ${RadioSpan}:after {
    transform: scale(2.6);
  }
  padding-bottom: ${props => props.black && '10px'};
  color: ${props => props.blackText && props.theme.blackText};
`;

export const RadioInput = styled.input`
  display: none;
  &:checked + ${RadioSpan} {
    border-color: ${props =>
      props.error ? '#F92828' : props.theme.errorBtnColor};
  }

  &:checked + ${RadioSpan}:after {
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
`;

export const LabelCheckbox = styled.label`
  cursor: pointer;
  color: ${props =>
    props.disabled ? props.theme.btnDisabledColor : props.theme.blackText};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
`;

export const CheckMark = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid #c5c5c5;
  border-radius: 2px;
  display: inline-block;
  min-width: 24px;
  box-sizing: border-box;
  margin-right: 10px;
`;

export const InputCheckbox = styled.input`
  display: none;

  :checked + ${CheckMark} {
    background: url(${props => props.theme.checkboxImage}) no-repeat center;
    animation: animation-checkbox 0.4s ease-in-out;
    border: 0;
    width: 24px;
    height: 24px;
    border-radius: 0;
  }

  @keyframes animation-checkbox {
    0% {
      transform: scale(0.8);
    }
    70% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;
