import styled from 'styled-components';
import { Button } from '../../ui-kit/Button';

export const StatusWrapper = styled.div`
  width: max-content;
  color: ${props => props.theme.fullBlackText};
  background-color: ${props => props.theme.grayBg};
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 8px;
`;

export const ResendButton = styled(Button)`
  font-size: 14px;
  background-color: #fff;
  color: ${props => props.theme.linkColor};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
