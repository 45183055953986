/* eslint-disable no-nested-ternary */
import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { pathEq, pathOr } from 'ramda';
import { DateTime } from 'luxon';
import wsConnectInstance from 'ws-connect';
import { getQrSystemWallet } from 'utils/services/request/app';
import { useAxiosState } from 'utils/hooks/axiosHook';
import { CancelOrderButton, ContinueOrderButton } from 'ui-kit/Button';
import { WITHDRAWAL, WT } from 'utils/constants/orderTypes';
import { ALFA, PROVIDER_TYPES } from 'utils/constants/paymentMethods';
import {
  getBlockchainFeeValueOrder,
  getServiceFeeValueOrder,
} from 'utils/fees';
import {
  getFiatCurrencyFromPair,
  isCrypto,
  getCurrencyValue,
} from 'utils/crypto';
import { localeNumberFromOutputNumber } from 'utils/number';
import tickers from 'utils/constants/tickers';
import {
  CHANGED,
  CONFIRMED,
  currencies,
  DECLINED,
  EXPIRED,
  PAUSED,
  PROCESSING,
  REJECTED,
  REQUEST,
} from 'utils/constant';
import Spinner from 'ui-kit/Spinner';
import TimerStatus from 'components/TimerStatus';
import ExchangeOperationStatus from 'components/ExchangeOperationStatus';
import PendingInstruction from 'components/PendingInstruction';
import BankAccountViewer from 'components/ExchangeOperationStatus/BankAccountViewer';
import EripOperationStatus from 'components/EripOperationStatus/EripOperationStatus';
import { pushAnalyticsEvent } from 'components/App/analytics';
import {
  ExchangeOperationDate,
  ExchangeOperationNumber,
} from 'components/ExchangeOperationStatus/styled-ui';

import {
  ActionButtonWrapper,
  DatesWrapper,
  InfoRequestWrapper,
  PendingGrid,
  PendingWrapper,
  Instruction,
  StyledPendingInstruction,
  Title,
} from './styled-ui';
import { IconFirst, IconSecond } from '../PendingInstruction/styled-ui';

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'unMount': {
      if (
        wsConnectInstance.wsConnection.connected &&
        state &&
        state.unsubscribe
      ) {
        state.unsubscribe();
      }
      break;
    }
    default:
  }
}

const redirectAfterPayUrl = {
  [PROVIDER_TYPES.ASSIST]: `&url_return=${window.location.origin}/order-payment`,
  [PROVIDER_TYPES.GAZPROM]: `&back_url_s=${window.location.origin}/order-payment&back_url_f=${window.location.origin}/order-payment`,
};

const acceptedSubmit = async (
  additionalSubmitTimeout,
  cryptoSell,
  refresh,
  setLoadingApproveRequest,
  isSettlementOrder,
  isEripOrder,
  internalToken
) => {
  setLoadingApproveRequest(true);
  if (cryptoSell) {
    try {
      await axios.post(
        `${process.env.REACT_APP_EXCHANGE_URL}/orders/processing`,
        { additionalSubmitTimeout }
      );
      setLoadingApproveRequest(false);
      refresh();
    } catch (e) {
      setLoadingApproveRequest(false);
      refresh();
    }
  } else {
    try {
      const responce = await axios.post(
        `${process.env.REACT_APP_EXCHANGE_URL}/orders/processing`,
        {
          ...((isSettlementOrder || isEripOrder) && { internalToken }),
        }
      );
      if (
        responce.data?.fiatTransaction.link &&
        !isSettlementOrder &&
        !isEripOrder
      ) {
        const returnUrl =
          redirectAfterPayUrl[responce.data?.fiatTransaction.providerType] ||
          '';
        window.location.replace(
          responce.data?.fiatTransaction.link + returnUrl
        );
      } else {
        setLoadingApproveRequest(false);
        refresh();
      }
    } catch (e) {
      setLoadingApproveRequest(false);
      if (e.response && e.response.data.status === 'NOT_FOUND') {
        refresh();
      }
    }
  }
};

const canceledSubmit = async (refresh, setDisabled) => {
  try {
    await axios.post(`${process.env.REACT_APP_EXCHANGE_URL}/orders/rejection`);
    refresh();
  } catch (e) {
    setDisabled(false);
    if (e.response && e.response.data.status === 'NOT_FOUND') {
      refresh();
    }
  }
};

const PendingStatus = ({ data, refresh }) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const [loadingApproveRequest, setLoadingApproveRequest] = useState(false);
  const [wsSubscription, dispatch] = useReducer(reducer, null);
  const [additionalSub, setAdditionalSub] = useState(false);
  const [internalToken, setInternalToken] = useState(null);
  const [addressChecked, setAddressChecked] = useState(false);
  const [publicOfferChecked, setPublicOfferChecked] = useState(false);
  const [additionalSubmitTimeout, setAdditionalSubmitTimeout] = useState(false);
  const [queryQrCode, setQueryQrCode] = useState('');
  const [walletQrData, setWalletQrData] = useState(null);

  const isSettlementOrder = pathEq(
    ['fiatTransaction', 'providerType'],
    PROVIDER_TYPES.SETTLEMENT,
    data
  );

  const isEripOrder = pathEq(
    ['fiatTransaction', 'providerType'],
    PROVIDER_TYPES.ERIP,
    data
  );

  const handleSocketComing = message => {
    if (message.body) {
      const messageBody = JSON.parse(message.body);
      if (messageBody.type === 'ORDER_STATUS_CHANGED') {
        // TODO handle socket refresh only on non-processing requests: loadingApproveRequest!==true
        // TODO now have issue with getting actual fetching state on new message
        // refresh();
      }
    }
  };

  useEffect(() => {
    if (wsConnectInstance.wsConnection.connected && !wsSubscription) {
      const subscription = wsConnectInstance.wsConnection.subscribe(
        '/user/notifications/orders/events',
        handleSocketComing
      );
      dispatch({ type: 'set', payload: subscription });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsConnectInstance.wsConnection.connected, additionalSub]);

  useEffect(() => {
    setTimeout(() => setAdditionalSub(true), 1000);
    return () => dispatch({ type: 'unMount' });
  }, []);

  const {
    creationDate,
    exchangeOperation: {
      currencyPair,
      plainRatio: ratio,
      outputAsset,
      inputAsset,
    },
    status,
    number,
    cryptoTransaction,
    fiatTransaction,
    serverDate,
    promoCode,
  } = data;

  useEffect(() => {
    if (isEripOrder) {
      setQueryQrCode(`link=${fiatTransaction.link}`);
    } else {
      setQueryQrCode(`currency=${cryptoTransaction.currency}`);
    }
  }, [fiatTransaction, cryptoTransaction]);

  const walletQr = useAxiosState(
    queryQrCode ? getQrSystemWallet(queryQrCode) : {},
    [queryQrCode]
  );

  const cryptoBuy = isCrypto(currencyPair.fromCurrency);

  useEffect(() => {
    if (cryptoBuy) {
      if (!walletQr.fetching && walletQr.loaded) {
        setWalletQrData(`${walletQr.data}&chf=bg,s,FFFFFF00`);
      }
    }
    if (isEripOrder) {
      if (!walletQr.fetching && walletQr.loaded) {
        setWalletQrData(`${walletQr.data}&chf=bg,s,FFFFFF00`);
      }
    }
  }, [cryptoBuy, fiatTransaction, walletQr]);

  const submitButtonTitle = isEripOrder
    ? t('pending.done')
    : data.orderType === WT
    ? t('pending.confirm')
    : t('pending.exchange');

  const handleSetDisabled = useCallback(
    variable => {
      if (!disabled) {
        refresh();
      }
      if (disabled !== variable) {
        setDisabled(variable);
      }
    },
    [refresh, disabled]
  );

  const acceptedSubmitHandler = useCallback(() => {
    acceptedSubmit(
      additionalSubmitTimeout,
      cryptoBuy,
      refresh,
      setLoadingApproveRequest,
      isSettlementOrder,
      isEripOrder,
      internalToken
    );

    if (data?.fiatTransaction?.paymentToken) {
      localStorage.setItem(
        'last_payment_id',
        data.fiatTransaction.paymentToken
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, internalToken, additionalSubmitTimeout]);
  const canceledSubmitHandler = useCallback(() => {
    const pairCurrencies = `${data?.exchangeOperation?.currencyPair?.fromCurrency}_${data?.exchangeOperation?.currencyPair?.toCurrency}`;
    setDisabled(true);
    canceledSubmit(refresh, setDisabled);
    pushAnalyticsEvent(`exchange_canceled_${pairCurrencies}`);
  }, [refresh, data?.exchangeOperation?.currencyPair]);

  const operationDateLuxon = DateTime.fromISO(creationDate).toFormat(
    'dd.MM.yyyy HH:mm:ss'
  );

  const isInternalTokenNecessary = isSettlementOrder && !cryptoBuy;
  const provider = pathOr(null, ['fiatTransaction', 'providerType'], data);
  const fiatCurrencyFromPair = getFiatCurrencyFromPair(currencyPair);

  const isDisabledButton =
    disabled ||
    loadingApproveRequest ||
    (isInternalTokenNecessary && !internalToken) ||
    (!isCrypto(currencyPair.fromCurrency) && !addressChecked) ||
    (!isCrypto(currencyPair.fromCurrency) &&
      provider === ALFA &&
      !publicOfferChecked);

  const serviceFee = getServiceFeeValueOrder(data, fiatCurrencyFromPair);

  return (
    <PendingWrapper>
      <PendingGrid extend={cryptoBuy}>
        <>
          <InfoRequestWrapper>
            <ExchangeOperationNumber>
              {t('pending.exchangeNumber', { number })}
            </ExchangeOperationNumber>
            <DatesWrapper>
              <ExchangeOperationDate>
                {t('pending.createdAt', { operationDateLuxon })}
              </ExchangeOperationDate>
              {status === REQUEST && (
                <TimerStatus
                  operationDate={creationDate}
                  currencyPair={currencyPair}
                  setDisabled={setDisabled}
                  serverDate={serverDate}
                  refresh={refresh}
                  isEripOrder={isEripOrder}
                  t={t}
                />
              )}
            </DatesWrapper>
          </InfoRequestWrapper>
          <ExchangeOperationStatus
            isSettlementOrder={isSettlementOrder}
            operationNumber={number}
            status={status}
            ratio={ratio}
            operationDate={creationDate}
            currencyPair={currencyPair}
            calculation={{ inputAsset, outputAsset }}
            paymentTokenId={data?.fiatTransaction?.paymentToken}
            provider={provider}
            serverDate={serverDate}
            address={cryptoTransaction?.toAddress}
            setDisabled={handleSetDisabled}
            blockchainFee={`${getBlockchainFeeValueOrder(
              data
            )} ${fiatCurrencyFromPair}`}
            serviceFee={serviceFee}
            feePaymentEnabledByClient={
              cryptoTransaction.feePaymentEnabledByClient
            }
            isPending
            promoCode={promoCode}
            isEripOrder={isEripOrder}
            eripNumber={fiatTransaction.internalToken}
            addressChecked={addressChecked}
            setAddressChecked={setAddressChecked}
            publicOfferChecked={publicOfferChecked}
            setPublicOfferChecked={setPublicOfferChecked}
          />
          {cryptoBuy && data.orderType !== WITHDRAWAL ? ( // all time cryptobuy
            <>
              <PendingInstruction
                additionalSubmitTimeout={additionalSubmitTimeout}
                setAdditionalSubmitTimeout={setAdditionalSubmitTimeout}
                amount={inputAsset}
                address={cryptoTransaction?.toAddress}
                type={currencyPair.fromCurrency}
                walletQrData={walletQrData}
                title={t('pending.title')}
                instruction={t('pending.instruction1', {
                  amount: localeNumberFromOutputNumber(
                    inputAsset,
                    currencyPair.fromCurrency
                  ),
                  currencyDescription: tickers[currencyPair.fromCurrency]
                    ? `${getCurrencyValue(currencyPair.fromCurrency)} (${
                        tickers[currencyPair.fromCurrency]
                      })`
                    : getCurrencyValue(currencyPair.fromCurrency),
                })}
                t={t}
              />
            </>
          ) : null}
          {isSettlementOrder && !isCrypto(currencyPair.fromCurrency) && (
            <StyledPendingInstruction>
              <Title>{t('bankAccountViewer.title')}</Title>
              <Instruction>
                <IconFirst />
                {t('bankAccountViewer.labelInstruction1')}
              </Instruction>
              <BankAccountViewer
                setInternalToken={setInternalToken}
                fromCurrency={currencyPair.fromCurrency}
                t={t}
              />
              <Instruction>
                <IconSecond />
                {t('bankAccountViewer.labelInstruction2')}
              </Instruction>
            </StyledPendingInstruction>
          )}
          {isEripOrder && !isCrypto(currencyPair.fromCurrency) && (
            <EripOperationStatus
              inputAsset={inputAsset}
              fromCurrency={currencyPair.fromCurrency}
              walletQrData={walletQrData}
              setInternalToken={setInternalToken}
              address={fiatTransaction.internalToken}
              t={t}
            />
          )}
        </>
        <ActionButtonWrapper>
          <CancelOrderButton
            orderInRow
            onClick={canceledSubmitHandler}
            disabled={loadingApproveRequest || disabled}
          >
            {disabled ? <Spinner /> : t('pending.cancelOrder')}
          </CancelOrderButton>

          <ContinueOrderButton
            onClick={acceptedSubmitHandler}
            disabled={isDisabledButton}
          >
            {loadingApproveRequest ? <Spinner /> : submitButtonTitle}
          </ContinueOrderButton>
        </ActionButtonWrapper>
      </PendingGrid>
    </PendingWrapper>
  );
};

PendingStatus.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.oneOf([
      REQUEST,
      PROCESSING,
      CONFIRMED,
      REJECTED,
      EXPIRED,
      DECLINED,
      CHANGED,
      PAUSED,
    ]).isRequired,
    modificationDate: PropTypes.string.isRequired,
    address: PropTypes.string,
    exchangeOperation: PropTypes.shape({
      currencyPair: PropTypes.shape({
        fromCurrency: PropTypes.oneOf(currencies).isRequired,
        toCurrency: PropTypes.oneOf(currencies).isRequired,
      }).isRequired,
      ratio: PropTypes.number.isRequired,
      inputAsset: PropTypes.number.isRequired,
      outputAsset: PropTypes.number.isRequired,
    }).isRequired,
    cryptoTransaction: PropTypes.shape({
      transactionHash: PropTypes.string,
      toAddress: PropTypes.string,
      status: PropTypes.string,
    }),
    fiatTransaction: PropTypes.shape({
      status: PropTypes.string,
      card: PropTypes.shape({
        id: PropTypes.string.isRequired,
        maskedPan: PropTypes.string.isRequired,
        expiryDate: PropTypes.string.isRequired,
        cardHolderName: PropTypes.string.isRequired,
      }),
    }),
    creationDate: PropTypes.string.isRequired,
  }).isRequired,
  refresh: PropTypes.func.isRequired,
};

export default PendingStatus;
