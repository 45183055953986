import styled from 'styled-components';
import device from 'device';

export const ProfileView = styled.div`
  background-color: ${props => props.theme.fullWhite};
`;

export const PaginationContainer = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  list-style: none;
`;

export const PageLi = styled.li`
  margin: 0 10px;
  @media ${device.tablet} {
    margin: 0 2px;
  }
`;

export const PageButton = styled.button`
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.fullBlackText : theme.grayText};
  @media ${device.mobileL} {
    padding: 0 10px;
  }
`;

export const OperationWrapper = styled.div`
  padding: 35px 15px;
`;
