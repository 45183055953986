import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { Label } from 'ui-kit/DropdownInput/styled-ui';
import TextareaWrapper from './styled-ui';

const CustomTextarea = ({ field, form, label, onBlur, ...props }) => {
  const handleBlur = event => {
    const {
      target: { value },
    } = event;

    if (value !== '' && !!onBlur) {
      onBlur(event);
    }
  };

  const { id } = props;
  const { name, value, ...fieldProps } = field;
  const error = getIn(form.errors, name);
  const isTouched = getIn(form.touched, name);
  const isError = !!error && isTouched;

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <TextareaWrapper>
        <TextareaAutosize
          name={name}
          value={value === null ? '' : value}
          {...fieldProps}
          {...props}
          onBlur={handleBlur}
          error={isError ? isError.toString() : ''}
          className="textareaAutoResize"
        />
      </TextareaWrapper>
    </>
  );
};

CustomTextarea.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,

  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomTextarea;
