import styled from 'styled-components';
import device from 'device';

export const Wrapper = styled.div`
  display: flex;
  line-height: 18px;
  flex-wrap: wrap;
  margin: 15px auto;
  align-items: center;
  font-size: 0.8rem;
  height: ${({ isEmpty }) => isEmpty && '18px'};
  justify-content: center;
  color: ${({ theme }) => theme.grayCharge};
  max-width: fit-content;
  @media ${device.mobile920} {
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
  },
`;

export const Charge = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${({ isBonus, theme }) =>
    isBonus ? theme.linkColor : theme.grayCharge};
`;
