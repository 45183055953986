import styled from 'styled-components';

export const CodeInputWrapper = styled.span`
  position: relative;
`;

export const Input = styled.input.attrs(() => ({
  type: 'number',
  pattern: '[0-9]*',
  inputtype: 'numeric',
}))`
  width: 0.82em;
  line-height: 1;
  margin-left: 14px;
  padding: 15px 12px;
  font-size: 1.5rem;
  text-align: center;
  appearance: textfield;
  -webkit-appearance: textfield;
  caret-color: transparent;
  border: 1px solid
    ${({ theme, value }) =>
      value === 0 || value ? theme.btnColor : theme.grayBorder};
  color: ${({ theme }) => theme.fullBlackText};
  border-radius: 8px;
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${({ theme, value }) =>
        value === 0 || value ? theme.btnColor : '#acceff'};
    background: ${({ value }) =>
      !(value === 0 || value) &&
      'linear-gradient(0deg, #acceff 16px, white 2px);'}
    background-clip: content-box;
    @media (max-width: 400px) {
      background: ${({ value }) =>
        !(value === 0 || value) &&
        'linear-gradient(0deg, #acceff 13px, white 2px);'}
      background-clip: content-box;
    }
  }
  &:first-child {
    margin-left: 0;
  }
  &:nth-child(4) {
    margin-left: 28px;
    @media (max-width: 500px) {
      margin-left: 14px;
    }
    @media (max-width: 340px) {
      margin-left: 10px;
    }
  }
  @media (max-width: 500px) {
    margin-left: 7px;
    padding: 12px 9px;
  }
  @media (max-width: 340px) {
    margin-left: 5px;
  }
`;
