import LoadingPlaceholder from 'ui-kit/LoadingPlaceholder';
import React from 'react';
import Select from 'react-select';
import { useAxiosStateWithRefetch } from 'utils/hooks/axiosHook';
import { getSystemBankAccountsOptions } from 'utils/services/request/card';
import { Wrapper, SelectOption, SelectOptionText } from './styled-ui';

import customStyle from './select-style';

const CardSelect = ({ setInternalToken, fromCurrency, t }) => {
  const bankAccountsInformation = useAxiosStateWithRefetch(
    getSystemBankAccountsOptions(fromCurrency)
  );

  const onChangeBankAccount = option => {
    const { id } = bankAccountsInformation.data.find(
      it => it.id === option.value
    );
    setInternalToken(id);
  };

  if (
    (!bankAccountsInformation.data && !bankAccountsInformation.error) ||
    !bankAccountsInformation.loaded ||
    bankAccountsInformation.fetching
  ) {
    return (
      <Wrapper>
        <div>
          <LoadingPlaceholder options={{ height: '60px' }} />
        </div>
      </Wrapper>
    );
  }

  if (!bankAccountsInformation.data || bankAccountsInformation.error) {
    return null;
  }

  // eslint-disable-next-line no-shadow
  const ValueComponent = ({ bankName }) => (
    <SelectOption>
      <SelectOptionText>{bankName}</SelectOptionText>
    </SelectOption>
  );

  const selectOptions =
    // eslint-disable-next-line no-shadow
    bankAccountsInformation.data
      ? bankAccountsInformation.data.map(({ id, bankName }) => ({
          label: ValueComponent({ id, bankName }),
          value: id,
        }))
      : [];

  return (
    <Wrapper>
      <Select
        name="bankAccounts"
        onChange={onChangeBankAccount}
        options={selectOptions}
        styles={customStyle()}
        error={false}
        isSearchable={false}
        placeholder={t('bankAccountViewer.chooseBankAccount')}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            borderColor: '1px solid red',
          },
        })}
      />
    </Wrapper>
  );
};

export default CardSelect;
