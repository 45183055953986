import React, { useEffect } from 'react';
import { Field } from 'formik';
import PhoneInput from 'ui-kit/PhoneInput';
import CountryCodeSelect from 'ui-kit/CountryCodeSelect';
import Dropdown from 'ui-kit/Dropdown';
import Error from 'ui-kit/Error';
import Spinner from 'ui-kit/Spinner';
import { Button } from 'ui-kit/Button';
import GENDER from 'utils/constants/gender';
import { getInitialCountryCode, getInputMask } from 'components/Auth/helpers';

import {
  DropdownContainer,
  ButtonWrapper,
  FormGroup,
  UserDetailsTitle,
  PhoneContainer,
  UserDetailsSubTitle,
} from './styled-ui';

const UserDetailsModal = ({
  t,
  user,
  setCountryCode,
  setGender,
  isUserNotHavePhone,
  isUserNotHaveGender,
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
  isSubmitting,
  setFieldValue,
  setErrors,
}) => {
  const selectOptions = GENDER.map(it => ({
    value: it.value,
    label: it.label,
  }));

  const resetInput = () => {
    setFieldValue('phone', '');
    setFieldValue('gender', '');
    setFieldValue('countryCode', getInitialCountryCode(user?.data?.phone));
    setErrors('phone', '');
  };

  useEffect(() => {
    setCountryCode(values.countryCode);
    setGender(values.gender);
  }, [values.countryCode, values.gender]);

  useEffect(() => {
    return () => {
      resetInput();
    };
  }, []);

  const isDisabledButton =
    isSubmitting ||
    (isUserNotHavePhone && !values.phone) ||
    (isUserNotHaveGender && !values.gender);

  return (
    <>
      <UserDetailsTitle>
        {t('profile.cryptoCardActions.createCryptoCard')}
      </UserDetailsTitle>
      <UserDetailsSubTitle>
        {t('profile.cryptoCardActions.userDetails.info')}
      </UserDetailsSubTitle>
      <FormGroup>
        {isUserNotHavePhone && (
          <PhoneContainer>
            <Field
              id="countryCode"
              type="text"
              name="countryCode"
              onChange={handleChange}
              onBlur={handleBlur}
              component={CountryCodeSelect}
              t={t}
            />
            <Field
              id="phone"
              type="text"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              resetInput={() => {}}
              component={PhoneInput}
              placeholder={getInputMask(values.countryCode)}
              label={t('profile.inputLabels.phone')}
              autoComplete="off"
            />
            {errors?.phone && touched?.phone && (
              <Error isSupportPage top={85}>
                {errors.phone}
              </Error>
            )}
          </PhoneContainer>
        )}
        {isUserNotHaveGender && (
          <DropdownContainer>
            <Dropdown
              label={t('profile.cryptoCardActions.userDetails.gender')}
              name="gender"
              value={values.gender}
              setFieldValue={(name, value) => setFieldValue(name, value)}
              selectOptions={selectOptions}
              labelInside
            />
          </DropdownContainer>
        )}
        <ButtonWrapper
          marginTop={isUserNotHaveGender ? '20px' : '40px'}
          display="block"
        >
          <Button type="submit" disabled={isDisabledButton}>
            {isSubmitting ? (
              <Spinner />
            ) : (
              t('profile.cryptoCardActions.userDetails.confirm')
            )}
          </Button>
        </ButtonWrapper>
      </FormGroup>
    </>
  );
};

export default UserDetailsModal;
