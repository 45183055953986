import axios from 'axios';

export const getUserNotificationsRequest = page =>
  axios.post(
    `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/events/search?page=${page}&size=5&sort=creationDate,DESC`,
    { viewed: false }
  );

export const getUserPageNotificationsRequest = ({ page }) => ({
  method: 'post',
  url: `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/events/search?page=${page}&size=10&sort=creationDate,DESC`,
  data: { viewed: null },
});

export const readNotificationByIdRequest = idToRemove => {
  axios.put(
    `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/events/viewed`,
    { eventsIds: [idToRemove] }
  );
};

export const readAllNotifications = () => {
  axios.put(
    `${process.env.REACT_APP_NOTIFICATION_URL}/notifications/events/viewed/all`
  );
};

export const getNotifications = (page, dispatch) => {
  dispatch({ type: 'FETCH_NOTIFICATIONS', page });
  getUserNotificationsRequest(page)
    .then(({ data }) => {
      dispatch({
        type: 'FETCH_NOTIFICATIONS_SUCCESS',
        data: {
          notifications: data.content,
          totalPages: data.totalPages,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: 'FETCH_NOTIFICATIONS_ERROR',
        error: 'Что-то пошло не так',
      });
    });
};
export const removeAllNotifications = dispatch => {
  dispatch({ type: 'REMOVE_ALL_NOTIFICATIONS' });
  readAllNotifications();
};

export const readNotification = (idToRemove, dispatch) => {
  dispatch({ type: 'REMOVE_NOTIFICATION_BY_ID', idToRemove });
  readNotificationByIdRequest(idToRemove);
};
