import React from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow-down.svg';
import {
  WrapperDropdownInput,
  LabelDropdownInput,
  ValuesWrapper,
  SelectWrapper,
  LabelWrapper,
} from './styled-ui';
import customStyle from './select-style';

const DropdownInput = ({
  name,
  value,
  label,
  selectOptions,
  setFieldValue,
  labelInside,
}) => {
  const onChangeSelect = option => {
    setFieldValue(name, option.value);
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <WrapperDropdownInput>
      <LabelWrapper>
        <LabelDropdownInput labelInside={labelInside}>
          {label}
        </LabelDropdownInput>
      </LabelWrapper>
      <ValuesWrapper labelInside={labelInside}>
        <SelectWrapper labelInside={labelInside}>
          <Select
            name={name}
            value={selectOptions.find(option => option.value === value)}
            onChange={onChangeSelect}
            options={selectOptions}
            styles={customStyle()}
            error={false}
            isSearchable={false}
            placeholder=""
            components={{ DropdownIndicator }}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                borderColor: '1px solid red',
              },
            })}
          />
        </SelectWrapper>
      </ValuesWrapper>
    </WrapperDropdownInput>
  );
};

DropdownInput.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default DropdownInput;
