import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const RedirectWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <Redirect to={to + search} {...props}>
      {children}
    </Redirect>
  );
};

export default RedirectWithQuery;
