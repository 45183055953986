import styled from 'styled-components';
import device from 'device';
import { ReactComponent as IconInfo } from 'assets/images/icons/info.svg';
import { ReactComponent as FirstIcon } from 'assets/images/icons/first.svg';
import { ReactComponent as SecondIcon } from 'assets/images/icons/second.svg';
import { ReactComponent as RectangleQr } from 'assets/images/RectangleQr.svg';

export const StyledPendingInstruction = styled.div`
  grid-column: 1 / -1;
  padding-top: 30px;
  @media ${device.tablet} {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

export const Title = styled.div`
  line-height: 32px;
  padding-bottom: 30px;
  box-sizing: border-box;
  color: ${props => props.theme.blackText};
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Instruction = styled.div`
  color: ${props => props.theme.blackText};
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: ${({ margin }) => margin || '20px 0 15px 30px'};
  background-color: ${props => props.theme.grayBg};
  border: 1px solid #e5ebf1;
  border-radius: 8px;
  @media ${device.tablet} {
    margin-left: 10px;
  }
`;

export const Wallet = styled.div`
  border-radius: 8px;
  flex-grow: 1;
  color: ${props => props.theme.blackText};
  font-size: 14px;
  line-height: 19px;
  padding: 20px;
  @media ${device.mobile920} {
    line-height: 17px;
    margin-right: 0;
    text-overflow: ellipsis;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Hint = styled.div`
  color: #c6384c;
  font-size: 14px;
  padding-bottom: 20px;
  padding-left: 30px;
  display: flex;
  white-space: pre-line;
  @media ${device.tablet} {
    padding-left: 10px;
  }
`;

export const QRCode = styled.div`
  position: absolute;
  height: 180px;
  width: 180px;
  left: 12px;
  top: 12px;
  display: flex;
  justify-content: center;
`;

export const AddressInfo = styled.div`
  margin: ${({ margin }) => margin || '24px 0'};
  display: flex;
  border-radius: 8px;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const AddressTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 27px;
  margin-left: ${({ marginLeft }) => marginLeft || '15px'};
  border-left: ${({ borderLeft }) => borderLeft || '1px solid #dcdcdc'};
  @media ${device.tablet} {
    padding: 0 10px;
  }
`;

export const IconFirst = styled(FirstIcon)`
  margin-right: 15px;
  min-width: 30px;
`;

export const IconSecond = styled(SecondIcon)`
  margin-right: 15px;
  min-width: 30px;
`;

export const QrRectangle = styled(RectangleQr)`
  height: 204px;
  width: 204px;
`;

export const WrapperQr = styled.div`
  position: relative;
  @media ${device.tablet} {
    display: none;
  }
`;

export const InfoIcon = styled(IconInfo)`
  cursor: pointer;
`;

export const InnerHover = styled.div`
  white-space: pre-line;
  display: none;
  position: absolute;
  width: 385px;
  height: max-content;
  color: #aeb7c6;
  text-align: left;
  font-size: 14px;
  background: #ffffff;
  padding: 5px 5px;
  box-shadow: 0px 6px 20px rgba(16, 58, 121, 0.1);
  border-radius: 10px;
  @media ${device.laptop} {
    margin: 10px;
    width: fit-content;
    font-size: 12px;
    left: 0;
  }
`;

export const LabelText = styled.span`
  color: ${props => props.theme.blackText};
  cursor: pointer;
  a {
    text-decoration: none;
  }
  text-align: ${props => props.textalign || 'inherit'};
  padding: ${props => props.padding || '0'};
`;

export const WrapperHover = styled.button`
  cursor: pointer;
  &:hover {
    ${InnerHover} {
      display: block;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 15px 0 0 45px;
`;
