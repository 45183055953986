export function pushGA(pathname) {
  if (process.env?.REACT_APP_GA_KEY && window?.gtag) {
    window.gtag('event', pathname);
  }
}

export function pushYM(pathname) {
  if (process.env?.REACT_APP_YM_KEY && window?.ym) {
    window.ym(process.env.REACT_APP_YM_KEY, 'hit', pathname);
  }
}

export function pushUserId(userId) {
  if (process.env?.REACT_APP_GA_KEY && window?.gtag) {
    window.gtag('event', 'user_id', { userId });
  }
  if (process.env.REACT_APP_YM_KEY && window.ym) {
    window.ym(process.env.REACT_APP_YM_KEY, 'userParams', { UserID: userId });
  }
}

export const pushAnalyticsWithParams = (targetName, params = {}) => {
  if (process.env?.REACT_APP_GA_KEY && window?.gtag) {
    window.gtag('event', targetName, params);
  }
  if (process.env?.REACT_APP_YM_KEY && window?.ym) {
    window.ym(process.env.REACT_APP_YM_KEY, 'reachGoal', targetName, params);
  }
};

export const pushAnalyticsEvent = targetName => {
  if (process.env?.REACT_APP_GA_KEY && window?.gtag) {
    window.gtag('event', targetName);
  }
  if (process.env?.REACT_APP_YM_KEY && window?.ym) {
    window.ym(process.env.REACT_APP_YM_KEY, 'reachGoal', targetName);
  }
};

export const bootAnalytics = () => {
  const { pathname } = window.location;
  pushGA(pathname);
  pushYM(pathname);
};

export const pushAnalytics = (action, location) => {
  if (action === 'PUSH' || action === 'POP') {
    const { pathname } = location;
    pushGA(pathname);
    pushYM(pathname);
  }
};
