import styled from 'styled-components';

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 0;
`;

export const GroupLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const CheckItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 14px 0;

  label {
    margin: 13px 15px 13px 0;
  }
`;
