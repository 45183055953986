import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import { Button, CancelChangesButton } from 'ui-kit/Button';
import Input from 'ui-kit/InputRedesign';
import Error from 'ui-kit/Error';
import WithdrawalDataFormSchema from './validation';
import {
  FormGrid,
  FormGroup,
  Header,
  ButtonWrapper,
  WrapperInput,
} from './styled-ui';

const MAX_LENGTH = {
  identificationNumber: 12,
  account: 34,
  bankAccountName: 64,
  bankAccountCode: 32,
};

const REGEX = {
  identificationNumber: /[^0-9]/g,
  account: /[^A-Za-z0-9]/g,
  bankAccountName: /^\s+|[^A-Za-zА-Яа-я0-9-"\s]/gi,
  bankAccountCode: /^\s+|[^A-Za-z0-9\s]/gi,
};

const WithdrawalDataForm = ({ t, initialValues, onSubmit, hideModal }) => (
  <>
    <Header>{t('profile.referralProgram.withdrawalDataForm.title')}</Header>
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={WithdrawalDataFormSchema(t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <FormGrid onSubmit={handleSubmit}>
          <FormGroup>
            {Object.keys(initialValues).map(key => (
              <Fragment key={key}>
                <WrapperInput>
                  <Field
                    id={key}
                    name={key}
                    type={key}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[key].replace(REGEX[key], '')}
                    component={Input}
                    label={t(
                      `profile.referralProgram.withdrawalDataForm.${key}`
                    )}
                    placeholder={t(
                      `profile.referralProgram.withdrawalDataForm.placeholders.${key}`
                    )}
                    maxLength={MAX_LENGTH[key]}
                  />
                </WrapperInput>
                {errors[key] && touched[key] && <Error>{errors[key]}</Error>}
              </Fragment>
            ))}
          </FormGroup>
          <ButtonWrapper>
            <Button type="submit" disabled={isSubmitting}>
              {t('profile.referralProgram.withdrawalDataForm.submitButton')}
            </Button>
            <CancelChangesButton type="button" onClick={hideModal}>
              {t('profile.referralProgram.withdrawalDataForm.cancelButton')}
            </CancelChangesButton>
          </ButtonWrapper>
        </FormGrid>
      )}
    </Formik>
  </>
);

export default WithdrawalDataForm;
