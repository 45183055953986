import styled from 'styled-components';
import device from 'device';

export const Container = styled.div`
  margin: 0 50px;
  border-top: 1px solid #dfdfdf;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.laptop} {
    margin: 0 25px;
  }
`;

export const LogosWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoIcon = styled.img`
  height: 35px;
  margin: 0 20px;
`;
