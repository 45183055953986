import { pushAnalyticsEvent } from 'components/App/analytics';

const VERIFICATION_STEPS = {
  IDENTITY: 'IDENTITY',
  PROOF_OF_RESIDENCE: 'PROOF_OF_RESIDENCE',
  SELFIE: 'SELFIE',
};

const pushAnalyticsEventByVerificationStep = step => {
  switch (step) {
    case VERIFICATION_STEPS.IDENTITY: {
      return pushAnalyticsEvent('verification_step1');
    }
    case VERIFICATION_STEPS.PROOF_OF_RESIDENCE: {
      return pushAnalyticsEvent('verification_step2');
    }
    case VERIFICATION_STEPS.SELFIE: {
      return pushAnalyticsEvent('verification_step3');
    }
    default: {
      return null;
    }
  }
};

export const loadWebSdk = callback => {
  const existingScript = document.getElementById('kyc-script');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      'https://static.cyberity.ru/idensic/static/sns-websdk-builder.js';
    script.id = 'kyc-script';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const launchWebSdk = (
  accessToken,
  applicantEmail,
  applicantPhone,
  setDocsSubmitted,
  updateClientData
) => {
  const sumsubWebSdkInstance = window.snsWebSdk
    .init(accessToken, () => null)
    .withBaseUrl('https://api.cyberity.ru')
    .withConf({
      lang: localStorage.getItem('sumsub_lang') || 'ru',
      email: applicantEmail,
      phone: applicantPhone,
    })
    .on('idCheck.onApplicantSubmitted', () => {
      pushAnalyticsEvent('verification_completed');
      setDocsSubmitted(true);
      updateClientData();
    })
    .on('idCheck.onStepInitiated', step => {
      pushAnalyticsEventByVerificationStep(step?.idDocSetType);
    })
    .build();

  sumsubWebSdkInstance.launch('#cyberity-websdk-container');
};
