import React from 'react';
import Header from '../Header';

import { NotFoundContainer, InsideWrapperNotFoundContainer } from './styled-ui';

const NotFoundLayout = ({ children }) => (
  <NotFoundContainer>
    <InsideWrapperNotFoundContainer>
      <Header is404 />
      {children}
    </InsideWrapperNotFoundContainer>
  </NotFoundContainer>
);

export default NotFoundLayout;
