const validationFieldsValue = t => values => {
  let errors = {};

  if (!values.promo) {
    errors = {
      ...errors,
      promo: t('promocode.errors.emptyField'),
    };
  }
  return errors;
};

export default validationFieldsValue;
